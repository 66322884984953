import { ApolloCache, MutationUpdaterFunction } from '@apollo/client'
import getCommunityUserQuery from 'GraphQL/Queries/CommunityUser/getCommunityUser.graphql'

import forEach from 'lodash/forEach'

interface ICommunityUser {
  userId: string
  communityUserId: string
  communityId: string
}

type IGetCommunityUserTagsUpdater = MutationUpdaterFunction<
  Pick<MainSchema.Mutation, 'connectUsersToTags'>, // TData
  {}, // TVariables
  any, // TContext
  ApolloCache<any> // TCache
>

export function getCommunityUserTagsUpdater({
  communityUsers,
  tags,
}: {
  communityUsers: ICommunityUser[]
  tags: MainSchema.Tag[]
}): IGetCommunityUserTagsUpdater {
  return (cache, { data }) => {
    if (!data?.connectUsersToTags) return

    forEach(communityUsers, async communityUser => {
      // TODO: Transiton from userId to communityUserId
      const { userId, communityId } = communityUser

      const queryGetCommunityUser = {
        query: getCommunityUserQuery,
        variables: {
          userId,
          communityId,
        },
      }

      const getCommunityUserData = cache.readQuery<
        Pick<MainSchema.Query, 'getCommunityUser'>,
        MainSchema.QueryGetCommunityUserArgs
      >(queryGetCommunityUser)

      if (!getCommunityUserData?.getCommunityUser) return

      const combinedTags: MainSchema.Tag[] = [
        ...(getCommunityUserData.getCommunityUser.tags || []),
        ...tags,
      ]

      const tagsMap = new Map<string, MainSchema.Tag>()

      for (const tag of combinedTags) {
        if (!tagsMap.has(tag.id)) {
          tagsMap.set(tag.id, tag)
        }
      }

      const mergedTags: MainSchema.Tag[] = Array.from(tagsMap.values())

      cache.writeQuery<
        Pick<MainSchema.Query, 'getCommunityUser'>,
        MainSchema.QueryGetCommunityUserArgs
      >({
        ...queryGetCommunityUser,
        data: {
          ...getCommunityUserData,
          getCommunityUser: {
            ...getCommunityUserData.getCommunityUser,
            tags: mergedTags,
          },
        },
      })
    })
  }
}

export function getCommunityUserTagsRemoveUpdater({
  id,
  userId,
  communityUserId,
  communityId,
  tagIds,
}: {
  id: string
  userId: string
  communityUserId: string
  communityId: string
  tagIds: any[]
}): MutationUpdaterFunction<
  Pick<MainSchema.Mutation, 'disconnectUsersFromTags'>, // TData
  {}, // TVariables
  any, // TContext
  ApolloCache<any> // TCache
> {
  return (cache, { data }) => {
    if (!data?.disconnectUsersFromTags) return

    const queryGetCommunityUser = {
      query: getCommunityUserQuery,
      variables: {
        userId,
        communityId,
      },
    }

    const getCommunityUserData = cache.readQuery<
      Pick<MainSchema.Query, 'getCommunityUser'>,
      MainSchema.QueryGetCommunityUserArgs
    >(queryGetCommunityUser)

    const filteredTags = getCommunityUserData?.getCommunityUser?.tags?.filter(
      tag => !tagIds.includes(tag.id),
    )

    if (
      getCommunityUserData?.getCommunityUser &&
      (id || userId || communityUserId) &&
      communityId
    ) {
      cache.writeQuery<
        Pick<MainSchema.Query, 'getCommunityUser'>,
        MainSchema.QueryGetCommunityUserArgs
      >({
        ...queryGetCommunityUser,
        data: {
          ...getCommunityUserData,
          getCommunityUser: {
            ...getCommunityUserData.getCommunityUser,
            tags: filteredTags,
          },
        },
      })
    }
  }
}
