import React from 'react'

import { Tag, Text } from 'Components/UI'

import * as Styled from './TagList.styles'

export interface ITag {
  id: string
  name: string
  colorKind: string
  renderAfter?: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

export interface ITagListProps {
  label: React.ReactNode
  items: ITag[]
}

const TagList = (props: ITagListProps) => {
  return (
    <>
      <Text header header4 mb={1}>
        {props.label}
      </Text>

      <Styled.TagsContainer mb={4}>
        {props.items.map(item => (
          <Tag
            colorKind={item.colorKind}
            key={item.id}
            renderAfter={item.renderAfter}
            small
            text={item.name}
            onClick={event => item.onClick?.(event)}
          />
        ))}
      </Styled.TagsContainer>
    </>
  )
}

export default TagList
