import React, { forwardRef } from 'react'

import { ContextMenu, ContextMenuItem } from 'Components/UI'

import { CONTEXT_RESET_FIELD } from '../constants'

export interface IResetContext {
  isOpen: boolean
  left: number
  top: number
  onLayoutGraph: () => void
  onResetGraph: () => void
}

const ResetContext = forwardRef<HTMLDivElement, IResetContext>(
  ({ isOpen, left, top, onLayoutGraph, onResetGraph }, ref) => (
    <ContextMenu isOpen={isOpen} left={left} ref={ref} top={top}>
      <ContextMenuItem
        id={CONTEXT_RESET_FIELD.RELAYOUT_GRAPH}
        label="Relayout Graph"
        onClick={onLayoutGraph}
      />
      <ContextMenuItem
        id={CONTEXT_RESET_FIELD.RESET_GRAPH}
        label="Reset Graph"
        onClick={onResetGraph}
      />
    </ContextMenu>
  ),
)

export default ResetContext
