import React from 'react'

import { Container, LogoIcon } from './styles'

export interface IAdminPanelLogo {
  size?: number | string
}

function AdminPanelLogo({ size = 48 }: IAdminPanelLogo) {
  return (
    <Container size={size}>
      <LogoIcon />
    </Container>
  )
}

export default AdminPanelLogo
