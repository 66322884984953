import React, { useMemo } from 'react'
import { Row as RTRow } from 'react-table'

import { Row, Tooltip } from 'Components/UI'

import { useScopedI18n } from 'Services/I18n'

import { IconPrivate, IconPublic, IconShared } from '../styles'

export interface ITypeProps {
  row: RTRow<MainSchema.Note>
}

function Type({ row }: ITypeProps) {
  const s = useScopedI18n('notesManagement')

  const icon = useMemo(() => {
    if (row.original.sharedWithMe)
      return (
        <Tooltip content={s('status.shared')}>
          <IconShared size={20} stroke={1} />
        </Tooltip>
      )

    if (!row.original.public)
      return (
        <Tooltip content={s('status.private')}>
          <IconPrivate size={20} stroke={1} />
        </Tooltip>
      )

    if (row.original.public)
      return (
        <Tooltip content={s('status.public')}>
          <IconPublic size={20} stroke={1} />
        </Tooltip>
      )

    return null
  }, [s, row])

  return <Row>{icon}</Row>
}

export default Type
