import React, {
  useEffect,
  // useRef,
  useState,
} from 'react'

import {
  // IconShare,
  IconX,
} from '@tabler/icons-react'
import Utils from 'Utils'

import QuickActions from 'Components/Blocks/QuickActions/ActionOptions'
import {
  ChangeReportTab,
  ConnectionsTab,
  NotesTab,
  OverviewTab,
} from 'Components/Blocks/UserProfile/Tabs'
import {
  Avatar,
  Column,
  Divider,
  Icon,
  Row,
  // IconButton,
  Tabs,
  Text,
  // Tooltip,
} from 'Components/UI'
import { ConditionalLink, Link } from 'Components/UI/_v2'

// import { PERMISSION_ACTION, PERMISSION_SUBJECT } from 'Constants/permissions'
import {
  // useAppContext,
  // useCommunityContext,
  useGraphContext,
  useLocationQueryParams,
  // usePermission,
} from 'Hooks'

import EventBus from 'Services/EventBus'

import colors from 'Theme/_v2/colors'

import AskOfferTab from './Tabs/AskOffer/AskOfferTab'
import {
  CloseContainer,
  Content,
  NameHolder,
  ScrollableContainer,
} from './styles'

import { utils } from '../Graph'
import { PROFILE_QUICK_ACTION_KINDS } from '../QuickActions/constants'
import useActionHandlers from '../QuickActions/useActionHandlers'

export const TABS = {
  ASK_OFFER: 'Ask/Offer',
  OVERVIEW: 'Profile',
  CHANGE_REPORT: 'Change Report',
  CONNECTIONS: 'Connections',
  NOTES: 'Notes',
}
const DEFAULT_TABS = [
  TABS.OVERVIEW,
  TABS.ASK_OFFER,
  TABS.NOTES,
  TABS.CONNECTIONS,
  TABS.CHANGE_REPORT,
]

export interface IUserProfileProps {
  user: MainSchema.GetCommunityUser
  graphUser: MainSchema.GraphUser
  // onShareUserClick: () => void
}

function UserProfile({
  user,
  graphUser,
}: // onShareUserClick
IUserProfileProps) {
  const { quickActions, setGraphState } = useGraphContext()

  const queryParams = useLocationQueryParams()
  // const { can } = usePermission()
  // const shareButtonRef = useRef()

  const [activeTab, setActiveTab] = useState(TABS.OVERVIEW)

  useEffect(() => {
    if (queryParams?.tab === TABS.NOTES) {
      setActiveTab(TABS.NOTES)
    }
  }, [queryParams])

  const avatar = user.photoUrl

  // const handleShareUser = () => {
  //   shareButtonRef.current.blur()
  //   onShareUserClick()
  // }

  // const canShareUser =
  //   can(PERMISSION_ACTION.CREATE, PERMISSION_SUBJECT.SHARED_USER, []) &&
  //   user.id !== me?.id

  const actionHandlers = useActionHandlers({
    quickActions,
  })

  const availableQuickActionsForUsers = utils.getAvailableQuickActionsForUsers(
    quickActions,
    actionHandlers,
    [graphUser],
    [graphUser],
  )
  const userAvailableQuickActions =
    availableQuickActionsForUsers[user.userId!] || {}
  const userQuickActions = utils.getEnabledQuickActions(
    PROFILE_QUICK_ACTION_KINDS,
    userAvailableQuickActions,
  )

  const showLinkedInUrl = !!user.linkedInUrl && user.linkedInUrl !== 'PRIVATE'
  const showTwitterUrl = !!user.twitterUrl && user.twitterUrl !== 'PRIVATE'
  const showFacebookUrl = !!user.facebookUrl && user.facebookUrl !== 'PRIVATE'

  const disabledColor = colors.social.disabled

  return (
    <>
      <NameHolder>
        <CloseContainer>
          <Link
            display="flex"
            p={1}
            variant="secondary"
            onClick={() => {
              EventBus.trigger(
                EventBus.actions.profile.closeRightUserPanelProfile,
              )
            }}
          >
            <IconX size={16} />
          </Link>
        </CloseContainer>

        <Avatar large mr={3} src={avatar} />

        <Column flex={1} fullWidth>
          <Text header header3 mb={1}>
            {Utils.User.getFullName(user)}
          </Text>

          <Row alignItems="center" gap={3}>
            <ConditionalLink
              condition={showLinkedInUrl}
              href={user.linkedInUrl}
              isExternal
              target="_blank"
            >
              <Icon.LinkedIn
                color={!showLinkedInUrl ? disabledColor : undefined}
                size={14}
              />
            </ConditionalLink>

            <ConditionalLink
              condition={showTwitterUrl}
              href={user.twitterUrl}
              isExternal
              target="_blank"
            >
              <Icon.Twitter
                color={!showTwitterUrl ? disabledColor : undefined}
                size={14}
              />
            </ConditionalLink>

            <ConditionalLink
              condition={showFacebookUrl}
              href={user.facebookUrl}
              isExternal
              target="_blank"
            >
              <Icon.Facebook
                color={!showFacebookUrl ? disabledColor : undefined}
                size={14}
              />
            </ConditionalLink>
          </Row>
        </Column>

        {userQuickActions.length > 0 && (
          <QuickActions dropdown quickActions={userQuickActions} />
        )}

        {/* TODO: Hiding share until new implementation is added */}
        {/* {!canShareUser && (
          <Tooltip content="Share the user">
            <IconButton outline ref={shareButtonRef} onClick={handleShareUser}>
              <IconShare />
            </IconButton>
          </Tooltip>
        )} */}
      </NameHolder>

      <Content>
        <Tabs
          activeTab={activeTab}
          gap={2}
          tabs={DEFAULT_TABS}
          onChange={setActiveTab}
        />

        <Divider mb={4} />

        {activeTab === TABS.NOTES ? (
          <NotesTab user={user} />
        ) : (
          <ScrollableContainer>
            {activeTab === TABS.OVERVIEW && <OverviewTab user={user} />}

            {activeTab === TABS.CHANGE_REPORT && (
              <ChangeReportTab user={user} />
            )}

            {activeTab === TABS.CONNECTIONS && (
              <ConnectionsTab
                setGraphState={setGraphState}
                userId={user.userId}
              />
            )}
            {activeTab === TABS.ASK_OFFER && (
              <AskOfferTab communityUser={user} />
            )}
          </ScrollableContainer>
        )}
      </Content>
    </>
  )
}

export default UserProfile
