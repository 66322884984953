import React from 'react'
import { Outlet } from 'react-router-dom'

import { BiCool, BiHive, BiShield, BiUser } from 'react-icons/bi'

import capitalize from 'lodash/capitalize'

import AdminPanelLogo from 'Components/Blocks/Admin/AdminPanelLogo'
import Header from 'Components/Blocks/Admin/Header'
import { Column, Link, Row } from 'Components/UI'

import { APP_NAME } from 'Config'

import {
  ADMIN_ADMINS,
  ADMIN_CHANGE_REPORT_COMMUNITIES,
  ADMIN_COMMUNITIES,
  ADMIN_SKILLS,
  ADMIN_USERS,
} from 'Router/routes'

import {
  AppName,
  Container,
  Content,
  Menu,
  MenuLink,
  PageContent,
} from './styles'

function Admin() {
  return (
    <Container>
      <Menu>
        <Row center fullWidth height={48} px={2}>
          <Link to={ADMIN_COMMUNITIES}>
            <AdminPanelLogo size={32} />
            <AppName ml={1}>{capitalize(APP_NAME)}</AppName>
          </Link>
        </Row>
        <Column mt={4}>
          <MenuLink to={ADMIN_ADMINS}>
            <BiShield /> Super Admins
          </MenuLink>
          <MenuLink to={ADMIN_USERS}>
            <BiUser /> Users
          </MenuLink>
          <MenuLink to={ADMIN_SKILLS}>
            <BiCool /> Skills
          </MenuLink>
          <MenuLink to={ADMIN_COMMUNITIES}>
            <BiHive /> Communities
          </MenuLink>

          <MenuLink to={ADMIN_CHANGE_REPORT_COMMUNITIES}>
            <BiHive /> Change Reports
          </MenuLink>
        </Column>
      </Menu>
      <Content>
        <Header />
        <PageContent>
          <Outlet />
        </PageContent>
      </Content>
    </Container>
  )
}

export default Admin
