import { ApolloCache, MutationUpdaterFunction } from '@apollo/client'
import getCommunityUserQuery from 'GraphQL/Queries/CommunityUser/getCommunityUser.graphql'

import forEach from 'lodash/forEach'

interface ICommunityUser {
  userId: string
  communityUserId: string
  communityId: string
}

type IGetCommunityUserSkillsUpdater = MutationUpdaterFunction<
  Pick<MainSchema.Mutation, 'connectUsersToSkills'>, // TData
  {}, // TVariables
  any, // TContext
  ApolloCache<any> // TCache
>

export function getCommunityUserSkillsUpdater({
  communityUsers,
  skills,
}: {
  communityUsers: ICommunityUser[]
  skills: any[]
}): IGetCommunityUserSkillsUpdater {
  return (cache, { data }) => {
    if (!data?.connectUsersToSkills) return

    forEach(communityUsers, async communityUser => {
      // TODO: Transiton from userId to communityUserId
      const { userId, communityId } = communityUser

      const queryGetCommunityUser = {
        query: getCommunityUserQuery,
        variables: {
          userId,
          communityId,
        },
      }

      const getCommunityUserData = cache.readQuery<
        Pick<MainSchema.Query, 'getCommunityUser'>,
        MainSchema.QueryGetCommunityUserArgs
      >(queryGetCommunityUser)

      if (getCommunityUserData?.getCommunityUser) {
        const combinedSkills: MainSchema.Skill[] = [
          ...(getCommunityUserData.getCommunityUser.skills || []),
          ...skills,
        ]

        const skillsMap = new Map<string, MainSchema.Skill>()

        for (const skill of combinedSkills) {
          if (!skillsMap.has(skill.id)) {
            skillsMap.set(skill.id, skill)
          }
        }

        const mergedSkills: MainSchema.Skill[] = Array.from(skillsMap.values())

        cache.writeQuery<
          Pick<MainSchema.Query, 'getCommunityUser'>,
          MainSchema.QueryGetCommunityUserArgs
        >({
          ...queryGetCommunityUser,
          data: {
            ...getCommunityUserData,
            getCommunityUser: {
              ...getCommunityUserData.getCommunityUser,
              skills: mergedSkills,
            },
          },
        })
      }
    })
  }
}

export function getCommunityUserSkillRemoveUpdater({
  id,
  userId,
  communityUserId,
  communityId,
  skillIds,
}: {
  id: string
  userId: string
  communityUserId: string
  communityId: string
  skillIds: any[]
}): MutationUpdaterFunction<
  Pick<MainSchema.Mutation, 'disconnectUsersFromSkills'>, // TData
  {}, // TVariables
  any, // TContext
  ApolloCache<any> // TCache
> {
  return (cache, { data }) => {
    if (!data?.disconnectUsersFromSkills) return

    const queryGetCommunityUser = {
      query: getCommunityUserQuery,
      variables: {
        userId,
        communityId,
      },
    }

    const getCommunityUserData = cache.readQuery<
      Pick<MainSchema.Query, 'getCommunityUser'>,
      MainSchema.QueryGetCommunityUserArgs
    >(queryGetCommunityUser)

    const filteredSkills =
      getCommunityUserData?.getCommunityUser?.skills?.filter(
        skill => !skillIds.includes(skill.id),
      )

    if (
      getCommunityUserData?.getCommunityUser &&
      (id || userId || communityUserId) &&
      communityId
    ) {
      cache.writeQuery<
        Pick<MainSchema.Query, 'getCommunityUser'>,
        MainSchema.QueryGetCommunityUserArgs
      >({
        ...queryGetCommunityUser,
        data: {
          ...getCommunityUserData,
          getCommunityUser: {
            ...getCommunityUserData.getCommunityUser,
            skills: filteredSkills,
          },
        },
      })
    }
  }
}
