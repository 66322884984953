import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import Utils from 'Utils'

import { LinkButton, Text } from 'Components/UI'

import { useTableContext } from '../context'

function UserName({ row }) {
  const { onUserClick } = useTableContext()

  const recommendation = row?.original

  const handleLinkButtonClick = useCallback(() => {
    onUserClick(recommendation?.recommendedUser?.graphUser)
  }, [onUserClick, recommendation])

  return (
    <LinkButton onClick={handleLinkButtonClick}>
      <Text bodyMedium header>
        {Utils.User.getFullName(recommendation?.recommendedUser?.profile)}
      </Text>
    </LinkButton>
  )
}

UserName.defaultProps = {
  row: {},
}

UserName.propTypes = {
  row: PropTypes.object,
}

export default UserName
