import { MutationUpdaterFn } from '@apollo/client'
import proposalsForUserQuery from 'GraphQL/Queries/Proposals/proposalsForUser.graphql'
import sharedUserQuery from 'GraphQL/Queries/User/sharedUser.graphql'

export function createProposalForUserUpdater({
  userId,
  communityId,
  sharedUserId,
}: {
  userId: string
  communityId: string
  sharedUserId?: string
}): MutationUpdaterFn<Pick<MainSchema.Mutation, 'createProposal'>> {
  return (cache, { data }) => {
    const queryProposalsForUser = {
      query: proposalsForUserQuery,
      variables: {
        userId,
        communityId,
      },
    }

    const proposalsForUserData = cache.readQuery<
      Pick<MainSchema.Query, 'proposalsForUser'>,
      MainSchema.QueryProposalsForUserArgs
    >(queryProposalsForUser)

    if (proposalsForUserData && userId && communityId && !sharedUserId) {
      cache.writeQuery<
        Pick<MainSchema.Query, 'proposalsForUser'>,
        MainSchema.QueryProposalsForUserArgs
      >({
        ...queryProposalsForUser,
        data: {
          ...proposalsForUserData,
          proposalsForUser: [
            ...(proposalsForUserData?.proposalsForUser || []),
            data!.createProposal,
          ],
        },
      })
    }

    if (!sharedUserId) return

    const querySharedUser = {
      query: sharedUserQuery,
      variables: { sharedUserId },
    }

    const querySharedUserData = cache.readQuery<
      Pick<MainSchema.Query, 'sharedUser'>,
      MainSchema.QuerySharedUserArgs
    >(querySharedUser)

    if (querySharedUserData && sharedUserId) {
      cache.writeQuery<
        Pick<MainSchema.Query, 'sharedUser'>,
        MainSchema.QuerySharedUserArgs
      >({
        ...querySharedUser,
        data: {
          ...querySharedUserData,
          sharedUser: {
            ...querySharedUserData.sharedUser,
            originalProposals: [
              ...(querySharedUserData?.sharedUser?.originalProposals ?? []),
              data!.createProposal,
            ],
          },
        },
      })
    }
  }
}

export function deleteProposalForUserUpdater({
  userId,
  sharedUserId,
  communityId,
  proposalId,
}: {
  userId: string
  communityId?: string
  sharedUserId?: string
  proposalId?: string
}): MutationUpdaterFn<Pick<MainSchema.Mutation, 'deleteProposal'>> {
  return cache => {
    if (!proposalId || !communityId) return

    const queryProposalsForUser = {
      query: proposalsForUserQuery,
      variables: {
        userId,
        communityId,
      },
    }

    const proposalsForUserData = cache.readQuery<
      Pick<MainSchema.Query, 'proposalsForUser'>,
      MainSchema.QueryProposalsForUserArgs
    >(queryProposalsForUser)

    if (proposalsForUserData) {
      cache.writeQuery<
        Pick<MainSchema.Query, 'proposalsForUser'>,
        MainSchema.QueryProposalsForUserArgs
      >({
        ...queryProposalsForUser,
        data: {
          ...proposalsForUserData,
          proposalsForUser: proposalsForUserData.proposalsForUser?.filter(
            proposal => proposal.id !== proposalId,
          ),
        },
      })
    }

    if (!sharedUserId) return

    const querySharedUser = {
      query: sharedUserQuery,
      variables: { sharedUserId },
    }

    const querySharedUserData = cache.readQuery<
      Pick<MainSchema.Query, 'sharedUser'>,
      MainSchema.QuerySharedUserArgs
    >(querySharedUser)

    if (querySharedUserData) {
      cache.writeQuery<
        Pick<MainSchema.Query, 'sharedUser'>,
        MainSchema.QuerySharedUserArgs
      >({
        ...querySharedUser,
        data: {
          ...querySharedUserData,
          sharedUser: {
            ...querySharedUserData.sharedUser,
            originalProposals:
              querySharedUserData.sharedUser.originalProposals?.filter(
                item => item.id !== proposalId,
              ),
          },
        },
      })
    }
  }
}
