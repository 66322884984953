import React, { useMemo } from 'react'

import { useQuery } from '@apollo/client'
import { IconChevronLeft } from '@tabler/icons-react'
import NoteForm from 'Features/Notes/Components/NoteForm'
import useNoteForm from 'Features/Notes/Components/NoteForm/useNoteForm'
import NoteTags from 'Features/Notes/Components/NoteTags'
import communitySearchUsersQuery from 'GraphQL/Queries/Community/communitySearchUsers.graphql'

import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import keyBy from 'lodash/keyBy'
import keys from 'lodash/keys'

import { Button, Column, Row } from 'Components/UI'
import { Link } from 'Components/UI/_v2'

import { PROPOSAL_KIND } from 'Constants/ids'

import { useCommunity } from 'Hooks'

import { useScopedI18n } from 'Services/I18n'

import * as Styled from './Note.styles'

export interface INoteProps {
  userId: string
  noteId?: string
  onBack: () => void
}

const Note = (props: INoteProps) => {
  const t = useScopedI18n('components.blocks.userProfile.tabs.notes.note')
  const { community } = useCommunity()

  const {
    user,
    isLoading,
    formStatus,
    formValues,
    canToggleIsPublic,
    canSubmit,
    handleToggleIsFavorite,
    handleToggleIsPublic,
    handleAddMention,
    handleChangeContent,
  } = useNoteForm({
    userId: props.userId,
    noteId: props.noteId,
  })

  const { connectedUserIds, userTags, userSkills } = useMemo(() => {
    const skillProposals: MainSchema.Proposal[] = []
    const needSkillProposals: MainSchema.Proposal[] = []
    const tagProposals: MainSchema.Proposal[] = []

    user?.proposals?.forEach(proposal => {
      switch (proposal.kind) {
        case PROPOSAL_KIND.SKILL:
          skillProposals.push(proposal)
          break
        case PROPOSAL_KIND.NEED_SKILL:
          needSkillProposals.push(proposal)
          break
        case PROPOSAL_KIND.ROLE:
        case PROPOSAL_KIND.CUSTOM:
        case PROPOSAL_KIND.PROJECT:
        case PROPOSAL_KIND.EVENT:
        case PROPOSAL_KIND.GROUP:
          tagProposals.push(proposal)
          break
        default:
          break
      }
    })

    return {
      connectedUserIds: keys(user?.connectedUsers),
      userTags: user?.tags,
      userSkills: user?.skills,
      userNeedSkills: user?.needSkills,
      userSkillProposals: skillProposals,
      userNeedSkillProposals: needSkillProposals,
      userTagProposals: tagProposals,
    }
  }, [user])

  const relatedUserIds = useMemo(
    () =>
      filter(
        [
          ...connectedUserIds,
          ...keys(formValues.mentionedItems.toMeetUserIds),
          ...keys(formValues.mentionedItems.toMentionedUserIds),
          ...keys(formValues.mentionedItems.toAppendUserIds),
        ],
        item => item !== undefined,
      ),
    [
      connectedUserIds,
      formValues.mentionedItems.toMeetUserIds,
      formValues.mentionedItems.toMentionedUserIds,
      formValues.mentionedItems.toAppendUserIds,
    ],
  )

  // Only load related users, not all users
  const { data } = useQuery<
    Pick<MainSchema.Query, 'communitySearch'>,
    MainSchema.QueryCommunitySearchArgs
  >(communitySearchUsersQuery, {
    skip: !community?.id || relatedUserIds?.length === 0,
    variables: community?.id
      ? {
          communityId: community.id,
          limit: relatedUserIds?.length,
          users: relatedUserIds,
        }
      : undefined,
  })

  const users = useMemo(() => data?.communitySearch?.users || [], [data])
  const usersById = useMemo(() => keyBy(users, 'id'), [users])

  const connectedUsers = useMemo(() => {
    const innerArray: MainSchema.GraphUser[] = []
    forEach(connectedUserIds, id => {
      if (usersById[id]) {
        innerArray.push(usersById[id])
      }
    })
    return innerArray
  }, [connectedUserIds, usersById])

  if (!user || isLoading) {
    return <></>
  }

  return (
    <>
      <Styled.NoteEditorScrollContainer>
        <Column grow>
          <Link
            alignItems="center"
            display="flex"
            flexDirection="row"
            fontSize="12px"
            fontWeight={600}
            gap={1}
            variant="secondary"
            onClick={props.onBack}
          >
            <IconChevronLeft size={16} />
            {t('back')}
          </Link>

          <Row center pb={3} pt={4} spaceBetween>
            <NoteForm.IsPublicField
              isDisabled={!canToggleIsPublic}
              value={formValues.isPublic}
              onChange={handleToggleIsPublic}
            />

            <NoteForm.IsFavoriteField
              value={formValues.isFavorite}
              onChange={handleToggleIsFavorite}
            />
          </Row>

          <Column>
            <NoteForm.ContentField
              existingMentions={formValues.existingMentions}
              userId={props.userId}
              value={formValues.content}
              onAddMention={handleAddMention}
              onChangeContent={handleChangeContent}
            />
          </Column>

          <Row center pb={6} pt={3} spaceBetween>
            <NoteForm.StatusMessage status={formStatus} />

            <Button
              disabled={!canSubmit}
              minWidth="80px"
              onClick={props.onBack}
            >
              {t('submit')}
            </Button>
          </Row>
        </Column>
      </Styled.NoteEditorScrollContainer>

      <Styled.ScrollContainer>
        <NoteTags.UserTags
          appendUsers={formValues.mentionedItems.toAddUsers}
          connectedUsers={connectedUsers}
          meetUserIds={formValues.mentionedItems.toMeetUserIds}
          meetUsers={formValues.mentionedItems.toMeetUsers}
          mentionedUserIds={formValues.mentionedItems.toMentionedUserIds}
          targetUser={user}
        />

        <NoteTags.SkillTags
          appendSkills={formValues.mentionedItems.toAppendSkills}
          userId={user.id!}
          userSkills={userSkills as any}
        />

        <NoteTags.Tags
          customTags={formValues.mentionedItems.customTags}
          events={formValues.mentionedItems.events}
          existingTags={userTags as any}
          groups={formValues.mentionedItems.groups}
          projects={formValues.mentionedItems.projects}
          roles={formValues.mentionedItems.roles}
          userId={user.id!}
        />
      </Styled.ScrollContainer>
    </>
  )
}

export default Note
