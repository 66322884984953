import styled from 'styled-components'
import { margin, MarginProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

export interface ITagsContainer extends MarginProps {}

export const TagsContainer = styled.div<ITagsContainer>`
  display: flex;
  gap: ${themeGet('space.2')}px;
  flex-wrap: wrap;

  ${margin}
`
