import React, { useCallback, useState } from 'react'

import { useMutation } from '@apollo/client'
import createProposalMutation from 'GraphQL/Mutations/Proposal/createProposal.graphql'
import { createProposalForUserUpdater } from 'GraphQL/Updaters/ProposalForUser'

import { Input } from 'Components/UI'

import { ProposalKind } from 'Constants/mainGraphQL'

import { useAppContext, useCommunityContext } from 'Hooks'
import usePhoneNumber from 'Hooks/usePhoneNumber'

import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import colors from 'Theme/_v2/colors'

import BlockTitle from '../UserProfile/Blocks/BlockTitle'
import { IconContacts } from '../UserProfile/Blocks/styles'
import Action from '../UserProfile/Blocks/TagBlock/Action'

const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/

export interface IContactProps {
  userId: string
  sharedUserId?: string
  showCreateProposals?: boolean
}

function Contact({
  userId,
  sharedUserId,
  showCreateProposals = true,
}: IContactProps) {
  const { me } = useAppContext()
  const { community } = useCommunityContext()
  const s = useScopedI18n('blocks.proposal.contact')

  const [proposedContact, setProposedContact] = useState<string>('')
  const [isLoading, setLoading] = useState(false)
  const { formatPhoneNumber, formalizePhoneNumber, validatePhoneNumber } =
    usePhoneNumber()

  const [createProposal] = useMutation<
    Pick<MainSchema.Mutation, 'createProposal'>,
    MainSchema.MutationCreateProposalArgs
  >(createProposalMutation)

  const isEmail = useCallback((value: string) => emailRegex.test(value), [])

  const handleChange = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const { value } = event.target

      setProposedContact(isEmail(value) ? value : formatPhoneNumber(value))
    },
    [formatPhoneNumber, isEmail],
  )

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const { value } = event.target

      setProposedContact(
        isEmail(value) ? value : formalizePhoneNumber(value).toString(),
      )
    },
    [isEmail, formalizePhoneNumber],
  )

  const handleSubmit = useCallback(async () => {
    if (!community) {
      return
    }

    setLoading(true)

    try {
      await createProposal({
        variables: {
          communityId: community?.id,
          userId,
          kind: emailRegex.test(proposedContact)
            ? ProposalKind.Email
            : ProposalKind.Phone,
          value: proposedContact,
        },
        update: createProposalForUserUpdater({
          userId,
          communityId: community.id,
          sharedUserId,
        }),
      })

      setProposedContact('')

      toast.success({
        title: s('messageTitle'),
        text: s('success'),
      })
    } catch (error) {
      let message = _('error.generic')

      if (error instanceof Error) {
        message = _(`error.${error.message || 'generic'}`)
      }

      toast.error({
        title: s('messageTitle'),
        text: message,
      })
    } finally {
      setLoading(false)
    }
  }, [createProposal, community, userId, proposedContact, sharedUserId, s])

  return (
    <Action
      disabled={
        !(
          validatePhoneNumber(proposedContact) ||
          emailRegex.test(proposedContact)
        )
      }
      isLoading={isLoading}
      showOnlyTitle={me?.id === userId || !showCreateProposals}
      title={
        <BlockTitle
          icon={<IconContacts color={colors.icon.profile} />}
          title={s('title')}
        />
      }
      onSave={handleSubmit}
    >
      <Input
        mt={2}
        name="emailOrPhone"
        placeholder={s('placeholder')}
        value={proposedContact}
        width={1}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    </Action>
  )
}

export default Contact
