import styled from 'styled-components'

import { resetButton } from 'Components/Styles'

export const Button = styled.button`
  ${resetButton}

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;

  :hover {
    background-color: #edeff1;
    border-radius: 100%;
  }
`
