import styled from 'styled-components'

import {
  contactsGlyph,
  customTagsGlyph,
  educationHistoriesGlyph,
  educationHistoryPlaceholderGlyph,
  eventsGlyph,
  groupsGlyph,
  industriesGlyph,
  projectsGlyph,
  skillsGlyph,
  workHistoriesGlyph,
  workHistoryPlaceholderGlyph,
} from 'Assets/Svg/icons/profile'

export const IconContacts = styled(contactsGlyph)``
export const IconCustomTags = styled(customTagsGlyph)``
export const IconEducationHistories = styled(educationHistoriesGlyph)``
export const IconEducationHistoryPlaceholder = styled(
  educationHistoryPlaceholderGlyph,
)``
export const IconEvents = styled(eventsGlyph)``
export const IconGroups = styled(groupsGlyph)``
export const IconIndustries = styled(industriesGlyph)``
export const IconProjects = styled(projectsGlyph)``
export const IconSkills = styled(skillsGlyph)``
export const IconWorkHistories = styled(workHistoriesGlyph)``
export const IconWorkHistoryPlaceholder = styled(workHistoryPlaceholderGlyph)``
