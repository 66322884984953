import styled, { css } from 'styled-components'
import { margin, MarginProps, padding, PaddingProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { IconAlertTriangle } from '@tabler/icons-react'

import { NOTIFICATION_KIND } from 'Constants/ids'

export interface IContainerIsReadCssProps {
  isRead?: boolean
}

const containerIsReadCss = ({ isRead }: IContainerIsReadCssProps) =>
  isRead &&
  css`
    background-color: ${themeGet('colors.bg.tertiary')};
  `

export interface IContainerProps
  extends PaddingProps,
    IContainerIsReadCssProps {}

export const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ${padding};

  ${containerIsReadCss};
`

export const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: ${themeGet('space.3')}px ${themeGet('space.5')}px;
`

export interface ITagKindCssProps {
  kind?: string
}

const tagKindCss = ({ kind }: ITagKindCssProps) => {
  switch (kind) {
    case NOTIFICATION_KIND.SHARED_USER: {
      return css`
        background: #e1fff8;
        color: #10b981;
      `
    }
    case NOTIFICATION_KIND.PROPOSAL: {
      return css`
        background: ${themeGet('colors.primaryWePeep')};
        color: ${themeGet('colors.primaryCardinal')};
      `
    }
    case NOTIFICATION_KIND.SHARED_NOTE: {
      return css`
        background: #f3f1ff;
        color: #7c3aed;
      `
    }
    case NOTIFICATION_KIND.NYLAS_GRANT_EXPIRED: {
      return css`
        background: ${themeGet('colors.lusciousPurple10')};
        color: ${themeGet('colors.lusciousPurple50')};
      `
    }
    default:
      return null
  }
}

export interface ITagProps extends ITagKindCssProps, MarginProps {}

export const Tag = styled.div<ITagProps>`
  border-radius: 100px;
  font-size: ${themeGet('fontSize.1')}px;
  line-height: 16px;
  padding: 2px 6px;
  flex-shrink: 0;

  ${tagKindCss};

  ${margin};
`

export const IconCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: ${themeGet('colors.warning10')};
  border: 1px solid ${themeGet('colors.warning40')};
  border-radius: 40px;
`

export const AlertIcon = styled(IconAlertTriangle)`
  width: 12px;
  height: 12px;
  color: ${themeGet('colors.warning40')};
`
