import React, { useMemo } from 'react'
import { Row } from 'react-table'

import stringReplacer from 'Utils/stringReplacer'

import truncate from 'lodash/truncate'

import { Text, Tooltip } from 'Components/UI'

import { notesReplacers } from 'Constants/replacers'

import { useResponsiveLayout } from 'Hooks'

import { PopoverWrapper } from './styles'

import { useTableContext } from '../context'

export interface INoteContentProps {
  row: Row<MainSchema.Note>
}

function NoteContent({ row }: INoteContentProps) {
  const { isMobile } = useResponsiveLayout()

  const { onReadNote } = useTableContext()

  const content = useMemo(
    () => stringReplacer(row.original.content, notesReplacers),
    [row.original],
  )

  const isRead = row.original.read

  return (
    <PopoverWrapper>
      {isMobile ? (
        <Text
          actionMedium={isRead}
          bodyMedium={!isRead}
          header
          onClick={() => onReadNote(row.original)}
        >
          {truncate(content.join(''), { length: 52 })}
        </Text>
      ) : (
        <Tooltip
          appendTo={document.body}
          content={<Text contrast>{content}</Text>}
          maxWidth={320}
        >
          <Text
            actionMedium={isRead}
            bodyMedium={!isRead}
            header
            onClick={() => onReadNote(row.original)}
          >
            {truncate(content.join(''), { length: 52 })}
          </Text>
        </Tooltip>
      )}
    </PopoverWrapper>
  )
}

export default NoteContent
