import React, { MouseEventHandler } from 'react'

import { Icon, Loader, Row, Text } from 'Components/UI'

import { ConnectAccountProvider } from './ConnectAccount'
import * as Styled from './ConnectAccountButton.styles'

export enum ConnectAccountButtonSize {
  Small = 'small',
  Large = 'large',
}

export interface IConnectAccountButton {
  provider: ConnectAccountProvider
  label: string
  onClick: MouseEventHandler
  isLoading?: boolean
  size: ConnectAccountButtonSize
}

const ConnectAccountButton = (props: IConnectAccountButton) => {
  if (props.isLoading) {
    return (
      <Row justifyCenter>
        <Loader />
      </Row>
    )
  }

  return (
    <Styled.Button
      px={props.size === ConnectAccountButtonSize.Large ? 4 : 2}
      py={props.size === ConnectAccountButtonSize.Large ? 2 : 1}
      onClick={props.onClick}
    >
      <Row center gap={1} justifyCenter>
        {props.provider === ConnectAccountProvider.Google && (
          <Icon.Google size={16} />
        )}
        <Text
          as={'span'}
          color={'inherit'}
          fontSize={props.size === ConnectAccountButtonSize.Large ? 14 : 12}
          fontWeight={600}
          lineHeight={'20px'}
        >
          {props.label}
        </Text>
      </Row>
    </Styled.Button>
  )
}

export default ConnectAccountButton
