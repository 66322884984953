import { FiChevronDown, FiX } from 'react-icons/fi'
import { HiOutlineMenuAlt3 } from 'react-icons/hi'

import styled from 'styled-components'
import {
  height,
  HeightProps,
  margin,
  MarginProps,
  padding,
  PaddingProps,
  width,
  WidthProps,
} from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { logoNetworkGlyph } from 'Assets/Svg'

import { Link } from 'Components/UI'
import { ILink } from 'Components/UI/Link/Link'

import { gap, GapProps } from 'Theme/system'

export const Container = styled.header`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid ${themeGet('colors.divider.default')};
  border-bottom: 1px solid ${themeGet('colors.divider.default')};
  background-color: ${themeGet('colors.bg.primary')};
  z-index: ${themeGet('zIndices.mobileFooter')};
`

export const Logo = styled(logoNetworkGlyph)`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
`

export const DownIcon = styled(FiChevronDown)`
  width: 16px;
  height: 16px;
  color: ${themeGet('colors.grayBoulder')};
`

export const MenuIcon = styled(HiOutlineMenuAlt3)`
  width: 24px;
  height: 24px;
  color: ${themeGet('colors.mineShaft')};
`

export const CloseIcon = styled(FiX)`
  width: 24px;
  height: 24px;
  color: ${themeGet('colors.mineShaft')};
  stroke-width: 1;
`

export interface IMenuButtonProps extends GapProps {}

export const MenuButton = styled.button<IMenuButtonProps>`
  display: flex;
  margin: 0;
  padding: 0;
  box-shadow: unset;
  border: unset;
  background: transparent;
  align-items: center;

  ${gap}
`

export interface IMenuContainerProps extends PaddingProps, GapProps {}

export const MenuContainer = styled.nav<IMenuContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid #f0f0f0;

  ${padding}
  ${gap}
`

export interface IMenuLinkProps
  extends ILink,
    WidthProps,
    HeightProps,
    MarginProps {}

export const MenuLink = styled(Link)<IMenuLinkProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 100px;
  transition: all 0.3s ease;
  padding: 10px;
  text-decoration: none;
  outline: unset;

  ${width}
  ${height}
  ${margin}
`

export const LogoWrapper = styled.div`
  display: flex;
  height: 100%;
`
