import { IoCheckmarkOutline } from 'react-icons/io5'

import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const Content = styled.div`
  word-break: break-word;
  min-width: 100vw;
  text-align: start;
  align-items: initial;
  padding-bottom: 20px;
  overflow: scroll;
  max-height: 50vh;
`

export const CommunityLink = styled.a`
  display: flex;
  width: 100%;
  padding: 14px 12px;
  border-bottom: 1px solid ${themeGet('colors.gallery')};
  color: ${themeGet('colors.primary')};
  justify-content: space-between;
  text-decoration: unset;

  &.active,
  &:hover,
  &:visited,
  &:focus {
    color: ${themeGet('colors.primary')};
  }
`

export const CheckIcon = styled(IoCheckmarkOutline)`
  width: 20px;
  height: 20px;
  color: ${themeGet('colors.primaryCardinal')};
`
