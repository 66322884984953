import React from 'react'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'

import { useLazyQuery, useMutation } from '@apollo/client'
import debounce from 'awesome-debounce-promise'
import connectUsersToSkillsMutation from 'GraphQL/Mutations/Community/connectUsersToSkills.graphql'
import createSkillsMutation from 'GraphQL/Mutations/Skill/createSkills.graphql'
import listCommunityUsersQuery from 'GraphQL/Queries/CommunityUser/listCommunityUsers.full.graphql'
import listSkillsQuery from 'GraphQL/Queries/listSkills.graphql'
import { setMinSearchLength } from 'Utils/Form'
import { communityUsersToOptions, entitiesToOptions } from 'Utils/Options'
import validate from 'validate.js'

import forEach from 'lodash/forEach'
import map from 'lodash/map'
import noop from 'lodash/noop'

import { Column, Modal, SelectField } from 'Components/UI'

import { DEFAULT_MIN_SEARCH_SIZE, DEFAULT_SEARCH_DEBOUNCE } from 'Constants/ids'

import { useCommunityContext } from 'Hooks'

import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

const FIELD = {
  SKILLS: 'skills',
  COMMUNITY_USERS: 'communityUserIds',
}

const initialValues = {
  [FIELD.SKILLS]: [],
  [FIELD.COMMUNITY_USERS]: [],
}

const CONSTRAINTS = {
  [FIELD.SKILLS]: {
    presence: { allowEmpty: false, message: `^Field is required` },
  },
  [FIELD.COMMUNITY_USERS]: {
    presence: { allowEmpty: false, message: `^Field is required` },
  },
}

function CreateSkill({ isOpen, onClose, onRefetch }) {
  const s = useScopedI18n('modals.createSkill')
  const { community } = useCommunityContext()

  const [loadSkills] = useLazyQuery(listSkillsQuery)
  const [loadUsers] = useLazyQuery(listCommunityUsersQuery)
  const [connectUsersToSkills] = useMutation(connectUsersToSkillsMutation)
  const [createSkills] = useMutation(createSkillsMutation)

  const onSubmit = React.useCallback(
    async values => {
      const skillIds = []
      const skills = []
      forEach(values?.[FIELD.SKILLS], option =>
        option.id
          ? skillIds.push(option.value)
          : skills.push({
              name: option.value.trim(),
            }),
      )
      try {
        let createdSkillIds = []

        if (skills.length > 0) {
          const response = await createSkills({
            variables: {
              communityId: community?.id,
              skills,
            },
          })

          const entities = response?.data?.createSkills || []

          if (entities?.length > 0) {
            createdSkillIds = map(entities, skill => skill.id)
          }
        }

        const combinedSkillIds = [...skillIds, ...createdSkillIds]
        const communityUserIds = [
          ...map(values[FIELD.COMMUNITY_USERS], 'value'),
        ]
        const usersToSkills = []

        combinedSkillIds.forEach(skillId => {
          communityUserIds.forEach(communityUserId =>
            usersToSkills.push({
              skillId,
              communityUserId,
            }),
          )
        })

        await connectUsersToSkills({
          variables: {
            communityId: community?.id,
            usersToSkills,
          },
        })

        await onRefetch()

        toast.success({
          title: s('messages.connectTitle'),
          text: s('messages.connectSuccess'),
        })

        onClose()
      } catch (error) {
        toast.error({
          title: s('messages.connectTitle'),
          text: _(`error.${error?.message || 'generic'}`),
        })
      }
    },
    [connectUsersToSkills, community, onRefetch, s, onClose, createSkills],
  )

  const loadUsersOptions = React.useCallback(
    excludeValues => async (inputValue, callback) => {
      try {
        const result = await loadUsers({
          variables: {
            search: inputValue,
            excludeUserIds: [...map(excludeValues, 'value')],
            communityId: community?.id,
            limit: 25,
          },
        })

        const users = result.data?.listCommunityUsers?.communityUsers || []

        callback(communityUsersToOptions(users))
      } catch (error) {
        toast.error({
          title: s('messages.loadUsersTitle'),
          text: _(`error.${error?.message || 'generic'}`),
        })
      }
    },
    [loadUsers, community, s],
  )

  const loadTagsOptions = React.useCallback(
    () => async (inputValue, callback) => {
      try {
        const result = await loadSkills({
          variables: {
            communityId: community?.id,
            search: inputValue,
            limit: 25,
          },
          fetchPolicy: 'network-only',
        })
        const suggestions = result?.data

        const skills = suggestions?.skills?.rows

        callback(entitiesToOptions(skills))
      } catch (error) {
        toast.error({
          title: s('messages.loadSkillsTitle'),
          text: _(`error.${error?.message || 'generic'}`),
        })
      }
    },
    [community?.id, loadSkills, s],
  )

  const debouncedLoadTagsOptions = React.useCallback(
    () =>
      setMinSearchLength(
        debounce(loadTagsOptions(), DEFAULT_SEARCH_DEBOUNCE),
        DEFAULT_MIN_SEARCH_SIZE,
      ),
    [loadTagsOptions],
  )

  const debouncedLoadUserOptions = React.useCallback(
    excludedValues =>
      setMinSearchLength(
        debounce(loadUsersOptions(excludedValues), DEFAULT_SEARCH_DEBOUNCE),
        DEFAULT_MIN_SEARCH_SIZE,
      ),
    [loadUsersOptions],
  )

  const renderForm = React.useCallback(
    ({ handleSubmit, values }) => (
      <Modal
        cancelText={_('general.cancel')}
        confirmText={_('general.save')}
        isOpen
        title={s('title')}
        onClose={onClose}
        onConfirm={handleSubmit}
      >
        <Column gap={4} width={['100%', '100%', '600px']}>
          <SelectField
            async
            creatable
            isMulti
            label={s('form.nameLabel')}
            loadOptions={debouncedLoadTagsOptions()}
            name={FIELD.SKILLS}
            placeholder={s('form.selectPlaceholder')}
            required
            width={1}
            withPortal
          />

          <SelectField
            async
            isMulti
            label={s('form.usersLabel')}
            loadOptions={debouncedLoadUserOptions(values.users)}
            name={FIELD.COMMUNITY_USERS}
            placeholder={s('form.selectPlaceholder')}
            required
            width={1}
            withPortal
          />
        </Column>
      </Modal>
    ),
    [debouncedLoadTagsOptions, debouncedLoadUserOptions, onClose, s],
  )

  if (!isOpen) return null

  return (
    <Form
      initialValues={initialValues}
      render={renderForm}
      validate={values => validate(values, CONSTRAINTS)}
      onSubmit={onSubmit}
    />
  )
}

CreateSkill.defaultProps = {
  isOpen: false,
  onClose: noop,
  onRefetch: noop,
}

CreateSkill.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onRefetch: PropTypes.func,
}

export default CreateSkill
