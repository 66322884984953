import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { useApolloClient, useMutation } from '@apollo/client'
import { IconChevronLeft } from '@tabler/icons-react'
import debounce from 'awesome-debounce-promise'
import removeShareNoteMutation from 'GraphQL/Mutations/Notes/removeShareNote.graphql'
import shareNoteMutation from 'GraphQL/Mutations/Notes/shareNote.graphql'
import listCommunityUsersQuery from 'GraphQL/Queries/CommunityUser/listCommunityUsers.full.graphql'
import { setMinSearchLength } from 'Utils/Form'
import { usersToOptions } from 'Utils/Options'

import noop from 'lodash/noop'
import reduce from 'lodash/reduce'

import { Column, Divider, Modal, Row, Select } from 'Components/UI'

import { useAppContext, useCommunityContext } from 'Hooks'

import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import AddUserStep from './AddUserStep'
import ShareNotesTable from './ShareNotesTable'
import { IconButton } from './styles'

const SEARCH_DEBOUNCE = 300
const SEARCH_MINIMUM_LENGTH = 3

function ShareNoteModal({ noteId, isOpen, onClose }) {
  const s = useScopedI18n('modals.shareNote')
  const { me } = useAppContext()
  const { community } = useCommunityContext()
  const client = useApolloClient()

  const [user, setUser] = useState()
  const [deleteUserIds, setDeleteUserIds] = useState([])
  const [changeAccessUsers, setChangeAccessUsers] = useState({})
  const [isLoading, setLoading] = useState(false)

  const [removeShareNote] = useMutation(removeShareNoteMutation)
  const [shareNote] = useMutation(shareNoteMutation)

  const loadUsersOptions = useCallback(
    () => async (inputValue, callback) => {
      const result = await client.query({
        query: listCommunityUsersQuery,
        variables: {
          communityId: community?.id,
          search: inputValue,
          limit: 25,
          excludeUserIds: [me?.id],
        },
      })

      const users = result.data?.listCommunityUsers?.communityUsers || []

      callback(usersToOptions(users))
    },
    [me, community, client],
  )

  const debouncedLoadUserOptions = useCallback(
    excludedValues =>
      setMinSearchLength(
        debounce(loadUsersOptions(excludedValues), SEARCH_DEBOUNCE),
        SEARCH_MINIMUM_LENGTH,
      ),
    [loadUsersOptions],
  )

  const handleDeleteUsers = useCallback(userId => {
    setDeleteUserIds(prevState => {
      if (prevState.includes(userId)) {
        return [...prevState.filter(deletedId => deletedId !== userId)]
      }
      return [...prevState, userId]
    })
  }, [])

  const handleChangeUserAccess = useCallback(value => {
    const { editable, userId } = value

    setChangeAccessUsers(prevState => ({
      ...prevState,
      [userId]: {
        editable,
      },
    }))
  }, [])

  const handleSave = useCallback(async () => {
    setLoading(true)

    try {
      const { editorIds, viewerIds } = reduce(
        changeAccessUsers,
        (acc, item, userId) => {
          if (item.editable) {
            acc.editorIds.push(userId)
          } else {
            acc.viewerIds.push(userId)
          }

          return acc
        },
        {
          viewerIds: [],
          editorIds: [],
        },
      )

      if (viewerIds.length) {
        await shareNote({
          variables: {
            noteId,
            userIds: viewerIds,
            editable: false,
          },
        })
      }

      if (editorIds.length) {
        await shareNote({
          variables: {
            noteId,
            userIds: editorIds,
            editable: true,
          },
        })
      }

      await removeShareNote({
        variables: {
          noteId,
          userIds: deleteUserIds,
        },
      })

      toast.success({
        title: s('messages.updateTitle'),
        text: s('messages.updateSuccess'),
      })

      onClose()
    } catch (error) {
      toast.error({
        title: 'Server error',
        text: _(`error.${error?.message || 'generic'}`),
      })
    } finally {
      setLoading(false)
    }
  }, [
    changeAccessUsers,
    removeShareNote,
    noteId,
    deleteUserIds,
    s,
    onClose,
    shareNote,
  ])

  const title = useMemo(() => {
    if (user)
      return (
        <Row center gap={2}>
          <IconButton strokeWidth={1} onClick={() => setUser(null)}>
            <IconChevronLeft size={24} />
          </IconButton>

          {s('title')}
        </Row>
      )

    return s('title')
  }, [user, s])

  if (!isOpen) return null

  if (user)
    return (
      <AddUserStep
        isOpen
        noteId={noteId}
        title={title}
        user={user}
        onCancel={() => setUser(null)}
        onLoadUsers={debouncedLoadUserOptions}
      />
    )

  return (
    <Modal
      cancelText={_('general.cancel')}
      confirmDisabled={isLoading}
      confirmText={_('general.save')}
      isOpen={isOpen}
      minWidth={['100%', '100%', '600px']}
      noScroll
      px={0}
      title={title}
      onClose={onClose}
      onConfirm={handleSave}
    >
      <Column px={5}>
        <Select
          async
          label={s('addUserLabel')}
          loadOptions={debouncedLoadUserOptions()}
          value={user}
          withPortal
          onChange={setUser}
        />
      </Column>

      <Divider my={5} />

      <Column px={5}>
        <ShareNotesTable
          deleteUserIds={deleteUserIds}
          noteId={noteId}
          onChangeAccess={handleChangeUserAccess}
          onDeleteUser={handleDeleteUsers}
        />
      </Column>
    </Modal>
  )
}

ShareNoteModal.defaultProps = {
  isOpen: false,
  noteId: null,
  onClose: noop,
}

ShareNoteModal.propTypes = {
  isOpen: PropTypes.bool,
  noteId: PropTypes.string,
  onClose: PropTypes.func,
}

export default ShareNoteModal
