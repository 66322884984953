import React from 'react'

import { IQuickAction } from 'Components/Blocks/Graph/utils'
import { IconButton, Row, Tooltip } from 'Components/UI'

import Dropdown from './Dropdown'

export interface IQuickActions {
  quickActions: IQuickAction[]
  dropdown?: boolean
}

function QuickActions({ quickActions, dropdown = false }: IQuickActions) {
  if (dropdown) {
    return (
      <Dropdown
        options={quickActions.map(quickAction => ({
          key: quickAction.kind,
          icon: quickAction.icon,
          label: quickAction.label,
          handleClick: quickAction.handleClick,
        }))}
      />
    )
  }

  return (
    <>
      sdfsfsfs
      {quickActions.map(quickAction => (
        <Tooltip
          content={<Row>{quickAction.label}</Row>}
          key={quickAction.kind}
        >
          <IconButton
            outline
            small
            title={quickAction.label}
            onClick={quickAction.handleClick}
          >
            {quickAction.icon}
          </IconButton>
        </Tooltip>
      ))}
    </>
  )
}

export default QuickActions
