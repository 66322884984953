import { useMemo } from 'react'

import { AccessCell, ActionsCell, FullNameCell, SharedAtCell } from './Cells'

export function useColumns() {
  return useMemo(
    () => [
      {
        Header: `Full name`,
        Cell: FullNameCell,
        width: 1,
      },
      {
        Header: 'Shared at',
        Cell: SharedAtCell,
        width: 1,
      },
      {
        Header: 'Access',
        Cell: AccessCell,
        width: 0.5,
      },
      {
        Header: 'Actions',
        Cell: ActionsCell,
        width: 0.5,
        cellRight: true,
        headerRight: true,
      },
    ],
    [],
  )
}
