import React, { useRef } from 'react'
import { Outlet } from 'react-router-dom'

import Header from 'Components/Blocks/Community/Header'
import MobileHeader from 'Components/Blocks/Mobile/Community/Header'
import MobileFooter from 'Components/Blocks/Mobile/Footer'
import { Column, Row } from 'Components/UI'

import { useAppContext, useResponsiveLayout } from 'Hooks'

import PermissionProvider from 'Services/Permission/Provider'

import PrivacyAgreement from './PrivacyAgreement'
import {
  Container,
  Content,
  MobileContainer,
  PageContent,
  PrivacyContainer,
} from './styles'

function OnboardingLayout() {
  const notificationsButtonRef = useRef<HTMLButtonElement | null>(null)
  const invitationsButtonRef = useRef<HTMLButtonElement | null>(null)
  const { isMobile } = useResponsiveLayout()
  const { me } = useAppContext()

  if (!me?.agreedToTermsAndConditions) {
    return (
      <PermissionProvider>
        {isMobile ? (
          <MobileContainer>
            <MobileHeader menuHidden restricted />
            <PrivacyAgreement />
          </MobileContainer>
        ) : (
          <Container>
            <Header menuHidden restricted />
            <Row fullHeight fullWidth justifyCenter p={5}>
              <PrivacyContainer>
                <PrivacyAgreement />
              </PrivacyContainer>
            </Row>
          </Container>
        )}
      </PermissionProvider>
    )
  }

  return (
    <PermissionProvider>
      <>
        {isMobile ? (
          <MobileContainer>
            <MobileHeader />
            <Column fullScreen pb="48px" pt="56px">
              <Outlet />
            </Column>
            <MobileFooter />
          </MobileContainer>
        ) : (
          <Container>
            <Header
              invitationsButtonRef={invitationsButtonRef}
              menuHidden
              notificationsButtonRef={notificationsButtonRef}
              restricted
            />
            <Content>
              <PageContent>
                <Outlet />
              </PageContent>
            </Content>
          </Container>
        )}
      </>
    </PermissionProvider>
  )
}

export default OnboardingLayout
