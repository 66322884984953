import { createTheme } from 'styled-breakpoints'

import { BREAKPOINTS_DIMENSIONS } from 'Constants/ids'

const styledBreakpoints = createTheme({
  xss: '1px',
  xs: `${BREAKPOINTS_DIMENSIONS.extraSmall}px`,
  sm: `${BREAKPOINTS_DIMENSIONS.small}px`,
  md: `${BREAKPOINTS_DIMENSIONS.middle}px`,
  lg: `${BREAKPOINTS_DIMENSIONS.large}px`,
  xl: `${BREAKPOINTS_DIMENSIONS.extraLarge}px`,
})

export const breakpoints = ['360px', '768px', '992px', '1200px']

//             0  1  2  3   4   5   6   7   8   9   10  11  12  13
const space = [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 56]

//                0   1   2   3   4   5
const fontSize = [10, 12, 14, 16, 20, 32]

//                  0    1    2    3
const fontWeight = [400, 500, 600, 700]

//                      0   1   2   3   4   5
const controlHeights = [16, 20, 24, 32, 40, 48]

const transitionTime = {
  default: '250ms',
  long: '400ms',
  short: '150ms',
  modal: '150ms',
}
const font = {
  fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, sans-serif',
  webFonts: ['Inter:wght@400;500;600'],
}

const zIndices = {
  popover: 400,
  modal: 1000,
  modalFullscreen: 2000,
  mobileFooter: 900,
}

const defaultTheme = {
  ...styledBreakpoints,
  breakpoints,
  controlHeights,
  font,
  fontSize,
  fontWeight,
  name: 'defaultTheme',
  space,
  transitionTime,
  zIndices,
}

export default defaultTheme
