import { TiMicrophoneOutline } from 'react-icons/ti'

import styled, { css } from 'styled-components'
import { margin, MarginProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { IconDotsVertical } from '@tabler/icons-react'

import { aiLightBulb, arrowRightGlyph, chevronLeftGlyph } from 'Assets/Svg'
import { glowingLightBulb } from 'Assets/Svg/icons'

import { resetButton } from 'Components/Styles'
import { IButton } from 'Components/UI/Button/Button'

import colors from 'Theme/_v2/colors'
import { customScrollbar } from 'Theme/styles'

export interface IContainerProps extends MarginProps {}

export const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  gap: ${themeGet('space.4')}px;

  ${margin}
`

const boxShadowOffset = '2px'

export const ScrollableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: scroll;
  gap: ${themeGet('space.3')}px;
  margin-right: -12px;
  padding-right: 12px;
  padding-top: ${boxShadowOffset};
  padding-left: ${boxShadowOffset};
  padding-bottom: ${boxShadowOffset};

  ${customScrollbar}
`
export const Card = styled.div`
  box-shadow: 0px 1px ${boxShadowOffset} 1px rgba(196, 196, 196, 0.32);
  border-radius: 4px;
  padding: 12px 14px;
`
export const CardHdg = styled.h3`
  font-size: 12px;
  background-color: #f0f2f3;
  padding: 2px 4px;
  display: inline-flex;
  margin: 0;
  color: #757575;
  font-weight: normal;
  line-height: 1;
  border-radius: 4px;
`
interface CardBdProps {
  truncate?: boolean
}
export const CardBd = styled.p<CardBdProps>`
  font-size: 12px;
  color: #2d2d2d;
  font-weight: normal;
  line-height: 18px;
  border-radius: 4px;
  margin-top: 8px;
  margin-bottom: 12px;

  ${({ truncate }) =>
    truncate &&
    `
    max-height: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    white-space: normal;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
  `}
`

export const CardMeta = styled.div`
  font-size: 10px;
  color: #757575;
  font-weight: normal;
  line-height: 1;
`

export const LightBulbIcon = styled(glowingLightBulb)``

export const DotsIcon = styled(IconDotsVertical)`
  width: 16px;
  height: 16px;
  color: ${themeGet('colors.text.header')};
  stroke-width: 1.5px;
`
export const PlainButton = styled.button<IButton>`
  ${resetButton}
  cursor: pointer;
  text-align: left;

  &:hover {
    background-color: rgba(240, 242, 243, 0.5);
  }
`

export const BtnWrapper = styled.div`
  margin-bottom: 20px;
`

export const RecommendedPeople = styled.ol`
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid #dde1e4;

  > * {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  > * + * {
    border-top: 1px solid #dde1e4;
  }
`

export const RecommendedPerson = styled.button<IButton>`
  ${resetButton}
  width: 100%;
  cursor: pointer;
  text-align: left;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;

  > * + * {
    margin-left: 10px;
  }
`
export const SectionHdg = styled.h3`
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 20px;
  margin-top: 20px;
`
export const ArrowIcon = styled(arrowRightGlyph)<{ isActive?: boolean }>`
  height: 8px;
  stroke: #757575;

  ${({ isActive }) =>
    isActive &&
    `
    transform: rotate(90deg);
  `}
`
export const BackArrowIcon = styled(chevronLeftGlyph)``
export const AiIcon = styled(aiLightBulb)`
  height: 8px;
`
export const Back = styled.div`
  display: flex;
  align-items: center;
  font-size: 11px;
  line-height: 16px;
  font-weight: 600;
  color: #757575;

  > *:last-child {
    margin-left: 4px;
  }
`
export const Explanation = styled.p`
  font-size: 12px;
  line-height: 18px;
`
export const Label = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const CreateMenu = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  top: 3px;
  right: 36px;
  background-color: #ffffff;
  min-width: 122px;

  box-shadow:
    0px 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 2px;

  button {
    ${resetButton}
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    line-height: 26px;
    padding: 4px 8px;
    width: 100%;
    color: #757575;
    text-align: left;

    &:hover {
      background-color: rgba(240, 242, 243, 0.5);
    }
  }
`
export const Actions = styled.div`
  display: flex;
  justify-content: end;
  padding-top: 10px;

  > * + * {
    margin-left: 8px;
  }
`
export const Controls = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 2px;
  align-items: center;
`

export const MicButton = styled.button<IButton>`
  ${resetButton}
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px;
`
export const MicIcon = styled(TiMicrophoneOutline)`
  height: 18px;
  width: 18px;
`
export const MicIconActive = styled.div`
  width: 18px;
  height: 18px;
  background-color: #eb2a29;
  border-radius: 6px;
`

export const InputWrapper = styled.div`
  position: relative;
`

interface IDotsButton {
  active?: boolean
}

const dotsButtonActiveCss = ({ active }: IDotsButton) =>
  active &&
  css`
    background-color: #f0f0f0;
  `
export const DotsButton = styled.button<IDotsButton>`
  ${resetButton}

  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.icon.profile};
  width: 30px;
  height: 30px;
  border-radius: 15px;

  :enabled {
    cursor: pointer;
  }

  ${dotsButtonActiveCss}

  :hover, :active {
    ${dotsButtonActiveCss({ active: true })}
  }
`
export const CardControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
