import React from 'react'
import PropTypes from 'prop-types'

import { useResponsiveLayout } from 'Hooks'

import { Container } from './styles'

function TableControlContainer({ children }) {
  const { isMobile } = useResponsiveLayout()

  return <Container wrapped={isMobile}>{children}</Container>
}

TableControlContainer.defaultProps = {
  children: null,
}

TableControlContainer.propTypes = {
  children: PropTypes.node,
}

export default TableControlContainer
