import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { handshakeGlyph } from 'Assets/Svg/icons'

export const HandshakeIcon = styled(handshakeGlyph)`
  @keyframes flashingHandshake {
    0% {
      stroke: ${themeGet('colors.grayBoulder')};
    }
    50% {
      stroke: ${themeGet('colors.primaryCardinal')};
    }
    100% {
      stroke: ${themeGet('colors.grayBoulder')};
    }
  }
  stroke: ${themeGet('colors.grayBoulder')};
  stroke-width: 1;
  padding: 2px;
  height: ${themeGet('space.5')}px;
  width: ${themeGet('space.5')}px;
  animation: flashingHandshake 1s infinite;
  :hover {
    color: ${themeGet('colors.primary')};
    text-decoration: underline;
    cursor: pointer;
  }
`
