import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useMutation } from '@apollo/client'
import { IconSearch } from '@tabler/icons-react'
import removeSharedUsersMutation from 'GraphQL/Mutations/User/removeSharedUsers.graphql'
import sharedUsersByCreatorQuery from 'GraphQL/Queries/User/sharedUsersByCreator.graphql'
import { entityCacheRemover } from 'GraphQL/Updaters/Common'
import Utils from 'Utils'
import { getFullName } from 'Utils/User'

import map from 'lodash/map'

import ContentCard from 'Components/Blocks/ContentCard'
import { Input, Modal, Row, Table, Text } from 'Components/UI'

import {
  useCommunity,
  useEntityModal,
  useEntityTable,
  useResponsiveLayout,
  useTableSearch,
} from 'Hooks'

import { useQuery } from 'Services/Apollo'
import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { useColumns } from './columns'
import TableContext from './context'
import Filter from './Filter'
import { composeSortBy } from './utils'

import { TableControlContainer, TablePagination } from '../Blocks'

const SORT_BY = [
  {
    column: 'createdAt',
    order: 'desc',
  },
]

const QUERY_KEY = 'sharedUsersByCreator'

function SharedUsersTable() {
  const s = useScopedI18n('sharedUsersManagement')

  const { community } = useCommunity()
  const { isMobile } = useResponsiveLayout()
  const [deleteModal, deleteModalActions] = useEntityModal()

  const [mostRecentPage, setMostRecentPage] = useState(0)
  const [mostRecentLimit, setMostRecentLimit] = useState(10)
  const [mostRecentSortBy, setMostRecentSortBy] = useState(SORT_BY)
  const [filter, setFilter] = useState({})
  const [innerSearch, setInnerSearch] = useState(null)

  const [removeSharedUsers] = useMutation(removeSharedUsersMutation)

  const [search, changeSearch, clearSearch] = useTableSearch({
    onSearch: value => {
      Utils.Search.tableSearch({
        search: value,
        onSetSearchValue: setInnerSearch,
        onGoToPage: tableProps.gotoPage,
      })
    },
  })

  const { data, loading } = useQuery(sharedUsersByCreatorQuery, {
    variables: {
      communityId: community?.id,
      page: mostRecentPage,
      limit: mostRecentLimit,
      recipientUserIds: filter?.recipientIds,
      createdFrom: filter?.createdFrom,
      createdTo: filter?.createdTo,
      sort: mostRecentSortBy,
      searchByTarget: innerSearch,
    },
    fetchPolicy: 'network-only',
    skip: !community,
  })

  const columns = useColumns()
  const entities = useMemo(() => data?.[QUERY_KEY] || {}, [data])

  const [tableProps, rowsCount] = useEntityTable({
    data: entities,
    columns,
    initialState: {
      sortBy: [
        {
          id: 'createdAt',
          desc: true,
        },
      ],
    },
  })

  const { pageSize, pageIndex, sortBy } = tableProps.state

  const sort = useMemo(
    () =>
      map(sortBy, value => ({
        column: value.id,
        order: value.desc ? 'desc' : 'asc',
      })),
    [sortBy],
  )

  useEffect(() => {
    setMostRecentPage(pageIndex)
    setMostRecentSortBy(sort.length !== 0 ? composeSortBy(sort) : null)
  }, [pageIndex, sort])

  useEffect(() => {
    setMostRecentPage(0)
    setMostRecentLimit(pageSize)
  }, [pageSize])

  const handleDeleteModalClose = deleteModalActions.closeModal

  const handleDelete = useCallback(
    async success => {
      if (!success) {
        return
      }

      try {
        await removeSharedUsers({
          variables: {
            sharedUserIds: [deleteModal?.entity?.id],
          },
          update: entityCacheRemover({
            entity: deleteModal?.entity,
            pageSize: mostRecentLimit,
            queryKey: QUERY_KEY,
          }),
        })
        toast.success({
          title: s('deleteTitle'),
          text: s('messages.deleteSuccess'),
        })

        handleDeleteModalClose().then()
      } catch (error) {
        toast.error({
          title: 'Server error',
          text: _(`error.${error?.message || 'generic'}`),
        })
      }
    },
    [
      removeSharedUsers,
      deleteModal,
      mostRecentLimit,
      s,
      handleDeleteModalClose,
    ],
  )

  const deleteMessage = useMemo(
    () => (
      <Text
        dangerouslySetInnerHTML={{
          __html: s('messages.delete', {
            userName: getFullName(deleteModal?.entity?.recipient?.profile),
          }),
        }}
      />
    ),
    [deleteModal, s],
  )

  const memoizedContext = useMemo(
    () => ({ onDeleteRow: deleteModalActions.openModal }),
    [deleteModalActions],
  )

  return (
    <ContentCard>
      <TableContext.Provider value={memoizedContext}>
        <TableControlContainer>
          <Row center gap={3} wrapped={isMobile}>
            <Input
              clearable
              name="search"
              placeholder={s('search')}
              renderBeforeElement={() => <IconSearch />}
              value={search}
              width="300px"
              onChange={changeSearch}
              onClear={clearSearch}
            />

            <Filter onChange={setFilter} />
          </Row>
        </TableControlContainer>

        <Table {...tableProps} loading={loading} />

        <TablePagination
          state={{ pageIndex, pageSize }}
          total={rowsCount}
          onPageIndexChange={tableProps?.gotoPage}
          onPageSizeChange={tableProps?.setPageSize}
        />

        <Modal
          cancelText={_('general.cancel')}
          confirmText={s('actions.delete')}
          isOpen={deleteModal.isOpen}
          title={s('actions.delete')}
          onClose={handleDeleteModalClose}
          onConfirm={handleDelete}
        >
          {deleteMessage}
        </Modal>
      </TableContext.Provider>
    </ContentCard>
  )
}

export default SharedUsersTable
