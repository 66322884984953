import React from 'react'
import { RouteObject } from 'react-router-dom'

import { AdminLayout } from 'Components/Layout'

import Admin from 'Pages/Admin'

import * as ROUTES from './routes'

export const ADMIN_ROUTES: RouteObject = {
  element: <AdminLayout />,
  path: ROUTES.ADMIN_ROOT,
  children: [
    {
      element: <Admin.Root />,
      path: ROUTES.ADMIN_ROOT,
    },
    {
      element: <Admin.Admins />,
      path: ROUTES.ADMIN_ADMINS,
    },
    {
      element: <Admin.Users />,
      path: ROUTES.ADMIN_USERS,
    },
    {
      element: <Admin.Skills />,
      path: ROUTES.ADMIN_SKILLS,
    },
    {
      element: <Admin.Communities />,
      path: ROUTES.ADMIN_COMMUNITIES,
    },
    {
      element: <Admin.CommunityUsers />,
      path: ROUTES.ADMIN_COMMUNITY_USERS,
    },
    {
      element: <Admin.CommunityRBA />,
      path: ROUTES.ADMIN_COMMUNITY_RBA,
    },
    {
      element: <Admin.ChangeReportCommunities />,
      path: ROUTES.ADMIN_CHANGE_REPORT_COMMUNITIES,
    },
    {
      element: <Admin.ChangeReports />,
      path: ROUTES.ADMIN_CHANGE_REPORTS,
    },
    {
      element: <Admin.ChangeReportsUpload />,
      path: ROUTES.ADMIN_CHANGE_REPORTS_UPLOAD,
    },
  ],
}
