import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import acceptCommunityInviteMutation from 'GraphQL/Mutations/Community/acceptCommunityInvite.graphql'

import { Column, Text } from 'Components/UI'

import { useLocationQueryParams } from 'Hooks'

import { REDIRECTOR } from 'Router/routes'

import { useMutation } from 'Services/Apollo'

function AcceptCommunityInvite() {
  const navigate = useNavigate()
  const queryParams = useLocationQueryParams()
  const [inviteId, setId] = useState<string | null>(
    queryParams?.inviteId as string,
  )

  const [acceptCommunityInvite] = useMutation<
    Pick<MainSchema.Mutation, 'acceptCommunityInvite'>,
    MainSchema.MutationAcceptCommunityInviteArgs
  >(acceptCommunityInviteMutation)

  useEffect(() => {
    async function checkInvite() {
      if (inviteId) {
        try {
          await acceptCommunityInvite({
            variables: { inviteId },
          })

          navigate(REDIRECTOR)
        } catch (e) {
          setId(null)
        }
      }
    }

    checkInvite()
  }, [navigate, acceptCommunityInvite, inviteId])

  return !inviteId ? (
    <Column center fullWidth grow justifyCenter>
      <Column>
        <Text big bold mb={1}>
          Oops, something went wrong
        </Text>
        <Text inline>
          Invite id is not valid.
          <br />
          Please request the new one.
        </Text>
      </Column>
    </Column>
  ) : null
}

export default AcceptCommunityInvite
