import React from 'react'

import { Row } from 'Components/UI'

import {
  Block,
  BulletContent,
  BulletItem,
  Content,
  Header,
  SubHeader,
} from '../styles'

export interface IBulletBlockProps {
  header: string
  items?: string[]
  subHeader?: string
  summary?: string
}

function BulletBlock({ header, subHeader, summary, items }: IBulletBlockProps) {
  return (
    <Block mb={4}>
      <Row mb={4}>
        <Header mineShaft>{header}</Header>
      </Row>
      <Row wrapped>
        {summary && (
          <Content justify lineHeight="20px" mineShaft>
            {summary}
          </Content>
        )}
        {!!items?.length && (
          <>
            {subHeader && (
              <SubHeader fontWeight="bold" mb={1} mineShaft mt={4}>
                {subHeader}
              </SubHeader>
            )}
            <BulletContent>
              {items.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <BulletItem key={index}>{item}</BulletItem>
              ))}
            </BulletContent>
          </>
        )}
      </Row>
    </Block>
  )
}

export default BulletBlock
