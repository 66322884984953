import reduce from 'lodash/reduce'

function composeSortBy(sortBy) {
  return reduce(
    sortBy,
    (acc, item) => {
      switch (item.column) {
        case 'user': {
          acc.push({
            column: 'targetFirstName',
            order: item.order,
          })
          acc.push({
            column: 'targetLastName',
            order: item.order,
          })
          break
        }
        case 'recipient': {
          acc.push({
            column: 'recipientFirstName',
            order: item.order,
          })
          acc.push({
            column: 'recipientLastName',
            order: item.order,
          })
          break
        }
        default:
          acc.push(item)
          break
      }

      return acc
    },
    [],
  )
}

export { composeSortBy }
