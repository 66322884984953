import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { IconDotsVertical } from '@tabler/icons-react'

import { resetButton } from 'Components/Styles'

import colors from 'Theme/_v2/colors'
import { gap, GapProps } from 'Theme/system'

export interface DotButtonActiveCss {
  active?: boolean
}

const dotsButtonActiveCss = ({ active }: DotButtonActiveCss) =>
  active &&
  css`
    background-color: #f0f0f0;
    visibility: visible !important;
  `

export interface DotsButtonProps extends DotButtonActiveCss {}

export const DotsButton = styled.button<DotsButtonProps>`
  ${resetButton}

  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.icon.profile};
  width: 14px;
  height: 14px;
  border-radius: 100%;

  :enabled {
    cursor: pointer;
  }

  ${dotsButtonActiveCss}

  :hover, :active {
    ${dotsButtonActiveCss({ active: true })}
  }
`

export const DotsIcon = styled(IconDotsVertical)`
  width: 12px;
  height: 12px;
  color: ${colors.icon.profile};
`

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${themeGet('colors.bg.primary')};
  border-radius: 2px;
  box-shadow:
    0px 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  min-width: 50px;
`

export interface OptionProps extends GapProps {}

export const Option = styled.button<OptionProps>`
  ${resetButton};

  :enabled {
    cursor: pointer;
  }

  display: flex;
  width: 100%;
  padding: ${themeGet('space.2')}px;
  align-items: center;

  span {
    color: #2d2d2d;
    font-size: 10px;
    line-height: 12px;
  }

  :hover {
    color: ${themeGet('colors.hover')};
    background-color: ${themeGet('colors.bg.hover')};

    span {
      color: ${themeGet('colors.hover')};
    }
  }

  ${gap}
`
