import React from 'react'
import PropTypes from 'prop-types'

import noop from 'lodash/noop'

import { Pagination } from 'Components/UI'

import { useResponsiveLayout } from 'Hooks'

import { Container } from './styles'

function TablePagination({
  state,
  total,
  onPageIndexChange,
  onPageSizeChange,
}) {
  const { isMobile } = useResponsiveLayout()

  return (
    <Container>
      <Pagination
        mobile={isMobile}
        showTotal={!isMobile}
        state={state}
        total={total}
        onPageIndexChange={onPageIndexChange}
        onPageSizeChange={onPageSizeChange}
      />
    </Container>
  )
}

TablePagination.defaultProps = {
  state: { pageSize: 10, pageIndex: 0 },
  total: 0,
  onPageIndexChange: noop,
  onPageSizeChange: noop,
}

TablePagination.propTypes = {
  state: PropTypes.shape({
    pageSize: PropTypes.number,
    pageIndex: PropTypes.number,
  }),
  total: PropTypes.number,
  onPageIndexChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
}

export default TablePagination
