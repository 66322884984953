import React, { useCallback, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import deleteUserMutation from 'GraphQL/Mutations/User/deleteUser.graphql'
import usersQuery from 'GraphQL/Queries/Admin/users.graphql'
import { entityCacheRemover } from 'GraphQL/Updaters/Common'
import Utils from 'Utils'

import {
  EditProfileModal,
  InviteUserModal,
} from 'Components/Blocks/Admin/Modals'
import { Dialog } from 'Components/Blocks/Modals'
import { Button, Input, Pagination, Row, Text } from 'Components/UI'
import { Table } from 'Components/UI/Admin'

import { USER_ROLE } from 'Constants/ids'

import {
  useAppContext,
  useEntityModal,
  useEntityTable,
  useTableSearch,
} from 'Hooks'

import { useAdminQuery, useMutation } from 'Services/Apollo'
import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { useColumns } from './columns'
import TableContext from './context'
import { Container, Content } from './styles'

const SORT_BY = [
  {
    column: 'lastLoginAt',
    order: 'asc',
  },
]

function AdminsTable() {
  const { isSuperAdmin } = useAppContext()

  const s = useScopedI18n('user')

  const mostRecentPage = useRef(0)
  const mostRecentLimit = useRef(10)

  const [innerSearch, setInnerSearch] = useState(null)

  const [search, changeSearch, clearSearch] = useTableSearch({
    onSearch: value => {
      Utils.Search.tableSearch({
        search: value,
        onSetSearchValue: setInnerSearch,
        onGoToPage: tableProps.gotoPage,
      })
    },
  })

  const { data, loading, refetch } = useAdminQuery(usersQuery, {
    variables: {
      page: mostRecentPage.current,
      limit: mostRecentLimit.current,
      sort: SORT_BY,
      roles: [USER_ROLE.SUPER_ADMIN],
      search: innerSearch,
    },
    fetchPolicy: 'network-only',
  })

  const columns = useColumns()
  const entities = data?.users ?? {}
  const [tableProps, rowsCount] = useEntityTable({
    data: entities,
    columns,
    disableSortBy: true,
  })
  const { pageSize, pageIndex } = tableProps.state

  mostRecentPage.current = pageIndex
  mostRecentLimit.current = pageSize

  const refetchOnClose = useCallback(
    ({ context }) =>
      async success => {
        if (!success) {
          return
        }

        try {
          await refetch()
        } catch (error) {
          toast.error({
            title: context,
            text: error?.message,
          })
        }
      },
    [refetch],
  )

  const [editModal, editActions] = useEntityModal({
    refetch: refetchOnClose({ context: s('actions.edit') }),
  })

  const [deleteModal, deleteActions] = useEntityModal({
    context: s('actions.delete'),
  })

  const [inviteModal, inviteActions] = useEntityModal({
    context: s('actions.delete'),
  })

  const [deleteUser] = useMutation(deleteUserMutation, {
    update: entityCacheRemover({
      entity: deleteModal.entity,
      pageSize,
      queryKey: 'users',
    }),
  })

  const handleDeleteUser = useCallback(
    async success => {
      if (!success) {
        return
      }

      try {
        await deleteUser({
          variables: {
            id: deleteModal.entity?.id,
          },
        })

        toast.success({
          title: s('actions.delete'),
          text: s('deleteSuccess'),
        })
      } catch (error) {
        toast.error({
          title: s('actions.delete'),
          text: error.message,
        })
      }
    },
    [deleteModal.entity?.id, deleteUser, s],
  )

  const handleEditModalClose = editActions.closeModal
  const handleDeleteModalClose = deleteActions.closeModal
  const handleOpenInviteModal = inviteActions.openModal
  const handleCloseInviteModal = inviteActions.closeModal

  const userToDeleteFirstName = Utils.Table.renderOptionalString({
    value: deleteModal.entity?.profile?.firstName,
  })
  const userToDeleteLastName = Utils.Table.renderOptionalString({
    value: deleteModal.entity?.profile?.lastName,
  })

  const deleteUserMessage = (
    <Text>
      {s('messages.delete', {
        userName: `${userToDeleteFirstName} ${userToDeleteLastName}`,
      })}
    </Text>
  )

  const memoizedContext = useMemo(() => {
    return {
      onEditRow: editActions.openModal,
      onDeleteRow: deleteActions.openModal,
    }
  }, [editActions, deleteActions])

  return (
    <TableContext.Provider value={memoizedContext}>
      <Container>
        <Text header header2 mb={3} mt={4}>
          Super Admins management
        </Text>
        <Content>
          <Row borderBottom center mb={2} pb={3} spaceBetween>
            <Row>
              <Input
                clearable
                name="search"
                placeholder={_('search.byName')}
                small
                value={search}
                width={300}
                onChange={changeSearch}
                onClear={clearSearch}
              />
            </Row>

            <Row>
              {isSuperAdmin && (
                <Button small onClick={handleOpenInviteModal}>
                  {_('user.actions.inviteAdmin')}
                </Button>
              )}
            </Row>
          </Row>

          <Table {...tableProps} loading={loading} />

          <Row borderTop mt={2} pt={3}>
            <Pagination
              state={{ pageSize, pageIndex }}
              total={rowsCount}
              onPageIndexChange={tableProps?.gotoPage}
              onPageSizeChange={tableProps?.setPageSize}
            />
          </Row>
        </Content>
      </Container>

      {isSuperAdmin && (
        <InviteUserModal
          isOpen={inviteModal.isOpen}
          onClose={handleCloseInviteModal}
        />
      )}

      <EditProfileModal
        isOpen={editModal.isOpen}
        user={editModal.entity}
        onClose={handleEditModalClose}
      />

      <Dialog
        content={deleteUserMessage}
        isOpen={deleteModal.isOpen}
        title={s('actions.delete')}
        onClose={handleDeleteModalClose}
        onFinish={handleDeleteUser}
      />
    </TableContext.Provider>
  )
}

AdminsTable.defaultProps = {
  search: '',
}

AdminsTable.propTypes = {
  search: PropTypes.string,
}

export default AdminsTable
