import React, {
  ChangeEvent,
  MouseEvent,
  PropsWithChildren,
  useRef,
  useState,
} from 'react'

import Switch from 'Components/UI/Form/Switch/Switch'

import { Item, ItemLabel, Menu, MenuUnfold } from './styles'

export interface IMenuItem extends PropsWithChildren {
  id: string
  isToggled?: boolean
  label: string
  useToggle?: boolean
  areChildrenScrollable?: boolean
  onClick?: (
    event: MouseEvent<HTMLDivElement> | ChangeEvent<Element>,
    id: string,
  ) => void
}

function MenuItem({
  children,
  label,
  id,
  useToggle = false,
  isToggled = false,
  areChildrenScrollable,
  onClick,
}: IMenuItem) {
  const itemRef = useRef(null)
  const [isHovered, setIsHovered] = useState(false)

  return (
    <Item
      ref={itemRef}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <ItemLabel onClick={event => onClick?.(event, id)}>{label}</ItemLabel>
      {useToggle && (
        <Switch checked={isToggled} onChange={event => onClick?.(event, id)} />
      )}

      {!useToggle && React.Children.count(children) > 0 && <MenuUnfold />}

      {isHovered && React.Children.count(children) > 0 && (
        <Menu hovered scrollable={areChildrenScrollable}>
          {children}
        </Menu>
      )}
    </Item>
  )
}

export default MenuItem
