import React from 'react'

import { IconPlus } from '@tabler/icons-react'

import { Button } from './AddButton.styles'

export interface AddButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

export default function AddButton({ onClick }: AddButtonProps) {
  return (
    <Button onClick={onClick}>
      <IconPlus size={10} />
    </Button>
  )
}
