export const TABS = {
  ACCOUNTS: 'Accounts',
  ADMIN: 'Admin',
  HUBSPOTS: 'HubSpots',
  NOTES: 'Notes',
  PROPOSALS: 'Proposals',
  RECOMMENDATIONS: 'Recommendations',
  ROLE_BASED_ACCESS: 'Role based access',
  SHARED_USERS: 'Shared users',
  SKILLS: 'Skills',
  TAGS: 'Tags',
}
