import React from 'react'

import { Row, Tooltip } from 'Components/UI'

import { useScopedI18n } from 'Services/I18n'

import { sdgsGoals, SDGSImage, SGGSContainer, SocialScore } from './styles'

import { Block, Header, InfoIcon, SubHeader, TooltipContent } from '../styles'

export interface ISocialRatingProps {
  sdgs?: string[]
  socialRating?: string
  user: MainSchema.GetCommunityUser
}

function SocialRating({ socialRating = '0', sdgs, user }: ISocialRatingProps) {
  const s = useScopedI18n('changeReport')

  const sdgsInfo = (
    <TooltipContent justify>
      To end poverty, protect the planet and ensure prosperity for all.{' '}
      {user.firstName}&apos;s work contributes towards the 5 UN SDGs shown
      below. There is no point of United We Transform and working smart locally
      if we don&apos;t connect to our wider humanity.
    </TooltipContent>
  )

  const socialRatingIfnp = (
    <TooltipContent justify>{s('socialRating')}</TooltipContent>
  )

  return (
    <Block mb={4}>
      <Row mb={4}>
        <Header fontSize={2} fontWeight="bold" mineShaft textAlign="center">
          Company & Social Score
        </Header>
      </Row>
      <Row wrapped>
        {socialRating !== null && (
          <SocialScore>
            Social Score: {socialRating}/15{' '}
            <Tooltip content={socialRatingIfnp}>
              <InfoIcon top="2px" />
            </Tooltip>
          </SocialScore>
        )}
        {!!sdgs?.length && (
          <>
            <SubHeader fontWeight="bold" mb={1} mineShaft mt={4}>
              <b>UN</b> Sustainable Development Goals
              <Tooltip content={sdgsInfo}>
                <InfoIcon left="5px" top="5px" />
              </Tooltip>
            </SubHeader>
            <SGGSContainer>
              {sdgs.map(score => {
                const ScoredIcon = sdgsGoals[score]

                return (
                  <div key={score}>
                    {ScoredIcon && (
                      <Tooltip content={s(`goals.${score}`)}>
                        <SDGSImage>
                          <ScoredIcon />
                        </SDGSImage>
                      </Tooltip>
                    )}
                  </div>
                )
              })}
            </SGGSContainer>
          </>
        )}
      </Row>
    </Block>
  )
}

export default SocialRating
