import React, { PropsWithChildren } from 'react'

import communityRoleAccessQuery from 'GraphQL/Queries/Community/communityRoleAccess.graphql'

import { Loader } from 'Components/UI'

import { USER_ROLE } from 'Constants/ids'

import { useAppContext, useCommunityContext } from 'Hooks'

import { useQuery } from 'Services/Apollo'
import PermissionService from 'Services/Permission'

import PermissionContext from './PermissionContext'

export interface IPermissionProvider extends PropsWithChildren {}

function PermissionProvider({ children }: IPermissionProvider) {
  const { me } = useAppContext()
  const { community } = useCommunityContext()

  const isSuperAdmin = me?.role === USER_ROLE.SUPER_ADMIN

  const { data: communityRoleAccessData, loading } = useQuery<
    Pick<MainSchema.Query, 'communityRoleAccess'>,
    MainSchema.QueryCommunityRoleAccessArgs
  >(communityRoleAccessQuery, {
    variables:
      community?.id && me?.communityRole?.id
        ? {
            communityId: community.id,
            communityRoleId: me.communityRole.id,
          }
        : undefined,
    skip: !community?.id || !me?.communityRole?.id || isSuperAdmin,
    fetchPolicy: 'network-only',
  })

  const ability = communityRoleAccessData?.communityRoleAccess
    ? PermissionService.createAbility(
        JSON.parse(communityRoleAccessData.communityRoleAccess),
      )
    : PermissionService.createAbility([])

  if (loading) return <Loader absolute />

  return (
    <PermissionContext.Provider value={ability}>
      {children}
    </PermissionContext.Provider>
  )
}

export default PermissionProvider
