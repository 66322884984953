import { NavLink } from 'react-router-dom'

import styled from 'styled-components'
import { padding, PaddingProps, space, SpaceProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { sidebarLogoGlyph } from 'Assets/Svg'

export interface IMenu extends PaddingProps {}

export const Menu = styled.header.attrs<IMenu>({
  px: [5, 5, 6],
})`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 70px;
  z-index: 1000;
  background-color: ${themeGet('colors.bg.primary')};
  transition-duration: ${themeGet('transitionTime.default')};
  border-bottom: 1px solid ${themeGet('colors.divider.default')};

  ${padding}
`

export const MenuLink = styled(NavLink)`
  display: flex;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover,
  &:focus {
    > p {
      color: ${themeGet('colors.hover')};
    }
  }
`

export interface ILogo extends SpaceProps {}

export const Logo = styled(sidebarLogoGlyph).attrs<ILogo>({
  width: '130px',
  height: '32px',
})`
  ${space};
`

export const HamburgerMenuContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${themeGet('space.5')}px;
  gap: ${themeGet('space.4')}px;
  background-color: ${themeGet('colors.bg.primary')};
  border-top: 1px solid ${themeGet('colors.divider.default')};
  color: #007aff;
`

export const LogoWrapper = styled.div`
  display: flex;
  height: 100%;
`
