import styled from 'styled-components'
import { margin, MarginProps, padding, PaddingProps } from 'styled-system'

import {
  colorGoal1,
  colorGoal2,
  colorGoal3,
  colorGoal4,
  colorGoal5,
  colorGoal6,
  colorGoal7,
  colorGoal8,
  colorGoal9,
  colorGoal10,
  colorGoal11,
  colorGoal12,
  colorGoal13,
  colorGoal14,
  colorGoal15,
  colorGoal16,
  colorGoal17,
} from 'Assets/Svg/sdgsIcons'

export const sdgsGoals: Record<
  string,
  React.FC<React.SVGProps<SVGSVGElement>> | null
> = {
  '0': null,
  '1': colorGoal1,
  '2': colorGoal2,
  '3': colorGoal3,
  '4': colorGoal4,
  '5': colorGoal5,
  '6': colorGoal6,
  '7': colorGoal7,
  '8': colorGoal8,
  '9': colorGoal9,
  '10': colorGoal10,
  '11': colorGoal11,
  '12': colorGoal12,
  '13': colorGoal13,
  '14': colorGoal14,
  '15': colorGoal15,
  '16': colorGoal16,
  '17': colorGoal17,
}

export interface ISocialScoreProps extends MarginProps, PaddingProps {}

export const SocialScore = styled.p<ISocialScoreProps>`
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 20px;

  ${margin}
  ${padding}
`

export interface ISGGSContainerProps extends MarginProps, PaddingProps {}

export const SGGSContainer = styled.div<ISGGSContainerProps>`
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: space-evenly;
  width: 100%;

  ${margin}
  ${padding}
`

export const SDGSImage = styled.svg`
  width: 64px;
  height: 64px;
`
