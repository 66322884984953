import React from 'react'
import PropTypes from 'prop-types'

import { IconCheck, IconRefresh, IconTrash } from '@tabler/icons-react'

import noop from 'lodash/noop'

import { Row, Text } from 'Components/UI'

import { useResponsiveLayout } from 'Hooks'

import { useScopedI18n } from 'Services/I18n'

import { Container, Divider, StyledButton } from './styles'

function TableGroupOperatingPanel({
  canApprove,
  canDelete,
  canUpdateRole,
  canUpdateStatus,
  itemCount,
  suffix,
  onApprove,
  onDelete,
  onUpdateRole,
  onUpdateStatus,
}) {
  const s = useScopedI18n('blocks.tables.groupOperatingPanel')

  const { isMobile } = useResponsiveLayout()

  if (!itemCount) return null

  return (
    <Row center fullWidth>
      {!isMobile && <Divider mx={6} />}
      <Container wrapped={isMobile}>
        <Text bodyMedium header>
          {s('youSelect')}&nbsp;{itemCount}&nbsp;{suffix}:
        </Text>

        {canUpdateStatus && (
          <StyledButton onClick={onUpdateStatus}>
            <IconRefresh />
            {s('updateStatus')}
          </StyledButton>
        )}

        {canUpdateRole && (
          <StyledButton onClick={onUpdateRole}>
            <IconRefresh />
            {s('updateRole')}
          </StyledButton>
        )}

        {canApprove && (
          <StyledButton onClick={onApprove}>
            <IconCheck />
            {s('approve')}
          </StyledButton>
        )}

        {canDelete && (
          <StyledButton danger onClick={onDelete}>
            <IconTrash />
            {s('delete')}
          </StyledButton>
        )}
      </Container>
    </Row>
  )
}

TableGroupOperatingPanel.defaultProps = {
  canApprove: false,
  canDelete: false,
  canUpdateRole: false,
  canUpdateStatus: false,
  itemCount: 0,
  suffix: 'items',
  onApprove: noop,
  onDelete: noop,
  onUpdateRole: noop,
  onUpdateStatus: noop,
}

TableGroupOperatingPanel.propTypes = {
  canApprove: PropTypes.bool,
  canDelete: PropTypes.bool,
  canUpdateRole: PropTypes.bool,
  canUpdateStatus: PropTypes.bool,
  itemCount: PropTypes.number,
  suffix: PropTypes.string,
  onApprove: PropTypes.func,
  onDelete: PropTypes.func,
  onUpdateRole: PropTypes.func,
  onUpdateStatus: PropTypes.func,
}

export default TableGroupOperatingPanel
