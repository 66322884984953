import React, { forwardRef, useCallback } from 'react'

import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import updateGraphSnapshotMutation from 'GraphQL/Mutations/GraphSnapshot/updateGraphSnapshot.graphql'
import graphSnapshotQuery from 'GraphQL/Queries/GraphSnapshot/graphSnapshot.graphql'
import graphSnapshotsQuery from 'GraphQL/Queries/GraphSnapshot/graphSnapshots.graphql'

import { ContextMenu, ContextMenuItem } from 'Components/UI'

import { useAppContext, useGraphContext } from 'Hooks'

import EventBus from 'Services/EventBus'
import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import utils from './utils'

const CONTEXT_GRAPH_SNAPSHOTS_FIELD = {
  SAVE_GRAPH_SNAPSHOT: 'saveGraphSnapshot',
  VIEW_GRAPH_SNAPSHOTS: 'viewGraphSnapshots',
  VIEW_GRAPH_SNAPSHOT: (graphSnapshotId: string) =>
    `viewGraphSnapshots-${graphSnapshotId}`,
  MANAGE_GRAPH_SNAPSHOTS: 'manageGraphSnapshots',
  CREATE_NEW_GRAPH_SNAPSHOT: 'createNewGraphSnapshot',
}

interface GraphSnapshotContextMenuProps {
  isOpen: boolean
  left: number
  top: number
  setShowGraphSnapshotsMenu: React.Dispatch<React.SetStateAction<boolean>>
}

const GraphSnapshotContextMenu = forwardRef<
  HTMLDivElement,
  GraphSnapshotContextMenuProps
>(({ isOpen, left, top, setShowGraphSnapshotsMenu }, ref) => {
  const { communityUser } = useAppContext()
  const {
    setIsLoading,
    currentGraphSnapshotId,
    setCurrentGraphSnapshotId,
    loadGraphState,
    setSavedLoadGraphState,
    graphState,
    graphMapper,
  } = useGraphContext()
  const t = useScopedI18n('features.graphSnapshot')
  const client = useApolloClient()

  const { data: graphSnapshotsData } = useQuery<
    Pick<MainSchema.Query, 'graphSnapshots'>,
    MainSchema.QueryGraphSnapshotsArgs
  >(graphSnapshotsQuery, {
    variables: communityUser
      ? {
          communityId: communityUser.communityId,
        }
      : undefined,
    skip: !communityUser,
    fetchPolicy: 'network-only',
  })
  const graphSnapshots = graphSnapshotsData?.graphSnapshots ?? []
  const currentGraphSnapshot = graphSnapshots.find(
    graphSnapshot => graphSnapshot.id === currentGraphSnapshotId,
  )

  const [updateGraphSnapshot] = useMutation<
    Pick<MainSchema.Mutation, 'updateGraphSnapshot'>,
    MainSchema.MutationUpdateGraphSnapshotArgs
  >(updateGraphSnapshotMutation)

  const handleSaveGraphSnapshot = useCallback(
    async (id: MainSchema.GraphSnapshot['id']) => {
      setShowGraphSnapshotsMenu(false)
      setIsLoading(true)

      try {
        const state = utils.generateGraphSnapshotStateFromGraphState(graphState)

        await updateGraphSnapshot({
          variables: {
            id,
            state: state.json,
            stateVersion: state.version,
          },
        })

        setSavedLoadGraphState(loadGraphState)

        toast.success({
          title: t('toast.title'),
          text: t('toast.saved'),
        })
      } catch (error) {
        let message = _('error.generic')

        if (error instanceof Error) {
          message = _(`error.${error.message || 'generic'}`)
        }

        toast.error({
          title: t('toast.title'),
          text: message,
        })
      } finally {
        setIsLoading(false)
      }
    },
    [
      graphState,
      t,
      updateGraphSnapshot,
      setIsLoading,
      setShowGraphSnapshotsMenu,
      setSavedLoadGraphState,
      loadGraphState,
    ],
  )

  const handleViewGraphSnapshot = useCallback(
    async (id: MainSchema.GraphSnapshot['id']) => {
      if (!communityUser) {
        throw new Error('Community user not found')
      }

      setShowGraphSnapshotsMenu(false)
      setCurrentGraphSnapshotId(id)
      setIsLoading(true)

      try {
        const graphSnapshotResult = await client.query({
          query: graphSnapshotQuery,
          variables: {
            id,
          },
        })

        const graphSnapshot = graphSnapshotResult.data?.graphSnapshot

        if (!graphSnapshot) {
          throw new Error('Graph snapshot not found')
        }

        const loadGraphState =
          await utils.generateLoadGraphStateFromGraphSnapshotState(
            communityUser.communityId,
            {
              json: graphSnapshot.state,
              version: graphSnapshot.stateVersion,
            },
            communityUser.userId,
          )
        setSavedLoadGraphState(loadGraphState)

        graphMapper.handleLoadGraphState(loadGraphState)

        toast.success({
          title: t('toast.title'),
          text: t('toast.loaded'),
        })
      } catch (error) {
        let message = _('error.generic')

        if (error instanceof Error) {
          message = _(`error.${error.message || 'generic'}`)
        }

        toast.error({
          title: t('toast.title'),
          text: message,
        })
      } finally {
        setIsLoading(false)
      }
    },
    [
      setCurrentGraphSnapshotId,
      setSavedLoadGraphState,
      t,
      client,
      graphMapper,
      setIsLoading,
      setShowGraphSnapshotsMenu,
      communityUser,
    ],
  )

  const handleManageGraphSnapshots = useCallback(() => {
    setShowGraphSnapshotsMenu(false)
    EventBus.trigger(EventBus.actions.dashboard.manageGraphSnapshots)
  }, [setShowGraphSnapshotsMenu])

  const handleCreateNewGraphSnapshot = useCallback(() => {
    setShowGraphSnapshotsMenu(false)
    EventBus.trigger(EventBus.actions.dashboard.createGraphSnapshot)
  }, [setShowGraphSnapshotsMenu])

  return (
    <ContextMenu isOpen={isOpen} left={left} ref={ref} top={top}>
      {currentGraphSnapshotId && (
        <ContextMenuItem
          id={CONTEXT_GRAPH_SNAPSHOTS_FIELD.SAVE_GRAPH_SNAPSHOT}
          label={t('graphSnapshotContextMenu.saveGraphSnapshot', {
            name: currentGraphSnapshot?.name,
          })}
          onClick={() => handleSaveGraphSnapshot(currentGraphSnapshotId)}
        />
      )}

      {graphSnapshots.length > 0 && (
        <ContextMenuItem
          areChildrenScrollable
          id={CONTEXT_GRAPH_SNAPSHOTS_FIELD.VIEW_GRAPH_SNAPSHOTS}
          label={t('graphSnapshotContextMenu.viewGraphSnapshots')}
        >
          {graphSnapshots.map(graphSnapshot => (
            <ContextMenuItem
              id={CONTEXT_GRAPH_SNAPSHOTS_FIELD.VIEW_GRAPH_SNAPSHOT(
                graphSnapshot.id,
              )}
              key={graphSnapshot.id}
              label={graphSnapshot.name}
              onClick={() => handleViewGraphSnapshot(graphSnapshot.id)}
            />
          ))}
        </ContextMenuItem>
      )}

      {graphSnapshots.length > 0 && (
        <ContextMenuItem
          id={CONTEXT_GRAPH_SNAPSHOTS_FIELD.MANAGE_GRAPH_SNAPSHOTS}
          label={t('graphSnapshotContextMenu.manageGraphSnapshots')}
          onClick={handleManageGraphSnapshots}
        />
      )}

      <ContextMenuItem
        id={CONTEXT_GRAPH_SNAPSHOTS_FIELD.CREATE_NEW_GRAPH_SNAPSHOT}
        label={t('graphSnapshotContextMenu.createNewGraphSnapshot')}
        onClick={handleCreateNewGraphSnapshot}
      />
    </ContextMenu>
  )
})

export default GraphSnapshotContextMenu
