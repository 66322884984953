import forEach from 'lodash/forEach'

import { ACTION_KIND } from 'Constants/graph'
import { MentionKind } from 'Constants/mainGraphQL'
import { mentionRegex } from 'Constants/regex'

export interface IMentionedItem {
  kind: MentionKind
  id: string
  name: string
  firstName: string
  lastName: string
  email?: string
}

export function formatItemsByKind(content: string) {
  if (!content) return {}

  return Array.from(content?.matchAll(mentionRegex)).reduce(
    (acc: Record<string, IMentionedItem[]>, item) => {
      const personName = item[2].split(' ')

      const mentionedItem: IMentionedItem = {
        kind: item[1] as MentionKind,
        id: item[3],
        name: item[2],
        firstName: (personName?.length && personName?.[0]) || 'N/A/',
        lastName: (personName?.length && personName?.[1]) || 'N/A',
      }

      if (!acc[mentionedItem.kind]) {
        acc[mentionedItem.kind] = []
      }

      acc[mentionedItem.kind].push(mentionedItem)

      return acc
    },
    {},
  )
}

export interface IMentionedItems {
  toMeetUsers: IMentionedItem[]
  toAddUsers: IMentionedItem[]
  toAppendSkills: IMentionedItem[]
  toMentionedSkills: IMentionedItem[]
  toAppendNeedSkills: IMentionedItem[]
  toMentionedNeedSkills: IMentionedItem[]
  projects: IMentionedItem[]
  events: IMentionedItem[]
  roles: IMentionedItem[]
  customTags: IMentionedItem[]
  groups: IMentionedItem[]
}
export type MentionedItemKey = keyof IMentionedItems

export interface IMentionedUserIds {
  toAppendUserIds: string[]
  toMentionedUserIds: string[]
  toMeetUserIds: string[]
}

export interface IMentions extends IMentionedItems, IMentionedUserIds {}

export function parseNoteTags({
  content,
  targetUserSkills,
  targetUserNeedSkills,
}: {
  content: string
  targetUserSkills: MainSchema.Skill[]
  targetUserNeedSkills: MainSchema.Skill[]
}): IMentions {
  const toAppendSkills: IMentionedItem[] = []
  const toMentionedSkills: IMentionedItem[] = []
  const toAppendNeedSkills: IMentionedItem[] = []
  const toMentionedNeedSkills: IMentionedItem[] = []

  const formattedItems = formatItemsByKind(content)

  const toMeetUsers: IMentionedItem[] =
    formattedItems[ACTION_KIND.meetUser] ?? []
  const toAddUsers: IMentionedItem[] = formattedItems[ACTION_KIND.addUser] ?? []
  const projects: IMentionedItem[] = formattedItems[ACTION_KIND.project] ?? []
  const roles: IMentionedItem[] = formattedItems[ACTION_KIND.role] ?? []
  const customTags: IMentionedItem[] = formattedItems[ACTION_KIND.custom] ?? []
  const events: IMentionedItem[] = formattedItems[ACTION_KIND.event] ?? []
  const groups: IMentionedItem[] = formattedItems[ACTION_KIND.group] ?? []
  const currentSkills: IMentionedItem[] =
    formattedItems[ACTION_KIND.skill] ?? []
  const neededSkills: IMentionedItem[] =
    formattedItems[ACTION_KIND.needSkill] ?? []

  // TODO: check id instead of index?
  forEach(currentSkills, (skill, id) => {
    if (targetUserSkills?.[id]) {
      toMentionedSkills.push(skill)
    } else {
      toAppendSkills.push(skill)
    }
  })

  forEach(neededSkills, (skill, id) => {
    if (targetUserNeedSkills?.[id]) {
      toMentionedNeedSkills.push(skill)
    } else {
      toAppendNeedSkills.push(skill)
    }
  })

  return {
    toAppendUserIds: toMeetUsers.map(user => user.id),
    toMentionedUserIds: toAddUsers.map(user => user.id),
    toMeetUserIds: toMeetUsers.map(user => user.id),
    toMeetUsers,
    toAddUsers,
    toAppendSkills,
    toMentionedSkills,
    toAppendNeedSkills,
    toMentionedNeedSkills,
    projects,
    events,
    roles,
    customTags,
    groups,
  }
}

export function parseToMentionsInput(
  items: IMentionedItems,
): MainSchema.MentionInput[] {
  const keys: MentionedItemKey[] = [
    'projects',
    'events',
    'roles',
    'customTags',
    'groups',
    'toMeetUsers',
    'toAddUsers',
    'toAppendSkills',
    'toMentionedSkills',
    'toAppendNeedSkills',
    'toMentionedNeedSkills',
  ]

  return keys.flatMap(
    key =>
      items[key]?.map(entity => ({
        entityId: entity.id,
        kind: entity.kind,
      })) ?? [],
  )
}
