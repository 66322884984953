import React, { useMemo } from 'react'

import concat from 'lodash/concat'
import isEmpty from 'lodash/isEmpty'
import uniqBy from 'lodash/uniqBy'

import { Column, Divider } from 'Components/UI'

import { TAG_COLOR_KIND } from 'Constants/tags'

import EventBus from 'Services/EventBus'
import { useScopedI18n } from 'Services/I18n'

import TagList from './TagList'

import { NODE_KIND, SkillTagKind } from '../../../../Constants/graph'

export interface ISkillTag {
  id: string
  name?: string | null
}

export interface ISkillTagsProps {
  userId: string
  appendSkills: ISkillTag[]
  userSkills: ISkillTag[]
}

function SkillTags({
  userId,
  userSkills = [],
  appendSkills = [],
}: ISkillTagsProps) {
  const t = useScopedI18n('features.notes.components.noteTags.skillTags')

  const userSkillsWithProposals = useMemo(() => {
    return uniqBy(concat(userSkills), 'id')
  }, [userSkills])

  const handleClick = (item: ISkillTag, kind: SkillTagKind) => {
    EventBus.trigger(EventBus.actions.graph.addSkillTags, {
      id: item.id,
      name: item.name,
      kind,
      userId,
    })
  }

  return (
    <Column>
      {!isEmpty(userSkillsWithProposals) && (
        <>
          <Divider mb={3} />

          <TagList
            items={userSkillsWithProposals.map(item => ({
              id: item.id,
              name: item.name || 'N/A',
              colorKind: TAG_COLOR_KIND.SKILL,
              onClick: () => handleClick(item, NODE_KIND.skill),
            }))}
            label={t('skills')}
          />
        </>
      )}

      {!isEmpty(appendSkills) && (
        <>
          <Divider mb={3} />

          <TagList
            items={appendSkills.map(item => ({
              id: item.id,
              name: item.name || 'N/A',
              colorKind: TAG_COLOR_KIND.SKILL,
              onClick: () => handleClick(item, NODE_KIND.skill),
            }))}
            label={t('mentionedSkills')}
          />
        </>
      )}
    </Column>
  )
}

export default SkillTags
