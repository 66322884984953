import styled, { css } from 'styled-components'
import { margin, MarginProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { resetButton } from 'Components/Styles'

export interface ContainerCssProps extends MarginProps {}

export const Container = styled.div<ContainerCssProps>`
  width: 100%;

  ${margin}
`

export interface SecondaryActionButtonCssProps {
  secondary?: boolean
}

const secondaryActionButtonCss = ({
  secondary,
}: SecondaryActionButtonCssProps) =>
  secondary &&
  css`
    color: ${themeGet('colors.error')};
  `

export interface DisabledActionButtonCssProps {
  disabled?: boolean
}

const disabledActionButtonCss = ({ disabled }: DisabledActionButtonCssProps) =>
  disabled &&
  css`
    cursor: not-allowed;
    color: ${themeGet('colors.text.disabled')};

    &:active,
    &:focus,
    &:hover {
      color: ${themeGet('colors.text.disabled')};
    }
  `

export interface ActionButtonCssProps
  extends SecondaryActionButtonCssProps,
    DisabledActionButtonCssProps {}

export const ActionButton = styled.button<ActionButtonCssProps>`
  ${resetButton};

  font-weight: ${themeGet('fontWeight.1')};
  font-size: ${themeGet('fontSize.1')}px;
  line-height: 16px;
  cursor: pointer;
  color: ${themeGet('colors.success')};

  ${secondaryActionButtonCss};
  ${disabledActionButtonCss}
`
