import React, { MouseEventHandler, useRef, useState } from 'react'

import { Popover, Text } from 'Components/UI'

import { useOnClickOutside } from 'Hooks'

import { DotsButton, DotsIcon, DropdownContainer, Option } from './styles'

export interface IOption {
  key: React.Key
  label: string
  icon: React.ReactNode
  handleClick: MouseEventHandler<HTMLButtonElement>
}

export interface IDropdown {
  options: IOption[]
}

function Dropdown({ options }: IDropdown) {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const [isOpen, setIsOpen] = useState(false)

  useOnClickOutside(containerRef, () => setIsOpen(false), buttonRef)

  if (options.length === 0) {
    return null
  }

  return (
    <Popover
      appendTo={document.body}
      content={
        <DropdownContainer ref={containerRef}>
          {options.map(option => (
            <Option
              gap={2}
              key={option.key}
              onClick={event => {
                event.stopPropagation()
                setIsOpen(false)
                option.handleClick(event)
              }}
            >
              {option.icon}
              <Text bodyMedium>{option.label}</Text>
            </Option>
          ))}
        </DropdownContainer>
      }
      delay={0}
      interactive
      offset={[0, 5]}
      placement="bottom-end"
      trigger="click"
      visible={isOpen}
      zIndex={999}
    >
      <DotsButton
        active={isOpen}
        ref={buttonRef}
        onClick={event => {
          event.stopPropagation()
          setIsOpen(!isOpen)
        }}
      >
        <DotsIcon />
      </DotsButton>
    </Popover>
  )
}

export default Dropdown
