import { MdOutlineArrowForwardIos } from 'react-icons/md'

import styled, { css } from 'styled-components'
import { margin, MarginProps, position, PositionProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

interface MenuHoveredCssProps {
  hovered?: boolean
}

const menuHoveredCss = ({ hovered }: MenuHoveredCssProps) =>
  hovered &&
  css`
    border: none;
    top: 0;
    right: 0;
    transform: translateX(100%);
    color: ${themeGet('colors.text.header')} !important;
  `

interface MenuScrollableCssProps {
  scrollable?: boolean
}

const menuScrollableCss = ({ scrollable }: MenuScrollableCssProps) =>
  scrollable &&
  css`
    max-height: 194px;
    overflow-y: auto;
  `

export interface IMenu
  extends PositionProps,
    MarginProps,
    MenuHoveredCssProps,
    MenuScrollableCssProps {}

export const Menu = styled.div<IMenu>`
  position: absolute;
  border: 8px solid transparent;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  z-index: ${themeGet('zIndices.popover')};
  transform: translateX(-8px);

  > div:first-of-type {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  > div:last-of-type {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  > div {
    color: ${themeGet('colors.text.header')};

    &:hover {
      background-color: ${themeGet('colors.bg.hover')};
      color: ${themeGet('colors.main')};
    }
  }

  ${position}
  ${margin}

  ${menuHoveredCss}
  ${menuScrollableCss}
`

export const Item = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: ${themeGet('space.6')}px;
  padding: ${themeGet('space.2')}px ${themeGet('space.3')}px;
  font-weight: ${themeGet('fontWeight.0')};
  font-size: ${themeGet('fontSize.2')}px;
  cursor: pointer;
  white-space: nowrap;
  background-color: ${themeGet('colors.bg.primary')};
  box-shadow: ${themeGet('shadows.box')};
`

export const ItemLabel = styled.div`
  display: flex;
  justify-content: left;
  width: calc(100%);
`

export const MenuUnfold = styled(MdOutlineArrowForwardIos)`
  display: flex;
  justify-content: right;
  margin-top: 2px;
`
