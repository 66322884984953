import React from 'react'
import { useNavigate } from 'react-router-dom'

import { IconHome, IconMail, IconUserPlus } from '@tabler/icons-react'
import Utils from 'Utils'

import NotificationsButton from 'Components/Blocks/Community/NotificationsButton'
import { Avatar, IconButton, Link } from 'Components/UI'

import {
  PERMISSION_ACTION,
  PERMISSION_SCOPES,
  PERMISSION_SUBJECT,
} from 'Constants/permissions'

import { useAppContext, useCommunity, usePermission } from 'Hooks'

import { COMMUNITY_DASHBOARD, PROFILE_ROOT } from 'Router/routes'

import { Container, ItemWrapper } from './styles'

export interface IFooterProps {
  restricted?: boolean
  onAddUserClick?: React.MouseEventHandler<HTMLButtonElement>
  onInviteClick?: React.MouseEventHandler<HTMLButtonElement>
  onOpenNotifications?: React.MouseEventHandler<HTMLButtonElement>
}

function Footer({
  restricted = false,
  onAddUserClick,
  onInviteClick,
  onOpenNotifications,
}: IFooterProps) {
  const { communityUser } = useAppContext()
  const { can } = usePermission()
  const navigate = useNavigate()
  const { community } = useCommunity()

  const canViewInvites = can(
    PERMISSION_ACTION.READ,
    PERMISSION_SUBJECT.INVITE,
    [],
  )

  const canCreateUser = can(
    PERMISSION_ACTION.CREATE,
    PERMISSION_SUBJECT.ACCOUNT,
    PERMISSION_SCOPES.ACCOUNT_OWN_CREATOR,
  )

  return (
    <Container>
      {community?.slug && (
        <ItemWrapper>
          <Link
            large
            secondary
            to={Utils.URL.generatePath(COMMUNITY_DASHBOARD, {
              slug: community.slug,
            })}
          >
            <IconHome />
          </Link>
        </ItemWrapper>
      )}

      {!restricted && canCreateUser && (
        <ItemWrapper>
          <IconButton large outline secondary onClick={onAddUserClick}>
            <IconUserPlus />
          </IconButton>
        </ItemWrapper>
      )}

      {!restricted && canViewInvites && (
        <ItemWrapper>
          <IconButton large outline secondary onClick={onInviteClick}>
            <IconMail />
          </IconButton>
        </ItemWrapper>
      )}

      {!restricted && (
        <ItemWrapper>
          <NotificationsButton clear onOpen={onOpenNotifications} />
        </ItemWrapper>
      )}

      <ItemWrapper>
        <Avatar
          extraSmall
          src={communityUser?.photoUrl}
          onClick={() => navigate(PROFILE_ROOT)}
        />
      </ItemWrapper>
    </Container>
  )
}

export default Footer
