import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { useTheme } from 'styled-components'

import { getFullName } from 'Utils/User'

import { Text } from 'Components/UI'

import { useScopedI18n } from 'Services/I18n'

function FullNameCell({ row, deletedSharedUserIds }) {
  const { colors } = useTheme
  const s = useScopedI18n('modals.shareNote.table')

  const sharedUser = row?.original
  const user = sharedUser?.recipient

  const userFullName = useMemo(() => `${getFullName(user?.profile)}`, [user])

  const isDeletedUser = useMemo(
    () => deletedSharedUserIds.includes(sharedUser?.id),
    [deletedSharedUserIds, sharedUser],
  )

  return (
    <Text color={isDeletedUser ? colors.primaryCardinal : colors.black}>
      {isDeletedUser
        ? s('actions.userHasBeenDeleted', { fullName: userFullName })
        : userFullName}
    </Text>
  )
}

FullNameCell.defaultProps = {
  deletedSharedUserIds: [],
  row: {},
}

FullNameCell.propTypes = {
  deletedSharedUserIds: PropTypes.array,
  row: PropTypes.object,
}

export default FullNameCell
