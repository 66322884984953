import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import { useApolloClient } from '@apollo/client'
import debounce from 'awesome-debounce-promise'
import listCommunityUsersQuery from 'GraphQL/Queries/CommunityUser/listCommunityUsers.full.graphql'
import { setMinSearchLength } from 'Utils/Form'
import { graphUsersToValues } from 'Utils/User'

import map from 'lodash/map'

import { Column, DateRangePickerInput, Select } from 'Components/UI'

import { DEFAULT_MIN_SEARCH_SIZE, DEFAULT_SEARCH_DEBOUNCE } from 'Constants/ids'

import { useCommunityContext } from 'Hooks'

import { useScopedI18n } from 'Services/I18n'

import { AdvancedFilter, FilterDropdown } from '../../Blocks'

const FILTER = {
  RECIPIENTS: 'recipients',
  DATE: 'date',
}

function Filter({ onChange }) {
  const s = useScopedI18n('general')
  const { community } = useCommunityContext()
  const client = useApolloClient()

  const [recipients, setRecipients] = useState([])
  const [date, setDate] = useState({
    from: null,
    to: null,
  })

  const [openFilter, setOpenFilter] = useState(false)

  const handleChangeDate = useCallback(({ to, from }) => {
    setDate({ to, from })
  }, [])

  const handleClearAll = useCallback(() => {
    setRecipients([])
    setDate({
      from: null,
      to: null,
    })
    onChange({
      createdFrom: null,
      createdTo: null,
    })
  }, [onChange])

  const handleApplyFilters = () => {
    onChange({
      createdTo: date.to,
      createdFrom: date.from,
      recipientIds:
        recipients.length > 0
          ? map(recipients, recipient => recipient.value)
          : undefined,
    })
  }

  const loadUsersOptions = useCallback(
    () => async (inputValue, callback) => {
      const result = await client.query({
        query: listCommunityUsersQuery,
        variables: {
          communityId: community?.id,
          search: inputValue,
          limit: 25,
        },
      })

      const usersSuggestions =
        result.data?.listCommunityUsers?.communityUsers || []

      callback(graphUsersToValues(usersSuggestions))
    },
    [community?.id, client],
  )

  const debouncedLoadOptions = useCallback(
    () =>
      setMinSearchLength(
        debounce(loadUsersOptions(), DEFAULT_SEARCH_DEBOUNCE),
        DEFAULT_MIN_SEARCH_SIZE,
      ),
    [loadUsersOptions],
  )

  return (
    <AdvancedFilter
      applyFilters={handleApplyFilters}
      clearFilter={handleClearAll}
    >
      <Column>
        <FilterDropdown
          isOpened={openFilter === FILTER.RECIPIENTS}
          setIsOpened={() =>
            setOpenFilter(
              openFilter === FILTER.RECIPIENTS ? null : FILTER.RECIPIENTS,
            )
          }
          title={
            recipients.length
              ? s('toWhomCount', { count: recipients.length })
              : s('toWhom')
          }
        >
          <Select
            async
            flexShrink={0}
            isMulti
            loadOptions={debouncedLoadOptions()}
            placeholder=""
            value={recipients}
            width={1}
            withLabel={false}
            onChange={setRecipients}
          />
        </FilterDropdown>

        <FilterDropdown
          isOpened={openFilter === FILTER.DATE}
          setIsOpened={() =>
            setOpenFilter(openFilter === FILTER.DATE ? null : FILTER.DATE)
          }
          title={s('date')}
        >
          <DateRangePickerInput
            dateRange={date}
            width={1}
            onChange={handleChangeDate}
          />
        </FilterDropdown>
      </Column>
    </AdvancedFilter>
  )
}

Filter.defaultProps = {}

Filter.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default Filter
