import React, { useCallback } from 'react'

import { pick } from '@styled-system/props'

import { useMutation } from '@apollo/client'
import deleteProposalMutation from 'GraphQL/Mutations/Proposal/deleteProposal.graphql'
import getCommunityUserQuery from 'GraphQL/Queries/CommunityUser/getCommunityUser.graphql'
import { deleteProposalForUserUpdater } from 'GraphQL/Updaters/ProposalForUser'

import { Dialog } from 'Components/Blocks/Modals'
import { Column } from 'Components/UI'
import { IColumn } from 'Components/UI/Flex'

import { PROPOSAL_KIND } from 'Constants/ids'

import { useAppContext, useCommunityContext, useEntityModal } from 'Hooks'

import _ from 'Services/I18n'
import toast from 'Services/Toast'

import Proposal from './Proposal'

import ShowMore from '../ShowMore'

export interface IUserProposalsProps extends IColumn {
  proposals: MainSchema.Proposal[]
  userId: string
  sharedUserId?: string
  showDelete?: boolean
}

function UserProposals({
  showDelete = true,
  userId,
  proposals = [],
  sharedUserId,
  ...rest
}: IUserProposalsProps) {
  const { me } = useAppContext()
  const { community } = useCommunityContext()
  const [deleteModal, deleteActions] = useEntityModal<MainSchema.Proposal>()
  const [deleteProposal] = useMutation<
    Pick<MainSchema.Mutation, 'deleteProposal'>,
    MainSchema.MutationDeleteProposalArgs
  >(deleteProposalMutation)

  const handleOpenDeleteModal = deleteActions.openModal
  const handleDeleteModalClose = deleteActions.closeModal

  const handleDelete = useCallback(
    async (success: boolean) => {
      if (!success || !deleteModal.entity) return

      try {
        await deleteProposal({
          variables: { id: deleteModal.entity.id },
          update: deleteProposalForUserUpdater({
            sharedUserId,
            userId,
            communityId: community?.id,
            proposalId: deleteModal?.entity?.id,
          }),
          refetchQueries: [
            {
              query: getCommunityUserQuery,
              variables: {
                communityId: community?.id,
                userId,
              },
            },
          ],
        })

        toast.success({
          title: 'Delete proposal',
          text: `Proposal successfully deleted`,
        })
      } catch (error) {
        let message = _('error.generic')

        if (error instanceof Error) {
          message = _(`error.${error.message || 'generic'}`)
        }

        toast.error({
          title: 'Delete proposal',
          text: message,
        })
      }
    },
    [deleteProposal, deleteModal, sharedUserId, userId, community],
  )

  const getName = (proposal: MainSchema.Proposal) => {
    let name

    switch (proposal.kind) {
      case PROPOSAL_KIND.PHONE:
      case PROPOSAL_KIND.TWITTER_URL:
      case PROPOSAL_KIND.FACEBOOK_URL:
      case PROPOSAL_KIND.LINKEDIN_URL:
        name = proposal.value
        break
      case PROPOSAL_KIND.SKILL:
      case PROPOSAL_KIND.NEED_SKILL:
        name = 'Obsolete Skill/Need Skill Proposal'
        break
      default:
        name = 'Obsolete Tag Proposal'
    }

    return name || ''
  }

  if (!proposals.length) return null

  return (
    <>
      {proposals.length > 0 && (
        <Column {...pick(rest)} fullWidth pt={2}>
          <ShowMore flexDirection={'row'} gap={2} initialShown={3} wrapped>
            {proposals?.map(proposal => {
              const name = getName(proposal)
              const isMyProposal = me?.id === proposal.creatorId
              const isShowDelete =
                showDelete && !isMyProposal ? false : showDelete

              return (
                <Proposal
                  key={proposal.id}
                  kind={proposal.kind}
                  name={name}
                  proposal={proposal}
                  showDelete={isShowDelete}
                  status={proposal.status}
                  onDelete={() => handleOpenDeleteModal(proposal)}
                />
              )
            })}
          </ShowMore>
        </Column>
      )}

      {deleteModal.entity && (
        <Dialog
          content={`Are you sure you want to delete your ${
            deleteModal.entity.kind
          } proposal: ${getName(deleteModal.entity)}?`}
          isOpen={deleteModal.isOpen}
          title="Delete proposal?"
          onClose={handleDeleteModalClose}
          onFinish={handleDelete}
        />
      )}
    </>
  )
}

export default UserProposals
