import React from 'react'

import { pick } from '@styled-system/props'

import { Text, Tooltip } from 'Components/UI'

import { useCommunityContext } from 'Hooks'

import { DescriptionContainer, Holder, IHolderProps } from './styles'

export interface ICommunitySelectorProps extends IHolderProps {}

function CommunitySelector(props: ICommunitySelectorProps) {
  const { community } = useCommunityContext()

  return (
    <Holder {...pick(props)}>
      <Tooltip
        content={
          <DescriptionContainer>{community?.description}</DescriptionContainer>
        }
        offset={[115, 10]}
      >
        <Text header header4>
          {community?.name}
        </Text>
      </Tooltip>
    </Holder>
  )
}

export default CommunitySelector
