import React, { useCallback, useMemo } from 'react'

import { pick } from '@styled-system/props'

import {
  IconChevronLeft,
  IconChevronRight,
  IconChevronsLeft,
  IconChevronsRight,
} from '@tabler/icons-react'

import { Row } from 'Components/UI/Flex'
import { Select } from 'Components/UI/Form/Select'
import Text from 'Components/UI/Text'

import {
  IPaginationContainerProps,
  Paginate,
  PaginationContainer,
  StyledButton,
} from './styles'

const SIZES = [10, 25, 50, 100]

const OPTIONS = SIZES.map(size => ({ value: size, label: size }))

export interface IPaginationProps extends IPaginationContainerProps {
  canChangePageSize?: boolean
  loading?: boolean
  mobile?: boolean
  showTotal?: boolean
  state?: {
    pageSize: number
    pageIndex: number
  }
  total?: number
  onFirstPage?: () => void
  onLastPage?: () => void
  onNextPage?: () => void
  onPageIndexChange?: (pageIndex: number) => void
  onPageSizeChange?: (pageSize: number) => void
  onPreviousPage?: () => void
}

function Pagination({
  canChangePageSize = true,
  loading = false,
  mobile,
  showTotal = true,
  state: { pageSize, pageIndex } = { pageSize: 10, pageIndex: 0 },
  total = 0,
  onNextPage,
  onFirstPage,
  onLastPage,
  onPageIndexChange,
  onPageSizeChange,
  onPreviousPage,
  ...rest
}: IPaginationProps) {
  const from = useMemo(() => pageSize * pageIndex + 1, [pageSize, pageIndex])

  const to = useMemo(
    () => (from + pageSize > total ? total : from + pageSize - 1),
    [total, from, pageSize],
  )

  const pageCount = useMemo(
    () => Math.ceil(total / pageSize),
    [pageSize, total],
  )

  // TODO: convert any to actual type
  const handleChangeSize = useCallback(
    (value: any) => {
      const nextPageSize = Number(
        (value && 'value' in value && value.value) ?? 0,
      )
      onPageSizeChange?.(nextPageSize)
    },
    [onPageSizeChange],
  )

  const handlePageChange = useCallback(
    (page: { selected: number }) => {
      onPageIndexChange?.(page?.selected ?? 0)
    },
    [onPageIndexChange],
  )

  const handleFirst = useCallback(() => {
    onPageIndexChange?.(0)

    onFirstPage?.()
  }, [onFirstPage, onPageIndexChange])

  const handlePrev = useCallback(() => {
    onPageIndexChange?.(pageIndex > 0 ? pageIndex - 1 : 0)

    onPreviousPage?.()
  }, [onPageIndexChange, onPreviousPage, pageIndex])

  const handleNext = useCallback(() => {
    onPageIndexChange?.(
      pageIndex < pageCount - 1 ? pageIndex + 1 : pageCount - 1,
    )

    onNextPage?.()
  }, [onNextPage, onPageIndexChange, pageCount, pageIndex])

  const handleLast = useCallback(() => {
    onPageIndexChange?.(total > 0 ? pageCount - 1 : 0)

    onLastPage?.()
  }, [onLastPage, onPageIndexChange, pageCount, total])

  const value = useMemo(
    () => ({ value: pageSize, label: pageSize }),
    [pageSize],
  )

  if (!total && !loading) return null

  return (
    <PaginationContainer {...pick(rest)}>
      {mobile ? (
        <>
          {canChangePageSize && (
            <Row center gap={3}>
              <Text body bodyMedium nowrap>
                Rows
              </Text>
              <Select
                options={OPTIONS}
                value={value}
                width={80}
                withPortal
                onChange={handleChangeSize}
              />
            </Row>
          )}

          {showTotal && (
            <Text body bodyMedium nowrap>
              {`Show ${from} to ${to} of ${total} results`}
            </Text>
          )}

          <Row center gap="2px">
            <StyledButton disabled={pageIndex === 0} onClick={handleFirst}>
              <IconChevronsLeft />
            </StyledButton>

            <StyledButton disabled={pageIndex === 0} onClick={handlePrev}>
              <IconChevronLeft />
            </StyledButton>

            <StyledButton
              disabled={pageIndex + 1 >= pageCount}
              onClick={handleNext}
            >
              <IconChevronRight />
            </StyledButton>

            <StyledButton
              disabled={pageIndex + 1 >= pageCount}
              onClick={handleLast}
            >
              <IconChevronsRight />
            </StyledButton>
          </Row>
        </>
      ) : (
        <>
          {showTotal && (
            <Text body bodyMedium nowrap>
              {`Show ${from} to ${to} of ${total} results`}
            </Text>
          )}

          <Paginate
            breakLabel="…"
            forcePage={pageCount ? pageIndex : -1}
            marginPagesDisplayed={1}
            nextLabel={<IconChevronRight />}
            pageCount={pageCount}
            pageRangeDisplayed={2}
            previousLabel={<IconChevronLeft />}
            renderOnZeroPageCount={null}
            onPageChange={handlePageChange}
          />

          {!!(canChangePageSize && total) && (
            <Row center gap={3}>
              <Text body bodyMedium nowrap>
                Rows per page
              </Text>
              <Select
                options={OPTIONS}
                value={value}
                width={80}
                withPortal
                onChange={handleChangeSize}
              />
            </Row>
          )}
        </>
      )}
    </PaginationContainer>
  )
}

export default Pagination
