import React, { useRef, useState } from 'react'

import { IconMenu2 } from '@tabler/icons-react'

import { IconButton, Row, Text } from 'Components/UI'

import { useOnClickOutside } from 'Hooks'

import { HamburgerMenuContainer, MenuLink } from './styles'

export interface ILink {
  title: string
  to: string
  isActive?: boolean
}

export interface IHamburgerMenu {
  links: ILink[]
}

function HamburgerMenu({ links = [] }: IHamburgerMenu) {
  const ref = useRef<HTMLDivElement | null>(null)

  const [isMenuOpen, setMenuOpen] = useState(false)

  useOnClickOutside(ref, () => setMenuOpen(false))

  const handleLinkClick = () => {
    setMenuOpen(false)
  }

  return (
    <Row>
      <IconButton outline onClick={() => setMenuOpen(prevState => !prevState)}>
        <IconMenu2 />
      </IconButton>

      {isMenuOpen && (
        <HamburgerMenuContainer ref={ref}>
          {links.map(item => (
            <MenuLink key={item.to} to={item.to} onClick={handleLinkClick}>
              <Text header={item.isActive} header4>
                {item.title}
              </Text>
            </MenuLink>
          ))}
        </HamburgerMenuContainer>
      )}
    </Row>
  )
}

export default HamburgerMenu
