import loadable from '@loadable/component'

const Admin = {
  Root: loadable(() => import('./Root' /* webpackChunkName: "admin-root" */)),
  Admins: loadable(
    () => import('./Admins' /* webpackChunkName: "admin-admins" */),
  ),
  Users: loadable(
    () => import('./Users' /* webpackChunkName: "admin-users" */),
  ),
  Skills: loadable(
    () => import('./Skills' /* webpackChunkName: "admin-skills" */),
  ),
  Communities: loadable(
    () => import('./Communities' /* webpackChunkName: "admin-communities" */),
  ),
  CommunityUsers: loadable(
    () =>
      import(
        './CommunityUsers' /* webpackChunkName: "admin-community-users" */
      ),
  ),
  CommunityRBA: loadable(
    () =>
      import('./CommunityRBA' /* webpackChunkName: "admin-community-rba" */),
  ),
  ChangeReportCommunities: loadable(
    () =>
      import(
        './ChangeReports/ChangeReportCommunityList' /* webpackChunkName: "features-change-report-change_report-community-list" */
      ),
  ),
  ChangeReports: loadable(
    () =>
      import(
        './ChangeReports/ChangeReportList' /* webpackChunkName: "features-change-report-change_report-list" */
      ),
  ),
  ChangeReportsUpload: loadable(
    () =>
      import(
        './ChangeReports/ChangeReportUpload' /* webpackChunkName: "features-change-report-change_report-upload" */
      ),
  ),
}

export default Admin
