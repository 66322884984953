export interface Address {
  address1?: string
  address2?: string
  locality?: string
  region?: string
  postalCode?: string
  country?: string
}

export function formatAddress(address: Address, lineBreak: string = '\n') {
  const { address1, address2, locality, region, postalCode, country } = address
  let formattedAddress = ''

  if (address1) {
    formattedAddress += `${address1}${lineBreak}`
  }

  if (address2) {
    formattedAddress += `${address2}${lineBreak}`
  }

  if (locality && region && postalCode) {
    formattedAddress += `${locality}, ${region} ${postalCode}${lineBreak}`
  } else if (region && postalCode) {
    formattedAddress += `${region} ${postalCode}${lineBreak}`
  } else if (region) {
    formattedAddress += `${region}${lineBreak}`
  }

  if (country) {
    formattedAddress += country
  }

  return formattedAddress.trim()
}
