import styled, { css } from 'styled-components'
import { position, PositionProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { bookmarkGlyph } from 'Assets/Svg/icons'

import { Loader } from 'Components/UI'

const containerIsMobileCss = ({ isMobile }: { isMobile?: boolean }) =>
  isMobile &&
  css`
    position: absolute;
    width: auto;
    top: 8px;
    left: 50%;
    transform: translate(-50%, 0);
    flex-direction: column;
    padding: 0;
    border: none;
    background: none;
    z-index: 1;
    gap: ${themeGet('space.2')}px;
    pointer-events: all;
  `

export interface IContainer {
  isMobile?: boolean
}

export const Container = styled.div<IContainer>`
  position: absolute;
  top: 80px;
  left: 0px;
  display: flex;
  flex-direction: column;
  padding: ${themeGet('space.2')}px ${themeGet('space.5')}px;
  z-index: ${themeGet('zIndices.popover')};
  pointer-events: none;

  ${containerIsMobileCss};
`

export const ButtonContainer = styled.div`
  position: relative;
`

export interface IGraphLoader extends PositionProps {}

export const GraphLoader = styled(Loader)<IGraphLoader>`
  position: absolute;
  z-index: ${themeGet('zIndices.popover')};
  color: ${themeGet('colors.blue')};

  ${position}
`

export const SearchWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: ${themeGet('space.2')}px;
  pointer-events: all;
  flex-direction: column;
`

export const BookmarkIcon = styled(bookmarkGlyph)``
