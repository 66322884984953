import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const RelativeContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
`

export const NotificationCounter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -11px;
  right: -12px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${themeGet('colors.primaryCardinal')};
  color: ${themeGet('colors.white')};
  font-weight: ${themeGet('fontWeights.3')};
  font-size: 9px;
  line-height: 12px;
  letter-spacing: 0.21px;
`
