import React, { forwardRef, MouseEventHandler } from 'react'

import { IconBell } from '@tabler/icons-react'
import unreadNotificationsCountQuery from 'GraphQL/Queries/Community/unreadNotificationsCount.graphql'

import { IconButton, Tooltip } from 'Components/UI'

import { useCommunityContext } from 'Hooks'

import { useQuery } from 'Services/Apollo'
import _ from 'Services/I18n'

import { NotificationCounter, RelativeContainer } from './styles'

export interface INotificationsButton {
  clear?: boolean
  onOpen?: MouseEventHandler<HTMLButtonElement>
}

const NotificationsButton = forwardRef<HTMLButtonElement, INotificationsButton>(
  ({ clear = false, onOpen }, ref) => {
    const { community } = useCommunityContext()

    const { data } = useQuery(unreadNotificationsCountQuery, {
      variables: {
        communityId: community?.id,
      },
      skip: !community?.id,
    })

    const unreadCounter = data?.unreadNotificationsCount?.count

    return (
      <RelativeContainer>
        <Tooltip content={_('tips.notifications')}>
          {clear ? (
            <IconButton large ref={ref} secondary onClick={onOpen}>
              {Boolean(unreadCounter) && unreadCounter > 0 && (
                <NotificationCounter>{unreadCounter}</NotificationCounter>
              )}
              <IconBell />
            </IconButton>
          ) : (
            <IconButton outline ref={ref} onClick={onOpen}>
              {Boolean(unreadCounter) && unreadCounter > 0 && (
                <NotificationCounter>{unreadCounter}</NotificationCounter>
              )}
              <IconBell />
            </IconButton>
          )}
        </Tooltip>
      </RelativeContainer>
    )
  },
)

export default NotificationsButton
