import { HttpLink, split } from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'

import { API } from 'Config'

export default function httpLink(path: string) {
  return split(
    operation => {
      return operation.getContext().batch === true
    },
    new BatchHttpLink({
      uri: `${API.URL}/${path}`,
      headers: { batch: 'true' },
      batchMax: 10, // Max operations per batch
      batchInterval: 20, // Milliseconds to wait before dispatching
    }),
    new HttpLink({ uri: `${API.URL}/${path}` }),
  )
}
