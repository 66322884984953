import { useCallback } from 'react'

import {
  PERMISSION_ACTION,
  PERMISSION_SCOPES,
  PERMISSION_SUBJECT,
} from 'Constants/permissions'

import { useAppContext, usePermission } from 'Hooks'

function useAbility() {
  const { me } = useAppContext()
  const { can } = usePermission()

  // Helpers

  const isCreatorMe = useCallback(
    (note: MainSchema.Note) => !!me && note.creatorId === me.id,
    [me],
  )

  const isReceiverMe = useCallback(
    (note: MainSchema.Note) => !!me && note.receiverId === me.id,
    [me],
  )

  // Create

  const canCreatePublic = useCallback(
    (targetUserId: string) =>
      me && targetUserId === me.id
        ? can(
            PERMISSION_ACTION.CREATE,
            PERMISSION_SUBJECT.NOTE,
            PERMISSION_SCOPES.NOTES_CREATOR_AND_TARGET_PUBLIC,
          )
        : can(
            PERMISSION_ACTION.CREATE,
            PERMISSION_SUBJECT.NOTE,
            PERMISSION_SCOPES.NOTES_OWN_PUBLIC,
          ),
    [me, can],
  )

  const canCreatePrivate = useCallback(
    (targetUserId: string) =>
      me && targetUserId === me.id
        ? can(
            PERMISSION_ACTION.CREATE,
            PERMISSION_SUBJECT.NOTE,
            PERMISSION_SCOPES.NOTES_CREATOR_AND_TARGET_PRIVATE,
          )
        : can(
            PERMISSION_ACTION.CREATE,
            PERMISSION_SUBJECT.NOTE,
            PERMISSION_SCOPES.NOTES_OWN_PRIVATE,
          ),
    [me, can],
  )

  const canCreate = useCallback(
    (targetUserId: string) =>
      canCreatePublic(targetUserId) || canCreatePrivate(targetUserId),
    [canCreatePublic, canCreatePrivate],
  )

  // Edit

  const canCreatorEditToPublic = useCallback(
    (note: MainSchema.Note) =>
      isReceiverMe(note)
        ? can(
            PERMISSION_ACTION.EDIT,
            PERMISSION_SUBJECT.NOTE,
            PERMISSION_SCOPES.NOTES_CREATOR_AND_TARGET_PUBLIC,
          )
        : can(
            PERMISSION_ACTION.EDIT,
            PERMISSION_SUBJECT.NOTE,
            PERMISSION_SCOPES.NOTES_OWN_PUBLIC,
          ),
    [can, isReceiverMe],
  )

  const canOtherEditToPublic = useCallback(
    (note: MainSchema.Note) =>
      isReceiverMe(note)
        ? can(
            PERMISSION_ACTION.EDIT,
            PERMISSION_SUBJECT.NOTE,
            PERMISSION_SCOPES.NOTES_ABOUT_ME_PUBLIC,
          )
        : can(
            PERMISSION_ACTION.EDIT,
            PERMISSION_SUBJECT.NOTE,
            PERMISSION_SCOPES.NOTES_OTHER_CREATOR_PUBLIC,
          ),
    [can, isReceiverMe],
  )

  const canCreatorEditToPrivate = useCallback(
    (note: MainSchema.Note) =>
      isReceiverMe(note)
        ? can(
            PERMISSION_ACTION.EDIT,
            PERMISSION_SUBJECT.NOTE,
            PERMISSION_SCOPES.NOTES_CREATOR_AND_TARGET_PRIVATE,
          )
        : can(
            PERMISSION_ACTION.EDIT,
            PERMISSION_SUBJECT.NOTE,
            PERMISSION_SCOPES.NOTES_OWN_PRIVATE,
          ),
    [can, isReceiverMe],
  )

  const canEditToPublic = useCallback(
    (note: MainSchema.Note) =>
      isCreatorMe(note) ? canCreatorEditToPublic : canOtherEditToPublic,
    [isCreatorMe, canCreatorEditToPublic, canOtherEditToPublic],
  )

  const canEditToPrivate = useCallback(
    (note: MainSchema.Note) => isCreatorMe(note) && canCreatorEditToPrivate,
    [isCreatorMe, canCreatorEditToPrivate],
  )

  const canCreatorEditNotes = useCallback(
    (note: MainSchema.Note) =>
      note.public ? canEditToPublic(note) : canEditToPrivate(note),
    [canEditToPublic, canEditToPrivate],
  )

  const canEdit = useCallback(
    (note: MainSchema.Note) =>
      canCreatorEditNotes(note) || (note.sharedWithMe && note.editable),
    [canCreatorEditNotes],
  )

  // Delete

  const canDeleteOwnPublic = useCallback(
    (note: MainSchema.Note) =>
      isCreatorMe(note) &&
      !isReceiverMe(note) &&
      note.public &&
      can(
        PERMISSION_ACTION.DELETE,
        PERMISSION_SUBJECT.NOTE,
        PERMISSION_SCOPES.NOTES_OWN_PUBLIC,
      ),
    [can, isCreatorMe, isReceiverMe],
  )

  const canDeleteOwnPrivate = useCallback(
    (note: MainSchema.Note) =>
      isCreatorMe(note) &&
      !isReceiverMe(note) &&
      !note.public &&
      can(
        PERMISSION_ACTION.DELETE,
        PERMISSION_SUBJECT.NOTE,
        PERMISSION_SCOPES.NOTES_OWN_PRIVATE,
      ),
    [can, isCreatorMe, isReceiverMe],
  )

  const canDeleteOwnAboutMeByMePublic = useCallback(
    (note: MainSchema.Note) =>
      isCreatorMe(note) &&
      isReceiverMe(note) &&
      note.public &&
      can(
        PERMISSION_ACTION.DELETE,
        PERMISSION_SUBJECT.NOTE,
        PERMISSION_SCOPES.NOTES_CREATOR_AND_TARGET_PUBLIC,
      ),
    [can, isCreatorMe, isReceiverMe],
  )

  const canDeleteOwnAboutMeByMePrivate = useCallback(
    (note: MainSchema.Note) =>
      isCreatorMe(note) &&
      isReceiverMe(note) &&
      !note.public &&
      can(
        PERMISSION_ACTION.DELETE,
        PERMISSION_SUBJECT.NOTE,
        PERMISSION_SCOPES.NOTES_CREATOR_AND_TARGET_PRIVATE,
      ),
    [can, isCreatorMe, isReceiverMe],
  )

  const canDeleteAboutMePublic = useCallback(
    (note: MainSchema.Note) =>
      !isCreatorMe(note) &&
      isReceiverMe(note) &&
      note.public &&
      can(
        PERMISSION_ACTION.DELETE,
        PERMISSION_SUBJECT.NOTE,
        PERMISSION_SCOPES.NOTES_ABOUT_ME_PUBLIC,
      ),
    [can, isCreatorMe, isReceiverMe],
  )

  const canDeleteOtherNotesPublic = useCallback(
    (note: MainSchema.Note) =>
      !isCreatorMe(note) &&
      !isReceiverMe(note) &&
      note.public &&
      can(
        PERMISSION_ACTION.DELETE,
        PERMISSION_SUBJECT.NOTE,
        PERMISSION_SCOPES.NOTES_OTHER_CREATOR_PUBLIC,
      ),
    [can, isCreatorMe, isReceiverMe],
  )

  const canDelete = useCallback(
    (note: MainSchema.Note) =>
      canDeleteOwnAboutMeByMePrivate(note) ||
      canDeleteAboutMePublic(note) ||
      canDeleteOwnAboutMeByMePublic(note) ||
      canDeleteOwnPrivate(note) ||
      canDeleteOtherNotesPublic(note) ||
      canDeleteOwnPublic(note),
    [
      canDeleteOwnAboutMeByMePrivate,
      canDeleteAboutMePublic,
      canDeleteOwnAboutMeByMePublic,
      canDeleteOwnPrivate,
      canDeleteOtherNotesPublic,
      canDeleteOwnPublic,
    ],
  )

  // Share

  const canBeShared = useCallback(
    (note: MainSchema.Note) =>
      isCreatorMe(note) &&
      !isReceiverMe(note) &&
      !!note.communityId &&
      !note.public &&
      can(
        PERMISSION_ACTION.CREATE,
        PERMISSION_SUBJECT.SHARED_NOTE,
        PERMISSION_SCOPES.NOTES_SHARED,
      ),
    [can, isCreatorMe, isReceiverMe],
  )

  return {
    // helpers
    isCreatorMe,
    isReceiverMe,
    // create
    canCreatePublic,
    canCreatePrivate,
    canCreate,
    // edit
    canCreatorEditToPublic,
    canOtherEditToPublic,
    canEditToPublic,
    canEditToPrivate,
    canCreatorEditNotes,
    canEdit,
    // delete
    canDeleteOwnPublic,
    canDeleteOwnPrivate,
    canDeleteOwnAboutMeByMePublic,
    canDeleteOwnAboutMeByMePrivate,
    canDeleteAboutMePublic,
    canDeleteOtherNotesPublic,
    canDelete,
    // share
    canBeShared,
  }
}

export default useAbility
