import React from 'react'

import { useResponsiveLayout } from 'Hooks'

import Desktop from './Desktop'
import Mobile from './Mobile'

export interface IGraphControls {
  graphControls?: {
    reset?: boolean
    options?: boolean
    myNetwork?: boolean
    graphSnapshots?: boolean
    search?: boolean
    browse?: boolean
  }
  isSubGraph?: boolean
  loading?: boolean
}

function GraphControls({
  graphControls,
  isSubGraph = false,
  loading = false,
}: IGraphControls) {
  const { isMobile } = useResponsiveLayout()

  // TODO: Make showing controls and position of controls more customizable in next iteration
  return isMobile ? (
    <Mobile
      graphControls={graphControls}
      isSubGraph={isSubGraph}
      loading={loading}
    />
  ) : (
    <Desktop
      graphControls={graphControls}
      isSubGraph={isSubGraph}
      loading={loading}
    />
  )
}

export default GraphControls
