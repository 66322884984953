import React from 'react'
import PropTypes from 'prop-types'

import { Row, Tag } from 'Components/UI'

import { TAG_COLOR_KIND } from 'Constants/tags'

function IndustriesCell({ row }) {
  const recommendation = row?.original

  return (
    <Row gap={2}>
      {recommendation?.industryTags?.map(tag => (
        <Tag
          colorKind={TAG_COLOR_KIND.INDUSTRY}
          key={tag.id}
          small
          text={tag.name}
        />
      ))}
    </Row>
  )
}

IndustriesCell.defaultProps = {
  row: {},
}

IndustriesCell.propTypes = {
  row: PropTypes.object,
}

export default IndustriesCell
