import styled from 'styled-components'
import { margin, MarginProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

export interface IHolderProps extends MarginProps {}

export const Holder = styled.div<IHolderProps>`
  display: flex;
  border-left: 1px solid ${themeGet('colors.text.heading')};
  flex-shrink: 0;
  padding-left: ${themeGet('space.3')}px;
  position: relative;
  z-index: 1000;

  ${margin};
`
export const DescriptionContainer = styled.div`
  width: 250px;
`
