import loadable from '@loadable/component'

import AcceptCommunityInvite from './AcceptCommunityInvite'
import Nylas from './Nylas'
import OAuth from './OAuth'
import Redirector from './Redirector'
import VerifyEmail from './VerifyEmail'

const Public = {
  Redirector,
  Root: loadable(() => import('./Root' /* webpackChunkName: "public-root" */)),
  Nylas,
  OAuth,
  AcceptCommunityInvite,
  VerifyEmail,
}

export default Public
