import React, { useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'
import ExplainRecommendedPersonQuery from 'GraphQL/Queries/ExplainRecommendedPersonQuery.graphql'

import { Avatar, Loader } from 'Components/UI'

import { useAppContext, useCommunity } from 'Hooks'

import * as Styled from './AskOfferTab.styles'

interface Props {
  recommendedPeople: MainSchema.RecommendedPersonType[]
  askOfferStatementId: string
  communityUser: MainSchema.GetCommunityUser
}

export default function RecommendedPeople({
  recommendedPeople,
  askOfferStatementId,
  communityUser,
}: Props) {
  const { community } = useCommunity()
  const { communityUser: c2 } = useAppContext()
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const [activeCommunityUserId, setActiveCommunityUserId] = useState<
    string | null
  >(null)
  const [explanations, setExplanations] = useState<Record<string, string>>({})

  function onClickRecommendedPerson(index: number, communityUserId: string) {
    if (activeIndex === index) {
      setActiveIndex(null)
      setActiveCommunityUserId(null)
    } else {
      setActiveIndex(index)
      setActiveCommunityUserId(communityUserId)
    }
  }

  const {
    data: explainRecommendedPersonQuery,
    refetch,
    loading,
  } = useQuery<Pick<MainSchema.Query, 'explainRecommendedPerson'>>(
    ExplainRecommendedPersonQuery,
    {
      variables: {
        forAskOfferStatementId: askOfferStatementId,
        recommendedCommunityUserId: activeCommunityUserId,
        asViewedByCommunityUserId: c2?.id,
      },
      skip:
        !activeCommunityUserId || !askOfferStatementId || !communityUser?.id,
      context: {
        headers: {
          'x-community-id': community?.id,
        },
      },
      onCompleted: data => {
        if (
          data?.explainRecommendedPerson?.explanation &&
          activeCommunityUserId
        ) {
          setExplanations(prev => ({
            ...prev,
            [activeCommunityUserId]: data.explainRecommendedPerson.explanation,
          }))
        }
      },
    },
  )

  useEffect(() => {
    if (activeCommunityUserId && !explanations[activeCommunityUserId]) {
      refetch({
        forAskOfferStatementId: askOfferStatementId,
        recommendedCommunityUserId: activeCommunityUserId,
        asViewedByCommunityUserId: c2?.id,
      })
    }
  }, [
    activeCommunityUserId,
    askOfferStatementId,
    c2?.id,
    refetch,
    explanations,
  ])

  const explanation =
    explainRecommendedPersonQuery?.explainRecommendedPerson.explanation

  if (recommendedPeople.length === 0) {
    return (
      <Styled.RecommendedPeople>
        <Styled.Explanation>
          No matches found, check back later.
        </Styled.Explanation>
      </Styled.RecommendedPeople>
    )
  }

  return (
    <Styled.RecommendedPeople>
      {recommendedPeople?.map((recommendedPerson, index) => (
        <li key={recommendedPerson.communityUser.id}>
          <Styled.RecommendedPerson
            onClick={() =>
              onClickRecommendedPerson(
                index,
                recommendedPerson.communityUser.id,
              )
            }
          >
            <Styled.ArrowIcon isActive={index === activeIndex} />
            <div>
              <Avatar
                extraSmall
                src={recommendedPerson.communityUser.user.profile?.photoUrl}
              />
            </div>
            <Styled.Label>
              {recommendedPerson.communityUser.user.profile?.firstName}{' '}
              {recommendedPerson.communityUser.user.profile?.lastName}{' '}
              {index === activeIndex && loading && <Loader />}
            </Styled.Label>
          </Styled.RecommendedPerson>
          {index === activeIndex && !loading && (
            <Styled.Explanation>{explanation}</Styled.Explanation>
          )}
        </li>
      ))}
    </Styled.RecommendedPeople>
  )
}
