export const DOMAIN_STRING_REGEX = '^((\\*)|([a-z]+))((\\.[a-z]*)+)$'

export const PASSWORD_REGEX =
  '^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*_-]).{8,}$'

export const LINKEDIN_REGEX =
  /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/([\w-%]+)\/?$/

export const LINKEDIN_STRING_REGEX = /\/in\/[a-zA-Z0-9-%]+/

export const TWITTER_REGEX =
  /^(https?:\/\/)?(www\.)?(twitter|x)\.com\/([a-zA-Z0-9_]{1,15})\/?/

export const FACEBOOK_REGEX =
  /^(https?:\/\/)?(www\.)?facebook\.com\/([a-zA-Z0-9.]{5,50})\/?/

export const EMAIL_STRING_REGEX =
  "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"

// TODO: write a regex that will take all spans with mention class
export const mentionElementRegex =
  /<span\s+class="([\s\S]*?)"\s+data-id="([\s\S]*?)"\s+data-kind="([\s\S]*?)"\s+data-tooltip="([\s\S]*?)"\s+contenteditable="false">([\s\S]*?)<\/span>/gi

export const htmlRegex = /<\/?([a-z][a-z0-9]*)\b[^>]*>|<!--[\s\S]*-->/gi

// [__kind__][__label__](__id__)
export const mentionRegex = /\[__([^_]+)__]\[__([^_]+)__]\(__([^)]+)__\)/gi
