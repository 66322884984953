import React, { useMemo } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useRoutes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { FiX } from 'react-icons/fi'

import canShowDashboardQuery from 'GraphQL/Queries/Community/canShowDashboard.graphql'
import getCommunityUserQuery from 'GraphQL/Queries/CommunityUser/getCommunityUser.graphql'
import meQuery from 'GraphQL/Queries/User/me.graphql'
import validate from 'validate.js'

import forEach from 'lodash/forEach'

import { Loader } from 'Components/UI'

import { APP_NAME } from 'Config'
import validators from 'Config/validators'

import { COMMUNITY_USER_STATUS, USER_ROLE } from 'Constants/ids'

import { useCommunity, useCommunitySurveySettings, useTelemetry } from 'Hooks'
import useBuildInfo from 'Hooks/useBuildInfo'

import getRoutes from 'Router'

import { useQuery } from 'Services/Apollo'
import AppContext, { IAppContext } from 'Services/AppContext'
import { LocaleRenderer } from 'Services/I18n'
import { getAuth } from 'Services/Store/auth'
import { getLocale } from 'Services/Store/i18n'

import { theme } from 'Theme'

forEach(validators, validator => {
  validate.validators[validator.key] = validator.value
})

export default function App() {
  useBuildInfo()

  const auth = getAuth()
  const locale = getLocale()
  const { community, refetchCommunityBySlug } = useCommunity()
  const { communitySurveySettings } = useCommunitySurveySettings({
    communityId: community?.id,
  })

  const { data: canShowDashboardData } = useQuery<
    MainQueryData<'canShowDashboard'>,
    MainSchema.QueryCanShowDashboardArgs
  >(canShowDashboardQuery, {
    variables: {
      communityId: community?.id as string,
    },
    skip: !community?.id,
  })

  const { data, loading, refetch } = useQuery<
    MainQueryData<'me'>,
    {
      communityId?: string
    }
  >(meQuery, {
    skip: !auth?.accessToken,
    fetchPolicy: 'network-only',
    variables: {
      communityId: community?.id,
    },
  })

  const {
    data: communityUserData,
    loading: loadingCommunityUser,
    refetch: refetchCommunityUser,
  } = useQuery<
    Pick<MainSchema.Query, 'getCommunityUser'>,
    MainSchema.QueryCommunityUserArgs
  >(getCommunityUserQuery, {
    skip: !data?.me?.id || !community?.id,
    variables: { communityId: community?.id!, userId: data?.me?.id! },
    fetchPolicy: 'network-only',
  })

  const me = data?.me
  const communityUser = communityUserData?.getCommunityUser

  useTelemetry(communityUser)

  const canShowDashboard = !!canShowDashboardData?.canShowDashboard?.ok

  const isRestricted =
    (communitySurveySettings?.dashboardAccess === false &&
      me?.communityStatus === COMMUNITY_USER_STATUS.PENDING) ||
    (!canShowDashboard && !!community?.id)

  const isSuperAdmin = me?.role === USER_ROLE.SUPER_ADMIN

  const routes = useRoutes(getRoutes(me, isRestricted))

  const memoizedContext: IAppContext = useMemo(
    () => ({
      me,
      refetchMe: refetch,
      loadingMe: loading,
      refetchCommunityBySlug,
      slug: community?.slug,
      locale,
      isSuperAdmin,
      isRestricted,
      canShowDashboard,
      communityUser,
      loadingCommunityUser,
      refetchCommunityUser,
    }),
    [
      canShowDashboard,
      communityUser,
      isRestricted,
      isSuperAdmin,
      loading,
      loadingCommunityUser,
      locale,
      me,
      community,
      refetch,
      refetchCommunityBySlug,
      refetchCommunityUser,
    ],
  )

  if (!me && loading) {
    return <Loader absolute text="Loading..." />
  }

  return (
    <LocaleRenderer key={locale}>
      <AppContext.Provider value={memoizedContext}>
        <HelmetProvider>
          <Helmet>
            <title>{APP_NAME}</title>

            {theme.webfonts.map(font => (
              <link
                href={`https://fonts.googleapis.com/css?family=${font}`}
                key={font}
                rel="stylesheet"
              />
            ))}
          </Helmet>

          {routes}

          <ToastContainer
            className="toast-container"
            closeButton={<FiX size={10} />}
            closeOnClick
            draggable
            progressClassName="toast-progress"
            toastClassName="toast"
          />
        </HelmetProvider>
      </AppContext.Provider>
    </LocaleRenderer>
  )
}
