import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import communityUserDirectConnectionsQuery from 'GraphQL/Queries/Community/communityUserDirectConnections.graphql'
import Utils from 'Utils'

import { ExtendedUserCard } from 'Components/Blocks/UserCard'
import { HandleClick } from 'Components/Blocks/UserCard/ExtendedUserCard'
import { Loader, Row } from 'Components/UI'

import { useAppContext, useCommunity, useResponsiveLayout } from 'Hooks'
import { IGraphState } from 'Hooks/useGraphContext'

import { COMMUNITY_USER } from 'Router/routes'

import { useQuery } from 'Services/Apollo'
import EventBus from 'Services/EventBus'

export interface IConnectionsTabProps {
  userId?: string
  setGraphState?: React.Dispatch<React.SetStateAction<IGraphState>>
}

function ConnectionsTab({ userId, setGraphState }: IConnectionsTabProps) {
  const { me } = useAppContext()
  const { community } = useCommunity()

  const { isMobile } = useResponsiveLayout()
  const navigate = useNavigate()

  const { data: userConnections, loading } = useQuery<
    Pick<MainSchema.Query, 'communityUserDirectConnections'>,
    MainSchema.QueryCommunityUserDirectConnectionsArgs
  >(communityUserDirectConnectionsQuery, {
    fetchPolicy: 'cache-and-network',
    variables:
      userId && community?.id
        ? { userId, communityId: community.id }
        : undefined,
    skip: !userId || !community?.id,
  })

  const connections = useMemo(
    () => userConnections?.communityUserDirectConnections || [],
    [userConnections],
  )

  const handleSelectUser = useCallback<HandleClick>(
    (selectedUser, isMultiSelect) => {
      if (isMobile) {
        navigate(
          Utils.URL.generatePath(COMMUNITY_USER, {
            slug: community?.slug,
            userId: selectedUser.id,
          }),
        )
      }

      if (isMultiSelect) {
        setGraphState?.(prevState => ({
          ...prevState,
          selection: {
            ...prevState.selection,
            [selectedUser.id]: true,
          },
        }))
      } else {
        setGraphState?.(prevState => ({
          ...prevState,
          selection: {
            [selectedUser.id]: true,
          },
        }))
      }

      EventBus.trigger(
        EventBus.actions.profile.openRightUserPanelProfile,
        selectedUser?.id,
      )
    },
    [community?.slug, navigate, isMobile, setGraphState],
  )

  if (loading) {
    return <Loader absolute text="Loading..." />
  }

  if (!connections.length) {
    return null
  }

  return (
    <Row fullWidth gap={2} wrapped>
      {connections.map(connection => (
        <ExtendedUserCard
          id={connection.id}
          key={connection.id}
          showConnectionActions={userId === me?.id}
          user={{
            id: connection.id!,
            firstName: connection.firstName,
            lastName: connection.lastName,
            photoUrl: connection.photoUrl,
            jobTitle: connection.jobTitle?.name,
            organization: connection.organization?.name,
          }}
          onClick={handleSelectUser}
        />
      ))}
    </Row>
  )
}

export default ConnectionsTab
