import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const Container = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
`

export interface IRightPanelIsMoBileCssProps {
  isMobile?: boolean
}

const RightPanelIsMoBileCss = ({ isMobile }: IRightPanelIsMoBileCssProps) =>
  isMobile &&
  css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    > button:first-of-type {
      display: flex;
    }

    > button:last-of-type {
      display: none;
    }
  `

export interface IRightPanelProps extends IRightPanelIsMoBileCssProps {}

export const RightPanel = styled.div<IRightPanelProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100%;
  width: 360px;
  background-color: ${themeGet('colors.bg.primary')};
  border-left: 1px solid ${themeGet('colors.divider.default')};

  > button:first-of-type {
    display: none;
  }

  > button:last-of-type {
    display: flex;
  }

  ${RightPanelIsMoBileCss}
`

export const StyledButton = styled.button`
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 96px;
  left: 0;
  transform: translateX(-50%);
  z-index: 1;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: ${themeGet('colors.main')};
  color: ${themeGet('colors.text.contrast')};
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;

  &:hover {
    background-color: ${themeGet('colors.hover')};
  }
`

export const BackButton = styled.button`
  align-items: center;
  gap: ${themeGet('space.2')}px;
  color: ${themeGet('colors.text.header')};
  padding: ${themeGet('space.3')}px ${themeGet('space.4')}px;
  border: none;
  outline: none;
  background: none;
  border-bottom: 1px solid ${themeGet('colors.divider.default')};
`
