import { FiClock, FiX } from 'react-icons/fi'

import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { resetButton } from 'Components/Styles'

const iconBaseCss = css`
  width: 12px;
  height: 12px;
  color: ${themeGet('colors.mineShaft')};
  stroke-width: 1;
`
export const CloseIcon = styled(FiX)`
  ${iconBaseCss};
`

export const ClockIcon = styled(FiClock)`
  ${iconBaseCss};
`

export const Button = styled.button`
  ${resetButton};

  display: flex;
  cursor: pointer;
`
