import React, { PropsWithChildren } from 'react'

import { Container } from './styles'

export interface ICardProps extends PropsWithChildren {}

function Card({ children }: ICardProps) {
  return <Container>{children}</Container>
}

export default Card
