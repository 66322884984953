import React, { useCallback, useMemo } from 'react'

import communityUserQuery from 'GraphQL/Queries/Community/communityUser.graphql'
import getCommunityUserQuery from 'GraphQL/Queries/CommunityUser/getCommunityUser.graphql'

import ShareUserModal from 'Components/Blocks/Modals/ShareUser'
import UserProfile from 'Components/Blocks/UserProfile'
import { Column, Loader } from 'Components/UI'

import {
  useCommunityContext,
  useEntityModal,
  useEventBusSubscribe,
} from 'Hooks'

import { useQuery } from 'Services/Apollo'
import EventBus from 'Services/EventBus'

export interface IRightPanelUserProfile {
  userId: MainSchema.QueryGetCommunityUserArgs['userId']
}

function RightPanelUserProfile({ userId }: IRightPanelUserProfile) {
  const { community, communityLoading } = useCommunityContext()
  const [shareUserModal, shareUserModalActions] =
    useEntityModal<MainSchema.QueryGetCommunityUserArgs['userId']>()

  const communityId = community?.id
  const skip = !communityId || !userId

  const {
    data: getCommunityUserData,
    loading: isGetCommunityUserLoading,
    refetch: refetchGetCommunityUser,
  } = useQuery<
    Pick<MainSchema.Query, 'getCommunityUser'>,
    MainSchema.QueryGetCommunityUserArgs
  >(getCommunityUserQuery, {
    skip,
    variables: communityId && userId ? { communityId, userId } : undefined,
    fetchPolicy: 'network-only',
  })
  const {
    data: communityUserData,
    loading: isCommunityUserLoading,
    refetch: refetchCommunityUser,
  } = useQuery<
    Pick<MainSchema.Query, 'communityUser'>,
    MainSchema.QueryGetCommunityUserArgs
  >(communityUserQuery, {
    fetchPolicy: 'network-only',
    skip,
    variables: communityId && userId ? { communityId, userId } : undefined,
  })

  const user = useMemo(
    () => getCommunityUserData?.getCommunityUser,
    [getCommunityUserData?.getCommunityUser],
  )
  const graphUser = useMemo(
    () => communityUserData?.communityUser,
    [communityUserData?.communityUser],
  )

  // const handleOpenShareUserModal = useCallback(() => {
  //   shareUserModalActions.openModal(user?.id)
  // }, [shareUserModalActions, user])

  const handleCloseShareUserModal = shareUserModalActions.closeModal

  const handleReload = useCallback(async () => {
    await refetchGetCommunityUser({ communityId, userId })
    await refetchCommunityUser({ communityId, userId })
  }, [communityId, refetchGetCommunityUser, refetchCommunityUser, userId])

  useEventBusSubscribe(EventBus.actions.profile.reload, handleReload)

  if (
    isGetCommunityUserLoading ||
    isCommunityUserLoading ||
    communityLoading ||
    !user ||
    !graphUser
  ) {
    return (
      <Column center fullHeight fullWidth justifyCenter>
        <Loader />
      </Column>
    )
  }

  return (
    <>
      <UserProfile
        graphUser={graphUser}
        user={user}
        // onShareUserClick={handleOpenShareUserModal}
      />

      <ShareUserModal
        isOpen={shareUserModal?.isOpen}
        userId={shareUserModal?.entity}
        onClose={handleCloseShareUserModal}
      />
    </>
  )
}

export default RightPanelUserProfile
