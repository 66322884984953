import React from 'react'

import { IconStar } from '@tabler/icons-react'

import { Column, Row, Text } from 'Components/UI'

import colors from 'Theme/_v2/colors'

import Card from './Card'

export interface IAboutUserBoxProps {
  user: MainSchema.GraphUser | MainSchema.GetCommunityUser
}

function AboutUserBox({ user }: IAboutUserBoxProps) {
  return (
    <Card>
      <Column gap={2}>
        <Row gap={2}>
          <IconStar color={colors.icon.profile} size={20} stroke={1} />
          <Text header header4>
            About me
          </Text>
        </Row>

        <Text body bodyMedium>
          {user.about}
        </Text>
      </Column>
    </Card>
  )
}

export default AboutUserBox
