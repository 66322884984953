import React, { useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'

import { TableActions } from 'Components/Blocks/Tables/Blocks'

import { useTableContext } from '../context'

function ActionsCell({ row, deletedSharedUserIds }) {
  const deleteRef = useRef(null)

  const { onDeleteRow } = useTableContext()

  const sharedUser = row?.original

  const handleDelete = useCallback(() => {
    deleteRef.current.blur()
    onDeleteRow(sharedUser)
  }, [onDeleteRow, sharedUser])

  const isDeletedUser = useMemo(
    () => deletedSharedUserIds.includes(sharedUser?.id),
    [deletedSharedUserIds, sharedUser],
  )

  return (
    <TableActions
      canDelete={!isDeletedUser}
      canRestore={isDeletedUser}
      deleteRef={deleteRef}
      restoreRef={deleteRef}
      onDelete={handleDelete}
      onRestore={handleDelete}
    />
  )
}

ActionsCell.defaultProps = {
  deletedSharedUserIds: [],
  row: {},
}

ActionsCell.propTypes = {
  deletedSharedUserIds: PropTypes.array,
  row: PropTypes.object,
}

export default ActionsCell
