import React, { useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'

import { TableActions } from 'Components/Blocks/Tables/Blocks'

import { useTableContext } from '../context'

function ActionsCell({ row, deleteUserIds }) {
  const deleteRef = useRef(null)

  const { onDeleteRow } = useTableContext()

  const user = row?.original?.user

  const handleDelete = useCallback(() => {
    deleteRef.current.blur()
    onDeleteRow(user)
  }, [onDeleteRow, user])

  const isDeletedUser = useMemo(
    () => deleteUserIds.includes(user?.id),
    [deleteUserIds, user?.id],
  )

  return (
    <TableActions
      canDelete={!isDeletedUser}
      canRestore={isDeletedUser}
      deleteRef={deleteRef}
      restoreRef={deleteRef}
      onDelete={handleDelete}
      onRestore={handleDelete}
    />
  )
}

ActionsCell.defaultProps = {
  deleteUserIds: [],
  row: {},
}

ActionsCell.propTypes = {
  deleteUserIds: PropTypes.array,
  row: PropTypes.object,
}

export default ActionsCell
