import React, { useEffect, useState } from 'react'

import { useMutation } from '@apollo/client'
import UpdateAskOffer from 'GraphQL/Mutations/AskOffer/UpdateAskOffer.graphql'

import { Button, Input, Loader, TextButton } from 'Components/UI'

import { useCommunity } from 'Hooks'
import useMediaRecorder from 'Hooks/useMediaRecorder'

import toast from 'Services/Toast'

import * as Styled from './AskOfferTab.styles'

interface Props {
  askOffer: MainSchema.AskOfferStatement
  handleNavigateBack: () => void
}

export default function AskOfferEdit({ askOffer, handleNavigateBack }: Props) {
  const { community } = useCommunity()
  const {
    startRecording,
    stopRecording,
    processRecording,
    transcription,
    isRecording,
    isProcessing,
  } = useMediaRecorder()

  const [updateAskOffer, { loading }] = useMutation(UpdateAskOffer, {
    context: {
      headers: {
        'x-community-id': community?.id,
      },
    },
    onCompleted: () => {
      toast.success({
        title: `${askOffer.kind} Saved`,
        text: ``,
      })
      resetForm()
    },
  })
  const [textareaValue, setTextareaValue] = useState<string>(
    askOffer.statement || '',
  )

  useEffect(() => {
    const text =
      transcription?.correctedTranscription === '<not enough content>'
        ? transcription.transcription
        : transcription?.correctedTranscription

    setTextareaValue(prevValue =>
      prevValue ? `${prevValue}\n${text ?? ''}` : text ?? '',
    )
  }, [transcription])

  function resetForm() {
    setTextareaValue('')
  }

  const onClickBack = () => {
    handleNavigateBack()
  }

  const onClickUpdateAskOffer = () => {
    updateAskOffer({
      variables: {
        kind: askOffer.kind,
        id: askOffer.id,
        statement: textareaValue,
        source: 'NetworkOS',
      },
    })

    handleNavigateBack()
  }

  const onChangeTextarea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextareaValue(event.target.value)
  }

  const handleStopRecording = async () => {
    stopRecording()
    processRecording()
  }

  return (
    <Styled.Container mr="-12px">
      <Styled.ScrollableContainer>
        <Styled.InputWrapper>
          <Input
            label={<span>Update &lsquo;{askOffer.kind}&rsquo;</span>}
            textArea
            value={textareaValue}
            onChange={onChangeTextarea}
          />
          {isRecording ? (
            <Styled.MicButton type="button" onClick={handleStopRecording}>
              <Styled.MicIconActive />
            </Styled.MicButton>
          ) : (
            <Styled.MicButton type="button" onClick={startRecording}>
              {isProcessing ? <Loader /> : <Styled.MicIcon />}
            </Styled.MicButton>
          )}
        </Styled.InputWrapper>
        <Styled.Actions>
          <TextButton secondary small onClick={onClickBack}>
            Cancel
          </TextButton>
          <Button
            disabled={loading || isRecording || isProcessing}
            small
            onClick={onClickUpdateAskOffer}
          >
            Save
          </Button>
        </Styled.Actions>
      </Styled.ScrollableContainer>
    </Styled.Container>
  )
}
