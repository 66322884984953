import styled, { css } from 'styled-components'

export interface IMobileCss {
  mobile?: boolean
}

const mobileCss = ({ mobile }: IMobileCss) =>
  mobile &&
  css`
    height: 100%;
  `

export interface IContainer extends IMobileCss {}

export const Container = styled.div<IContainer>`
  position: relative;
  border: 0 solid lightgray;
  width: 100%;
  height: 100%;
  ${mobileCss}
`
