import { down } from 'styled-breakpoints'
import styled from 'styled-components'
import { margin, MarginProps } from 'styled-system'

import { customScrollbar } from 'Theme/styles'
import { gap, GapProps } from 'Theme/system'

export interface INoteEditorScrollContainer extends MarginProps {}

export const NoteEditorScrollContainer = styled.div<INoteEditorScrollContainer>`
  display: flex;
  width: 100%;
  flex-shrink: 0;
  max-height: 50vh;
  overflow: auto;

  ${margin};
  ${customScrollbar};
`

export interface IScrollContainer extends GapProps {}

export const ScrollContainer = styled.div<IScrollContainer>`
  display: flex;
  width: 100%;
  flex-direction: column;
  height: auto;
  min-height: 100px;
  padding: 12px;
  overflow: auto;

  :empty {
    min-height: unset;
  }

  ${down('md')} {
    max-height: 100%;
  }

  ${customScrollbar}
  ${gap}
`
