import React, { PropsWithChildren, useCallback, useState } from 'react'

import { useMutation } from '@apollo/client'
import createProposalMutation from 'GraphQL/Mutations/Proposal/createProposal.graphql'
import getCommunityUserQuery from 'GraphQL/Queries/CommunityUser/getCommunityUser.graphql'
import { createProposalForUserUpdater } from 'GraphQL/Updaters/ProposalForUser'

import { Input, Row, Text } from 'Components/UI'

import { ProposalKind } from 'Constants/mainGraphQL'
import { FACEBOOK_REGEX, LINKEDIN_REGEX, TWITTER_REGEX } from 'Constants/regex'

import { useAppContext, useCommunityContext } from 'Hooks'

import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import Action from '../UserProfile/Blocks/TagBlock/Action'

const twitterRegex = new RegExp(TWITTER_REGEX)
const facebookRegex = new RegExp(FACEBOOK_REGEX)
const linkedinRegex = new RegExp(LINKEDIN_REGEX)

export interface ISocial extends PropsWithChildren {
  sharedUserId?: string
  showCreateProposals?: boolean
  userId?: string
}

function Social({
  showCreateProposals = true,
  userId,
  sharedUserId,
  children,
}: ISocial) {
  const { me } = useAppContext()
  const { community } = useCommunityContext()
  const s = useScopedI18n('blocks.proposal.social')

  const [proposedUrl, setProposedUrl] = useState<string>('')
  const [isLoading, setLoading] = useState(false)

  const [createProposal] = useMutation<
    Pick<MainSchema.Mutation, 'createProposal'>,
    MainSchema.MutationCreateProposalArgs
  >(createProposalMutation)

  const handleSubmit = useCallback(async () => {
    if (!userId || !community) {
      return
    }

    setLoading(true)

    let kind: ProposalKind | undefined

    if (twitterRegex.test(proposedUrl)) {
      kind = ProposalKind.TwitterUrl
    } else if (facebookRegex.test(proposedUrl)) {
      kind = ProposalKind.FacebookUrl
    } else if (linkedinRegex.test(proposedUrl)) {
      kind = ProposalKind.LinkedInUrl
    }

    try {
      if (!kind) {
        return
      }

      await createProposal({
        variables: {
          communityId: community.id,
          userId,
          kind,
          value: proposedUrl,
        },
        update: createProposalForUserUpdater({
          userId,
          communityId: community.id,
          sharedUserId,
        }),
        refetchQueries: [
          {
            query: getCommunityUserQuery,
            variables: {
              communityId: community.id,
              userId,
            },
          },
        ],
      })

      setProposedUrl('')

      toast.success({
        title: s('messageTitle'),
        text: s('success'),
      })
    } catch (error) {
      let message = _('error.generic')

      if (error instanceof Error && error?.message) {
        message = _(`error.${error.message}`)
      }

      toast.error({
        title: s('messageTitle'),
        text: message,
      })
    } finally {
      setLoading(false)
    }
  }, [createProposal, community, userId, proposedUrl, sharedUserId, s])

  const isValid =
    twitterRegex.test(proposedUrl) ||
    facebookRegex.test(proposedUrl) ||
    linkedinRegex.test(proposedUrl)

  return (
    <Action
      disabled={!isValid}
      isLoading={isLoading}
      showOnlyTitle={me?.id === userId || !showCreateProposals}
      title={
        <Row alignItems="center" gap={3}>
          <Text header header4>
            {s('title')}
          </Text>

          {children}
        </Row>
      }
      onSave={handleSubmit}
    >
      <Input
        mt={2}
        name="socialUrl"
        placeholder={s('placeholder')}
        value={proposedUrl}
        width={1}
        onChange={event => setProposedUrl(event?.target?.value)}
      />
    </Action>
  )
}

export default Social
