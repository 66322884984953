import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const MOBILE_FOOTER_HEIGHT = 48

export const Container = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-top: 1px solid ${themeGet('colors.divider.default')};
  background-color: ${themeGet('colors.bg.primary')};
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${themeGet('zIndices.mobileFooter')};
`

export const ItemWrapper = styled.div`
  width: 60px;
  height: ${MOBILE_FOOTER_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: center;
`
