import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { FiLogOut } from 'react-icons/fi'

import Avatar from 'Components/Blocks/Avatar'
import { Column, Dropdown, LinkButton, Row, Text } from 'Components/UI'

import { ROLE_NAMES } from 'Constants/strings'

import { useAppContext } from 'Hooks'

import { SIGN_OUT } from 'Router/routes'

import { Container } from './styles'

function Header() {
  const { communityUser } = useAppContext()
  const navigate = useNavigate()

  const handleSignOutClick = useCallback(() => {
    navigate(SIGN_OUT)
  }, [navigate])

  return (
    <Container center height={48} justifyEnd noShrink px={3}>
      <Column mr={3}>
        <Text bold textAlign="right">
          {communityUser?.firstName || ''} {communityUser?.lastName}
        </Text>
        <Text mt={-1} textAlign="right">
          {communityUser?.role && communityUser.role in ROLE_NAMES
            ? ROLE_NAMES[communityUser.role]
            : ''}
        </Text>
      </Column>
      <Dropdown
        content={
          <Row px={3} py={2}>
            <LinkButton small onClick={handleSignOutClick}>
              Sign Out
              <FiLogOut size={16} />
            </LinkButton>
          </Row>
        }
        offset={[0, 0]}
        placement="bottom"
        withArrow
      >
        <Row style={{ cursor: 'pointer' }}>
          <Avatar size={44} src={communityUser?.photoUrl} />
        </Row>
      </Dropdown>
    </Container>
  )
}

export default Header
