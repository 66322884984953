import styled from 'styled-components'
import { size, SizeProps } from 'styled-system'

import LogoGlyph from 'Assets/Svg/logo-network.svg'

export interface IContainer extends SizeProps {}

export const Container = styled.div<IContainer>`
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
  display: flex;

  ${size}
`

export const LogoIcon = styled(LogoGlyph)`
  display: flex;
  flex: 1;
  align-self: center;
`
