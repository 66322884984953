import { useMemo } from 'react'

import {
  ActionsCell,
  IndustriesCell,
  SelectCell,
  SkillsCell,
  UserNameCell,
} from './Cells'
import { SelectAllHeader } from './Headers'

export function useColumns() {
  return useMemo(
    () => [
      {
        id: 'select',
        Header: SelectAllHeader,
        Cell: SelectCell,
        width: '16px',
      },
      {
        Header: 'User',
        accessor: 'recommendedUser',
        Cell: UserNameCell,
        width: 1,
      },
      {
        Header: 'Skills',
        Cell: SkillsCell,
        width: 2,
        disableSortBy: true,
      },
      {
        Header: 'Industries',
        Cell: IndustriesCell,
        width: 2,
        disableSortBy: true,
      },
      {
        Header: 'Actions',
        id: 'actions',
        Cell: ActionsCell,
        width: 0.5,
        disableSortBy: true,
        cellRight: true,
        headerRight: true,
      },
    ],
    [],
  )
}
