import { ACTION_KIND } from 'Constants/graph'

export const TOOLTIPS_BY_KIND = {
  [ACTION_KIND.addUser]: 'User',
  [ACTION_KIND.meetUser]: 'Meet user',
  [ACTION_KIND.skill]: 'Skill',
  [ACTION_KIND.needSkill]: 'Need skill',
  [ACTION_KIND.role]: 'Role',
  [ACTION_KIND.group]: 'Group',
  [ACTION_KIND.project]: 'Project',
  [ACTION_KIND.event]: 'Event',
  [ACTION_KIND.custom]: 'Custom',
}
