import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { SortingRule } from 'react-table'

import { useMutation } from '@apollo/client'
import { IconSearch } from '@tabler/icons-react'
import useAbility from 'Features/Notes/useAbility'
import updateNoteFavoriteMutation from 'GraphQL/Mutations/User/updateNoteFavorite.graphql'
import notesQuery from 'GraphQL/Queries/Notes/notes.graphql'
import Utils from 'Utils'

import filterLodash from 'lodash/filter'
import map from 'lodash/map'

import ContentCard from 'Components/Blocks/ContentCard'
import DeleteNotesModal from 'Components/Blocks/Modals/DeleteNotes'
import ShareNoteModal from 'Components/Blocks/Modals/ShareNote'
import { Input, Row, Switch, Table, Text } from 'Components/UI'

import { SortInputOrder } from 'Constants/mainGraphQL'

import {
  useCommunity,
  useEntityModal,
  useEntityTable,
  useResponsiveLayout,
  useTableSearch,
} from 'Hooks'

import { useQuery } from 'Services/Apollo'
import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { useColumns } from './columns'
import TableContext from './context'
import Filter, { IFilters } from './Filter'
import NotesReader from './NoteReader'
import { Container } from './styles'

import {
  TableControlContainer,
  TableGroupOperatingPanel,
  TablePagination,
} from '../Blocks'

const DEFAULT_PAGE_SIZE = 10

const SORT_BY: MainSchema.SortInput[] = [
  {
    column: 'updatedAt',
    order: SortInputOrder.Desc,
  },
]

function NotesTable() {
  const s = useScopedI18n('notesManagement')
  const [deleteModal, deleteActions] = useEntityModal<string>()
  const [shareNoteModal, shareNoteModalActions] = useEntityModal<string>()
  const { community } = useCommunity()
  const mostRecentSortBy = useRef<MainSchema.SortInput[] | null>(SORT_BY)
  const { isMobile } = useResponsiveLayout()

  const { canDelete } = useAbility()

  const [mostRecentPage, setMostRecentPage] = useState(0)
  const [mostRecentLimit, setMostRecentLimit] = useState(DEFAULT_PAGE_SIZE)
  const [filter, setFilter] = useState<IFilters>({})
  const [showOnlyOwn, setShowOnlyOwn] = useState(true)
  const [isSelectedAll, setSelectedAll] = useState(false)
  const [selectedNotes, setSelectedNotes] = useState<MainSchema.Note[]>([])
  const [readNote, setReadNote] = useState<MainSchema.Note | null>(null)
  const [innerSearch, setInnerSearch] = useState<string | null>(null)

  const [updateNoteFavorite] = useMutation(updateNoteFavoriteMutation)

  const [search, changeSearch, clearSearch] = useTableSearch({
    onSearch: value => {
      Utils.Search.tableSearch({
        search: value,
        onSetSearchValue: setInnerSearch,
        onGoToPage: tableProps.gotoPage,
      })
    },
  })

  const { data, loading, refetch } = useQuery<
    Pick<MainSchema.Query, 'notes'>,
    MainSchema.QueryNotesArgs
  >(notesQuery, {
    variables: community
      ? {
          communityId: community.id,
          page: mostRecentPage,
          limit: mostRecentLimit,
          sort: mostRecentSortBy.current,
          showOnlyOwn,
          filter,
          search: innerSearch,
        }
      : undefined,
    skip: !community?.id,
    fetchPolicy: 'network-only',
  })

  const columns = useColumns()
  const entities = useMemo(() => data?.notes, [data])

  const [tableProps, rowsCount] = useEntityTable({
    data: entities,
    columns,
  })

  const { pageSize, pageIndex, sortBy } = tableProps.state

  useEffect(() => {
    setMostRecentPage(pageIndex)
    setSelectedAll(false)
  }, [pageIndex])

  useEffect(() => {
    setMostRecentPage(0)
    setMostRecentLimit(pageSize)
  }, [pageSize])

  const sort: MainSchema.SortInput[] = useMemo(
    () =>
      map(sortBy, (value: SortingRule<MainSchema.Note>) => ({
        column: value.id,
        order: value.desc ? SortInputOrder.Desc : SortInputOrder.Asc,
      })),
    [sortBy],
  )
  mostRecentSortBy.current = sort.length !== 0 ? sort : null

  const handleOpenDeleteModal = deleteActions.openModal
  const handleOpenShareNoteModal = shareNoteModalActions.openModal

  const handleCloseDeleteModal = (success: boolean) => {
    if (success) setSelectedNotes([])

    deleteActions.closeModal(success).then()
  }

  const handleCloseShareNoteModal = (success: boolean) => {
    shareNoteModalActions.closeModal(success).then()

    if (success) refetch().then()
  }

  const handleShowMyNotes = useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(event => {
    setShowOnlyOwn(event.target.checked)
  }, [])

  const handleSelectAll = useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    event => {
      const checked = event?.target?.checked

      if (checked) {
        const filteredNotes = filterLodash(entities?.rows, note =>
          canDelete(note),
        )
        setSelectedAll(true)
        setSelectedNotes(filteredNotes)
      } else {
        setSelectedAll(false)
        setSelectedNotes([])
      }
    },
    [entities, canDelete],
  )

  const handleSelectNote = useCallback(
    (note: MainSchema.Note) => {
      const selectedNotesIds = map(selectedNotes, 'id')

      if (selectedNotesIds.includes(note.id)) {
        setSelectedNotes(prevState =>
          prevState.filter(user => user.id !== note.id),
        )
      } else {
        setSelectedNotes(prevState => [...prevState, note])
      }
    },
    [selectedNotes],
  )

  const handleUpdateFavorite = useCallback(
    async (noteId: string, favorite: boolean) => {
      try {
        await updateNoteFavorite({
          variables: {
            noteId,
            favorite,
            communityId: community?.id,
          },
        })
      } catch (error) {
        let message = _(`error.generic`)

        if (error instanceof Error) {
          message = error.message
        }

        toast.error({
          title: 'Update note prioritization',
          text: message,
        })
      }
    },
    [updateNoteFavorite, community],
  )

  const memoizedContext = useMemo(
    () => ({
      onDeleteNote: handleOpenDeleteModal,
      onSelectAll: handleSelectAll,
      onSelectNote: handleSelectNote,
      isSelectedAll,
      selectedNotesIds: map(selectedNotes, 'id'),
      onUpdateFavorite: handleUpdateFavorite,
      onReadNote: setReadNote,
      onShareNote: handleOpenShareNoteModal,
    }),
    [
      handleOpenDeleteModal,
      handleOpenShareNoteModal,
      handleSelectAll,
      handleSelectNote,
      handleUpdateFavorite,
      isSelectedAll,
      selectedNotes,
    ],
  )

  return (
    <Container>
      <ContentCard>
        <TableContext.Provider value={memoizedContext}>
          <TableControlContainer>
            <Row center fullWidth gap={3} wrapped={isMobile}>
              <Input
                clearable
                name="search"
                placeholder={s('search.byNote')}
                renderBeforeElement={() => <IconSearch />}
                value={search}
                width="300px"
                onChange={changeSearch}
                onClear={clearSearch}
              />

              <Filter onChange={setFilter} />

              <TableGroupOperatingPanel
                canDelete
                itemCount={selectedNotes.length}
                suffix={s('notes', { count: selectedNotes.length })}
                onDelete={handleOpenDeleteModal}
              />
            </Row>

            <Row mt={isMobile ? 2 : 0}>
              <Row center>
                <Text header header4 mr={2} nowrap>
                  {s('onlyMyNotes')}
                </Text>
                <Switch
                  checked={showOnlyOwn}
                  height={18}
                  small
                  width={32}
                  onChange={handleShowMyNotes}
                />
              </Row>
            </Row>
          </TableControlContainer>

          <Table {...tableProps} loading={loading} />

          <TablePagination
            state={{ pageIndex, pageSize }}
            total={rowsCount}
            onPageIndexChange={tableProps?.gotoPage}
            onPageSizeChange={tableProps?.setPageSize}
          />

          <ShareNoteModal
            isOpen={shareNoteModal.isOpen}
            noteId={shareNoteModal?.entity}
            onClose={handleCloseShareNoteModal}
          />

          <DeleteNotesModal
            isOpen={deleteModal.isOpen}
            notes={selectedNotes}
            updater={{
              queryKey: 'notes',
              pageSize,
            }}
            onClose={handleCloseDeleteModal}
          />
        </TableContext.Provider>
      </ContentCard>

      {readNote && (
        <NotesReader
          noteId={readNote.id}
          onClose={() => setReadNote(null)}
          onShareNote={handleOpenShareNoteModal}
          onUpdateFavorite={handleUpdateFavorite}
        />
      )}
    </Container>
  )
}

export default NotesTable
