import styled, { css } from 'styled-components'
import {
  margin,
  MarginProps,
  padding,
  PaddingProps,
  position,
  PositionProps,
  typography,
  TypographyProps,
} from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { IconInfoCircle } from '@tabler/icons-react'

export interface IJustifyCssProps {
  justify?: boolean
}

const justifyCss = ({ justify }: IJustifyCssProps) =>
  justify &&
  css`
    text-align: justify;
  `

export interface IContainerProps extends MarginProps, PaddingProps {}

export const Container = styled.div<IContainerProps>`
  margin: 0;
  padding: 0;

  ${margin}
  ${padding}
`

export interface IHeaderProps
  extends MarginProps,
    PaddingProps,
    TypographyProps {
  mineShaft?: boolean
}

export const Header = styled.p<IHeaderProps>`
  width: 100%;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-weight: bold;

  ${margin}
  ${padding}
`

export interface ISubHeaderProps
  extends MarginProps,
    PaddingProps,
    TypographyProps {
  mineShaft?: boolean
}

export const SubHeader = styled.p<ISubHeaderProps>`
  width: 100%;
  margin: 0;
  padding: 0;

  ${margin}
  ${padding}
  ${typography}
`

export interface IContentProps
  extends MarginProps,
    TypographyProps,
    IJustifyCssProps {
  mineShaft?: boolean
}

export const Content = styled.p<IContentProps>`
  width: 100%;
  margin: 0;
  padding: 0;

  ::first-letter {
    text-transform: uppercase;
    font-weight: ${themeGet('fontWeights.0')};
  }

  ${margin}
  ${justifyCss}
  ${typography}
`

export interface ITooltipContentProps extends IJustifyCssProps {}

export const TooltipContent = styled.p<ITooltipContentProps>`
  display: flex;
  margin: 0;
  padding: 0;
  width: 450px;

  ${justifyCss}
`

export const BulletContent = styled.ul`
  width: 100%;
`
export const BulletItem = styled.li`
  width: 100%;
`

export interface IBlockProps extends MarginProps {}

export const Block = styled.div<IBlockProps>`
  display: flex;
  flex-direction: column;
  border: 1px solid ${themeGet('colors.alto')};
  background: ${themeGet('colors.white')};
  border-radius: 6px;
  padding: 12px;
  width: -webkit-fill-available;

  ${margin}
`

export interface IInfoIconProps extends MarginProps, PositionProps {}

export const InfoIcon = styled(IconInfoCircle)<IInfoIconProps>`
  position: relative;
  width: 20px;
  height: 20px;

  ${margin}
  ${position}
`
