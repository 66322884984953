import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Utils from 'Utils'

import { Loader } from 'Components/UI'

import { useAppContext } from 'Hooks'

import { COMMUNITY_DASHBOARD, COMMUNITY_PANEL } from 'Router/routes'

export type RedirectorParams = {
  slug?: string
}

export default function Redirector() {
  const navigate = useNavigate()
  const { slug } = useParams<RedirectorParams>()
  const { refetchCommunityBySlug } = useAppContext()

  if (slug) {
    refetchCommunityBySlug?.()
  }

  useEffect(() => {
    if (slug) {
      navigate(Utils.URL.generatePath(COMMUNITY_DASHBOARD, { slug }), {
        replace: true,
      })
    } else {
      navigate(COMMUNITY_PANEL, { replace: true })
    }
  }, [navigate, slug])

  return <Loader absolute />
}
