import React from 'react'

// import CreateEducationHistoryModal from 'Components/Blocks/Modals/CreateEducationHistoryModal/CreateEducationHistoryModal'
// import DeleteEducationHistoryModal from 'Components/Blocks/Modals/DeleteEducationHistoryModal/DeleteEducationHistoryModal'
// import UpdateEducationHistoryModal from 'Components/Blocks/Modals/UpdateEducationHistoryModal/UpdateEducationHistoryModal'
// import AddButton from 'Components/Blocks/UserProfile/Blocks/AddButton'
import { Column, Row } from 'Components/UI'

import { SEARCH_TYPES } from 'Constants/ids'

// import { useEntityModal } from 'Hooks'
import EventBus from 'Services/EventBus'
import { useScopedI18n } from 'Services/I18n'

import colors from 'Theme/_v2/colors'

import BlockTitle from '../BlockTitle'
import Card from '../Card'
import EducationHistory from '../EducationHistory/EducationHistory'
import ShowMore from '../ShowMore'
import { IconEducationHistories } from '../styles'

export interface EducationHistoryBoxProps {
  communityUser: MainSchema.GraphUser | MainSchema.GetCommunityUser
}

export default function EducationHistoryBox({
  communityUser,
}: EducationHistoryBoxProps) {
  const t = useScopedI18n(
    'components.blocks.userProfile.blocks.educationHistoryBox',
  )

  // const [deleteModal, deleteModalActions] =
  //   useEntityModal<MainSchema.CommunityUserEducationHistory>()
  // const [updateModal, updateModalActions] =
  //   useEntityModal<MainSchema.CommunityUserEducationHistory>()
  // const [createModal, createModalActions] = useEntityModal<
  //   MainSchema.GraphUser | MainSchema.GetCommunityUser
  // >()

  // const handleOpenDeleteModal = deleteModalActions.openModal
  // const handleDeleteModalClose = deleteModalActions.closeModal

  // const handleOpenUpdateModal = updateModalActions.openModal
  // const handleUpdateModalClose = updateModalActions.closeModal

  // const handleOpenCreateModal = createModalActions.openModal
  // const handleCreateModalClose = createModalActions.closeModal

  const handleView = (
    educationHistory: MainSchema.CommunityUserEducationHistory,
  ) => {
    if (!educationHistory.organization) {
      return
    }

    // TODO: refactor events so this is clear about what it's doing
    EventBus.trigger(EventBus.actions.search.community, {
      id: educationHistory.organization.id,
      type: SEARCH_TYPES.organization,
      label: educationHistory.organization.name,
      value: educationHistory.organization,
    })
    EventBus.trigger(
      EventBus.actions.graph.focusNode,
      educationHistory.organization.id,
    )
  }

  return (
    <>
      <Card>
        <Row spaceBetween>
          <BlockTitle
            icon={<IconEducationHistories color={colors.icon.profile} />}
            title={t('title')}
          />

          {/* <AddButton onClick={() => handleOpenCreateModal(communityUser)} /> */}
        </Row>

        <Column gap={1} pl={5}>
          <Column gap={2}>
            <ShowMore initialShown={2}>
              {communityUser.educationHistory?.map(educationHistory => (
                <EducationHistory
                  educationHistory={educationHistory}
                  key={educationHistory.id}
                  // onDelete={() => handleOpenDeleteModal(educationHistory)}
                  // onEdit={() => handleOpenUpdateModal(educationHistory)}
                  onView={handleView}
                />
              ))}
            </ShowMore>
          </Column>
        </Column>
      </Card>

      {/* {deleteModal.entity && deleteModal.isOpen && (
        <DeleteEducationHistoryModal
          educationHistory={deleteModal.entity}
          onClose={handleDeleteModalClose}
        />
      )}

      {updateModal.entity && updateModal.isOpen && (
        <UpdateEducationHistoryModal
          educationHistory={updateModal.entity}
          onClose={handleUpdateModalClose}
        />
      )}

      {createModal.entity && createModal.isOpen && (
        <CreateEducationHistoryModal
          // user={createModal.entity}
          onClose={handleCreateModalClose}
        />
      )} */}
    </>
  )
}
