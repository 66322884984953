import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import confirmEmailMutation from 'GraphQL/Mutations/EmailConfirmation/confirmEmail.graphql'

import { Column, Text } from 'Components/UI'

import { useLocationQueryParams } from 'Hooks'

import { REDIRECTOR } from 'Router/routes'

import { useMutation } from 'Services/Apollo'

function VerifyEmail() {
  const timerRef = useRef<NodeJS.Timeout | null>(null)
  const navigate = useNavigate()
  const queryParams = useLocationQueryParams()

  const [seconds, setSeconds] = useState<number | null>(null)

  const [token, setToken] = useState<string | null>(
    queryParams?.token as string,
  )
  const [isSuccess, setSuccess] = useState(false)

  const [confirmEmail] = useMutation<
    Pick<MainSchema.Mutation, 'confirmEmail'>,
    MainSchema.MutationConfirmEmailArgs
  >(confirmEmailMutation)

  useEffect(() => {
    if (seconds === null) return () => {}

    timerRef.current = setInterval(() => {
      setSeconds(prevSeconds => (prevSeconds ? prevSeconds - 1 : 0))
    }, 1000)

    return () => {
      if (!timerRef.current) {
        return
      }

      clearInterval(timerRef.current)
    }
  }, [seconds])

  useEffect(() => {
    if (seconds === 0) {
      if (timerRef.current) {
        clearInterval(timerRef.current)
      }

      navigate(REDIRECTOR)
    }
  }, [navigate, seconds])

  useEffect(() => {
    async function checkToken() {
      if (token) {
        try {
          await confirmEmail({ variables: { token } })
          setSuccess(true)
          setSeconds(5)
        } catch (e) {
          setToken(null)
        }
      }
    }

    checkToken()
  }, [queryParams, navigate, token, confirmEmail])

  return (
    <Column alignSelf="center" center fullWidth justifyCenter>
      {isSuccess && (
        <>
          <Text big bold mb={1}>
            Email has been confirmed
          </Text>
          <Text inline>You will be redirected in {seconds} seconds</Text>
        </>
      )}

      {!token && (
        <>
          <Text big bold mb={1}>
            Oops, something went wrong
          </Text>
          <Text inline>
            Email confirmation token not found or already used.
          </Text>
        </>
      )}
    </Column>
  )
}

export default VerifyEmail
