import styled from 'styled-components'
import { margin, MarginProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { customScrollbar } from 'Theme/styles'

export interface IContainerProps extends MarginProps {}

export const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  gap: ${themeGet('space.4')}px;

  ${margin}
`

export const Note = styled.div`
  position: relative;
  width: 100%;
  user-select: none;
  cursor: pointer;
  border: 1px solid ${themeGet('colors.divider.default')};
  display: flex;
  flex-direction: column;
  gap: ${themeGet('space.1')}px;
  border-radius: 4px;
  padding: ${themeGet('space.3')}px;
  transition: all 0.1s ease;

  :hover {
    border-color: ${themeGet('colors.divider.hover')};
  }
`

export const ScrollableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: scroll;
  gap: ${themeGet('space.3')}px;
  margin-right: -12px;
  padding-right: 12px;

  ${customScrollbar}
`
