import React, { useCallback, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'

import { useMutation } from '@apollo/client'
import shareNoteMutation from 'GraphQL/Mutations/Notes/shareNote.graphql'
import { isSharedUpdater } from 'GraphQL/Updaters/Note'
import validate from 'validate.js'

import noop from 'lodash/noop'

import { Modal, Row, SelectField, TagMultiValueRemove } from 'Components/UI'

import { TAG_COLOR_KIND } from 'Constants/tags'

import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

const FIELD = {
  USERS: 'users',
  EDITABLE: 'editable',
}

const CONSTRAINTS = {
  [FIELD.USERS]: {
    presence: {
      presence: true,
      allowEmpty: false,
      message: `^Cannot be empty`,
    },
  },
}

function renderClearIndicator(props) {
  return <TagMultiValueRemove {...props} tagColorKind={TAG_COLOR_KIND.USER} />
}

function AddUserStep({ isOpen, noteId, title, user, onLoadUsers, onCancel }) {
  const s = useScopedI18n('modals.shareNote')
  const [shareNote] = useMutation(shareNoteMutation)

  const [isLoading, setLoading] = useState(false)

  const submit = useCallback(
    async values => {
      setLoading(true)
      try {
        await shareNote({
          variables: {
            noteId,
            userIds: values[FIELD.USERS].map(option => option?.value),
            editable: values[FIELD.EDITABLE]?.value === 'editor',
          },
          update: isSharedUpdater({ noteId }),
        })

        toast.success({
          title: s('messages.shareNoteToUserTitle'),
          text: s('messages.shareNoteToUserSuccess'),
        })

        onCancel()
      } catch (error) {
        toast.error({
          title: 'Server error',
          text: _(`error.${error?.message || 'generic'}`),
        })
      } finally {
        setLoading(false)
      }
    },
    [shareNote, noteId, s, onCancel],
  )

  const options = useMemo(
    () => [
      { label: _('modals.shareNote.viewer'), value: 'viewer' },
      { label: _('modals.shareNote.editor'), value: 'editor' },
    ],
    [],
  )

  const initialValues = useMemo(
    () => ({
      [FIELD.USERS]: [user],
      [FIELD.EDITABLE]: options[0],
    }),
    [options, user],
  )

  const renderForm = useCallback(
    ({ handleSubmit, invalid, submitting }) => (
      <Modal
        cancelText={s('actions.cancel')}
        confirmDisabled={isLoading || submitting || invalid}
        confirmText={s('actions.ok')}
        isOpen={isOpen}
        minWidth={['100%', '100%', '600px']}
        title={title}
        onClose={onCancel}
        onConfirm={handleSubmit}
      >
        <Row gap={4}>
          <SelectField
            async
            components={{
              MultiValueRemove: renderClearIndicator,
            }}
            isClearable={false}
            isMulti
            loadOptions={onLoadUsers()}
            name={FIELD.USERS}
            withPortal
          />

          <SelectField
            maxWidth="150px"
            name={FIELD.EDITABLE}
            options={options}
            withPortal
          />
        </Row>
      </Modal>
    ),
    [isLoading, isOpen, onCancel, onLoadUsers, options, s, title],
  )

  return (
    <Form
      initialValues={initialValues}
      render={renderForm}
      validate={values => validate(values, CONSTRAINTS)}
      onSubmit={submit}
    />
  )
}

AddUserStep.defaultProps = {
  isOpen: false,
  noteId: null,
  title: null,
  user: null,
  onLoadUsers: noop,
  onCancel: noop,
}

AddUserStep.propTypes = {
  isOpen: PropTypes.bool,
  noteId: PropTypes.string,
  title: PropTypes.string,
  user: PropTypes.object,
  onCancel: PropTypes.func,
  onLoadUsers: PropTypes.func,
}

export default AddUserStep
