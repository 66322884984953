import { MouseEventHandler } from 'react'

import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { margin, MarginProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { avatarEmptyGlyph } from 'Assets/Svg'

const borderedCss = ({ bordered }: { bordered?: boolean }) =>
  bordered &&
  css`
    border: ${mapToTheme('avatars.borderWidth')}px solid
      ${themeGet('colors.avatar.border')};
  `

const clickableCss = ({ clickable }: { clickable?: boolean }) =>
  clickable &&
  css`
    cursor: pointer;
  `

const squareCss = ({ square }: { square?: boolean }) =>
  square &&
  css`
    border-radius: ${mapToTheme('avatars.squareBorderRadius')}px;
  `

export interface IContainer extends MarginProps {
  bordered?: boolean
  clickable?: boolean
  square?: boolean
  extraSmall?: boolean
  large?: boolean
  small?: boolean
  outline?: boolean
  profileInput?: boolean
  onClick?: MouseEventHandler<HTMLDivElement>
}

export const Container = styled.div<IContainer>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: ${mapToTheme('avatars.size')}px;
  height: ${mapToTheme('avatars.size')}px;
  overflow: hidden;
  border-radius: ${mapToTheme('avatars.circleBorderRadius')}px;
  background-color: ${themeGet('colors.avatar.bg')};

  > svg {
    width: 100%;
    height: 100%;
    stroke: ${themeGet('colors.avatar.color')} !important;
  }

  ${margin}

  ${borderedCss}
  ${clickableCss}
  ${squareCss}
`

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const StyledText = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${themeGet('colors.avatar.textBg')};
  font-size: ${themeGet('fontSize.2')}px;
  font-weight: ${themeGet('fontWeight.3')};
  line-height: 100%;
  color: ${themeGet('colors.avatar.color')};
`

export const AvatarEmptyIcon = styled(avatarEmptyGlyph)``
