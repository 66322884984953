import React, { ComponentType } from 'react'
import ReactModal, { Classes, Props } from 'react-modal'

import { FiX } from 'react-icons/fi'

import styled from 'styled-components'
import { margin, MarginProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { flexCenter } from 'Components/Styles'
import { Column } from 'Components/UI/Flex'

const OVERLAY_BACKGROUND_COLOR = 'transparent'
const MODAL_SCALE = '0.5, 0.5, 0.5'

const ModalSafeForReact18 = ReactModal as ComponentType<ReactModal['props']>

export interface IReactModalAdapter
  extends Omit<Props, 'className' | 'modalClassName' | 'overlayClassName'> {
  className?: string
  modalClassName: Classes
  overlayClassName: Classes
}

function ReactModalAdapter({
  className,
  modalClassName,
  overlayClassName,
  ...props
}: IReactModalAdapter) {
  return (
    <ModalSafeForReact18
      className={modalClassName}
      overlayClassName={overlayClassName}
      portalClassName={className}
      {...props}
    />
  )
}

export const StyledModal = styled(ReactModalAdapter).attrs({
  modalClassName: {
    base: 'Modal',
    afterOpen: 'Modal--after-open',
    beforeClose: 'Modal--before-close',
  },
  overlayClassName: {
    base: 'Overlay',
    afterOpen: 'Overlay--after-open',
    beforeClose: 'Overlay--before-close',
  },
})`
  .Overlay {
    position: fixed;
    ${flexCenter};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${OVERLAY_BACKGROUND_COLOR};
    transition: all ${themeGet('transitionTime.modal')} ease-in;
    z-index: 1000;

    &--after-open {
      background-color: rgba(0, 0, 0, 0.31);
    }

    &--before-close {
      background-color: ${OVERLAY_BACKGROUND_COLOR};
      backdrop-filter: none;
    }
  }

  .Modal {
    display: flex;
    flex-direction: column;
    width: fit-content;
    background-color: ${themeGet('colors.white')};
    box-shadow: ${themeGet('shadow')};
    outline: none;
    transform: scale3d(${MODAL_SCALE});
    transition: all ${themeGet('transitionTime.modal')} ease-in;
    max-height: 98vh;
    border-radius: 15px 15px 0 0;

    &--after-open {
      transform: scale3d(1, 1, 1);
      overflow-y: scroll !important;
      max-height: 95%;
      margin-top: auto;
      background: #ffffff;
    }

    &--before-close {
      transform: scale3d(${MODAL_SCALE});
      opacity: 0;
    }
  }
`

export const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
`

export interface ICloseButtonProps extends MarginProps {}

export const CloseButton = styled.div<ICloseButtonProps>`
  background: ${themeGet('colors.wildSand')};
  border-radius: 10px;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 16px;

  ${flexCenter};

  ${margin}
`

export const CloseIcon = styled(FiX)`
  width: 24px;
  height: 24px;
  color: ${themeGet('colors.mineShaft')};
  stroke-width: 1;
`

export const ModalHeader = styled(Column)`
  position: relative;
  border-bottom: 1px solid ${themeGet('colors.grayLightest')};
`
