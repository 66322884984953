import React, { useEffect, useRef } from 'react'

import MentionTextarea, {
  IMentionTextareaHandle,
  MentionTextareaAddHandler,
  MentionTextareaChangeHandler,
  MentionTextareaRemoveHandler,
} from 'Components/Blocks/MentionTextarea'

export interface IContentFieldProps {
  userId: string
  existingMentions?: Record<string, MainSchema.Mention>
  value?: string
  onAddMention?: MentionTextareaAddHandler
  onChangeContent?: MentionTextareaChangeHandler
  onRemoveMention?: MentionTextareaRemoveHandler
}

const ContentField = (props: IContentFieldProps) => {
  const mentionTextareaRef = useRef<IMentionTextareaHandle | null>(null)

  // auto focus field when user id changes
  useEffect(() => {
    mentionTextareaRef.current?.focus()
  }, [props.userId])

  return (
    <MentionTextarea
      disabled={false}
      existingMentions={props.existingMentions}
      initialValue={props.value}
      isForcingMobile
      ref={mentionTextareaRef}
      onAdd={props.onAddMention}
      onChange={props.onChangeContent}
      onRemove={props.onRemoveMention}
    />
  )
}

export default ContentField
