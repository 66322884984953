import React, { useEffect, useMemo, useState } from 'react'
import { SingleValue } from 'react-select'

import { useMutation, useQuery } from '@apollo/client'
import { IconPlus } from '@tabler/icons-react'
import AddAskOfferMutation from 'GraphQL/Mutations/AskOffer/AddAskOffer.graphql'
import GetAskOfferQuery from 'GraphQL/Queries/GetAskOffer.graphql'
import RecommendPeopleQuery from 'GraphQL/Queries/RecommendPeople.graphql'

import {
  Button,
  IconButton,
  Input,
  Loader,
  Row,
  Select,
  TextButton,
} from 'Components/UI'

import { AskOfferStatementKind } from 'Constants/mainGraphQL'

import { useCommunity } from 'Hooks'
import useMediaRecorder from 'Hooks/useMediaRecorder'

import EventBus from 'Services/EventBus'
import toast from 'Services/Toast'

import AskOfferCard from './AskOfferCard'
import * as Styled from './AskOfferTab.styles'
import RecommendedPeople from './RecommendedPeople'

interface Props {
  communityUser: MainSchema.GetCommunityUser
}

export default function AskOfferTab({ communityUser }: Props) {
  const { community } = useCommunity()
  const {
    startRecording,
    stopRecording,
    processRecording,
    transcription,
    isRecording,
    isProcessing,
  } = useMediaRecorder()
  const { data: askOffersQuery, refetch } = useQuery<
    Pick<MainSchema.Query, 'getAskOffers'>
  >(GetAskOfferQuery, {
    variables: {
      communityUserId: communityUser?.id,
    },
    skip: !communityUser?.id || !community?.id,
    context: {
      headers: {
        'x-community-id': community?.id,
      },
    },
  })
  const [addAskOffer, { loading }] = useMutation(AddAskOfferMutation, {
    context: {
      headers: {
        'x-community-id': community?.id,
      },
    },
    onCompleted: () => {
      toast.success({
        title: `${activeAskOfferKind} Saved`,
        text: ``,
      })
      resetForm()
      refetch()
    },
  })
  const askOffers = askOffersQuery?.getAskOffers
    .slice()
    .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
  const [activeId, setActiveId] = useState<string | null>(null)
  const [filter, setFilter] = useState<AskOfferStatementKind | 'all'>('all')
  const [isOpenCreateMenu, setIsOpenCreateMenu] = useState(false)
  const [textareaValue, setTextareaValue] = useState<string>('')
  const [activeAskOfferKind, setActiveAskOfferKind] =
    useState<AskOfferStatementKind | null>(null)
  const [isCreateAskOfferFormOpen, setIsCreateAskOfferFormOpen] =
    useState(false)
  const activeAskOffer = askOffers?.find(askOffer => askOffer.id === activeId)

  const { data: recommendPeopleQuery } = useQuery<
    Pick<MainSchema.Query, 'recommendPeople'>
  >(RecommendPeopleQuery, {
    variables: {
      askOfferStatementId: activeId,
      numberToRecommend: 3,
      minScoreThreshold: 0.7,
    },
    skip: !activeId,
    context: {
      headers: {
        'x-community-id': community?.id,
      },
    },
  })

  const recommendedPeople = useMemo(
    () => recommendPeopleQuery?.recommendPeople,
    [recommendPeopleQuery],
  )

  useEffect(() => {
    recommendedPeople?.forEach(p => {
      EventBus.trigger(EventBus.actions.graph.addUserById, {
        userId: p.communityUser.userId,
      })
    })
  }, [recommendedPeople])

  useEffect(() => {
    const text =
      transcription?.correctedTranscription === '<not enough content>'
        ? transcription.transcription
        : transcription?.correctedTranscription

    setTextareaValue(prevValue =>
      prevValue ? `${prevValue}\n${text ?? ''}` : text ?? '',
    )
  }, [transcription])

  function resetForm() {
    setActiveId(null)
    setIsOpenCreateMenu(false)
    setActiveAskOfferKind(null)
    setTextareaValue('')
    setIsCreateAskOfferFormOpen(false)
  }

  const onClickCard = (id: string) => {
    setActiveId(id)
  }

  const onClickBack = () => {
    resetForm()

    if (isRecording) {
      handleStopRecording()
    }
  }

  const onClickCreateMenu = () => {
    setIsOpenCreateMenu(!isOpenCreateMenu)
  }

  const onClickCreateAskOffer = (kind: AskOfferStatementKind) => {
    setIsOpenCreateMenu(!isOpenCreateMenu)
    setIsCreateAskOfferFormOpen(!isCreateAskOfferFormOpen)
    setActiveAskOfferKind(kind)
  }

  const onClickSaveAskOffer = () => {
    addAskOffer({
      variables: {
        communityUserId: communityUser.id,
        kind: activeAskOfferKind,
        statement: textareaValue,
        source: 'NetworkOS',
      },
    })
  }

  const onChangeTextarea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextareaValue(event.target.value)
  }

  const handleStopRecording = async () => {
    stopRecording()
    processRecording()
  }

  const askOffersLength = askOffers?.length ?? 0
  const asksLength =
    askOffers?.filter(askOffer => askOffer.kind === AskOfferStatementKind.Ask)
      .length ?? 0
  const offersLength =
    askOffers?.filter(askOffer => askOffer.kind === AskOfferStatementKind.Offer)
      .length ?? 0

  const filteredAskOffers = useMemo(() => {
    return askOffers?.filter(askOffer => {
      switch (filter) {
        case AskOfferStatementKind.Ask:
          return askOffer.kind === AskOfferStatementKind.Ask
        case AskOfferStatementKind.Offer:
          return askOffer.kind === AskOfferStatementKind.Offer
        default:
          return true
      }
    })
  }, [askOffers, filter])

  const infoText =
    activeAskOfferKind === AskOfferStatementKind.Ask
      ? `What do you need help with? This
    could be a specific problem you're
    facing, a project you're working
    on, or expertise you're seeking.`
      : `What skills, knowledge, or
    resources can you provide to help
    others? Share your expertise or
    support to assist with their needs.`

  const recommendedPeopleOfferHdg = `Who ${communityUser.firstName} can help`
  const recommendedPeopleAskHdg = `Who can help ${communityUser.firstName}`

  return (
    <Styled.Container mr="-12px">
      {!activeId && !activeAskOffer && (
        <Row
          center
          fullWidth
          gap={3}
          pr={3}
          relative
          spaceBetween
          style={{ display: isCreateAskOfferFormOpen ? 'none' : 'flex' }}
        >
          <Styled.Controls>
            <Select
              maxWidth={132}
              options={[
                {
                  value: 'all',
                  label: `View All (${askOffersLength})`,
                },
                {
                  value: AskOfferStatementKind.Ask,
                  label: `View Asks (${asksLength})`,
                },
                {
                  value: AskOfferStatementKind.Offer,
                  label: `View Offers (${offersLength})`,
                },
              ]}
              placeholder={`View All (${askOffersLength})`}
              width={152}
              onChange={(
                event: SingleValue<{
                  value: AskOfferStatementKind | 'all'
                  label: string
                }>,
              ) => {
                if (event) {
                  setFilter(event.value)
                }
              }}
            />
            <IconButton extraSmall outline onClick={onClickCreateMenu}>
              <IconPlus />
            </IconButton>
            {isOpenCreateMenu && (
              <Styled.CreateMenu>
                <li>
                  <button
                    type="button"
                    onClick={() =>
                      onClickCreateAskOffer(AskOfferStatementKind.Ask)
                    }
                  >
                    Create New Ask
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    onClick={() =>
                      onClickCreateAskOffer(AskOfferStatementKind.Offer)
                    }
                  >
                    Create New Offer
                  </button>
                </li>
              </Styled.CreateMenu>
            )}
          </Styled.Controls>
        </Row>
      )}
      <Styled.ScrollableContainer>
        {!activeId &&
          !isCreateAskOfferFormOpen &&
          filteredAskOffers?.map(askOffer => (
            <Styled.PlainButton
              key={askOffer.id}
              type="button"
              onClick={() => onClickCard(askOffer.id)}
            >
              <AskOfferCard
                askOffer={askOffer}
                handleNavigateBack={onClickBack}
                refetch={refetch}
                truncate
              />
            </Styled.PlainButton>
          ))}
        {activeId && activeAskOffer && (
          <div>
            <Styled.BtnWrapper>
              <Styled.PlainButton type="button" onClick={onClickBack}>
                <Styled.Back>
                  <Styled.BackArrowIcon /> <span>Back</span>
                </Styled.Back>
              </Styled.PlainButton>
            </Styled.BtnWrapper>
            <AskOfferCard
              askOffer={activeAskOffer}
              handleNavigateBack={onClickBack}
              refetch={refetch}
            />
            {recommendedPeople && (
              <>
                <Styled.SectionHdg>
                  {activeAskOffer.kind === AskOfferStatementKind.Ask
                    ? recommendedPeopleAskHdg
                    : recommendedPeopleOfferHdg}
                </Styled.SectionHdg>
                <RecommendedPeople
                  askOfferStatementId={activeAskOffer.id}
                  communityUser={communityUser}
                  recommendedPeople={recommendedPeople}
                />
              </>
            )}
          </div>
        )}
        {isCreateAskOfferFormOpen && (
          <div>
            <Styled.PlainButton type="button" onClick={onClickBack}>
              <Styled.Back>
                <Styled.BackArrowIcon /> <span>Back</span>
              </Styled.Back>
            </Styled.PlainButton>
            <Styled.SectionHdg />
            <Styled.InputWrapper>
              <Input
                infoText={infoText}
                label={
                  <span>Create a New &lsquo;{activeAskOfferKind}&rsquo;</span>
                }
                textArea
                value={textareaValue}
                onChange={onChangeTextarea}
              />
              {isRecording ? (
                <Styled.MicButton type="button" onClick={handleStopRecording}>
                  <Styled.MicIconActive />
                </Styled.MicButton>
              ) : (
                <Styled.MicButton type="button" onClick={startRecording}>
                  {isProcessing ? <Loader /> : <Styled.MicIcon />}
                </Styled.MicButton>
              )}
            </Styled.InputWrapper>
            <Styled.Actions>
              <TextButton secondary small onClick={onClickBack}>
                Cancel
              </TextButton>
              <Button
                disabled={loading || isRecording || isProcessing}
                small
                onClick={onClickSaveAskOffer}
              >
                Save
              </Button>
            </Styled.Actions>
          </div>
        )}
      </Styled.ScrollableContainer>
    </Styled.Container>
  )
}
