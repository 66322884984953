import ReactPaginate from 'react-paginate'

import styled from 'styled-components'
import { mapToTheme } from 'styled-map'
import { margin, MarginProps, padding, PaddingProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

export const Paginate = styled(ReactPaginate).attrs({
  activeClassName: 'active',
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style-type: none;
  user-select: none;
  gap: ${themeGet('space.2')}px;
  background-color: ${themeGet('colors.pagination.bg')};

  svg {
    width: ${mapToTheme('pagination.iconSize')}px;
    height: ${mapToTheme('pagination.iconSize')}px;
  }

  li a {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${mapToTheme('pagination.pageSize')}px;
    height: ${mapToTheme('pagination.pageSize')}px;
    border-radius: ${mapToTheme('pagination.pageBorderRadius')}px;
    color: ${themeGet('colors.pagination.color')};

    &:hover {
      background: ${themeGet('colors.pagination.hover.bg')};
      color: ${themeGet('colors.pagination.hover.color')};
    }
  }

  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;

    &:hover {
      background: none;
    }
  }

  li.active a {
    background: ${themeGet('colors.pagination.active.bg')};
    color: ${themeGet('colors.pagination.active.color')};
    cursor: default;

    &:hover {
      ${themeGet('colors.pagination.active.bg')};
    }
  }

  li.disabled a {
    color: ${themeGet('colors.pagination.disabled.color')};

    :hover {
      background: none;
    }
  }

  li.disable,
  li.disabled a {
    cursor: default;
  }
`

export interface IPaginationContainerProps extends MarginProps, PaddingProps {}

export const PaginationContainer = styled.div<IPaginationContainerProps>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  ${margin}
  ${padding}
`

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  width: ${mapToTheme('pagination.pageSize')}px;
  height: ${mapToTheme('pagination.pageSize')}px;
  color: ${themeGet('colors.pagination.color')};

  svg {
    width: ${mapToTheme('pagination.iconSize')}px;
    height: ${mapToTheme('pagination.iconSize')}px;
  }

  &:disabled {
    cursor: default;
    color: ${themeGet('colors.pagination.disabled.color')};
  }
`
