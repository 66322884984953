import communitySurveySettingsQuery from 'GraphQL/Queries/Community/communitySurveySettings.graphql'

import { useQuery } from 'Services/Apollo'

export interface ICommunitySurveySettings {
  communityId: string | undefined
}

export default function useCommunitySurveySettings({
  communityId,
}: ICommunitySurveySettings) {
  const { data, loading, error } = useQuery<
    Pick<MainSchema.Query, 'communitySurveySettings'>,
    MainSchema.QueryCommunitySurveySettingsArgs
  >(communitySurveySettingsQuery, {
    variables: communityId
      ? {
          communityId,
        }
      : undefined,
    skip: !communityId,
  })

  return {
    communitySurveySettings: data?.communitySurveySettings,
    loading,
    error,
  }
}
