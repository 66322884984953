import React, {
  memo,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react'

import CreateGraphSnapshotModal from 'Features/GraphSnapshot/Modal/CreateGraphSnapshotModal'
import DeleteGraphSnapshotModal from 'Features/GraphSnapshot/Modal/DeleteGraphSnapshotModal'
import ManageGraphSnapshotModal from 'Features/GraphSnapshot/Modal/ManageGraphSnapshotsModal'
import UpdateGraphSnapshotModal from 'Features/GraphSnapshot/Modal/UpdateGraphSnapshotModal'
import { Chart, Index, Items, Link } from 'regraph'

import keys from 'lodash/keys'

import CommunityIntroduce from 'Components/Blocks/Community/CommunityIntroduce'
import AddToCommunityModal from 'Components/Blocks/Modals/AddToCommunity'

import { QuickActionKind } from 'Constants/graph'
import { KEYS } from 'Constants/ids'

import { useAppContext, useEntityModal } from 'Hooks'
import {
  GraphContext,
  IGraphContext,
  IGraphState,
  IShowGraphMenu,
  ISubGraph,
  LoadGraphState,
} from 'Hooks/useGraphContext'

import EventBus from 'Services/EventBus'

import { DEFAULT_LAYOUT, DEFAULT_POSITIONS } from './constants'
import useAction from './useAction'
import useBackgroundLoaders from './useBackgroundLoaders'
import useGraphHandlers from './useGraphHandlers'
import useGraphMapper from './useGraphMapper'
import useGraphQuery from './useGraphQuery'
import useKeysDown from './useKeysDown'
import utils, { AnalyzerFunction, COMBO_PROPERTIES, IItemData } from './utils'

import UpdateContactModal from '../Modals/UpdateContactModal/UpdateContactModal'

export interface IOptions {
  users: string[]
  skills: string[]
  needSkills: string[]
  organizations: string[]
  tags: string[]
}

export interface IGraphProvider extends PropsWithChildren {
  graphControls?: {
    reset?: boolean
    options?: boolean
    myNetwork?: boolean
    search?: boolean
    browse?: boolean
  }
  options?: IOptions
  setOptions?: React.Dispatch<React.SetStateAction<IOptions | undefined>>
  quickActions?: QuickActionKind[]
  showTargetConnections?: boolean
  showTargetOrganizations?: boolean
  showTargetSkills?: boolean
  showTargetTags?: boolean
  targetUser?: MainSchema.GraphUser
  useQuickActions?: boolean
}

// TODO: How can we break this file up better?
function GraphProvider({
  children,
  targetUser,
  graphControls,
  options,
  setOptions,
  showTargetSkills,
  showTargetTags,
  showTargetConnections,
  showTargetOrganizations,
  quickActions = [],
  useQuickActions = false,
}: IGraphProvider) {
  const { me } = useAppContext()

  const [addToCommunityModal, addCommunityActions] =
    useEntityModal<MainSchema.GraphUser[]>()
  const [updateContactModal, updateContactActions] =
    useEntityModal<MainSchema.GraphUser>()
  const [createGraphSnapshotModal, createGraphSnapshotActions] =
    useEntityModal()
  const [updateGraphSnapshotModal, updateGraphSnapshotActions] =
    useEntityModal<MainSchema.GraphSnapshot>()
  const [deleteGraphSnapshotModal, deleteGraphSnapshotActions] =
    useEntityModal<MainSchema.GraphSnapshot>()
  const [manageGraphSnapshotsModal, manageGraphSnapshotsActions] =
    useEntityModal()

  const onOpenAddToCommunityModal = addCommunityActions.openModal
  const handleCloseAddToCommunityModal = addCommunityActions.closeModal

  const onUpdateContactModal = updateContactActions.openModal
  const handleUpdateContactModal = updateContactActions.closeModal

  const onCreateGraphSnapshotModal = createGraphSnapshotActions.openModal
  const handleCloseCreateGraphSnapshotModal =
    createGraphSnapshotActions.closeModal

  const onUpdateGraphSnapshotModal = updateGraphSnapshotActions.openModal
  const handleCloseUpdateGraphSnapshotModal =
    updateGraphSnapshotActions.closeModal

  const onDeleteGraphSnapshotModal = deleteGraphSnapshotActions.openModal
  const handleCloseDeleteGraphSnapshotModal =
    deleteGraphSnapshotActions.closeModal

  const onManageGraphSnapshotsModal = manageGraphSnapshotsActions.openModal
  const handleCloseManageGraphSnapshotsModal =
    manageGraphSnapshotsActions.closeModal

  const [paths, setPaths] = useState<MainSchema.GraphUser[][]>([])
  const [subGraphs, setSubGraphs] = useState<ISubGraph[]>([])
  const [showGraphMenu, setShowGraphMenu] = useState<IShowGraphMenu | null>(
    null,
  )
  const [showContextMenuIds, setShowContextMenuIds] = useState<string[]>([])
  const [nodes, setNodes] = useState<Items<IItemData>>({})

  const [edges, setEdges] = useState<Index<Link<IItemData>>>({})

  const [currentAnalyzer, setCurrentAnalyzer] =
    useState<AnalyzerFunction | null>(null)
  const [showRelationshipStrength, setShowRelationshipStrength] = useState<
    Record<string, boolean>
  >({})

  // TODO: Load initial state from user settings
  const [clusteringEnabled, setClusteringEnabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const initialLayout = useMemo<Chart.LayoutOptions>(() => {
    return { ...DEFAULT_LAYOUT, top: targetUser?.id || me?.id || undefined }
  }, [targetUser?.id, me?.id])

  // Regraph examples, documentations and their recommendation suggested merging state, so multiple state changes can be applied at same time.
  const [graphState, setGraphState] = useState<IGraphState>({
    analyzerFunction: currentAnalyzer,
    items: {},
    appendUsers: [],
    selection: {},
    positions: DEFAULT_POSITIONS,
    combine: {
      properties: COMBO_PROPERTIES,
      level: clusteringEnabled ? 3 : 0,
    },
    openCombos: {},
    firstLoad: true,
    layout: initialLayout,
    clusteringEnabled,
    setClusteringEnabled,
    showRelationshipStrength,
    setShowRelationshipStrength,
  })
  const selectedIds = useMemo(
    () => keys(graphState.selection),
    [graphState.selection],
  )

  const loadGraphState = useMemo<LoadGraphState>(
    () => ({
      clusteringEnabled: graphState.clusteringEnabled,
      showRelationshipStrength: graphState.showRelationshipStrength,
      analyzerFunction: graphState.analyzerFunction,
      nodes,
      edges,
      layout: graphState.layout,
      positions: graphState.positions,
      selection: graphState.selection,
      combine: graphState.combine,
      openCombos: graphState.openCombos,
    }),
    [
      graphState.clusteringEnabled,
      graphState.showRelationshipStrength,
      graphState.analyzerFunction,
      nodes,
      edges,
      graphState.layout,
      graphState.positions,
      graphState.selection,
      graphState.combine,
      graphState.openCombos,
    ],
  )

  const [currentGraphSnapshotId, setCurrentGraphSnapshotId] = useState<string>()
  const [savedLoadGraphState, setSavedLoadGraphState] =
    useState<LoadGraphState>()

  // TODO: Add control+z or command+z for undo
  const keysDown = useKeysDown()

  const isHandMode = useMemo(
    () => !(keysDown.includes(KEYS.SHIFT) || keysDown.includes(KEYS.META)),
    [keysDown],
  )

  const [myUserNodes, myUserEdges] = useMemo(
    () =>
      utils.appendItems({
        me,
        users: me?.graphUser ? [me.graphUser] : [],
        showTargetSkills: me?.id === targetUser?.id && showTargetSkills,
        showTargetTags: me?.id === targetUser?.id && showTargetTags,
        showTargetOrganizations:
          me?.id === targetUser?.id && showTargetOrganizations,
        selectedIds: [],
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      me,
      showTargetOrganizations,
      showTargetSkills,
      showTargetTags,
      targetUser?.id,
    ],
  )

  const [targetUserNodes, targetUserEdges] = useMemo(
    () =>
      targetUser
        ? utils.appendItems({
            me,
            users: [targetUser],
            showTargetSkills,
            showTargetTags,
            showTargetOrganizations,
            selectedIds: [],
          })
        : [{}, {}],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [me, showTargetOrganizations, showTargetSkills, showTargetTags, targetUser],
  )

  const {
    introduceTo,
    availableQuickActions,
    handleIntroduceToCancel,
    handleIntroduceTo,
  } = useAction({
    selectedIds,
    quickActions,
    graphState,
    onOpenAddToCommunityModal,
    onUpdateContactModal,
    onCreateGraphSnapshotModal,
    onUpdateGraphSnapshotModal,
    onDeleteGraphSnapshotModal,
    onManageGraphSnapshotsModal,
  })

  const {
    memoizedRelationshipEdges,
    isFilteringByRelationship,
    memoizedTags,
    top25RecentlyCreatedUserIds,
  } = useBackgroundLoaders({
    options,
    showRelationshipStrength,
  })

  // Core context items
  // Mapping handlers for generating data for regraph
  const graphMapper = useGraphMapper({
    initialLayout,
    nodes,
    edges,
    currentAnalyzer,
    showRelationshipStrength,
    targetUser,
    memoizedRelationshipEdges,
    paths,
    subGraphs,
    graphState,
    isFilteringByRelationship,
    targetUserNodes,
    targetUserEdges,
    myUserNodes,
    myUserEdges,
    savedLoadGraphState,
    onSetNodes: setNodes,
    onSetEdges: setEdges,
    onSetCurrentAnalyzer: setCurrentAnalyzer,
    onSetShowRelationshipStrength: setShowRelationshipStrength,
    onSetSubGraphs: setSubGraphs,
    onSetGraphState: setGraphState,
    onSetShowGraphMenu: setShowGraphMenu,
    onSetContextMenuIds: setShowContextMenuIds,
    setClusteringEnabled,
    setShowRelationshipStrength,
  })

  // Regraph only handlers
  const graphHandler = useGraphHandlers({
    targetUser,
    initialLayout,
    nodes,
    edges,
    graphState,
    onSetPaths: setPaths,
    onSetGraphState: setGraphState,
    onSetShowGraphMenu: setShowGraphMenu,
    onSetContextMenuIds: setShowContextMenuIds,
  })

  const graphQuery = useGraphQuery({
    options,
    setOptions,
    communityTags: memoizedTags,
    setPaths,
    setIsLoading,
    setGraphState,
    handleAppendItems: graphMapper.handleAppendItems,
    handleTemporaryConnectUser: graphMapper.handleTemporaryConnectUser,
  })

  // Enable/Disable clustering when setting changes, we need to wipe positions and triggers relayout
  useEffect(() => {
    setGraphState(prevState => ({
      ...prevState,
      clusteringEnabled,
      combine: {
        ...prevState.combine,
        properties: COMBO_PROPERTIES,
        level: clusteringEnabled ? 3 : 0,
      },
      positions: DEFAULT_POSITIONS,
      layout: initialLayout,
    }))
    // including initialLayout causes too many relayouts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clusteringEnabled, setGraphState])

  // Initialize basic graph on initial load
  useEffect(() => {
    if (showTargetConnections && targetUser) {
      const targetUserConnections = keys(targetUser?.connectedUsers || {})
      if (targetUserConnections.length)
        graphQuery
          .handleSearch({
            limit: targetUserConnections.length || 1,
            users: targetUserConnections,
          })
          .then()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetUser])

  useEffect(() => {
    EventBus.trigger(EventBus.actions.graph.addUsersById, {
      userIds: top25RecentlyCreatedUserIds,
    })
  }, [top25RecentlyCreatedUserIds])

  const memoizedContext = useMemo<IGraphContext>(
    () => ({
      isLoading,
      setIsLoading,
      isHandMode,
      useQuickActions,
      graphState,
      setGraphState,
      showGraphMenu,
      showContextMenuIds,
      setShowContextMenuIds,
      subGraphs,
      graphControls,
      graphMapper,
      graphHandler,
      graphQuery,
      quickActions,
      availableQuickActions,
      currentGraphSnapshotId,
      setCurrentGraphSnapshotId,
      loadGraphState,
      setSavedLoadGraphState,
    }),
    [
      isLoading,
      setIsLoading,
      isHandMode,
      useQuickActions,
      graphState,
      showGraphMenu,
      showContextMenuIds,
      setShowContextMenuIds,
      subGraphs,
      graphControls,
      graphMapper,
      graphHandler,
      graphQuery,
      quickActions,
      availableQuickActions,
      currentGraphSnapshotId,
      setCurrentGraphSnapshotId,
      loadGraphState,
      setSavedLoadGraphState,
    ],
  )

  return (
    <GraphContext.Provider value={memoizedContext}>
      {children}

      {introduceTo.isOpen && (
        <CommunityIntroduce
          userTo={introduceTo.userTo}
          userWhom={introduceTo.userWhom}
          onCancel={handleIntroduceToCancel}
          onSearchSelect={handleIntroduceTo}
        />
      )}

      <AddToCommunityModal
        isOpen={addToCommunityModal.isOpen}
        users={addToCommunityModal?.entity}
        onClose={handleCloseAddToCommunityModal}
      />

      <UpdateContactModal
        isOpen={updateContactModal.isOpen}
        user={updateContactModal?.entity}
        onClose={handleUpdateContactModal}
      />

      <CreateGraphSnapshotModal
        isOpen={createGraphSnapshotModal.isOpen}
        onClose={handleCloseCreateGraphSnapshotModal}
      />

      {updateGraphSnapshotModal.entity && (
        <UpdateGraphSnapshotModal
          graphSnapshot={updateGraphSnapshotModal.entity}
          isOpen={updateGraphSnapshotModal.isOpen}
          onClose={handleCloseUpdateGraphSnapshotModal}
        />
      )}

      {deleteGraphSnapshotModal.entity && (
        <DeleteGraphSnapshotModal
          graphSnapshot={deleteGraphSnapshotModal.entity}
          isOpen={deleteGraphSnapshotModal.isOpen}
          onClose={handleCloseDeleteGraphSnapshotModal}
        />
      )}

      <ManageGraphSnapshotModal
        isOpen={manageGraphSnapshotsModal.isOpen}
        onClose={handleCloseManageGraphSnapshotsModal}
      />
    </GraphContext.Provider>
  )
}

export default memo(GraphProvider)
