import { QUICK_ACTION_KIND } from 'Constants/graph'

export const MOBILE_SECTIONS = {
  GRAPH: 'graph',
  RESULTS: 'results',
  SELECTED_USER: 'selectedUser',
}

export const QUICK_ACTIONS = [
  QUICK_ACTION_KIND.QUICK_CONNECTOR,
  QUICK_ACTION_KIND.VIEW_PROFILE,
  QUICK_ACTION_KIND.ADD_SKILLS_TAGS,
  QUICK_ACTION_KIND.PATH_TO_USER,
  QUICK_ACTION_KIND.INTRODUCE_TO,
  QUICK_ACTION_KIND.ADD_TO_COMMUNITY,
  QUICK_ACTION_KIND.CREATE_NOTE,
  QUICK_ACTION_KIND.EDIT,
  QUICK_ACTION_KIND.ARCHIVE,
  QUICK_ACTION_KIND.HIDE,
  QUICK_ACTION_KIND.VIEW_SELECTED_ITEMS,
]
