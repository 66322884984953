import React, { useCallback } from 'react'

import { useMutation, useQuery } from '@apollo/client'
import removeUserOAuthMutation from 'GraphQL/Mutations/User/removeUserOAuth.graphql'
import listOAuthProvidersQuery from 'GraphQL/Queries/User/listOAuthProviders.graphql'

import { Column, Loader, Row } from 'Components/UI'
import { Text } from 'Components/UI/_v2'

import _ from 'Services/I18n'
import toast from 'Services/Toast'

import Scopes from './Scopes'

import Item from '../Item/Item'

export enum UserOAuthState {
  Unknown = 'unknown',
  Pending = 'pending',
  Active = 'active',
  Inactive = 'inactive',
  Expired = 'expired',
  Revoked = 'revoked',
  Errored = 'errored',
}

export interface IList {
  provider: MainSchema.UserOAuthProvider
  onClickItemReconnect: () => void
  hideScopes?: boolean
}

// TODO: move text to translations
const List = (props: IList) => {
  // TODO: need to find a better way to get graphql types
  const { data } = useQuery<Pick<MainSchema.Query, 'listOAuthProviders'>>(
    listOAuthProvidersQuery,
  )
  const [removeUserOAuth] = useMutation(removeUserOAuthMutation)

  const userOAuths = data?.listOAuthProviders?.filter(
    userOAuth => userOAuth.provider === props.provider,
  )

  const handleRemove = useCallback(
    async (userOAuth: MainSchema.UserOAuth) => {
      try {
        await removeUserOAuth({
          variables: {
            id: userOAuth.id,
            provider: userOAuth.provider,
          },
          refetchQueries: [listOAuthProvidersQuery],
        })
        toast.success({
          title: 'Remove connected account',
          text: 'Your connected account has been removed',
        })
      } catch (error) {
        let message = _('error.generic')

        if (error instanceof Error) {
          message = _(`error.${error.message}`)
        }

        toast.error({
          title: 'Remove connected account',
          text: message,
        })
      }
    },
    [removeUserOAuth],
  )

  if (!userOAuths?.length) {
    return null
  }

  function getHasError(status: UserOAuthState) {
    return (
      status === UserOAuthState.Inactive ||
      status === UserOAuthState.Expired ||
      status === UserOAuthState.Revoked ||
      status === UserOAuthState.Errored
    )
  }

  function getStateAction(userOAuth: MainSchema.UserOAuth) {
    const hasError = getHasError(userOAuth.state as UserOAuthState)

    if (hasError) {
      return {
        label: 'Reconnect',
        onPress: () => props.onClickItemReconnect(),
      }
    }

    switch (userOAuth.state) {
      case UserOAuthState.Pending:
        return {
          label: 'Pending',
          onPress: () => props.onClickItemReconnect(),
        }
      default:
        return undefined
    }
  }

  return (
    <Column gap={2}>
      {userOAuths?.map(userOAuth => {
        const isSyncing = userOAuth.state === UserOAuthState.Pending
        const isActive = userOAuth.state === UserOAuthState.Active
        const hasError = getHasError(userOAuth.state as UserOAuthState)

        return (
          <>
            <Item
              action={getStateAction(userOAuth)}
              description={
                isSyncing ? (
                  <Row gap={1}>
                    <Loader />
                    <Text>This account is currently syncing...</Text>
                  </Row>
                ) : undefined
              }
              error={
                hasError
                  ? 'Unable to connect to this account, please reconnect.'
                  : undefined
              }
              key={userOAuth.id}
              label={userOAuth.email}
              remove={{
                label: 'Remove connected account',
                onPress: () => handleRemove(userOAuth),
              }}
            />
            {isActive && !props.hideScopes && <Scopes userOAuth={userOAuth} />}
          </>
        )
      })}
    </Column>
  )
}

export default List
