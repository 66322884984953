import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import { useScopedI18n } from 'Services/I18n'

import { TableActions } from '../../Blocks'
import { useTableContext } from '../context'

function Actions({ row }) {
  const { onDeleteRow, onApproveRow, selectedRowIds } = useTableContext()
  const s = useScopedI18n('blocks.tables.recommendationsTable')

  const recommendation = useMemo(() => row?.original, [row])

  const handleDelete = useCallback(() => {
    onDeleteRow(recommendation)
  }, [onDeleteRow, recommendation])

  const handleApprove = useCallback(() => {
    onApproveRow(recommendation)
  }, [onApproveRow, recommendation])

  return (
    <TableActions
      approveTooltip={s('tooltips.approve')}
      canApprove
      canDelete
      deleteTooltip={s('tooltips.delete')}
      disabled={!!selectedRowIds.length}
      editRef={null}
      onApprove={handleApprove}
      onDelete={handleDelete}
    />
  )
}

Actions.defaultProps = {
  row: {},
}

Actions.propTypes = {
  row: PropTypes.object,
}

export default Actions
