import React, { useCallback, useState } from 'react'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'

import { useApolloClient, useMutation } from '@apollo/client'
import debounce from 'awesome-debounce-promise'
import removeSharedUsersMutation from 'GraphQL/Mutations/User/removeSharedUsers.graphql'
import shareUserMutation from 'GraphQL/Mutations/User/shareUser.graphql'
import listCommunityUsersQuery from 'GraphQL/Queries/CommunityUser/listCommunityUsers.full.graphql'
import { setMinSearchLength } from 'Utils/Form'
import { usersToOptions } from 'Utils/Options'

import filter from 'lodash/filter'
import map from 'lodash/map'
import noop from 'lodash/noop'

import {
  Column,
  Divider,
  Modal,
  Row,
  SelectField,
  TagMultiValueRemove,
} from 'Components/UI'

import { TAG_COLOR_KIND } from 'Constants/tags'

import { useAppContext, useCommunityContext } from 'Hooks'

import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import ShareUsersTable from './ShareUsersTable'

const SEARCH_DEBOUNCE = 300
const SEARCH_MINIMUM_LENGTH = 3

const FIELD = {
  RECIPIENTS_USER_IDS: 'recipientUserIds',
}

const INITIAL_VALUES = {
  [FIELD.RECIPIENTS_USER_IDS]: [],
}

function renderClearIndicator(props) {
  return <TagMultiValueRemove {...props} tagColorKind={TAG_COLOR_KIND.USER} />
}

function ShareUsers({ userId, onClose, isOpen }) {
  const s = useScopedI18n('modals.shareUser')
  const { me } = useAppContext()
  const { community } = useCommunityContext()
  const client = useApolloClient()

  const [deletedSharedUserIds, setDeletedSharedUserIds] = useState([])
  const [isLoading, setLoading] = useState(false)

  const [removeSharedUsers] = useMutation(removeSharedUsersMutation)
  const [shareUser] = useMutation(shareUserMutation)

  const loadUsersOptions = useCallback(
    () => async (inputValue, callback) => {
      const result = await client.query({
        query: listCommunityUsersQuery,
        variables: {
          communityId: community?.id,
          search: inputValue,
          limit: 25,
          excludeUserIds: [me?.id],
        },
      })

      const users = result.data?.listCommunityUsers?.communityUsers || []

      callback(
        usersToOptions(filter(users, graphUser => graphUser?.id !== userId)),
      )
    },
    [userId, me, community, client],
  )

  const debouncedLoadUserOptions = useCallback(
    excludedValues =>
      setMinSearchLength(
        debounce(loadUsersOptions(excludedValues), SEARCH_DEBOUNCE),
        SEARCH_MINIMUM_LENGTH,
      ),
    [loadUsersOptions],
  )

  const handleDeleteUsers = useCallback(id => {
    setDeletedSharedUserIds(prevState => {
      if (prevState.includes(id)) {
        return [...prevState.filter(deletedId => deletedId !== id)]
      }
      return [...prevState, id]
    })
  }, [])

  const handleSave = useCallback(
    async values => {
      setLoading(true)

      try {
        const recipientUserIds = map(values[FIELD.RECIPIENTS_USER_IDS], 'value')

        if (recipientUserIds.length) {
          await shareUser({
            variables: {
              communityId: community?.id,
              userId,
              recipientUserIds,
            },
          })
        }

        if (deletedSharedUserIds.length) {
          await removeSharedUsers({
            variables: {
              sharedUserIds: deletedSharedUserIds,
            },
          })
        }

        toast.success({
          title: s('messages.updateTitle'),
          text: s('messages.updateSuccess'),
        })

        onClose()
      } catch (error) {
        toast.error({
          title: 'Server error',
          text: _(`error.${error?.message || 'generic'}`),
        })
      } finally {
        setLoading(false)
      }
    },
    [
      deletedSharedUserIds,
      s,
      onClose,
      shareUser,
      community,
      userId,
      removeSharedUsers,
    ],
  )

  const renderForm = useCallback(
    ({ handleSubmit, values }) => (
      <Modal
        cancelText={_('general.cancel')}
        confirmDisabled={
          isLoading ||
          (!values[FIELD.RECIPIENTS_USER_IDS].length &&
            !deletedSharedUserIds.length)
        }
        confirmText={_('general.save')}
        isOpen={isOpen}
        minWidth={['100%', '100%', '600px']}
        noScroll
        px={0}
        title={s('title')}
        onClose={onClose}
        onConfirm={handleSubmit}
      >
        <Row px={5}>
          <SelectField
            async
            components={{
              MultiValueRemove: renderClearIndicator,
            }}
            isMulti
            label={s('addUserLabel')}
            loadOptions={debouncedLoadUserOptions(
              values[FIELD.RECIPIENTS_USER_IDS],
            )}
            name={FIELD.RECIPIENTS_USER_IDS}
            withPortal
          />
        </Row>

        <Divider my={4} />

        <Column px={5}>
          <ShareUsersTable
            deletedSharedUserIds={deletedSharedUserIds}
            userId={userId}
            onDeleteUser={handleDeleteUsers}
          />
        </Column>
      </Modal>
    ),
    [
      debouncedLoadUserOptions,
      deletedSharedUserIds,
      handleDeleteUsers,
      isLoading,
      isOpen,
      onClose,
      s,
      userId,
    ],
  )

  if (!isOpen) return null

  return (
    <Form
      initialValues={INITIAL_VALUES}
      render={renderForm}
      onSubmit={handleSave}
    />
  )
}

ShareUsers.defaultProps = {
  isOpen: false,
  userId: null,
  onClose: noop,
}

ShareUsers.propTypes = {
  isOpen: PropTypes.bool,
  userId: PropTypes.string,
  onClose: PropTypes.func,
}

export default ShareUsers
