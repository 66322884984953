import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import { useTableContext } from 'Components/Blocks/Modals/ShareNote/ShareNotesTable/context'
import { SelectableDropdown } from 'Components/UI'

import _, { useScopedI18n } from 'Services/I18n'

function Access({ row }) {
  const s = useScopedI18n('modals.shareNote')
  const { onChangeAccess } = useTableContext()

  const handleChangeAccess = useCallback(
    option => {
      onChangeAccess({
        userId: row?.original?.user?.id,
        editable: option?.value === 'editor',
      })
    },
    [onChangeAccess, row?.original?.user],
  )

  const isEditable = row?.original?.editable

  const options = useMemo(
    () => [
      { label: _('modals.shareNote.viewer'), value: 'viewer' },
      { label: _('modals.shareNote.editor'), value: 'editor' },
    ],
    [],
  )

  return (
    <SelectableDropdown
      options={options}
      placeholder={isEditable ? s('editor') : s('viewer')}
      onChange={handleChangeAccess}
    />
  )
}

Access.defaultProps = {
  row: {},
}

Access.propTypes = {
  row: PropTypes.object,
}

export default Access
