import { useEffect, useState } from 'react'

import fetchWithAuth from 'Utils/fetchWithAuth'

import toast from 'Services/Toast'

import useCommunity from './useCommunity'

interface TranscribeRequest {
  audio: string
  mimeType: string
}

interface TranscribeResponse {
  status: string
  transcription: string
  correctedTranscription: string
}

export default function useMediaRecorder() {
  const { community } = useCommunity()
  const mimeType = MediaRecorder.isTypeSupported('video/webm')
    ? 'audio/webm'
    : 'video/mp4'
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null)
  const [transcription, setTranscription] = useState<TranscribeResponse | null>(
    null,
  )
  const [chunks, setChunks] = useState<Blob[]>([])
  const [isRecording, setIsRecording] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)

  function onDataAvailable(event: BlobEvent) {
    setChunks(prevChunks => [...prevChunks, event.data])
  }

  useEffect(() => {
    if (mediaRecorder) {
      mediaRecorder.addEventListener('dataavailable', onDataAvailable)
    }
    return () => {
      if (mediaRecorder) {
        mediaRecorder.removeEventListener('dataavailable', onDataAvailable)
      }
    }
  }, [mediaRecorder])

  async function setupRecorder() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      const recorder = new MediaRecorder(stream, { mimeType })
      recorder.addEventListener('dataavailable', onDataAvailable)
      setMediaRecorder(recorder)
    } catch (error) {
      toast.error({
        title: 'Microphone Access Denied',
        text: 'Unable to access the microphone. Please check your permissions.',
      })
    }
  }

  async function startRecording() {
    setTranscription(null)
    setChunks([])
    if (mediaRecorder) {
      mediaRecorder.removeEventListener('dataavailable', onDataAvailable)
      mediaRecorder.stream.getTracks().forEach(track => track.stop())
      setMediaRecorder(null)
    }
    await setupRecorder()
    setIsRecording(true)
  }

  useEffect(() => {
    if (mediaRecorder && isRecording) {
      mediaRecorder.start(1000)
    }
  }, [mediaRecorder, isRecording])

  function stopRecording() {
    if (mediaRecorder) {
      mediaRecorder.stop()
      mediaRecorder.stream.getTracks().forEach(track => track.stop())
      setIsRecording(false)
    }
  }

  async function transcribe(body: TranscribeRequest) {
    try {
      return fetchWithAuth<TranscribeResponse>(`/v1/tts`, {
        method: 'POST',
        headers: {
          'x-community-id': community?.id ?? '',
        },
        body: JSON.stringify(body),
      })
    } catch (error) {
      return null
    }
  }

  async function processRecording() {
    setIsProcessing(true)
    const blob = new Blob(chunks, { type: mimeType })
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onload = async () => {
      const base64Audio = reader.result
      try {
        if (!base64Audio) throw new Error('No audio data')

        const text = await transcribe({
          audio: base64Audio as string,
          mimeType,
        })
        setTranscription(text)
      } catch (error) {
        toast.error({
          title: 'Error sending audio',
          text: '',
        })
      } finally {
        setIsProcessing(false)
      }
    }
  }

  return {
    startRecording,
    stopRecording,
    processRecording,
    transcription,
    isRecording,
    isProcessing,
  }
}
