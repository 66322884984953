import styled from 'styled-components'

import { DotsButton } from '../Dropdown/Dropdown.styles'

export const EducationHistory = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  :active {
    background-color: rgba(237, 239, 241, 0.63);
    border-radius: 5px;
  }

  :not(:hover) {
    ${DotsButton} {
      visibility: hidden;
    }
  }
`

export const EducationHistoryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
