import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { customScrollbar } from 'Theme/styles'

const headerHeight = '70px'

const tableInviteOpenedCss = ({ tableOpened }: { tableOpened?: boolean }) =>
  tableOpened &&
  css`
    width: 720px;
  `

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  overflow: hidden;
  padding-top: ${headerHeight};
`

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: calc(100vh - ${headerHeight});
  overflow: hidden;
  transition-duration: ${themeGet('transitionTime.default')};
`

export const PageContent = styled.div`
  overflow: auto;
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ${customScrollbar}
`

export interface IInviteTable {
  tableOpened?: boolean
}

export const InviteTable = styled.div<IInviteTable>`
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  z-index: 999;
  flex-shrink: 0;
  background: ${themeGet('colors.bg.primary')};
  border-left: 1px solid ${themeGet('colors.divider.default')};
  height: calc(100vh - ${headerHeight});

  ${customScrollbar}

  ${tableInviteOpenedCss};

  transition: all 0.3s ease;
`

export const MobileContainer = styled.section`
  display: flex;
  flex-direction: column;
  background: ${themeGet('colors.bg.primary')};
  width: 100%;
  flex-grow: 1;
`

const notificationsContainerOpenedCss = ({
  opened,
  fullWidth,
}: {
  opened?: boolean
  fullWidth?: boolean
}) =>
  opened &&
  css`
    width: ${fullWidth ? '100%' : '588px'};
  `

export interface INotificationsContainer {
  opened?: boolean
  fullWidth?: boolean
}

export const NotificationsContainer = styled.div<INotificationsContainer>`
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  z-index: 999;
  flex-shrink: 0;
  background-color: ${themeGet('colors.bg.primary')};
  border-left: 1px solid ${themeGet('colors.divider.default')};
  height: calc(100vh - ${headerHeight});
  overflow: auto;
  transition: all 0.3s ease;

  ${customScrollbar}

  ${notificationsContainerOpenedCss};
`

export const PrivacyContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  max-width: 1160px;
  border-radius: 4px;
  background-color: ${themeGet('colors.bg.primary')};
  border: 1px solid ${themeGet('colors.divider.default')};
`
