import React from 'react'
import { Field } from 'react-final-form'

import { FieldValidator } from 'final-form'
import Utils from 'Utils'

import usePhoneNumber from 'Hooks/usePhoneNumber'

import Input, { IInput } from './Input'

export interface IInputField extends IInput {
  checkErrorIfDirty?: boolean
  displayError?: boolean
  name: string
  validate?: FieldValidator<string | number>
  phoneNumberFormat?: boolean
}

function InputField({
  caption,
  checkErrorIfDirty,
  displayError = true,
  name,
  required = false,
  textArea = false,
  maxLength = undefined,
  validate,
  phoneNumberFormat = false,
  ...rest
}: IInputField) {
  const { formatPhoneNumber, formalizePhoneNumber } = usePhoneNumber()

  return (
    <Field name={name} validate={validate}>
      {({ input: { value, onChange, onBlur, onFocus }, meta }) => {
        const { hasError, error } = Utils.Form.hasError(meta, checkErrorIfDirty)
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          const { value } = event.target

          onChange(phoneNumberFormat ? formatPhoneNumber(value) : value)
        }

        const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
          if (phoneNumberFormat) {
            onChange(formalizePhoneNumber(event.target.value))
          }

          onBlur()
        }

        return (
          <Input
            {...rest}
            caption={(displayError && error) || caption}
            error={hasError}
            maxLength={maxLength}
            required={required}
            textArea={textArea}
            value={value}
            onBlur={handleBlur}
            onChange={handleChange}
            onClear={() => onChange('')}
            onFocus={onFocus}
          />
        )
      }}
    </Field>
  )
}

export default InputField
