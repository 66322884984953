import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import { useApolloClient } from '@apollo/client'
import debounce from 'awesome-debounce-promise'
import listCommunityUsersQuery from 'GraphQL/Queries/CommunityUser/listCommunityUsers.full.graphql'
import { setMinSearchLength } from 'Utils/Form'
import { graphUsersToValues } from 'Utils/User'

import map from 'lodash/map'
import upperFirst from 'lodash/upperFirst'

import { AdvancedFilter, FilterDropdown } from 'Components/Blocks/Tables/Blocks'
import { Column, DateRangePickerInput, Select } from 'Components/UI'

import {
  DEFAULT_MIN_SEARCH_SIZE,
  DEFAULT_SEARCH_DEBOUNCE,
  TAG_KIND,
} from 'Constants/ids'

import { useCommunityContext } from 'Hooks'

import { useScopedI18n } from 'Services/I18n'

const KIND_OPTIONS = [
  { value: TAG_KIND.PROJECT, label: upperFirst(TAG_KIND.PROJECT) },
  { value: TAG_KIND.EVENT, label: upperFirst(TAG_KIND.EVENT) },
  { value: TAG_KIND.ROLE, label: upperFirst(TAG_KIND.ROLE) },
  { value: TAG_KIND.GROUP, label: upperFirst(TAG_KIND.GROUP) },
  { value: TAG_KIND.CUSTOM, label: 'Custom tags' },
  { value: TAG_KIND.INDUSTRY, label: upperFirst(TAG_KIND.INDUSTRY) },
]

const FILTER = {
  CREATORS: 'creators',
  DATE: 'date',
  KIND: 'kind',
}

function Filter({ showKind, showDate, onChange }) {
  const s = useScopedI18n('general')
  const { community } = useCommunityContext()
  const client = useApolloClient()

  const [creators, setCreators] = useState([])
  const [date, setDate] = useState({
    from: null,
    to: null,
  })
  const [kind, setKind] = useState()

  const [openFilter, setOpenFilter] = useState(false)

  const handleChangeDate = useCallback(({ to, from }) => {
    setDate({ to, from })
  }, [])

  const handleClearAll = useCallback(() => {
    setCreators([])
    setKind(null)
    setDate({
      from: null,
      to: null,
    })
    onChange({
      createdFrom: null,
      createdTo: null,
    })
  }, [onChange])

  const handleApplyFilters = () => {
    onChange({
      createdTo: date.to,
      createdFrom: date.from,
      creatorIds: creators.length
        ? map(creators, creator => creator.value)
        : undefined,
      kind: kind?.value,
    })
  }

  const loadUsersOptions = useCallback(
    () => async (inputValue, callback) => {
      const result = await client.query({
        query: listCommunityUsersQuery,
        variables: {
          communityId: community?.id,
          search: inputValue,
          limit: 25,
        },
      })

      const usersSuggestions =
        result.data?.listCommunityUsers?.communityUsers || []

      callback(graphUsersToValues(usersSuggestions))
    },
    [community?.id, client],
  )

  const debouncedLoadOptions = useCallback(
    () =>
      setMinSearchLength(
        debounce(loadUsersOptions(), DEFAULT_SEARCH_DEBOUNCE),
        DEFAULT_MIN_SEARCH_SIZE,
      ),
    [loadUsersOptions],
  )

  return (
    <AdvancedFilter
      applyFilters={handleApplyFilters}
      clearFilter={handleClearAll}
    >
      <Column>
        {showKind && (
          <FilterDropdown
            isOpened={openFilter === FILTER.KIND}
            setIsOpened={() =>
              setOpenFilter(openFilter === FILTER.KIND ? null : FILTER.KIND)
            }
            title={s('kind')}
          >
            <Select
              blurInputOnSelect
              flexShrink={0}
              isSearchable={false}
              options={KIND_OPTIONS}
              placeholder=""
              value={kind}
              width={1}
              withLabel={false}
              onChange={setKind}
            />
          </FilterDropdown>
        )}

        <FilterDropdown
          isOpened={openFilter === FILTER.CREATORS}
          setIsOpened={() =>
            setOpenFilter(
              openFilter === FILTER.CREATORS ? null : FILTER.CREATORS,
            )
          }
          title={`${s('creators')} (${creators.length})`}
        >
          <Select
            async
            flexShrink={0}
            isMulti
            loadOptions={debouncedLoadOptions()}
            placeholder=""
            value={creators}
            width={1}
            withLabel={false}
            onChange={setCreators}
          />
        </FilterDropdown>

        {showDate && (
          <FilterDropdown
            isOpened={openFilter === FILTER.DATE}
            setIsOpened={() =>
              setOpenFilter(openFilter === FILTER.DATE ? null : FILTER.DATE)
            }
            title={s('date')}
          >
            <DateRangePickerInput
              dateRange={date}
              width={1}
              onChange={handleChangeDate}
            />
          </FilterDropdown>
        )}
      </Column>
    </AdvancedFilter>
  )
}

Filter.defaultProps = {
  showDate: true,
  showKind: false,
}

Filter.propTypes = {
  showDate: PropTypes.bool,
  showKind: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

export default Filter
