import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { useQuery } from '@apollo/client'
import sharedUsersByCreatorQuery from 'GraphQL/Queries/User/sharedUsersByCreator.graphql'
import { getFullName } from 'Utils/User'

import noop from 'lodash/noop'

import { Divider, Input, Modal, Pagination, Table, Text } from 'Components/UI'

import {
  useCommunityContext,
  useEntityModal,
  useEntityTable,
  useTableSearch,
} from 'Hooks'

import _, { useScopedI18n } from 'Services/I18n'

import { useColumns } from './columns'
import TableContext from './context'
import { Container } from './styles'

const DEFAULT_PAGE_SIZE = 10

function SharedUsersTable({ userId, deletedSharedUserIds, onDeleteUser }) {
  const s = useScopedI18n('modals.shareUser.table')
  const [deleteModal, deleteActions] = useEntityModal()
  const { community } = useCommunityContext()

  const [mostRecentPage, setMostRecentPage] = useState(0)
  const [mostRecentLimit, setMostRecentLimit] = useState(DEFAULT_PAGE_SIZE)

  const { data, loading, refetch } = useQuery(sharedUsersByCreatorQuery, {
    variables: {
      communityId: community?.id,
      targetUserIds: [userId],
      page: mostRecentPage,
      limit: mostRecentLimit,
    },
    skip: !community,
    fetchPolicy: 'network-only',
  })

  const columns = useColumns()
  const entities = useMemo(() => data?.sharedUsersByCreator, [data])

  const [tableProps, rowsCount] = useEntityTable({
    data: entities,
    columns,
    disableSortBy: true,
    deletedSharedUserIds,
  })

  const { pageSize, pageIndex } = tableProps.state

  useEffect(() => {
    setMostRecentPage(tableProps.state.pageIndex)
  }, [tableProps.state.pageIndex])

  useEffect(() => {
    setMostRecentPage(0)
    setMostRecentLimit(tableProps.state.pageSize)
  }, [tableProps.state.pageSize])

  const [search, changeSearch] = useTableSearch({
    onSearch: async value => {
      tableProps.gotoPage(0)

      await refetch({
        limit: mostRecentLimit,
        searchByRecipient: value || undefined,
      })
    },
  })

  const handleDeleteModalClose = deleteActions.closeModal

  const handleDelete = useCallback(
    success => {
      if (!success) {
        return
      }
      onDeleteUser(deleteModal.entity.id)
      handleDeleteModalClose()
    },
    [deleteModal, handleDeleteModalClose, onDeleteUser],
  )

  const { deleteMessage, confirmButtonText, modalTitle } = useMemo(() => {
    const isDelete = !deletedSharedUserIds?.includes(deleteModal?.entity?.id)

    return isDelete
      ? {
          deleteMessage: (
            <Text>
              {s('messages.deleteUser', {
                userName: getFullName(deleteModal?.entity?.recipient?.profile),
              })}
            </Text>
          ),
          confirmButtonText: 'Delete',
          modalTitle: s('actions.deleteUser'),
        }
      : {
          deleteMessage: (
            <Text>
              {s('messages.deleteUser', {
                userName: getFullName(deleteModal?.entity?.recipient?.profile),
              })}
            </Text>
          ),
          confirmButtonText: 'Restore',
          modalTitle: s('actions.restoreUser'),
        }
  }, [deleteModal, deletedSharedUserIds, s])

  const memoizedContext = useMemo(
    () => ({
      onDeleteRow: deleteActions.openModal,
    }),
    [deleteActions],
  )

  return (
    <TableContext.Provider value={memoizedContext}>
      <Input
        clearable
        label={_('search.byName')}
        mb={4}
        name="search"
        placeholder="Search by users"
        value={search}
        onChange={changeSearch}
        onClear={changeSearch}
      />

      <Container>
        <Table {...tableProps} loading={loading} />
      </Container>

      <Divider my={4} />

      <Pagination
        showTotal={false}
        state={{ pageIndex, pageSize }}
        total={rowsCount}
        onPageIndexChange={tableProps?.gotoPage}
        onPageSizeChange={tableProps?.setPageSize}
      />

      <Modal
        cancelText={_('general.cancel')}
        confirmText={confirmButtonText}
        isOpen={deleteModal.isOpen}
        title={modalTitle}
        onClose={handleDeleteModalClose}
        onConfirm={handleDelete}
      >
        {deleteMessage}
      </Modal>
    </TableContext.Provider>
  )
}

SharedUsersTable.defaultProps = {
  deletedSharedUserIds: [],
  userId: null,
  onDeleteUser: noop,
}

SharedUsersTable.propTypes = {
  deletedSharedUserIds: PropTypes.array,
  userId: PropTypes.string,
  onDeleteUser: PropTypes.func,
}
export default SharedUsersTable
