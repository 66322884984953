import React from 'react'

import userCommunitiesQuery from 'GraphQL/Queries/Community/userCommunities.graphql'
import Utils from 'Utils'

import map from 'lodash/map'

import { Loader } from 'Components/UI'
import { Modal } from 'Components/UI/Mobile'

import { useCommunityContext, useModal } from 'Hooks'

import { ROOT } from 'Router/routes'

import { useQuery } from 'Services/Apollo'

import { CheckIcon, CommunityLink, Content } from './styles'

import { IUseModalProps } from '../../../../../Hooks/useModal'

export interface ICommunitySelectorModalProps extends IUseModalProps {}

function CommunitySelectorModal(props: ICommunitySelectorModalProps) {
  const [modalProps] = useModal(props)

  const { data, loading } = useQuery(userCommunitiesQuery)
  const { community } = useCommunityContext()

  const userCommunities = data?.userCommunities?.rows

  return (
    <Modal {...modalProps}>
      <Content>
        {loading ? (
          <Loader absolute />
        ) : (
          <>
            {map(userCommunities, userCommunity => (
              <CommunityLink
                href={Utils.URL.buildFrontendUrl({
                  slug: userCommunity?.slug,
                  path: ROOT,
                })}
                key={userCommunity?.id}
              >
                {userCommunity?.slug}
                {userCommunity?.id === community?.id && <CheckIcon />}
              </CommunityLink>
            ))}
          </>
        )}
      </Content>
    </Modal>
  )
}

export default CommunitySelectorModal
