import { down } from 'styled-breakpoints'
import { createGlobalStyle } from 'styled-components'
import styledNormalize from 'styled-normalize'
import { themeGet } from '@styled-system/theme-get'

import { selectMenu, toasts } from './styles'

import 'react-toastify/dist/ReactToastify.min.css'

export default createGlobalStyle`
  ${styledNormalize}

  html {
    height: 100%;
    width: 100%;
    overflow: hidden;
   }

  body {
    background-color: ${themeGet('colors.bg.primary')};
    font-size: 14px;
    font-family: ${themeGet('font')};
    line-height: 20px;
    min-width: 100vw;
    min-height: 100vh;
    height: 100%;
    padding: 0;
    overflow: auto;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;

    /* Mobile safari fixes (avoid min-height: 100vh) */
    ${down('md')} {
      min-height: auto;
    }

  }

  #root {
    ${down('md')} {
      height: 100%;
    }
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  ${toasts}
  ${selectMenu}
`
