import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { useTheme } from 'styled-components'

import Utils from 'Utils'

import { Text } from 'Components/UI'

function SharedAt({ row, deleteUserIds }) {
  const { colors } = useTheme()

  const sharedDate = useMemo(
    () => Utils.Table.renderDateTimeCell({ value: row?.original?.createdAt }),
    [row?.original],
  )

  const isDeletedUser = useMemo(
    () => deleteUserIds.includes(row?.original?.id),
    [deleteUserIds, row?.original],
  )

  return (
    <Text color={isDeletedUser ? colors.primaryCardinal : colors.grayBoulder}>
      {sharedDate}
    </Text>
  )
}

SharedAt.defaultProps = {
  deleteUserIds: [],
  row: {},
}

SharedAt.propTypes = {
  deleteUserIds: PropTypes.array,
  row: PropTypes.object,
}

export default SharedAt
