import React from 'react'

import { format, intervalToDuration } from 'date-fns'
import { formatExperience } from 'Utils/User'

import { Box, Column, Row } from 'Components/UI'
import { Text } from 'Components/UI/_v2'

import { useScopedI18n } from 'Services/I18n'

import colors from 'Theme/_v2/colors'

import * as Styled from './WorkHistory.styles'

// import Dropdown from '../Dropdown/Dropdown'
import { IconWorkHistoryPlaceholder } from '../styles'

export interface WorkHistoryProps {
  workHistory: MainSchema.CommunityUserWorkHistory
  onView?: (workHistory: MainSchema.CommunityUserWorkHistory) => void
  // onEdit?: (workHistory: MainSchema.CommunityUserWorkHistory) => void
  // onDelete?: (workHistory: MainSchema.CommunityUserWorkHistory) => void
}

export default function WorkHistory({
  workHistory,
  onView,
  // onEdit,
  // onDelete,
}: WorkHistoryProps) {
  const t = useScopedI18n('components.blocks.userProfile.blocks.workHistory')

  const startDate = workHistory.startDate
    ? new Date(workHistory.startDate)
    : null
  const endDate = workHistory.endDate ? new Date(workHistory.endDate) : null
  const duration = startDate
    ? intervalToDuration({
        start: startDate,
        end: endDate ?? new Date(),
      })
    : null

  return (
    <Styled.WorkHistory onClick={() => onView?.(workHistory)}>
      <Box
        alignItems={'center'}
        backgroundColor={'#F0F0F0'}
        borderRadius={'100%'}
        display={'flex'}
        height={'24px'}
        justifyContent={'center'}
        overflow={'hidden'}
        width={'24px'}
      >
        {workHistory.organization?.logoUrl ? (
          <Styled.WorkHistoryImage
            alt={t('logo.alt', {
              organizationName: workHistory.organization.name,
            })}
            src={workHistory.organization.logoUrl}
          />
        ) : (
          <IconWorkHistoryPlaceholder color={colors.icon.profile} />
        )}
      </Box>

      <Column flex={1}>
        <Text
          color={colors.text.primary}
          fontSize={'12px'}
          fontWeight={400}
          lineHeight={'16px'}
        >
          {formatExperience(workHistory)}
        </Text>

        <Row alignItems={'center'}>
          <Text
            color={colors.text.secondary}
            fontSize={'10px'}
            fontWeight={400}
            lineHeight={'16px'}
          >
            {t('duration', {
              count: duration?.years ?? 0,
            })}
          </Text>

          <Text
            color={'#d9d9d9'}
            fontSize={'10px'}
            fontWeight={400}
            lineHeight={'16px'}
          >
            &nbsp;&bull;&nbsp;
          </Text>

          <Text
            color={colors.text.secondary}
            fontSize={'10px'}
            fontWeight={400}
            lineHeight={'16px'}
          >
            {startDate ? format(startDate, 'MMM yyyy') : t('startDate.empty')}
            &nbsp;-&nbsp;
            {endDate ? format(endDate, 'MMM yyyy') : t('endDate.empty')}
          </Text>
        </Row>
      </Column>

      {/* <Dropdown
        options={[
          {
            key: 'edit',
            label: t('actions.edit'),
            onClick: () => onEdit?.(workHistory),
          },
          {
            key: 'delete',
            label: t('actions.delete'),
            onClick: () => onDelete?.(workHistory),
          },
        ]}
      /> */}
    </Styled.WorkHistory>
  )
}
