import React, { useEffect, useRef, useState } from 'react'

import {
  ApolloQueryResult,
  OperationVariables,
  useMutation,
} from '@apollo/client'
import { format } from 'date-fns'
import DeleteAskOfferMutation from 'GraphQL/Mutations/AskOffer/DeleteAskOffer.graphql'

import { Dialog } from 'Components/Blocks/Modals'

import { useCommunity } from 'Hooks'

import toast from 'Services/Toast'

import AskOfferEdit from './AskOfferEdit'
import * as Styled from './AskOfferTab.styles'

interface Props {
  askOffer: MainSchema.AskOfferStatement
  truncate?: boolean
  refetch: (
    variables?: Partial<OperationVariables>,
  ) => Promise<ApolloQueryResult<Pick<MainSchema.Query, 'getAskOffers'>>>
  handleNavigateBack: () => void
}

export default function AskOfferCard({
  askOffer,
  truncate,
  refetch,
  handleNavigateBack,
}: Props) {
  const { community } = useCommunity()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const menuRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isMenuOpen])

  const [deleteAskOffer] = useMutation(DeleteAskOfferMutation, {
    context: {
      headers: {
        'x-community-id': community?.id,
      },
    },
    onCompleted: () => {
      toast.success({
        title: `${askOffer.kind} Deleted`,
        text: '',
      })
      refetch()
      handleNavigateBack()
    },
  })

  const onClickCard = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const onClickDelete = () => {
    handleModalVisibility()
  }

  const handleModalVisibility = () => {
    setIsDeleting(!isDeleting)
  }

  const handleOnFinish = async (success: boolean) => {
    if (success) {
      await deleteAskOffer({
        variables: {
          id: askOffer.id,
        },
      })
    }
  }

  if (isEditing) {
    return (
      <AskOfferEdit
        askOffer={askOffer}
        handleNavigateBack={handleNavigateBack}
      />
    )
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsMenuOpen(false)
    }
  }

  return (
    <>
      <Styled.Card key={askOffer.id}>
        <Styled.CardControls>
          <Styled.CardHdg>{askOffer.kind}</Styled.CardHdg>
          {!truncate && (
            <Styled.InputWrapper ref={menuRef}>
              <Styled.DotsButton onClick={onClickCard}>
                <Styled.DotsIcon />
              </Styled.DotsButton>
              {isMenuOpen && (
                <Styled.CreateMenu>
                  <li>
                    <button type="button" onClick={() => setIsEditing(true)}>
                      Edit
                    </button>
                  </li>
                  <li>
                    <button type="button">Mark as Complete</button>
                  </li>
                  <li>
                    <button type="button" onClick={onClickDelete}>
                      Delete
                    </button>
                  </li>
                </Styled.CreateMenu>
              )}
            </Styled.InputWrapper>
          )}
        </Styled.CardControls>
        <Styled.CardBd truncate={truncate}>{askOffer.statement}</Styled.CardBd>
        <Styled.CardMeta>
          <span>
            Created{' '}
            <span title={format(askOffer.createdAt, 'MMMM dd, yyyy')}>
              {format(askOffer.createdAt, 'MMMM dd')}
            </span>
          </span>
        </Styled.CardMeta>
      </Styled.Card>
      <Dialog
        content={`Are you sure you want to delete your ${askOffer.kind}?`}
        isOpen={isDeleting}
        title={`Delete ${askOffer.kind}?`}
        onClose={handleModalVisibility}
        onFinish={handleOnFinish}
      />
    </>
  )
}
