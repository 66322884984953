import React from 'react'
import { MultiValue } from 'react-select'

import { useApolloClient, useMutation } from '@apollo/client'
import debounce from 'awesome-debounce-promise'
import connectUsersToSkillsMutation from 'GraphQL/Mutations/Community/connectUsersToSkills.graphql'
import createSkillsMutation from 'GraphQL/Mutations/Skill/createSkills.graphql'
import listSkillsQuery from 'GraphQL/Queries/listSkills.graphql'
import { getCommunityUserSkillsUpdater } from 'GraphQL/Updaters/GetCommunityUserSkills'
import { setMinSearchLength } from 'Utils/Form'
import { ISkillOption, ISkillOptionInput, tagsToOptions } from 'Utils/Options'

import { Select, Tag } from 'Components/UI'

import {
  DEFAULT_MIN_SEARCH_SIZE,
  DEFAULT_SEARCH_DEBOUNCE,
  SkillKind,
} from 'Constants/ids'

import { useCommunityContext } from 'Hooks'

import EventBus from 'Services/EventBus'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import Action from './Action'

import BlockTitle from '../BlockTitle'

interface ISkillsProps {
  icon: React.ReactNode
  targetUser?: MainSchema.GraphUser | MainSchema.GetCommunityUser
}

const Skills: React.FC<ISkillsProps> = ({ icon, targetUser }) => {
  const { community } = useCommunityContext()
  const client = useApolloClient()
  const s = useScopedI18n('community.communityUserSkill')

  const [skillsToCreate, setSkillsToCreate] = React.useState<
    MainSchema.CreateSkillInput[]
  >([])

  const [existingSkills, setExistingSkills] = React.useState<
    MainSchema.Skill[]
  >([])

  const [isLoading, setLoading] = React.useState(false)

  const [connectUsersToSkills] = useMutation(connectUsersToSkillsMutation)
  const [createSkills] = useMutation(createSkillsMutation)

  const loadSkillOptions = React.useCallback(
    async (
      inputValue: string,
      callback: (options: ISkillOptionInput[]) => void,
    ) => {
      try {
        const result = await client.query({
          query: listSkillsQuery,
          variables: {
            communityId: community?.id,
            search: inputValue,
            limit: 25,
          },
        })

        const options = result?.data?.listSkills?.rows.map(
          (skill: ISkillOption) => ({
            ...skill,
            kind: SkillKind.Skill,
          }),
        )

        callback(tagsToOptions(options))
      } catch (error: any) {
        toast.error({
          title: s('errorTitle'),
          text: error.message,
        })
      }
    },
    [client, community?.id, s],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedLoadOptions = React.useCallback(
    setMinSearchLength(
      debounce(loadSkillOptions, DEFAULT_SEARCH_DEBOUNCE),
      DEFAULT_MIN_SEARCH_SIZE,
    ),
    [loadSkillOptions],
  )

  const handleSubmit = React.useCallback(async () => {
    setLoading(true)

    let createdSkills: MainSchema.Skill[] = []

    try {
      // If we need to create skills, do so
      if (skillsToCreate.length > 0) {
        const createSkillsResponse = await createSkills({
          variables: {
            communityId: community?.id,
            skills: skillsToCreate,
          },
        })
        createdSkills = createSkillsResponse?.data?.createSkills || []
      }

      // Combine the existing skills with the newly created skills and connect them to the user
      const combinedSkills = [...existingSkills, ...createdSkills]

      // Map to our graphql input type
      const usersToSkills = combinedSkills.map(skill => ({
        skillId: skill.id!,
        communityUserId: targetUser?.communityUserId!,
      })) as MainSchema.ConnectUsersToSkillsInputType[]

      // Prepare the community user data for the updater
      const communityUsers = [
        {
          communityUserId: targetUser?.communityUserId!,
          userId: targetUser?.userId!,
          communityId: community?.id!,
        },
      ]

      // Connect the skills to the user, and update the cache
      await connectUsersToSkills({
        variables: {
          communityId: community?.id,
          usersToSkills,
        },
        update: getCommunityUserSkillsUpdater({
          communityUsers,
          skills: combinedSkills,
        }),
      })

      // Add the skills to the graph and create the edges
      combinedSkills.forEach(skill => {
        // Add skill to graph
        EventBus.trigger(EventBus.actions.graph.addSkillTags, {
          id: skill.id,
          name: skill.name,
          userId: targetUser?.userId,
          kind: SkillKind.Skill,
        })

        // Connect skill to user on graph
        EventBus.trigger(EventBus.actions.graph.connectSkillTag, {
          fromId: targetUser?.userId,
          toId: skill.id,
        })
      })

      toast.success({
        title: s('title'),
        text: s('createSuccess'),
      })
    } catch (error: any) {
      toast.error({
        title: s('errorTitle'),
        text: error?.message,
      })
    } finally {
      setExistingSkills([])
      setSkillsToCreate([])
      setLoading(false)
    }
  }, [
    skillsToCreate,
    existingSkills,
    targetUser?.communityUserId,
    targetUser?.userId,
    community?.id,
    connectUsersToSkills,
    s,
    createSkills,
  ])

  const renderMultiValue = React.useCallback(
    (selectProps: any) => (
      <Tag
        colorKind={SkillKind.Skill}
        removable
        small
        text={selectProps?.children?.props?.text ?? selectProps?.children}
        onRemove={() => selectProps?.removeProps?.onClick()}
      />
    ),
    [],
  )

  const handleIsValidNewOption = React.useCallback((inputValue: string) => {
    return inputValue?.length >= 3
  }, [])

  const handleOnChange = React.useCallback(
    (newValue: MultiValue<ISkillOptionInput>) => {
      const selectedSkills = newValue.map(skill => ({ ...skill }))
      // Filter newly added skills that need to be created
      const skillsToCreate = selectedSkills
        .filter(options => !options.id)
        .map(option => ({
          name: option?.value?.trim(),
        })) as MainSchema.CreateSkillInput[]
      setSkillsToCreate(skillsToCreate)

      // Filter the existing skills in the NOS system that just need to be appended to the user
      const existingSkills = selectedSkills
        .filter(options => options.id)
        .map(option => ({
          id: option?.id,
          name: option?.name,
        })) as MainSchema.Skill[]
      setExistingSkills(existingSkills)
    },
    [],
  )

  return (
    <Action
      disabled={!skillsToCreate?.length && !existingSkills?.length}
      isLoading={isLoading}
      title={<BlockTitle icon={icon} title={s('name')} />}
      onSave={handleSubmit}
    >
      <Select
        async
        components={{ MultiValue: renderMultiValue }}
        creatable
        isMulti
        isValidNewOption={handleIsValidNewOption}
        loadOptions={debouncedLoadOptions}
        mt={2}
        placeholder={s('placeholder')}
        onChange={handleOnChange}
      />
    </Action>
  )
}

export default Skills
