import React, { forwardRef, MouseEventHandler } from 'react'

import { IconMail } from '@tabler/icons-react'

import { IconButton, Tooltip } from 'Components/UI'

import _ from 'Services/I18n'

export interface IInviteManagementButton {
  onOpenInvitePanel?: MouseEventHandler<HTMLButtonElement>
}

const InviteManagementButton = forwardRef<
  HTMLButtonElement,
  IInviteManagementButton
>(({ onOpenInvitePanel }, ref) => (
  <Tooltip content={_('tips.invitations')}>
    <IconButton outline ref={ref} onClick={onOpenInvitePanel}>
      <IconMail />
    </IconButton>
  </Tooltip>
))

export default InviteManagementButton
