import React, { useCallback, useMemo } from 'react'
import { GroupBase } from 'react-select'

import debounce from 'awesome-debounce-promise'
import useCommunitySearch, {
  COMMUNITY_SEARCH_DEBOUNCE,
  SearchCommunityType,
} from 'Features/CommunitySearch/useCommunitySearch'

import { SelectField } from 'Components/UI'
import { Text } from 'Components/UI/_v2'

import { SkillKind, SkillTagKind } from 'Constants/ids'
import { TagKind } from 'Constants/mainGraphQL'

import { useCommunityContext } from 'Hooks'

import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import * as Styled from './SkillTagSelectField.styles'

export interface SkillTagOption {
  label: string
  value: string | null
  kind: SkillTagKind
}

export interface SkillTagSelectFieldProps {
  name: string
  label: string
  placeholder?: string
  isRequired?: boolean
}

const skillSuggestionToOption = (
  suggestion: MainSchema.SearchScoredSkillsType,
  kind: SkillKind,
): SkillTagOption => ({
  label: suggestion?.skill?.name || 'N/A',
  value: suggestion.skill.id,
  kind,
})

const tagSuggestionToOption = (
  suggestion: MainSchema.SearchScoredCommunityTagType,
): SkillTagOption => ({
  label: suggestion.tag.name,
  value: suggestion.tag.id,
  kind: suggestion.tag.kind,
})

interface SkillTagGroupLabelProps {
  group: GroupBase<SkillTagOption>
}

// TODO: refactor to a shared component
const SkillTagGroupLabel: React.FC<SkillTagGroupLabelProps> = props => {
  return (
    <Styled.Heading>
      <Styled.HeadingLabel>{props.group.label}</Styled.HeadingLabel>
      <Styled.HeadingBadge>{props.group.options.length}</Styled.HeadingBadge>
    </Styled.Heading>
  )
}

interface SkillTagOptionLabelProps {
  option: SkillTagOption
}

// TODO: refactor to a shared component
const SkillTagOptionLabel: React.FC<SkillTagOptionLabelProps> = props => {
  return (
    <Text fontWeight={500} variant="body-s">
      {props.option.label}
    </Text>
  )
}

// TODO: support multiple being selected
// TODO: support only a specific kind of tag
const SkillTagSelectField: React.FC<SkillTagSelectFieldProps> = props => {
  const t = useScopedI18n('components.blocks.forms.fields.skillTagSelectField')
  const errorT = useScopedI18n('error')
  const { community } = useCommunityContext()
  const communitySearch = useCommunitySearch()

  const loadOptions = useCallback(
    async (inputValue: string) => {
      if (!community) {
        throw new Error('Community is not defined')
      }

      try {
        const groups: GroupBase<SkillTagOption>[] = []
        const result = await communitySearch.searchCommunity({
          communityId: community.id,
          searchText: inputValue,
          types: [
            SearchCommunityType.CustomTags,
            SearchCommunityType.EventTags,
            SearchCommunityType.GroupTags,
            SearchCommunityType.IndustryTags,
            SearchCommunityType.ProjectTags,
            SearchCommunityType.RoleTags,
            SearchCommunityType.Skills,
          ],
        })

        groups.push({
          label: t('groups.custom.label'),
          options: result.customTags.data.searchCommunityTags.results.map(
            tagSuggestion => tagSuggestionToOption(tagSuggestion),
          ),
        })

        groups.push({
          label: t('groups.events.label'),
          options: result.eventTags.data.searchCommunityTags.results.map(
            tagSuggestion => tagSuggestionToOption(tagSuggestion),
          ),
        })

        groups.push({
          label: t('groups.groups.label'),
          options: result.groupTags.data.searchCommunityTags.results.map(
            tagSuggestion => tagSuggestionToOption(tagSuggestion),
          ),
        })

        groups.push({
          label: t('groups.industries.label'),
          options: result.industryTags.data.searchCommunityTags.results.map(
            tagSuggestion => tagSuggestionToOption(tagSuggestion),
          ),
        })

        groups.push({
          label: t('groups.projects.label'),
          options: result.projectTags.data.searchCommunityTags.results.map(
            tagSuggestion => tagSuggestionToOption(tagSuggestion),
          ),
        })

        groups.push({
          label: t('groups.roles.label'),
          options: result.roleTags.data.searchCommunityTags.results.map(
            tagSuggestion => tagSuggestionToOption(tagSuggestion),
          ),
        })

        groups.push({
          label: t('groups.skills.label'),
          options: result.skills.data.searchSkills.results.map(
            skillSuggestion =>
              skillSuggestionToOption(skillSuggestion, SkillKind.Skill),
          ),
        })

        return groups
      } catch (error) {
        let message = errorT('generic')

        if (error instanceof Error) {
          message = errorT('server.message', {
            message: error.message,
          })
        }

        toast.error({
          title: errorT('server.title'),
          text: message,
        })
      }

      return []
    },
    [community, communitySearch, t, errorT],
  )

  const debouncedLoadOptions = useMemo(
    () => debounce(loadOptions, COMMUNITY_SEARCH_DEBOUNCE),
    [loadOptions],
  )

  const handleLoadOptions = useCallback(
    async (inputValue: string) => {
      if (communitySearch.isSearchTextValid(inputValue)) {
        return debouncedLoadOptions(inputValue)
      }

      return []
    },
    [communitySearch, debouncedLoadOptions],
  )

  const handleFormatCreateLabel = useCallback(
    (inputValue: string) => {
      return t('createNew.label.notEmpty', {
        name: inputValue,
      })
    },
    [t],
  )

  const handleGetNewOptionData = useCallback(
    (_: string, optionLabel: string) => ({
      label: optionLabel,
      value: null,
      kind: TagKind.Custom,
    }),
    [],
  )

  const handleIsValidNewOption = useCallback(
    (inputValue: string) => {
      return communitySearch.isSearchTextValid(inputValue)
    },
    [communitySearch],
  )

  const handleFormatGroupLabel = useCallback(
    (group: GroupBase<SkillTagOption>) => {
      return <SkillTagGroupLabel group={group} />
    },
    [],
  )

  const handleFormatOptionLabel = useCallback((option: SkillTagOption) => {
    return <SkillTagOptionLabel option={option} />
  }, [])

  return (
    <SelectField<SkillTagOption>
      async
      checkErrorIfDirty
      clearable
      creatable
      createOptionPosition="first"
      formatCreateLabel={handleFormatCreateLabel}
      formatGroupLabel={handleFormatGroupLabel}
      formatOptionLabel={handleFormatOptionLabel}
      getNewOptionData={handleGetNewOptionData}
      isValidNewOption={handleIsValidNewOption}
      label={props.label}
      loadOptions={handleLoadOptions}
      name={props.name}
      placeholder={props.placeholder}
      // TODO: Forcing top placement until https://github.com/JedWatson/react-select/issues/5642 is resolved
      placement="top"
      required={props.isRequired}
      withPortal
    />
  )
}

export default SkillTagSelectField
