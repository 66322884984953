import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { TableActions } from 'Components/Blocks/Tables/Blocks'

import { useTableContext } from '../context'

function ActionsCell({ row }) {
  const { onDeleteRow } = useTableContext()

  const sharedUser = row?.original

  const handleDelete = useCallback(() => {
    onDeleteRow(sharedUser)
  }, [onDeleteRow, sharedUser])

  return <TableActions canDelete onDelete={handleDelete} />
}

ActionsCell.defaultProps = {
  row: {},
}

ActionsCell.propTypes = {
  row: PropTypes.object,
}

export default ActionsCell
