import { MutationUpdaterFn } from '@apollo/client'
import CommunityUserFragment from 'GraphQL/Fragments/CommunityUser.graphql'
import GraphUserFragment from 'GraphQL/Fragments/Graph/User.graphql'
import communityUserDirectConnectionsQuery from 'GraphQL/Queries/Community/communityUserDirectConnections.graphql'

export const removeConnectionsUpdater: (props: {
  communityId: string
  graphUser: MainSchema.GraphUser
  removedUserId: string
}) => MutationUpdaterFn<Pick<MainSchema.Mutation, 'removeConnections'>> =
  ({ communityId, graphUser, removedUserId }) =>
  (store, { data }) => {
    if (!data?.removeConnections) return

    const query = {
      query: communityUserDirectConnectionsQuery,
      variables: {
        communityId,
        userId: graphUser.id!,
      },
    }

    const queryData = store.readQuery<
      Pick<MainSchema.Query, 'communityUserDirectConnections'>,
      MainSchema.QueryCommunityUserDirectConnectionsArgs
    >(query)

    if (queryData) {
      store.writeQuery<
        Pick<MainSchema.Query, 'communityUserDirectConnections'>,
        MainSchema.QueryCommunityUserDirectConnectionsArgs
      >({
        ...query,
        data: {
          ...queryData,
          communityUserDirectConnections:
            queryData.communityUserDirectConnections.filter(
              user => user.id !== removedUserId,
            ),
        },
      })
    }

    const graphUserFragment = {
      id: `GraphUser:${graphUser.id}`,
      fragmentName: 'GraphUserFields',
      fragment: GraphUserFragment,
      variables: { communityId },
    }

    const communityUserFragment = {
      id: `CommunityUser:${graphUser.id}`,
      fragmentName: 'CommunityUserFields',
      fragment: CommunityUserFragment,
      variables: { communityId },
    }

    // TODO: add type
    const graphUserFragmentData = store.readFragment(
      graphUserFragment,
    ) as object
    // TODO: add type
    const communityUserFragmentData = store.readFragment(
      communityUserFragment,
    ) as object

    if (graphUserFragmentData) {
      // TODO: add type
      store.writeFragment({
        ...graphUserFragment,
        data: {
          ...graphUserFragmentData,
          connectedUsers: {},
        },
      })
    }

    if (communityUserFragment) {
      // TODO: add type
      store.writeFragment({
        ...communityUserFragment,
        data: {
          ...communityUserFragmentData,
          graphUser: {
            connectedUsers: {},
          },
        },
      })
    }
  }
