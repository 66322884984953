import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useMutation } from '@apollo/client'
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react'
import updateCommunityUserMutation from 'GraphQL/Mutations/CommunityUser/updateCommunityUser.graphql'
import acceptRecommendationsMutation from 'GraphQL/Mutations/Recommendations/acceptRecommendations.graphql'
import rejectRecommendationsMutation from 'GraphQL/Mutations/Recommendations/rejectRecommendations.graphql'
import communitySettingsQuery from 'GraphQL/Queries/Community/communitySettings.graphql'
import recommendationsQuery from 'GraphQL/Queries/Recommendations/recommendations.graphql'
import { meIsRecommendationEnabledUpdater } from 'GraphQL/Updaters/Me'
import Utils from 'Utils'

import map from 'lodash/map'

import ContentCard from 'Components/Blocks/ContentCard'
import GraphProvider from 'Components/Blocks/Graph/GraphProvider'
import RightPanelUserProfile from 'Components/Blocks/RightPanelUserProfile'
import { Modal, Row, Switch, Table, Text } from 'Components/UI'

import {
  useAppContext,
  useCommunity,
  useEntityTable,
  useResponsiveLayout,
} from 'Hooks'

import { QUICK_ACTIONS } from 'Pages/Community/Dashboard/constants'

import { useQuery } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { useColumns } from './columns'
import TableContext from './context'
import { BackButton, Container, RightPanel, StyledButton } from './styles'

import {
  TableControlContainer,
  TableGroupOperatingPanel,
  TablePagination,
} from '../Blocks'

const DEFAULT_PAGE_SIZE = 10

const SORT_BY = [
  {
    column: 'updatedAt',
    order: 'desc',
  },
]

function getRecommendedUserNames(recommendations) {
  return recommendations
    .map(recommendation =>
      Utils.User.getFullName(recommendation?.recommendedUser?.profile),
    )
    .join(', ')
}

function RecommendationsTable() {
  const s = useScopedI18n('blocks.tables.recommendationsTable')

  const { community } = useCommunity()
  const { me, communityUser } = useAppContext()
  const mostRecentSortBy = useRef(SORT_BY)
  const { isMobile } = useResponsiveLayout()

  const [mostRecentPage, setMostRecentPage] = useState(0)
  const [mostRecentLimit, setMostRecentLimit] = useState(DEFAULT_PAGE_SIZE)

  const [isSelectedAll, setSelectedAll] = useState(false)
  const [selectedRecommendations, setSelectedRecommendations] = useState([])
  const [activeRecommendation, setActiveRecommendation] = useState(null)
  const [openedUser, setOpenedUser] = useState(null)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false)
  const [isRecommendationEnabled, setIsRecommendationEnabled] = useState(
    me?.isRecommendationEnabled ?? false,
  )

  const { data: communitySettingsData } = useQuery(communitySettingsQuery, {
    variables: {
      communityId: community.id,
    },
  })

  const [updateCommunityUser, { loading: loadingUpdateCommunityUser }] =
    useMutation(updateCommunityUserMutation)
  const [acceptRecommendations] = useMutation(acceptRecommendationsMutation)
  const [rejectRecommendations] = useMutation(rejectRecommendationsMutation)

  const {
    data,
    loading: loadingRecommendations,
    refetch,
  } = useQuery(recommendationsQuery, {
    variables: {
      communityId: community?.id,
      page: mostRecentPage,
      limit: mostRecentLimit,
      sort: mostRecentSortBy.current,
      entityKind: 'user',
    },
    skip: !community?.id,
  })

  const isAllowToEnableRecommendations =
    communitySettingsData?.communitySettings
      ?.recommendationIndustriesRequired &&
    me?.graphUser?.needIndustryTags.length === 0

  const columns = useColumns()
  const entities = useMemo(() => data?.recommendations || {}, [data])

  const [tableProps, rowsCount] = useEntityTable({
    data: entities,
    columns,
  })

  const { pageSize, pageIndex, sortBy } = tableProps.state

  useEffect(() => {
    setMostRecentPage(pageIndex)
    setSelectedAll(false)
  }, [pageIndex])

  useEffect(() => {
    setMostRecentPage(0)
    setMostRecentLimit(pageSize)
  }, [pageSize])

  const sort = useMemo(
    () =>
      map(sortBy, value => ({
        column: value.id,
        order: value.desc ? 'desc' : 'asc',
      })),
    [sortBy],
  )
  mostRecentSortBy.current = sort.length !== 0 ? sort : null

  const handleShowDeleteModal = useCallback(recommendation => {
    setIsDeleteModalOpen(true)
    if (recommendation) {
      setActiveRecommendation(recommendation)
    }
  }, [])

  const handleShowApproveModal = useCallback(recommendation => {
    setIsApproveModalOpen(true)
    if (recommendation) {
      setActiveRecommendation(recommendation)
    }
  }, [])

  const handleDeleteRows = useCallback(async () => {
    const rows = []
    if (selectedRecommendations.length) {
      rows.push(...selectedRecommendations)
    } else {
      rows.push(activeRecommendation)
    }

    if (!rows.length) return

    try {
      await rejectRecommendations({
        variables: {
          recommendationIds: rows.map(({ id }) => id),
        },
      })

      toast.success({
        title: s('messages.title'),
        text: s('messages.success', {
          action: s('messages.actions.deleted'),
        }),
      })

      refetch().then()
    } catch (error) {
      toast.error({
        title: s('messages.title'),
        text: s('messages.error', {
          action: s('messages.actions.deleted'),
        }),
      })
    }

    setIsDeleteModalOpen(false)
  }, [
    activeRecommendation,
    refetch,
    rejectRecommendations,
    s,
    selectedRecommendations,
  ])

  const handleApproveRows = useCallback(async () => {
    const rows = []
    if (selectedRecommendations.length) {
      rows.push(...selectedRecommendations)
    } else {
      rows.push(activeRecommendation)
    }

    if (!rows.length) return

    try {
      await acceptRecommendations({
        variables: {
          recommendationIds: rows.map(({ id }) => id),
        },
      })

      refetch().then()

      toast.success({
        title: s('messages.title'),
        text: s('messages.success', {
          action: s('messages.actions.approved'),
        }),
      })
    } catch (error) {
      toast.error({
        title: s('messages.title'),
        text: s('messages.error', {
          action: s('messages.actions.approved'),
        }),
      })
    }

    setIsApproveModalOpen(false)
  }, [
    acceptRecommendations,
    activeRecommendation,
    refetch,
    s,
    selectedRecommendations,
  ])

  const handleToggleReceiveRecommendations = useCallback(async () => {
    if (isAllowToEnableRecommendations && isRecommendationEnabled) {
      toast.error({
        title: s('messages.title'),
        text: s('messages.toEnableRecommendations'),
      })
      return
    }

    setIsRecommendationEnabled(prevState => !prevState)

    try {
      const newIsRecommendationEnabled = !isRecommendationEnabled
      await updateCommunityUser({
        variables: {
          id: communityUser?.id,
          userId: communityUser?.userId,
          communityId: community?.id,
          isRecommendationEnabled: newIsRecommendationEnabled,
        },
        update: meIsRecommendationEnabledUpdater(
          community?.id,
          newIsRecommendationEnabled,
        ),
      })
    } catch (error) {
      toast.error({
        title: s('messages.title'),
        text: s('errorUpdateCommunityUser'),
      })
      setIsRecommendationEnabled(prevState => !prevState)
    }
  }, [
    communityUser,
    isAllowToEnableRecommendations,
    s,
    updateCommunityUser,
    community,
    isRecommendationEnabled,
  ])

  const handleSelectAll = useCallback(
    event => {
      const checked = event?.target?.checked

      if (checked) {
        setSelectedAll(true)
        setSelectedRecommendations(entities?.rows)
      } else {
        setSelectedAll(false)
        setSelectedRecommendations([])
      }
    },
    [entities],
  )

  const handleSelectItem = useCallback(
    item => {
      const selectedNotesIds = map(selectedRecommendations, 'id')

      if (selectedNotesIds.includes(item?.id)) {
        setSelectedRecommendations(prevState =>
          prevState.filter(user => user?.id !== item?.id),
        )
      } else {
        setSelectedRecommendations(prevState => [...prevState, item])
      }
    },
    [selectedRecommendations],
  )

  const loading = loadingRecommendations || loadingUpdateCommunityUser

  const memoizedContext = useMemo(
    () => ({
      onSelectAll: handleSelectAll,
      isSelectedAll,
      selectedRowIds: map(selectedRecommendations, 'id'),
      onSelectRow: handleSelectItem,
      onDeleteRow: handleShowDeleteModal,
      onApproveRow: handleShowApproveModal,
      onUserClick: setOpenedUser,
    }),
    [
      handleSelectAll,
      handleSelectItem,
      handleShowApproveModal,
      handleShowDeleteModal,
      isSelectedAll,
      selectedRecommendations,
    ],
  )

  return (
    <Container>
      <ContentCard>
        <TableContext.Provider value={memoizedContext}>
          <TableControlContainer>
            <Row center fullWidth gap={3} wrapped={isMobile}>
              <TableGroupOperatingPanel
                canApprove
                canDelete
                itemCount={selectedRecommendations.length}
                suffix={s('users', {
                  count: selectedRecommendations.length,
                })}
                onApprove={() => setIsApproveModalOpen(true)}
                onDelete={() => setIsDeleteModalOpen(true)}
              />
            </Row>

            <Row mt={isMobile ? 2 : 0}>
              <Row center>
                <Text header header4 mr={2} nowrap>
                  {s('receiveRecommendations')}
                </Text>
                <Switch
                  checked={isRecommendationEnabled}
                  onChange={handleToggleReceiveRecommendations}
                />
              </Row>
            </Row>
          </TableControlContainer>

          {isRecommendationEnabled && !loadingUpdateCommunityUser && (
            <>
              <Table {...tableProps} loading={loading} />

              <TablePagination
                state={{ pageIndex, pageSize }}
                total={rowsCount}
                onPageIndexChange={tableProps?.gotoPage}
                onPageSizeChange={tableProps?.setPageSize}
              />
            </>
          )}

          {isDeleteModalOpen && (
            <Modal
              cancelText={s('modals.buttons.cancel')}
              confirmText={s('modals.buttons.delete')}
              isOpen
              maxWidth="350px"
              title={s('modals.titleDelete')}
              onClose={() => setIsDeleteModalOpen(false)}
              onConfirm={handleDeleteRows}
            >
              <Text center header header4>
                {s('modals.areYouSure', {
                  action: s('modals.actions.delete'),
                })}
                &nbsp;
                <br />
                {!selectedRecommendations.length
                  ? Utils.User.getFullName(
                      activeRecommendation?.recommendedUser?.profile,
                    )
                  : getRecommendedUserNames(selectedRecommendations)}
                ?
              </Text>
            </Modal>
          )}

          {isApproveModalOpen && (
            <Modal
              cancelText={s('modals.buttons.cancel')}
              confirmText={s('modals.buttons.approve')}
              isOpen
              maxWidth="350px"
              title={s('modals.titleApprove')}
              onClose={() => setIsApproveModalOpen(false)}
              onConfirm={handleApproveRows}
            >
              <Text center header header4>
                {s('modals.areYouSure', {
                  action: s('modals.actions.approve'),
                })}
                &nbsp;
                <br />
                {!selectedRecommendations.length
                  ? Utils.User.getFullName(
                      activeRecommendation?.recommendedUser?.profile,
                    )
                  : getRecommendedUserNames(selectedRecommendations)}
                ?
              </Text>
            </Modal>
          )}
        </TableContext.Provider>
      </ContentCard>

      {/* / TODO: Replace quick action code that was used here? */}
      {/* // TODO: Find a better way for Quick Actions to be calculated outside of graph selection, this is part of another longtime TODO */}
      {openedUser && (
        <GraphProvider quickActions={QUICK_ACTIONS} targetUser={me?.graphUser}>
          <RightPanel isMobile={isMobile}>
            <BackButton onClick={() => setOpenedUser(false)}>
              <IconChevronLeft size={16} />
              <Text actionMedium>Back</Text>
            </BackButton>

            <StyledButton onClick={() => setOpenedUser(false)}>
              <IconChevronRight size={14} />
            </StyledButton>

            <RightPanelUserProfile userId={openedUser?.id} />
          </RightPanel>
        </GraphProvider>
      )}
    </Container>
  )
}

export default RecommendationsTable
