import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import { useApolloClient } from '@apollo/client'
import debounce from 'awesome-debounce-promise'
import listCommunityUsersQuery from 'GraphQL/Queries/CommunityUser/listCommunityUsers.full.graphql'
import { setMinSearchLength } from 'Utils/Form'
import { graphUsersToValues } from 'Utils/User'

import map from 'lodash/map'

import { Column, DateRangePickerInput, Select } from 'Components/UI'

import { COMMUNITY_USER_STATUS } from 'Constants/ids'

import { useCommunityContext } from 'Hooks'

import { useScopedI18n } from 'Services/I18n'

import { AdvancedFilter, FilterDropdown } from '../../Blocks'

const SEARCH_DEBOUNCE = 300
const SEARCH_MINIMUM_LENGTH = 3

const STATUS_OPTIONS = [
  { label: 'Full', value: COMMUNITY_USER_STATUS.FULL },
  { label: 'Pending', value: COMMUNITY_USER_STATUS.PENDING },
]

const FILTERS = {
  NAMES: 'names',
  CREATORS: 'creators',
  DATE: 'date',
  STATUS: 'status',
}

function Filter({ showCreators, onChange }) {
  const s = useScopedI18n('general')

  const [users, setUsers] = useState([])
  const [creators, setCreators] = useState([])
  const [status, setStatus] = useState(null)

  const [date, setDate] = useState({
    from: null,
    to: null,
  })

  const [openFilter, setOpenFilter] = useState(false)

  const handleChangeDate = useCallback(({ to, from }) => {
    setDate({ to, from })
  }, [])

  const clearAll = useCallback(() => {
    setUsers([])
    setCreators([])
    setDate({
      from: null,
      to: null,
    })
    setStatus(null)

    onChange({
      createdFrom: null,
      createdTo: null,
      states: null,
      status: null,
    })
  }, [onChange])

  const applyFilters = () => {
    onChange({
      createdTo: date.to,
      createdFrom: date.from,
      creatorIds:
        creators.length > 0
          ? map(creators, creator => creator.value)
          : undefined,
      includeUserIds:
        users.length > 0 ? map(users, user => user.value) : undefined,
      status: status?.value,
    })
  }

  const { community } = useCommunityContext()
  const client = useApolloClient()

  const loadUsersOptions = useCallback(
    () => async (inputValue, callback) => {
      const result = await client.query({
        query: listCommunityUsersQuery,
        variables: {
          communityId: community?.id,
          search: inputValue,
          limit: 25,
        },
      })

      const usersSuggestions =
        result.data?.listCommunityUsers?.communityUsers || []

      callback(graphUsersToValues(usersSuggestions))
    },
    [community?.id, client],
  )

  const debouncedLoadOptions = useCallback(
    () =>
      setMinSearchLength(
        debounce(loadUsersOptions(), SEARCH_DEBOUNCE),
        SEARCH_MINIMUM_LENGTH,
      ),
    [loadUsersOptions],
  )

  return (
    <AdvancedFilter applyFilters={applyFilters} clearFilter={clearAll}>
      <Column>
        <FilterDropdown
          isOpened={openFilter === FILTERS.NAMES}
          setIsOpened={() =>
            setOpenFilter(openFilter === FILTERS.NAMES ? null : FILTERS.NAMES)
          }
          title={
            users.length ? s('namesCount', { count: users.length }) : s('names')
          }
        >
          <Select
            async
            flexShrink={0}
            isMulti
            loadOptions={debouncedLoadOptions()}
            placeholder=""
            value={users}
            width={1}
            withLabel={false}
            onChange={setUsers}
          />
        </FilterDropdown>

        {showCreators && (
          <FilterDropdown
            isOpened={openFilter === FILTERS.CREATORS}
            setIsOpened={() =>
              setOpenFilter(
                openFilter === FILTERS.CREATORS ? null : FILTERS.CREATORS,
              )
            }
            title={
              creators.length
                ? s('creatorsCount', { count: creators.length })
                : s('creators')
            }
          >
            <Select
              async
              flexShrink={0}
              isMulti
              loadOptions={debouncedLoadOptions()}
              placeholder=""
              value={creators}
              width={1}
              withLabel={false}
              onChange={setCreators}
            />
          </FilterDropdown>
        )}

        <FilterDropdown
          isOpened={openFilter === FILTERS.DATE}
          setIsOpened={() =>
            setOpenFilter(openFilter === FILTERS.DATE ? null : FILTERS.DATE)
          }
          title={s('date')}
        >
          <DateRangePickerInput
            dateRange={date}
            width={1}
            onChange={handleChangeDate}
          />
        </FilterDropdown>

        <FilterDropdown
          isOpened={openFilter === FILTERS.STATUS}
          setIsOpened={() =>
            setOpenFilter(openFilter === FILTERS.STATUS ? null : FILTERS.STATUS)
          }
          title={s('status')}
        >
          <Select
            blurInputOnSelect
            flexShrink={0}
            isSearchable={false}
            options={STATUS_OPTIONS}
            placeholder=""
            value={status}
            width={1}
            withLabel={false}
            onChange={setStatus}
          />
        </FilterDropdown>
      </Column>
    </AdvancedFilter>
  )
}

Filter.defaultProps = {
  showCreators: true,
}

Filter.propTypes = {
  showCreators: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

export default Filter
