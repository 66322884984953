import React, { forwardRef, useCallback } from 'react'

import { ContextMenu, ContextMenuItem } from 'Components/UI'

import { ANALYZERS_NAME } from 'Constants/graph'
import {
  USER_RELATIONSHIP_STRENGTH,
  UserRelationshipStrength,
} from 'Constants/ids'

import { useGraphContext } from 'Hooks'

import { CONTEXT_NETWORK_FIELD, GraphLayout } from '../constants'
import { AnalyzerFunction } from '../utils'

export interface INetworkMagicContext {
  isOpen: boolean
  left: number
  top: number
  onSetAnalyzer: (analyzer: AnalyzerFunction | null) => void
  onShowAllTags: () => void
  onShowAllUsers: () => void
  onShowNetwork: (degree: number) => void
  onSetLayout: (layout: GraphLayout) => void
}

const NetworkMagicContext = forwardRef<HTMLDivElement, INetworkMagicContext>(
  (
    {
      isOpen,
      left,
      top,
      onSetAnalyzer,
      onShowNetwork,
      onShowAllTags,
      onShowAllUsers,
      onSetLayout,
    },
    ref,
  ) => {
    const { graphState } = useGraphContext()

    const handleToggleRelationship = useCallback(
      (strength: UserRelationshipStrength) => {
        graphState.setShowRelationshipStrength(prevState => ({
          ...prevState,
          [strength]: !prevState[strength],
        }))
      },
      [graphState],
    )

    const handleToggleClustering = useCallback(() => {
      graphState.setClusteringEnabled(prevState => !prevState)
    }, [graphState])

    return (
      <ContextMenu isOpen={isOpen} left={left} ref={ref} top={top}>
        <ContextMenuItem
          id={CONTEXT_NETWORK_FIELD.SHOW_NETWORK}
          label="Show Network"
        >
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.FIRST_DEGREE}
            label="1st Degree"
            onClick={() => onShowNetwork(1)}
          />
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.SECOND_DEGREE}
            label="2nd Degree"
            onClick={() => onShowNetwork(2)}
          />
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.THIRD_DEGREE}
            label="3rd Degree"
            onClick={() => onShowNetwork(3)}
          />
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.ALL_TAGS}
            label="All Tags"
            onClick={onShowAllTags}
          />
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.ALL_USERS}
            label="All Users"
            onClick={onShowAllUsers}
          />
        </ContextMenuItem>

        <ContextMenuItem id={CONTEXT_NETWORK_FIELD.LAYOUTS} label="Layouts">
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.ORGANIC_LAYOUT}
            label="Organic"
            onClick={() => onSetLayout(GraphLayout.Organic)}
          />
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.SEQUENTIAL_LAYOUT}
            label="Sequential"
            onClick={() => onSetLayout(GraphLayout.Sequential)}
          />
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.STRUCTURAL_LAYOUT}
            label="Structural"
            onClick={() => onSetLayout(GraphLayout.Structural)}
          />
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.RADIAL_LAYOUT}
            label="Radial"
            onClick={() => onSetLayout(GraphLayout.Radial)}
          />
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.LENS_LAYOUT}
            label="Lens"
            onClick={() => onSetLayout(GraphLayout.Lens)}
          />
        </ContextMenuItem>

        <ContextMenuItem
          id={CONTEXT_NETWORK_FIELD.NETWORK_ANALYZER}
          label="Network Analyzers"
        >
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.NO_ANALYZER}
            label="None"
            onClick={() => onSetAnalyzer(null)}
          />
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.BETWEENESS}
            label="Betweeness"
            onClick={() => onSetAnalyzer(ANALYZERS_NAME.between)}
          />
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.CLOSENESS}
            label="Closeness"
            onClick={() => onSetAnalyzer(ANALYZERS_NAME.close)}
          />
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.EIGEN_CENTRALITY}
            label="Degrees"
            onClick={() => onSetAnalyzer(ANALYZERS_NAME.degree)}
          />
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.ALL_USERS}
            label="Centrality"
            onClick={() => onSetAnalyzer(ANALYZERS_NAME.central)}
          />
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.PAGE_RANK}
            label="Rank"
            onClick={() => onSetAnalyzer(ANALYZERS_NAME.rank)}
          />
        </ContextMenuItem>

        <ContextMenuItem
          id={CONTEXT_NETWORK_FIELD.RELATIONSHIP_STRENGTH}
          label="Relationship Strength"
        >
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.SHOW_STRONG}
            isToggled={
              graphState.showRelationshipStrength[
                USER_RELATIONSHIP_STRENGTH.STRONG
              ]
            }
            label="Strong (3)"
            useToggle
            onClick={() =>
              handleToggleRelationship(USER_RELATIONSHIP_STRENGTH.STRONG)
            }
          />
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.SHOW_MODERATE}
            isToggled={
              graphState.showRelationshipStrength[
                USER_RELATIONSHIP_STRENGTH.MODERATE
              ]
            }
            label="Moderate (2)"
            useToggle
            onClick={() =>
              handleToggleRelationship(USER_RELATIONSHIP_STRENGTH.MODERATE)
            }
          />
          <ContextMenuItem
            id={CONTEXT_NETWORK_FIELD.SHOW_WEAK}
            isToggled={
              graphState.showRelationshipStrength[
                USER_RELATIONSHIP_STRENGTH.WEAK
              ]
            }
            label="Weak (1)"
            useToggle
            onClick={() =>
              handleToggleRelationship(USER_RELATIONSHIP_STRENGTH.WEAK)
            }
          />
        </ContextMenuItem>

        <ContextMenuItem
          id={CONTEXT_NETWORK_FIELD.CLUSTER}
          isToggled={graphState.clusteringEnabled}
          label="Clustering"
          useToggle
          onClick={handleToggleClustering}
        />
      </ContextMenu>
    )
  },
)

export default NetworkMagicContext
