import React from 'react'

import userTopSearchedSkillsQuery from 'GraphQL/Queries/User/userTopSearchedSkills.graphql'
import Utils from 'Utils'

import { Box } from 'Components/Blocks/Box'
import NumberOfConnections from 'Components/Blocks/NumberOfConnections'
import TopResult from 'Components/Blocks/TopResult'
import TopSkills from 'Components/Blocks/TopSkills'
import { ExtendedUserCard } from 'Components/Blocks/UserCard'
import { Link, OldModal, Row } from 'Components/UI'

import { useCommunity, useEntityModal, useEventBusSubscribe } from 'Hooks'

import { COMMUNITY_USER } from 'Router/routes'

import { useQuery } from 'Services/Apollo'
import EventBus from 'Services/EventBus'

function Profile() {
  const { community } = useCommunity()
  const [profileModal, profileModalActions] =
    useEntityModal<MainSchema.GetCommunityUser>()

  const userId = profileModal.entity?.id
  const topSearchedSkills = useQuery<
    Pick<MainSchema.Query, 'userTopSearchedSkills'>,
    MainSchema.QueryUserTopSearchedSkillsArgs
  >(userTopSearchedSkillsQuery, {
    skip: !userId,
    variables: userId ? { userId, limit: 5 } : undefined,
  })

  const handleModalClose = profileModalActions.closeModal

  useEventBusSubscribe(
    EventBus.actions.profile.open,
    profileModalActions.openModal,
  )

  return (
    <OldModal
      isOpen={profileModal.isOpen}
      title="User Profile"
      onClose={handleModalClose}
    >
      <Row my={4} px={48}>
        <Box flex="3 0 0" mr={4} px={4} py={5}>
          {profileModal.entity && (
            <Link
              to={Utils.URL.generatePath(COMMUNITY_USER, {
                slug: community?.slug,
                userId: profileModal?.entity?.id,
              })}
              width={1}
            >
              <ExtendedUserCard
                user={{
                  id: profileModal.entity.id,
                  firstName: profileModal.entity.firstName,
                  lastName: profileModal.entity.lastName,
                  photoUrl: profileModal.entity.photoUrl,
                  jobTitle: profileModal.entity.jobTitle?.name,
                  organization: profileModal.entity.organization?.name,
                }}
              />
            </Link>
          )}
        </Box>
        <Box flex="3 0 0" p={4}>
          <TopSkills userId={userId} />
        </Box>
      </Row>
      <Row mb={5} px={48}>
        <Box flex="1 0 0" mr={4} p={4} style={{ minHeight: 200 }}>
          {community?.id && profileModal.entity?.id && (
            <NumberOfConnections
              communityId={community?.id}
              userId={profileModal.entity?.id}
            />
          )}
        </Box>

        <Box flex="1 0 0" p={4} style={{ minHeight: 200 }}>
          <TopResult
            loading={topSearchedSkills.loading}
            skills={topSearchedSkills.data?.userTopSearchedSkills}
            small
          />
        </Box>
      </Row>
    </OldModal>
  )
}

export default Profile
