import React from 'react'

import {
  IconArrowBack,
  IconChartDots3,
  IconReload,
  IconSettings,
} from '@tabler/icons-react'
import GraphSnapshotContextMenu from 'Features/GraphSnapshot/GraphSnapshotContextMenu'

import { Column, IconButton, Tooltip } from 'Components/UI'

import _ from 'Services/I18n'

import {
  BookmarkIcon,
  ButtonContainer,
  Container,
  GraphLoader,
  SearchWrapper,
} from './styles'
import useControlHandlers from './useControlHandlers'

import { NetworkMagicContext, ResetContext } from '../ContextMenu'

export interface IDesktop {
  graphControls?: {
    reset?: boolean
    options?: boolean
    myNetwork?: boolean
    graphSnapshots?: boolean
    search?: boolean
    browse?: boolean
  }
  isSubGraph?: boolean
  loading?: boolean
}

function Desktop({ graphControls, isSubGraph, loading }: IDesktop) {
  const {
    setShowResetMenu,
    setShowNetworkMenu,
    setShowGraphSnapshotsMenu,
    showResetMenu,
    showNetworkMenu,
    showGraphSnapshotsMenu,
    contextResetMenuRef,
    contextNetworkMenuRef,
    contextGraphSnapshotsMenuRef,
    handleGraphOptions,
    handleExitSubGraph,
    handleResetGraph,
    handleLayoutGraph,
    handleShowNetwork,
    handleShowAllTags,
    handleShowAllUsers,
    handleSetAnalyzer,
    handleSetLayout,
  } = useControlHandlers()

  // TODO: Make showing controls and position of controls more customizable in next iteration
  return (
    <Container>
      {/* TODO: Why is the top offset so much different in the center vs the left/right??? */}
      <SearchWrapper>
        {isSubGraph && (
          <Tooltip content="Exit Subgraph">
            <IconButton tertiary onClick={handleExitSubGraph}>
              <IconArrowBack />
            </IconButton>
          </Tooltip>
        )}

        {graphControls?.reset && (
          <ButtonContainer>
            <Tooltip content={_('tips.graphReset')}>
              <IconButton tertiary onClick={() => setShowResetMenu(true)}>
                <IconReload />
              </IconButton>
            </Tooltip>

            <ResetContext
              isOpen={showResetMenu}
              left={0}
              ref={contextResetMenuRef}
              top={10}
              onLayoutGraph={handleLayoutGraph}
              onResetGraph={handleResetGraph}
            />
          </ButtonContainer>
        )}

        {graphControls?.myNetwork && (
          <Column>
            <ButtonContainer>
              <Tooltip content={_('tips.networkOptions')}>
                <IconButton tertiary onClick={() => setShowNetworkMenu(true)}>
                  <IconChartDots3 />
                </IconButton>
              </Tooltip>

              <NetworkMagicContext
                isOpen={showNetworkMenu}
                left={0}
                ref={contextNetworkMenuRef}
                top={10}
                onSetAnalyzer={handleSetAnalyzer}
                onSetLayout={handleSetLayout}
                onShowAllTags={handleShowAllTags}
                onShowAllUsers={handleShowAllUsers}
                onShowNetwork={handleShowNetwork}
              />
            </ButtonContainer>
          </Column>
        )}

        {graphControls?.graphSnapshots && (
          <ButtonContainer>
            <Tooltip content={_('tips.graphSnapshots')}>
              <IconButton
                tertiary
                onClick={() => setShowGraphSnapshotsMenu(true)}
              >
                <BookmarkIcon />
              </IconButton>
            </Tooltip>

            <GraphSnapshotContextMenu
              isOpen={showGraphSnapshotsMenu}
              left={0}
              ref={contextGraphSnapshotsMenuRef}
              setShowGraphSnapshotsMenu={setShowGraphSnapshotsMenu}
              top={10}
            />
          </ButtonContainer>
        )}

        {loading && (
          <Column>
            <GraphLoader size={35} />
          </Column>
        )}

        {graphControls?.options && (
          <Column>
            <Tooltip content={_('tips.graphControls')}>
              <IconButton pointerEventAll tertiary onClick={handleGraphOptions}>
                <IconSettings />
              </IconButton>
            </Tooltip>
          </Column>
        )}
      </SearchWrapper>

      {/* Reserved for any right side controls, moved loader to center to be more obvious */}
      <IconButton disabled secondary />
    </Container>
  )
}

export default Desktop
