import { useEffect, useMemo } from 'react'

import communityUserRelationshipsQuery from 'GraphQL/Queries/Community/communityUsersRelationships.graphql'
import listCommunityUsersQuery from 'GraphQL/Queries/CommunityUser/listCommunityUsers.min.graphql'
import listTagsQuery from 'GraphQL/Queries/listTags.graphql'

import filter from 'lodash/filter'

import { NODE_KIND } from 'Constants/graph'
import { USER_RELATIONSHIP_STRENGTH } from 'Constants/ids'
import { SortInputOrder } from 'Constants/mainGraphQL'

import { useCommunityContext } from 'Hooks'

import { useQuery } from 'Services/Apollo'

import utils from './utils'

export interface IOptions {
  tags: string[]
}

export interface IUseBackgroundLoaders {
  options?: IOptions
  showRelationshipStrength: Record<string, boolean>
}

// Processes some background loading, so things that change very little are ready quicker
export default function useBackgroundLoaders({
  options,
  showRelationshipStrength,
}: IUseBackgroundLoaders) {
  const { community } = useCommunityContext()
  const communityId = community?.id

  const { data: tagData } = useQuery<
    Pick<MainSchema.Query, 'listTags'>,
    MainSchema.QueryListTagsArgs
  >(listTagsQuery, {
    skip: !communityId,
    variables: communityId
      ? {
          communityId,
          kinds: {
            [NODE_KIND.custom]: true,
            [NODE_KIND.event]: true,
            [NODE_KIND.group]: true,
            [NODE_KIND.project]: true,
            [NODE_KIND.role]: true,
          },
          limit: 100000,
        }
      : undefined,
  })

  const {
    data: communityUsersRelationshipsData,
    refetch: refetchCommunityUserRelationships,
  } = useQuery<
    Pick<MainSchema.Query, 'communityUsersRelationships'>,
    MainSchema.QueryCommunityUsersRelationshipsArgs
  >(communityUserRelationshipsQuery, {
    skip: !communityId && !options,
    variables: communityId
      ? {
          communityId,
        }
      : undefined,
  })

  const { data: communityUserData } = useQuery<
    Pick<MainSchema.Query, 'listCommunityUsers'>,
    MainSchema.QueryListCommunityUsersArgs
  >(listCommunityUsersQuery, {
    skip: !communityId,
    variables: communityId
      ? {
          communityId,
          limit: 25,
          sortBy: [
            {
              column: 'createdAt',
              order: SortInputOrder.Desc,
            },
          ],
        }
      : undefined,
  })

  const top25RecentlyCreatedUserIds = useMemo(
    () =>
      communityUserData?.listCommunityUsers?.communityUsers?.map(
        communityUser => communityUser.userId,
      ) || [],
    [communityUserData?.listCommunityUsers?.communityUsers],
  )

  const memoizedRelationshipEdges = useMemo(() => {
    if (!communityUsersRelationshipsData?.communityUsersRelationships) return {}
    return utils.deserializeUserRelationStrength(
      communityUsersRelationshipsData?.communityUsersRelationships,
    )
  }, [communityUsersRelationshipsData?.communityUsersRelationships])

  const isFilteringByRelationship = useMemo(
    () =>
      showRelationshipStrength[USER_RELATIONSHIP_STRENGTH.WEAK] ||
      showRelationshipStrength[USER_RELATIONSHIP_STRENGTH.MODERATE] ||
      showRelationshipStrength[USER_RELATIONSHIP_STRENGTH.STRONG] ||
      false,
    [showRelationshipStrength],
  )

  const memoizedTags = useMemo(() => {
    if (options?.tags?.length) {
      const filtered = filter(tagData?.listTags?.rows || [], tag =>
        options.tags.includes(tag.id),
      )
      return { count: tagData?.listTags?.count || 0, rows: filtered }
    }
    return {
      count: tagData?.listTags?.count || 0,
      rows: tagData?.listTags?.rows || [],
    }
  }, [options?.tags, tagData?.listTags?.count, tagData?.listTags?.rows])

  // TODO: remove this in favor updating the cached relationships when they change
  useEffect(() => {
    refetchCommunityUserRelationships()
  }, [refetchCommunityUserRelationships, showRelationshipStrength])

  return {
    memoizedRelationshipEdges,
    isFilteringByRelationship,
    top25RecentlyCreatedUserIds,
    memoizedTags,
  }
}
