import React, { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useMutation } from '@apollo/client'
import createUserFromCodeMutation from 'GraphQL/Mutations/Auth/createUserFromCode.graphql'
import signInWithOAuthMutation from 'GraphQL/Mutations/Auth/signInWithOAuth.graphql'
import connectUserOAuthMutation from 'GraphQL/Mutations/User/connectUserOAuth.graphql'
import listOAuthProvidersQuery from 'GraphQL/Queries/User/listOAuthProviders.graphql'

import { Loader } from 'Components/UI'

import { UserOAuthProvider } from 'Constants/mainGraphQL'

import { useAppContext } from 'Hooks'

import * as ROUTES from 'Router/routes'

import AuthService from 'Services/Auth'
import toast from 'Services/Toast'

enum ConnectAccountType {
  SignIn = 'signIn',
  SignUp = 'signUp',
  Connect = 'connect',
  Onboarding = 'onboarding',
}

enum OAuthResponseKind {
  Success = 'success',
  Exists = 'exists',
  NotFound = 'notFound',
  Created = 'created',
  Deleted = 'deleted',
  RequiresConsent = 'requiresConsent',
}

interface ITokens {
  accessToken?: string
  refreshToken?: string
}

const connectTitle = 'Connection Error'
const generalErrorMessage = 'Something went wrong, please try again later'

export default function OAuth() {
  const { me } = useAppContext()
  const navigate = useNavigate()
  const [signInWithOAuth] = useMutation(signInWithOAuthMutation)
  const [createUserFromCode] = useMutation(createUserFromCodeMutation)
  const [connectUserOAuth] = useMutation(connectUserOAuthMutation)
  const urlParams = new URLSearchParams(window.location.search)
  const state = urlParams.get('state')

  const onError = useCallback(
    (title: string, message: string) => {
      switch (state) {
        case ConnectAccountType.Connect:
          navigate(ROUTES.PROFILE_ROOT)
          break
        case ConnectAccountType.SignIn:
        case ConnectAccountType.SignUp:
        default:
          navigate(ROUTES.COMMUNITY_PANEL)
      }

      toast.error({ title, text: message })
    },
    [navigate, state],
  )

  const handleAuthSuccess = useCallback(
    async (tokens: ITokens) => {
      await AuthService.handleAuth(tokens)

      navigate(ROUTES.COMMUNITY_PANEL)
    },
    [navigate],
  )

  const handleSignUp = useCallback(
    async (provider: UserOAuthProvider, code: string) => {
      if (me) {
        onError(connectTitle, generalErrorMessage)

        return
      }

      const { data } = await createUserFromCode({
        variables: {
          provider,
          createCode: code,
        },
      })

      if (!data?.createUserFromCode) {
        onError(connectTitle, generalErrorMessage)

        return
      }

      handleAuthSuccess({
        accessToken: data.createUserFromCode.accessToken,
        refreshToken: data.createUserFromCode.refreshToken,
      })
    },
    [createUserFromCode, me, handleAuthSuccess, onError],
  )

  const handleAuth = useCallback(
    async (provider: UserOAuthProvider, code: string) => {
      if (me) {
        return
      }

      const { data } = await signInWithOAuth({
        variables: {
          provider,
          code,
        },
      })

      switch (data?.signInWithOAuth?.kind) {
        case OAuthResponseKind.Success:
        case OAuthResponseKind.Exists:
        case OAuthResponseKind.Created:
        case OAuthResponseKind.Deleted:
          handleAuthSuccess({
            accessToken: data?.signInWithOAuth?.accessToken,
            refreshToken: data?.signInWithOAuth?.refreshToken,
          })
          break
        case OAuthResponseKind.NotFound:
          if (state === ConnectAccountType.SignUp) {
            await handleSignUp(provider, data?.signInWithOAuth.createCode)
          } else {
            onError(
              `${provider} account not found`,
              'Please try again using the account you originally used to sign in',
            )
          }
          break
        case OAuthResponseKind.RequiresConsent:
          navigate(data?.signInWithOAuth?.consentUrl)
          break
        default:
          break
      }
    },
    [
      handleSignUp,
      me,
      navigate,
      handleAuthSuccess,
      onError,
      signInWithOAuth,
      state,
    ],
  )

  const handleConnect = useCallback(
    async (provider: UserOAuthProvider, code: string) => {
      const { data } = await connectUserOAuth({
        variables: { provider, code },
        refetchQueries: [listOAuthProvidersQuery],
      })

      if (!data?.connectUserOAuth.ok) {
        onError(connectTitle, generalErrorMessage)
        return
      }

      if (state === ConnectAccountType.Onboarding) {
        navigate(ROUTES.ONBOARDING_CONNECT)
      } else {
        navigate(ROUTES.PROFILE_ROOT)
      }

      toast.success({
        title: 'Updated permissions',
        text: 'Your connected account has been updated',
      })
    },
    [connectUserOAuth, navigate, onError, state],
  )

  useEffect(() => {
    const handleOAuthCallback = async () => {
      const urlParams = new URLSearchParams(window.location.search)
      const code = urlParams.get('code')
      const provider = urlParams.get('provider') as UserOAuthProvider

      if (!code || !provider || !state) {
        onError(connectTitle, generalErrorMessage)
        return
      }

      try {
        switch (state) {
          case ConnectAccountType.SignIn:
          case ConnectAccountType.SignUp:
            await handleAuth(provider, code)
            break
          case ConnectAccountType.Connect:
          case ConnectAccountType.Onboarding:
            await handleConnect(provider, code)
            break
          default:
            onError(connectTitle, generalErrorMessage)
        }
      } catch (error) {
        let message = generalErrorMessage

        if (error instanceof Error) {
          message = error.message
        }

        onError(connectTitle, message)
      }
    }

    handleOAuthCallback()
    // run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Loader absolute />
}
