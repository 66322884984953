import React, { useMemo } from 'react'

import { IMentionedItem } from 'Features/Notes/utils'

import concat from 'lodash/concat'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import uniqBy from 'lodash/uniqBy'

import { Column, Divider } from 'Components/UI'

import { ACTION_KIND, NODE_KIND, SkillTagKind } from 'Constants/graph'
import { ProposalKind } from 'Constants/mainGraphQL'
import { TAG_COLOR_KIND } from 'Constants/tags'

import EventBus from 'Services/EventBus'
import { useScopedI18n } from 'Services/I18n'

import TagList from './TagList'

export interface ITag {
  id: string
  name: string
  kind: ProposalKind
}

export interface ITagsProps {
  userId: string
  customTags: IMentionedItem[]
  events: IMentionedItem[]
  existingTags: ITag[]
  groups: IMentionedItem[]
  projects: IMentionedItem[]
  roles: IMentionedItem[]
}

function Tags({
  userId,
  events = [],
  projects = [],
  groups = [],
  roles = [],
  customTags = [],
  existingTags = [],
}: ITagsProps) {
  const t = useScopedI18n('features.notes.components.noteTags.tags')

  const {
    existingEvents,
    existingProjects,
    existingGroups,
    existingRoles,
    existingCustomTags,
  } = useMemo(() => {
    const groupedExistingTagsByKind = groupBy(existingTags, 'kind')

    return {
      existingEvents: uniqBy(
        concat(groupedExistingTagsByKind[ACTION_KIND.event] || []),
        'id',
      ),

      existingProjects: uniqBy(
        concat(groupedExistingTagsByKind[ACTION_KIND.project] || []),
        'id',
      ),
      existingGroups: uniqBy(
        concat(groupedExistingTagsByKind[ACTION_KIND.group] || []),
        'id',
      ),
      existingRoles: uniqBy(
        concat(groupedExistingTagsByKind[ACTION_KIND.role] || []),
        'id',
      ),
      existingCustomTags: uniqBy(
        concat(groupedExistingTagsByKind[ACTION_KIND.custom] || []),
        'id',
      ),
    }
  }, [existingTags])

  const handleClick = (item: ITag | IMentionedItem, kind: SkillTagKind) => {
    EventBus.trigger(EventBus.actions.graph.addSkillTags, {
      id: item.id,
      name: item.name,
      kind,
      userId,
    })
  }

  return (
    <Column>
      {!isEmpty(existingEvents) && (
        <>
          <Divider mb={3} />

          <TagList
            items={existingEvents.map(item => ({
              id: item.id,
              name: item.name,
              colorKind: TAG_COLOR_KIND.EVENT,
              onClick: () => handleClick(item, NODE_KIND.event),
            }))}
            label={t('events')}
          />
        </>
      )}

      {!isEmpty(events) && (
        <>
          <Divider mb={3} />

          <TagList
            items={events.map(item => ({
              id: item.id,
              name: item.name,
              colorKind: TAG_COLOR_KIND.EVENT,
              onClick: () => handleClick(item, NODE_KIND.event),
            }))}
            label={t('mentionedEvents')}
          />
        </>
      )}

      {!isEmpty(existingProjects) && (
        <>
          <Divider mb={3} />

          <TagList
            items={existingProjects.map(item => ({
              id: item.id,
              name: item.name,
              colorKind: TAG_COLOR_KIND.PROJECT,
              onClick: () => handleClick(item, NODE_KIND.project),
            }))}
            label={t('projects')}
          />
        </>
      )}

      {!isEmpty(projects) && (
        <>
          <Divider mb={3} />

          <TagList
            items={projects.map(item => ({
              id: item.id,
              name: item.name,
              colorKind: TAG_COLOR_KIND.PROJECT,
              onClick: () => handleClick(item, NODE_KIND.project),
            }))}
            label={t('mentionedProjects')}
          />
        </>
      )}

      {!isEmpty(existingGroups) && (
        <>
          <Divider mb={3} />

          <TagList
            items={existingGroups.map(item => ({
              id: item.id,
              name: item.name,
              colorKind: TAG_COLOR_KIND.GROUP,
              onClick: () => handleClick(item, NODE_KIND.group),
            }))}
            label={t('groups')}
          />
        </>
      )}

      {!isEmpty(groups) && (
        <>
          <Divider mb={3} />

          <TagList
            items={groups.map(item => ({
              id: item.id,
              name: item.name,
              colorKind: TAG_COLOR_KIND.GROUP,
              onClick: () => handleClick(item, NODE_KIND.group),
            }))}
            label={t('mentionedGroups')}
          />
        </>
      )}

      {!isEmpty(existingRoles) && (
        <>
          <Divider mb={3} />

          <TagList
            items={existingRoles.map(item => ({
              id: item.id,
              name: item.name,
              colorKind: TAG_COLOR_KIND.ROLE,
              onClick: () => handleClick(item, NODE_KIND.role),
            }))}
            label={t('roles')}
          />
        </>
      )}

      {!isEmpty(roles) && (
        <>
          <Divider mb={3} />

          <TagList
            items={roles.map(item => ({
              id: item.id,
              name: item.name,
              colorKind: TAG_COLOR_KIND.ROLE,
              onClick: () => handleClick(item, NODE_KIND.role),
            }))}
            label={t('mentionedRoles')}
          />
        </>
      )}

      {!isEmpty(existingCustomTags) && (
        <>
          <Divider mb={3} />

          <TagList
            items={existingCustomTags.map(item => ({
              id: item.id,
              name: item.name,
              colorKind: TAG_COLOR_KIND.CUSTOM,
              onClick: () => handleClick(item, NODE_KIND.custom),
            }))}
            label={t('customTags')}
          />
        </>
      )}

      {!isEmpty(customTags) && (
        <>
          <Divider mb={3} />

          <TagList
            items={customTags.map(item => ({
              id: item.id,
              name: item.name,
              colorKind: TAG_COLOR_KIND.CUSTOM,
              onClick: () => handleClick(item, NODE_KIND.custom),
            }))}
            label={t('mentionedCustomTags')}
          />
        </>
      )}
    </Column>
  )
}

export default Tags
