import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { IconX } from '@tabler/icons-react'

import { resetButton } from 'Components/Styles'

import colors from 'Theme/_v2/colors'

export const Container = styled.div`
  display: flex;
  gap: ${themeGet('space.2')}px;
  align-items: center;
`

export const CloseIcon = styled(IconX)`
  width: 12px;
  height: 12px;
  color: ${colors.icon.profile};
`

export const Button = styled.button`
  ${resetButton};

  display: flex;
  cursor: pointer;
`
