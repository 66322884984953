import { AbilityBuilder, PureAbility } from '@casl/ability'

import forEach from 'lodash/forEach'
import isEqual from 'lodash/isEqual'
import reduce from 'lodash/reduce'

function parseBySubjectAndScope(rules) {
  return reduce(
    rules,
    (acc, item) => {
      const subject = item?.subject
      const conditions = Object.keys(item?.conditions).join('-')
      const action = item?.action

      if (!acc[subject]) {
        acc[subject] = {}
      }

      acc[subject] = {
        ...acc[subject],
        [conditions]: {
          ...acc[subject][conditions],
          [action]: true,
        },
      }

      return acc
    },
    {},
  )
}

function createAbility(rules) {
  const { can, build } = new AbilityBuilder(PureAbility)

  forEach(rules, ({ action, subject, conditions }) => {
    // By default, CASL works as OR for rules matching, we need strong equality for our checks.
    can(action, subject, scope => isEqual(conditions, scope))
  })

  return build({ conditionsMatcher: matchConditions => matchConditions })
}

const PermissionService = {
  parseBySubjectAndScope,
  createAbility,
}

export default PermissionService
