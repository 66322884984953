import React from 'react'

import { IconTent } from '@tabler/icons-react'

import { Column, Row, Text } from 'Components/UI'

import colors from 'Theme/_v2/colors'

import Card from './Card'

export interface IHobbiesBoxProps {
  user: MainSchema.GraphUser
}

function HobbiesBox({ user }: IHobbiesBoxProps) {
  return (
    <Card>
      <Column gap={2}>
        <Row gap={2}>
          <IconTent color={colors.icon.profile} size={20} stroke={1} />
          <Text header header4>
            Hobbies & Interests
          </Text>
        </Row>

        <Text body bodyMedium>
          {user.interestsHobbies}
        </Text>
      </Column>
    </Card>
  )
}

export default HobbiesBox
