import { NodeKind } from 'Constants/graph'

import oldColors from './oldColors'

export interface IGraph {
  nodeKind: Record<NodeKind, string | undefined>
  nodeKindLight: Partial<Record<NodeKind, string | undefined>>
  nodeTextKind: Record<NodeKind, string | undefined>
  nodeEdgeKind: Record<NodeKind, string | undefined>
}

const graph: IGraph = {
  nodeKindLight: {
    skill: '#F8D3B3',
    role: '#F2BCB9',
    project: '#BBF0EB',
    event: '#D5C1EA',
    group: '#BFEDD4',
    custom: '#F3B9D3',
    organization: '#B4DAF7',
  },
  nodeKind: {
    user: oldColors.primaryCardinal,
    addUser: oldColors.positiveDark,
    meetUser: oldColors.lochmara,
    sharedConnection: oldColors.positiveDark,
    mentionUser: oldColors.positiveDark,
    skill: '#F19C55',
    needSkill: '#F9A825',
    role: '#E2645D',
    project: '#2BCBBA',
    event: '#A97FD5',
    group: '#4ACD88',
    custom: '#E25393',
    organization: '#50A9EE',

    // TODO: is this used at all?
    industry: undefined,
    connection: oldColors.positiveDark,
    myConnection: oldColors.positiveDark,
    // TODO: is this used at all?
    mention: oldColors.positiveDark,
  },
  nodeTextKind: {
    user: oldColors.white,
    addUser: oldColors.white,
    meetUser: oldColors.white,
    sharedConnection: oldColors.white,
    mentionUser: oldColors.white,
    skill: oldColors.white,
    needSkill: oldColors.white,
    role: oldColors.white,
    project: oldColors.white,
    event: oldColors.white,
    group: oldColors.white,
    custom: oldColors.white,
    // TODO: is this used at all?
    industry: undefined,
    connection: oldColors.white,
    myConnection: oldColors.white,
    organization: oldColors.white,
    // TODO: is this used at all?
    mention: oldColors.white,
  },
  nodeEdgeKind: {
    user: oldColors.primaryCardinal,
    addUser: oldColors.positiveDark,
    meetUser: oldColors.lochmara,
    sharedConnection: oldColors.positiveDark,
    mentionUser: oldColors.positiveDark,
    skill: oldColors.chartOrange,
    needSkill: oldColors.chartYellow,
    role: oldColors.darkCyan,
    project: oldColors.chartreuse,
    event: oldColors.purple,
    group: oldColors.teal,
    custom: oldColors.lightcoral,
    // TODO: is this used at all?
    industry: undefined,
    connection: oldColors.positiveDark,
    myConnection: oldColors.positiveDark,
    organization: oldColors.positiveDark,
    // TODO: is this used at all?
    mention: oldColors.positiveDark,
  },
}

export default graph
