import React, { useCallback, useState } from 'react'
import { MultiValue, SingleValue } from 'react-select'

import { useApolloClient } from '@apollo/client'
import debounce from 'awesome-debounce-promise'
import communitySearchSuggestionsQuery from 'GraphQL/Queries/Community/communitySearchSuggestions.graphql'
import { setMinSearchLength } from 'Utils/Form'
import { getFullName } from 'Utils/User'

import filter from 'lodash/filter'
import map from 'lodash/map'

import { Button, Popover, Row, Select, Text } from 'Components/UI'

import { useAppContext, useCommunity, useResponsiveLayout } from 'Hooks'

import _, { useScopedI18n } from 'Services/I18n'

import SelectOption, { Option } from './SelectOption'
import { Container, Inner, SearchContainer, UserWrapper } from './styles'
import User from './User'

interface CommunityIntroduceProps {
  userWhom: MainSchema.UserSuggestion | null
  userTo: MainSchema.UserSuggestion | null
  onCancel: () => void
  onSearchSelect: (users: MainSchema.UserSuggestion[]) => void
}

function CommunityIntroduce({
  userWhom,
  userTo,
  onCancel,
  onSearchSelect,
}: CommunityIntroduceProps) {
  const s = useScopedI18n('blocks.communityIntroduce')
  const { isMobile, isBetweenMobileAndDesktop } = useResponsiveLayout()
  const { communityUser } = useAppContext()
  const { community } = useCommunity()
  const client = useApolloClient()

  const [isShowSearch, setShowSearch] = useState(false)

  const isValid = userWhom?.id && userTo?.id
  const userWhomFullName = getFullName(userWhom)
  const userToFullName = getFullName(userTo)

  const handleIntroduce = useCallback<React.MouseEventHandler>(
    event => {
      event.preventDefault()
      if (!isValid) return

      const meFullName = getFullName(communityUser)
      const userWhomEmail = userWhom?.email
      const userToEmail = userTo?.email

      const mailTo = userWhomEmail
        ? `${userWhomEmail},${userToEmail}`
        : userToEmail
      const subject = `${userWhomFullName} and ${userToFullName}`
      // %0A - new line
      const body = `Dear, ${userWhomFullName} and ${userToFullName},%0A%0AIt seems like you two can help each other out, so I thought it'd be a good idea for you to connect and start that conversation. Happy to help wherever I can, but I'll let you two take it from here.%0A%0AThanks,%0A%0A${meFullName}`
      window.open(`mailto:${mailTo}?subject=${subject}&body=${body}`)
    },
    [
      isValid,
      userWhom,
      userTo,
      communityUser,
      userWhomFullName,
      userToFullName,
    ],
  )

  const handleSelectUser = useCallback(
    (options: MultiValue<Option> | SingleValue<Option>) => {
      setShowSearch(false)
      onSearchSelect(options ? [options].flat().map(option => option.user) : [])
    },
    [onSearchSelect],
  )

  const loadUsersOptions = useCallback(
    async (inputValue: string, callback: (options: any) => void) => {
      if (!userWhom) {
        throw new Error('User whom is not defined')
      }

      if (!communityUser) {
        throw new Error('Community user is not defined')
      }

      if (!community) {
        throw new Error('Community is not defined')
      }

      const result = await client.query<
        Pick<MainSchema.Query, 'communitySearchSuggestions'>,
        MainSchema.QueryCommunitySearchSuggestionsArgs
      >({
        query: communitySearchSuggestionsQuery,
        variables: {
          communityId: community.id,
          search: inputValue,
          limit: 25,
          searchOptions: { user: true },
        },
      })

      const userSuggestions = result.data.communitySearchSuggestions.users

      const uniqueUsers = filter(
        userSuggestions,
        userSuggestion =>
          userWhom.id !== userSuggestion.id &&
          communityUser.userId !== userSuggestion.id &&
          !!userSuggestion.email &&
          userSuggestion.email !== 'Private',
      )

      const options: Option[] = map(uniqueUsers, user => ({
        label: getFullName(user),
        value: user.id,
        user,
      }))

      callback(options)
    },
    [community, communityUser, userWhom, client],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedLoadOptions = useCallback(
    setMinSearchLength(debounce(loadUsersOptions, 700), 3),
    [loadUsersOptions],
  )

  return (
    <Container>
      <Inner>
        <UserWrapper>
          <Text mb={[2, 2, 2, 2, 0]} secondary small={isMobile}>
            {s('introduce')}
          </Text>
          <User
            fullName={userWhomFullName}
            id={userWhom?.id}
            imageUrl={userWhom?.photoUrl}
            ml={[0, 0, 0, 0, 5]}
          />
        </UserWrapper>

        <UserWrapper ml={[0, 0, 0, 0, 5]}>
          <Text mb={[2, 2, 2, 2, 0]} secondary small={isMobile}>
            {s('to')}
          </Text>

          <Popover
            appendTo={document.body}
            content={
              <SearchContainer>
                <Select
                  async
                  blurInputOnSelect
                  components={{
                    Option: SelectOption,
                  }}
                  label="Search user"
                  loadOptions={debouncedLoadOptions}
                  maxMenuHeight={150}
                  placeholder={_('search.byName')}
                  value={null}
                  onChange={handleSelectUser}
                />
              </SearchContainer>
            }
            interactive
            offset={[25, 10]}
            placement="top-start"
            visible={isShowSearch}
            onClickOutside={() => setShowSearch(false)}
          >
            <div>
              <User
                fullName={userToFullName}
                id={userTo?.id}
                imageUrl={userTo?.photoUrl}
                ml={[0, 0, 0, 0, 5]}
                onAddUserClick={() => setShowSearch(true)}
              />
            </div>
          </Popover>
        </UserWrapper>

        <Row
          center
          flexDirection={isBetweenMobileAndDesktop ? 'row-reverse' : 'row'}
          fullWidth={isBetweenMobileAndDesktop}
          gap={3}
          ml={[0, 0, 0, 0, '40px']}
          mt={[4, 4, 4, 4, 0]}
          spaceBetween={isBetweenMobileAndDesktop}
        >
          <Button
            disabled={!isValid}
            id="handshake"
            width={['48%', '48%', '48%', '48%', '100px']}
            onClick={handleIntroduce}
          >
            {s('actions.submit')}
          </Button>

          <Button
            secondary
            width={['48%', '48%', '48%', '48%', '100px']}
            onClick={onCancel}
          >
            {s('actions.cancel')}
          </Button>
        </Row>
      </Inner>
    </Container>
  )
}

export default CommunityIntroduce
