import loadable from '@loadable/component'

import ComingSoon from './ComingSoon'
import NotFound from './NotFound'
import Redirector from './Redirector'

const App = {
  Notes: loadable(
    () => import('./Notes') /* webpackChunkName: "app-community-notes" */,
  ),
  Dashboard: loadable(
    () =>
      import('./Dashboard' /* webpackChunkName: "app-community-dashboard" */),
  ),
  DashboardUsers: loadable(
    () =>
      import(
        './Dashboard/Users' /* webpackChunkName: "app-community-dashboard-users" */
      ),
  ),
  DashboardUser: loadable(
    () =>
      import(
        './Dashboard/User' /* webpackChunkName: "app-community-dashboard-user" */
      ),
  ),
  Metrics: loadable(
    () => import('./Metrics') /* webpackChunkName: "app-community-metrics" */,
  ),
  Communities: loadable(
    () =>
      import(
        './Communities'
      ) /* webpackChunkName: "app-community-communities" */,
  ),
  HubSpotOAuth: loadable(() => import('./HubSpot/OAuth')),
  UploadData: loadable(
    () =>
      import(
        './UploadData'
      ) /* webpackChunkName: "app-community-upload-data" */,
  ),
  Management: loadable(
    () =>
      import('./Management') /* webpackChunkName: "app-community-management" */,
  ),
  SharedUsers: loadable(
    () =>
      import(
        './SharedUsers'
      ) /* webpackChunkName: "app-community-shared-users" */,
  ),
  SharedUser: loadable(
    () =>
      import(
        './SharedUsers/SharedUser'
      ) /* webpackChunkName: "app-community-shared-user" */,
  ),
  Thanks: loadable(
    () => import('./Thanks') /* webpackChunkName: "app-community-thanks" */,
  ),
  ComingSoon,
  Redirector,
  NotFound,
}

export default App
