import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react'
import ReactModal from 'react-modal'

import pick from 'lodash/pick'
import replace from 'lodash/replace'
import toNumber from 'lodash/toNumber'

import Text from 'Components/UI/Text'

import { theme } from 'Theme'

import { CloseButton, CloseIcon, ModalHeader, StyledModal } from './styles'

ReactModal.setAppElement('body')

const TIMEOUT = toNumber(replace(theme.transitionTime.modal, 'ms', ''))

export interface ModalProps extends PropsWithChildren {
  isOpen?: boolean
  shouldCloseOnOverlayClick?: boolean
  shouldCloseOnEsc?: boolean
  title?: string
  onClose?: () => void
  onMount: ({ handleClose }: { handleClose: () => void }) => void
}

function Modal({
  children,
  isOpen = false,
  shouldCloseOnOverlayClick = true,
  shouldCloseOnEsc = true,
  title,
  onClose,
  onMount,
}: ModalProps) {
  const [internalOpen, setInternalOpen] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setInternalOpen(true)
    }
  }, [isOpen])

  const handleClose = useCallback(() => {
    setInternalOpen(false)
  }, [])

  useEffect(() => {
    onMount({ handleClose })
  }, [handleClose, onMount])

  if (!isOpen) {
    return null
  }

  return (
    <StyledModal
      closeTimeoutMS={TIMEOUT}
      isOpen={internalOpen}
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onAfterClose={onClose}
      onRequestClose={handleClose}
    >
      {title && (
        <ModalHeader px={4} py="18px">
          <Text fontSize={0} grayBoulder>
            {title}
          </Text>

          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
        </ModalHeader>
      )}

      {children}
    </StyledModal>
  )
}

const PROPS = [
  'isOpen',
  'shouldCloseOnOverlayClick',
  'title',
  'showClose',
  'onClose',
  'onMount',
]

Modal.pickProps = (props: any) => pick(props, PROPS)

export default Modal
