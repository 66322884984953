import map from 'lodash/map'
import reduce from 'lodash/reduce'

import { PROPOSAL_KIND } from 'Constants/ids'

import _ from 'Services/I18n'

export interface IUser {
  firstName?: string
  lastName?: string
}

export function getFullName(value?: IUser | null) {
  return `${value?.firstName || '—'} ${value?.lastName || '—'}`
}

export interface IProposalsForUser {
  contact: MainSchema.Proposal[]
  emails: MainSchema.Proposal[]
  skills: MainSchema.Proposal[]
  needSkills: MainSchema.Proposal[]
  events: MainSchema.Proposal[]
  projects: MainSchema.Proposal[]
  groups: MainSchema.Proposal[]
  roles: MainSchema.Proposal[]
  custom: MainSchema.Proposal[]
  socialLinks: MainSchema.Proposal[]
}

export function getProposalsForUser(
  data: MainSchema.Proposal[],
): IProposalsForUser {
  return reduce(
    data,
    (acc: IProposalsForUser, item) => {
      switch (item.kind) {
        case PROPOSAL_KIND.TWITTER_URL:
        case PROPOSAL_KIND.FACEBOOK_URL:
        case PROPOSAL_KIND.LINKEDIN_URL:
          acc.socialLinks.push(item)
          break
        case PROPOSAL_KIND.SKILL:
          acc.skills.push(item)
          break
        case PROPOSAL_KIND.NEED_SKILL:
          acc.needSkills.push(item)
          break
        case PROPOSAL_KIND.PHONE:
          acc.contact.push(item)
          break
        case PROPOSAL_KIND.EMAIL:
          acc.emails.push(item)
          break
        case PROPOSAL_KIND.EVENT:
          acc.events.push(item)
          break
        case PROPOSAL_KIND.PROJECT:
          acc.projects.push(item)
          break
        case PROPOSAL_KIND.GROUP:
          acc.groups.push(item)
          break
        case PROPOSAL_KIND.ROLE:
          acc.roles.push(item)
          break
        case PROPOSAL_KIND.CUSTOM:
          acc.custom.push(item)
          break
        default:
          break
      }

      return acc
    },
    {
      contact: [],
      emails: [],
      skills: [],
      needSkills: [],
      events: [],
      projects: [],
      groups: [],
      roles: [],
      custom: [],
      socialLinks: [],
    },
  )
}

export interface IUserValue {
  value: string
  label: string
  user: MainSchema.User
}

export function usersToValues(users: MainSchema.User[]): IUserValue[] {
  return map(users, user => ({
    value: user.id,
    label:
      `${user.profile?.firstName || ''} ${
        user.profile?.lastName || ''
      }`.trim() || '<no name>',
    user,
  }))
}

export interface IGraphUserValue {
  value: string
  label: string
  user: MainSchema.GetCommunityUser
}

export function graphUsersToValues(
  users: MainSchema.GetCommunityUser[],
): IGraphUserValue[] {
  return map(users, user => ({
    value: user.id,
    label:
      `${user.firstName || ''} ${user.lastName || ''}`.trim() || '<no name>',
    user,
  }))
}

export function formatExperience(
  workHistory: MainSchema.CommunityUserWorkHistory,
) {
  if (workHistory.jobTitle?.name && workHistory.organization?.name) {
    return _('utils.user.experience.jobTitleAndOrganization', {
      jobTitle: workHistory.jobTitle.name,
      organization: workHistory.organization.name,
    })
  }

  if (workHistory.jobTitle?.name) {
    return _('utils.user.experience.jobTitle', {
      jobTitle: workHistory.jobTitle.name,
    })
  }

  if (workHistory.organization?.name) {
    return _('utils.user.experience.organization', {
      organization: workHistory.organization.name,
    })
  }

  return ''
}

export function formatEducation(
  educationHistory: MainSchema.CommunityUserEducationHistory,
  lineBreak: string = '\n',
) {
  const educationLine1: string[] = []
  const degrees = educationHistory.degrees ?? []
  const majors = educationHistory.majors ?? []
  const minors = educationHistory.minors ?? []

  if (degrees.length > 0) {
    degrees.forEach(degree => {
      educationLine1.push(degree)
    })
  }

  if (majors.length > 0) {
    educationLine1.push(
      _('utils.user.education.majors', {
        majors: majors.join(', '),
      }),
    )
  }

  if (minors.length > 0) {
    educationLine1.push(
      _('utils.user.education.minors', {
        minors: minors.join(', '),
      }),
    )
  }

  let education = educationLine1.join(', ')

  if (educationHistory.organization) {
    if (education) {
      education += lineBreak
    }

    education += educationHistory.organization.name
  }

  return education
}
