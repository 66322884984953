import { useMemo } from 'react'

import Utils from 'Utils'

import { ActionsCell } from './Cells'

export function useColumns() {
  return useMemo(
    () => [
      {
        Header: 'User',
        accessor: 'user',
        Cell: Utils.Table.renderProfileFullName,
        width: 1,
      },
      {
        Header: 'To whom',
        accessor: 'recipient',
        Cell: Utils.Table.renderProfileFullName,
        width: 1,
      },
      {
        Header: 'Shared at',
        accessor: 'createdAt',
        Cell: Utils.Table.renderDateTimeCell,
        width: 1,
      },
      {
        Header: 'Actions',
        Cell: ActionsCell,
        width: 0.5,
        cellRight: true,
        headerRight: true,
      },
    ],
    [],
  )
}
