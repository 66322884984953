import React, { ReactNode, useCallback, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import oAuthUrlQuery from 'GraphQL/Queries/Auth/oAuthUrl.graphql'

import { useAppContext } from 'Hooks'

export enum ConnectAccountProvider {
  Google = 'gmail',
}

export enum ConnectAccountType {
  SignIn = 'signIn',
  SignUp = 'signUp',
  Connect = 'connect',
  Onboarding = 'onboarding',
}

const TYPE_TO_BUTTON_LABEL_PREFIX: { [key in ConnectAccountType]: string } = {
  [ConnectAccountType.SignIn]: 'Sign in with',
  [ConnectAccountType.SignUp]: 'Sign up with',
  [ConnectAccountType.Connect]: 'Connect',
  [ConnectAccountType.Onboarding]: 'Connect',
}

const PROVIDER_TO_LABEL: { [key in ConnectAccountProvider]: string } = {
  [ConnectAccountProvider.Google]: 'Google',
}

export interface ITokens {
  accessToken?: string
  refreshToken?: string
}

export type HandleConnect = () => Promise<void>

export interface IConnectAccount {
  provider: ConnectAccountProvider
  type: ConnectAccountType
  onError?: (title: string, message: string) => void
  children?:
    | ReactNode
    | undefined
    | ((props: {
        isLoading: boolean
        onClick: HandleConnect
        provider: ConnectAccountProvider
        type: ConnectAccountType
        label: string
        buttonLabel: string
      }) => ReactNode | undefined)
}

const ConnectAccount = (props: IConnectAccount) => {
  const [isLoading, setIsLoading] = useState(false)
  const { me } = useAppContext()
  const client = useApolloClient()
  const label = PROVIDER_TO_LABEL[props.provider]
  const buttonLabelPrefix = TYPE_TO_BUTTON_LABEL_PREFIX[props.type]
  const buttonLabel = `${buttonLabelPrefix} ${label}`
  const connectTitle = `Connect ${label}`
  const generalErrorMessage = 'Something went wrong, please try again later'

  const handleOnClick = useCallback(async () => {
    setIsLoading(true)

    try {
      const result = await client.query({
        query: oAuthUrlQuery,
        fetchPolicy: 'network-only',
        variables: {
          provider: props.provider,
          options: {
            // If logged in, we are linking and should always force consent
            promptConsent: !!me?.id,
          },
        },
      })

      const loginUrl = result.data?.oAuthUrl

      if (!loginUrl) {
        props.onError?.(connectTitle, generalErrorMessage)
        return
      }

      window.location.href = `${loginUrl}&state=${props.type}`
    } catch (error) {
      let message = generalErrorMessage

      // Server throws an error if connection fails, already connected to another account, etc
      if (error instanceof Error) {
        message = error.message
      }

      props.onError?.(connectTitle, message)
    } finally {
      setIsLoading(false)
    }
  }, [client, me, props, connectTitle, generalErrorMessage])

  return (
    <>
      {typeof props.children === 'function'
        ? props.children?.({
            isLoading,
            onClick: handleOnClick,
            provider: props.provider,
            type: props.type,
            label,
            buttonLabel,
          })
        : props.children}
    </>
  )
}

export default ConnectAccount
