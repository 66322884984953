import styled, { css } from 'styled-components'

import { showMoreGlyph } from 'Assets/Svg/icons/profile'

import { resetButton } from 'Components/Styles'

export const Button = styled.button`
  ${resetButton}

  border-color: #dde1e4;
  border-radius: 100px;
  border-style: solid;
  border-width: 0.7px;
  padding: 1px 2px;

  :enabled {
    cursor: pointer;

    :hover {
      background-color: rgba(237, 239, 241, 0.63);
    }
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1px;
`

const hiddenCss = ({ isHidden }: { isHidden?: boolean }) =>
  isHidden &&
  css`
    display: none;
  `

export const Child = styled.div`
  ${hiddenCss}
`

export const IconShowMore = styled(showMoreGlyph)``
