import React, { useCallback } from 'react'

import { pick } from '@styled-system/props'

import { useApolloClient } from '@apollo/client'
import debounce from 'awesome-debounce-promise'
import communitySkillsQuery from 'GraphQL/Queries/Community/communitySkills.graphql'
import listTagsQuery from 'GraphQL/Queries/listTags.graphql'
import { setMinSearchLength } from 'Utils/Form'
import { entitiesToOptions } from 'Utils/Options'

import { SelectField } from 'Components/UI'
import DropdownIndicator from 'Components/UI/Form/Select/Components/DropdownIndicator'

import {
  DEFAULT_MIN_SEARCH_SIZE,
  DEFAULT_SEARCH_DEBOUNCE,
  SEARCH_TYPES,
  SearchType,
} from 'Constants/ids'

import _ from 'Services/I18n'
import toast from 'Services/Toast'

export interface ITagsField {
  communityId: string
  label?: string
  name: string
  placeholder?: string
  type: SearchType
}

function TagsField({
  communityId,
  name,
  label,
  type,
  placeholder,
  ...rest
}: ITagsField) {
  const client = useApolloClient()

  const loadTagsOptions = useCallback(
    () =>
      async (
        inputValue: string,
        callback: (...args: any[]) => Promise<void> | void,
      ) => {
        try {
          const result = await client.query({
            query:
              type === SEARCH_TYPES.skill
                ? communitySkillsQuery
                : listTagsQuery,
            variables: {
              communityId,
              kind: type === SEARCH_TYPES.skill ? undefined : type,
              search: inputValue,
              limit: 25,
            },
            context: {
              admin: true,
            },
          })
          const suggestions = result?.data

          const tags =
            type === SEARCH_TYPES.skill
              ? suggestions?.communitySkills?.rows
              : suggestions?.listTags?.rows

          callback(entitiesToOptions(tags))
        } catch (error) {
          let message = _(`error.generic`)

          if (error instanceof Error) {
            message = error.message
          }

          toast.error({
            title: 'Oops...',
            text: `The server returned an error: "${message}"`,
          })
        }
      },
    [client, type, communityId],
  )

  const debouncedLoadTagsOptions = useCallback(
    () =>
      setMinSearchLength(
        debounce(loadTagsOptions(), DEFAULT_SEARCH_DEBOUNCE),
        DEFAULT_MIN_SEARCH_SIZE,
      ),
    [loadTagsOptions],
  )

  return (
    <SelectField
      {...pick(rest)}
      async
      components={{
        DropdownIndicator,
      }}
      isMulti
      label={label}
      loadOptions={debouncedLoadTagsOptions()}
      name={name}
      placeholder={placeholder}
      width={1}
      withLabel={false}
    />
  )
}

export default TagsField
