import React, { forwardRef, MouseEventHandler } from 'react'

import { IconUserPlus } from '@tabler/icons-react'

import { IconButton, Tooltip } from 'Components/UI'

import _ from 'Services/I18n'

export interface ICreateUserButton {
  onClick: MouseEventHandler<HTMLButtonElement>
}

const CreateUserButton = forwardRef<HTMLButtonElement, ICreateUserButton>(
  ({ onClick }, ref) => (
    <Tooltip content={_('tips.addContact')}>
      <IconButton outline ref={ref} onClick={onClick}>
        <IconUserPlus />
      </IconButton>
    </Tooltip>
  ),
)

export default CreateUserButton
