import { TAG_KIND } from 'Constants/ids'

export const TAG_COLOR_KIND = {
  COMMUNITY: 'community',
  COMPANY: 'company',
  CONNECT: 'connect',
  CUSTOM: TAG_KIND.CUSTOM,
  EVENT: TAG_KIND.EVENT,
  GROUP: TAG_KIND.GROUP,
  INDUSTRY: TAG_KIND.INDUSTRY,
  MESSAGE: 'message',
  NEED_INDUSTRIES: TAG_KIND.INDUSTRY,
  NEED_SKILL: 'needSkill',
  NOTE: 'note',
  ORGANIZATION: 'organization',
  PROJECT: TAG_KIND.PROJECT,
  PROPOSAL: 'proposal',
  RECOMMENDATION: 'recommendation',
  ROLE: TAG_KIND.ROLE,
  SKILL: 'skill',
  USER: 'user',
  SEARCH_KEYWORD: 'searchKeyword',
} as const
export type TagColorKindKeys = keyof typeof TAG_COLOR_KIND
export type TagColorKind = (typeof TAG_COLOR_KIND)[TagColorKindKeys]

export const TAG_BLOCK_KIND = {
  SKILL: 'skill',
  NEED_SKILL: 'needSkill',
  EVENT: TAG_KIND.EVENT,
  ROLE: TAG_KIND.ROLE,
  PROJECT: TAG_KIND.PROJECT,
  GROUP: TAG_KIND.GROUP,
  CUSTOM: TAG_KIND.CUSTOM,
}
export type TagBlockKindKeys = keyof typeof TAG_BLOCK_KIND
export type TagBlockKind = (typeof TAG_BLOCK_KIND)[TagBlockKindKeys]
