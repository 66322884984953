import React from 'react'

import { Row } from 'Components/UI'

import { Block, Content, Header } from '../styles'

export interface IBiographyProps {
  bio: string[]
  user: MainSchema.GetCommunityUser
}

function Biography({ bio, user }: IBiographyProps) {
  return (
    <Block mb={4}>
      <Row mb={4}>
        <Header>Meet {user.firstName}</Header>
      </Row>
      <Row wrapped>
        {bio?.length > 0 &&
          bio.map((paragraph, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Content justify key={index} mb={2}>
              {paragraph}
            </Content>
          ))}
      </Row>
    </Block>
  )
}

export default Biography
