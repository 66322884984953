import React from 'react'

import { Column, Row, Tag } from 'Components/UI'

import { PROPOSAL_KIND } from 'Constants/ids'
import { TAG_BLOCK_KIND, TAG_COLOR_KIND, TagBlockKind } from 'Constants/tags'

import EventBus from 'Services/EventBus'

import colors from 'Theme/_v2/colors'

import Skills from './Skills'
import Tags from './Tags'

import Card from '../Card'
import ShowMore from '../ShowMore'
import {
  IconCustomTags,
  IconEvents,
  IconGroups,
  IconProjects,
  IconSkills,
} from '../styles'

export type TagBlockParams = {
  slug?: string
}

// TODO: Need to decouple Skill/Tag/Proposals from TagBlock
export interface ITagSkillProposalProps {
  id?: string
  entityId?: string
  name?: string
  kind?: TagBlockKind
  value?: string
}

export interface ITagBlock extends React.PropsWithChildren {
  kind: TagBlockKind
  tags?: ITagSkillProposalProps[]
  targetUser: MainSchema.GraphUser | MainSchema.GetCommunityUser
  onRemove?: (value: string) => void
}

function TagBlock({
  children,
  kind,
  tags = [],
  targetUser,
  onRemove,
}: ITagBlock) {
  const { icon, tagKind, title } = React.useMemo(() => {
    switch (kind) {
      case TAG_BLOCK_KIND.SKILL:
        return {
          icon: <IconSkills color={colors.icon.profile} />,
          title: 'Skills',
          proposalKind: PROPOSAL_KIND.SKILL,
          tagKind: TAG_COLOR_KIND.SKILL,
        }
      case TAG_BLOCK_KIND.EVENT:
        return {
          icon: <IconEvents color={colors.icon.profile} />,
          title: 'Events',
          proposalKind: PROPOSAL_KIND.EVENT,
          tagKind: TAG_COLOR_KIND.EVENT,
        }
      case TAG_BLOCK_KIND.PROJECT:
        return {
          icon: <IconProjects color={colors.icon.profile} />,
          title: 'Projects',
          proposalKind: PROPOSAL_KIND.PROJECT,
          tagKind: TAG_COLOR_KIND.PROJECT,
        }
      case TAG_BLOCK_KIND.GROUP:
        return {
          icon: <IconGroups color={colors.icon.profile} />,
          title: 'Groups',
          proposalKind: PROPOSAL_KIND.GROUP,
          tagKind: TAG_COLOR_KIND.GROUP,
        }
      default:
        return {
          icon: <IconCustomTags color={colors.icon.profile} />,
          title: 'Custom',
          proposalKind: PROPOSAL_KIND.CUSTOM,
          tagKind: TAG_COLOR_KIND.CUSTOM,
        }
    }
  }, [kind])

  return (
    <Card>
      {kind === TAG_BLOCK_KIND.SKILL ? (
        <Skills icon={icon} targetUser={targetUser} />
      ) : (
        <Tags icon={icon} kind={kind} targetUser={targetUser} title={title} />
      )}

      <Column pl={5}>
        {tags.length > 0 && (
          <Row pt={2} wrapped>
            <ShowMore
              flexDirection={'row'}
              gap={2}
              initialShown={kind === TAG_BLOCK_KIND.SKILL ? 6 : 3}
              wrapped
            >
              {tags.map(tag => (
                <Tag
                  colorKind={tagKind}
                  key={tag?.entityId || tag?.id}
                  removable={!!onRemove}
                  small
                  text={tag?.value || tag?.name || 'N/A'}
                  onClick={() => {
                    // TODO: refactor events so this is clear about what it's doing
                    EventBus.trigger(EventBus.actions.search.community, {
                      id: tag?.entityId || tag?.id,
                      type: tagKind,
                      label: tag?.value || tag?.name,
                      value: tag?.value || tag?.name,
                    })
                    EventBus.trigger(
                      EventBus.actions.graph.focusNode,
                      tag?.entityId || tag?.id,
                    )
                  }}
                  onRemove={() => onRemove && tag?.id && onRemove(tag.id)}
                />
              ))}
            </ShowMore>
          </Row>
        )}

        {children}
      </Column>
    </Card>
  )
}

export default TagBlock
