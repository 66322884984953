import React, { useCallback, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'

import { useApolloClient } from '@apollo/client'
import debounce from 'awesome-debounce-promise'
import updateSkillMutation from 'GraphQL/Mutations/Skill/updateSkill.graphql'
import updateTagMutation from 'GraphQL/Mutations/Tag/updateTag.graphql'
import listCommunityUsersQuery from 'GraphQL/Queries/CommunityUser/listCommunityUsers.full.graphql'
import { setMinSearchLength } from 'Utils/Form'
import { communityUsersToOptions } from 'Utils/Options'
import validate from 'validate.js'

import map from 'lodash/map'
import noop from 'lodash/noop'
import upperFirst from 'lodash/upperFirst'

import { Column, Divider, InputField, Modal, SelectField } from 'Components/UI'

import { SEARCH_TYPES } from 'Constants/ids'

import { useAppContext, useCommunityContext, useResponsiveLayout } from 'Hooks'

import { useMutation } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import TagUsersTable from './TagUsersTable'

const SEARCH_DEBOUNCE = 300
const SEARCH_MINIMUM_LENGTH = 3
const FIELD = {
  NAME: 'name',
  ADD_COMMUNITY_USER_IDS: 'addCommunityUserIds',
  ADD_USER_IDS: 'addUserIds',
  DELETE_COMMUNITY_USER_IDS: 'deleteCommunityUserIds',
  DELETE_USER_IDS: 'deleteUserIds',
}

// TODO: Split Skills and Tags into their own components
function AddEditTagModal({ tag, type, refetch, title, onClose, isOpen }) {
  const s = useScopedI18n('tag')
  const { me } = useAppContext()
  const client = useApolloClient()
  const { community } = useCommunityContext()
  const { isMobile } = useResponsiveLayout()

  const [loading, setLoading] = useState(false)
  const [deleteCommunityUserIds, setDeleteCommunityUserIds] = useState([])

  const [updateTag] = useMutation(updateTagMutation)
  const [updateSkill] = useMutation(updateSkillMutation)

  const formConstraints = useMemo(
    () => ({
      [FIELD.NAME]: {
        presence: { allowEmpty: false, message: `^Field is required` },
      },
      [FIELD.ADD_COMMUNITY_USER_IDS]: {},
      [FIELD.DELETE_COMMUNITY_USER_IDS]: {},
    }),
    [],
  )

  const updateMutation = useMemo(
    () => (type === SEARCH_TYPES.skill ? updateSkill : updateTag),
    [type, updateSkill, updateTag],
  )

  const submit = useCallback(
    async values => {
      setLoading(true)
      const variables =
        type === SEARCH_TYPES.skill
          ? {
              name: values[FIELD.NAME],
              skillId: tag?.id,
              communityId: community?.id,
              addCommunityUserIds: [
                ...map(values[FIELD.ADD_COMMUNITY_USER_IDS], 'value'),
              ],
              deleteCommunityUserIds,
            }
          : {
              name: values[FIELD.NAME],
              tagId: tag?.id,
              communityId: community?.id,
              addCommunityUserIds: [
                ...map(values[FIELD.ADD_COMMUNITY_USER_IDS], 'value'),
              ],
              deleteCommunityUserIds,
            }
      try {
        await updateMutation({
          variables,
        })

        await refetch()

        toast.success({
          title: 'Modify tag',
          text: `${values[FIELD.NAME]} tag updated`,
        })

        setLoading(false)
        setDeleteCommunityUserIds([])
        onClose()
      } catch (error) {
        toast.error({
          title: 'Oops...',
          text: error?.message,
        })
        setLoading(false)
      }
    },
    [
      type,
      tag,
      community,
      deleteCommunityUserIds,
      updateMutation,
      refetch,
      onClose,
    ],
  )

  const loadUsersOptions = useCallback(
    excludeValues => async (inputValue, callback) => {
      try {
        const result = await client.query({
          query: listCommunityUsersQuery,
          variables: {
            search: inputValue,
            excludeUserIds: [me.id, ...map(excludeValues, 'value')],
            communityId: community?.id,
            limit: 25,
          },
        })

        const users = result.data?.listCommunityUsers?.communityUsers || []

        callback(communityUsersToOptions(users))
      } catch (error) {
        toast.error(`The server returned an error: "${error.message}"`)
      }
    },
    [client, me.id, community?.id],
  )

  const debouncedLoadUserOptions = useCallback(
    excludedValues =>
      setMinSearchLength(
        debounce(loadUsersOptions(excludedValues), SEARCH_DEBOUNCE),
        SEARCH_MINIMUM_LENGTH,
      ),
    [loadUsersOptions],
  )

  const handleDeleteUsers = useCallback(
    communityUserId => {
      if (deleteCommunityUserIds.includes(communityUserId)) {
        return setDeleteCommunityUserIds([
          ...deleteCommunityUserIds.filter(id => id !== communityUserId),
        ])
      }
      return setDeleteCommunityUserIds([
        ...deleteCommunityUserIds,
        communityUserId,
      ])
    },
    [deleteCommunityUserIds, setDeleteCommunityUserIds],
  )

  const renderForm = useCallback(
    ({ handleSubmit, values }) => (
      <Modal
        cancelText="Cancel"
        confirmText="Save"
        isOpen
        loading={loading}
        minWidth={isMobile ? '100%' : '600px'}
        noScroll
        px={0}
        title={s('actions.edit', {
          tagType: upperFirst(title || tag?.kind),
          tagName: tag?.name,
        })}
        onClose={() => onClose()}
        onConfirm={handleSubmit}
      >
        <Column width={['100%', '100%', '600px']}>
          <Column gap={4} px={5}>
            {type !== SEARCH_TYPES.skill && (
              <InputField
                label={`${upperFirst(
                  title || tag?.kind || SEARCH_TYPES.skill,
                )} name`}
                name={FIELD.NAME}
              />
            )}

            <SelectField
              async
              isMulti
              label="Add users"
              loadOptions={debouncedLoadUserOptions(values.users)}
              name={FIELD.ADD_COMMUNITY_USER_IDS}
              placeholder="Type at least 3 symbols"
            />
          </Column>

          <Divider my={4} />

          <Column px={5}>
            <TagUsersTable
              deleteCommunityUserIds={deleteCommunityUserIds}
              handleDeleteUsers={handleDeleteUsers}
              px={5}
              tagId={tag?.id}
              type={tag?.kind || SEARCH_TYPES.skill}
            />
          </Column>
        </Column>
      </Modal>
    ),
    [
      debouncedLoadUserOptions,
      deleteCommunityUserIds,
      handleDeleteUsers,
      isMobile,
      loading,
      onClose,
      s,
      tag?.id,
      tag?.kind,
      tag?.name,
      title,
      type,
    ],
  )

  if (!isOpen) return null

  return (
    <Form
      initialValues={tag}
      render={renderForm}
      validate={values => validate(values, formConstraints)}
      onSubmit={submit}
    />
  )
}

AddEditTagModal.defaultProps = {
  isOpen: false,
  refetch: noop,
  title: '',
  tag: null,
  onClose: noop,
}

AddEditTagModal.propTypes = {
  isOpen: PropTypes.bool,
  refetch: PropTypes.func,
  tag: PropTypes.object,
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func,
}

export default AddEditTagModal
