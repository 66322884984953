import { useCallback, useMemo } from 'react'

import { useApolloClient } from '@apollo/client'

import reduce from 'lodash/reduce'
import values from 'lodash/values'

import { TagKind } from 'Constants/mainGraphQL'

import directSearchCommunityUsersQuery from './Queries/directSearchCommunityUsersQuery.graphql'
import searchCommunityTagsQuery from './Queries/searchCommunityTagsQuery.graphql'
import searchSkillsQuery from './Queries/searchSkillsQuery.graphql'
import semanticSearchCommunityUsersQuery from './Queries/semanticSearchCommunityUsersQuery.graphql'

export const COMMUNITY_SEARCH_DEBOUNCE = 700
export const COMMUNITY_SEARCH_MINIMUM_LENGTH = 3

export type SearchCommunitySkillsParams = {
  communityId: string
  searchText: string
  limit?: number
}

export type SearchCommunityTagsParams = {
  communityId: string
  searchText: string
  kind: TagKind
  limit?: number
}

export type SearchCommunityUsersParams = {
  communityId: string
  searchText: string
  limit?: number
}

export enum SearchCommunityType {
  CustomTags = 'customTags',
  EventTags = 'eventTags',
  GroupTags = 'groupTags',
  IndustryTags = 'industryTags',
  ProjectTags = 'projectTags',
  RoleTags = 'roleTags',
  Skills = 'skills',
  SemanticUsers = 'semanticUsers',
  DirectUsers = 'directUsers',
}

export type SearchCommunityParams<T extends SearchCommunityType> = {
  communityId: string
  searchText: string
  types: T[]
}

// TODO: Consider refactoring this to a class instead of a hook in the future
const useCommunitySearch = () => {
  const client = useApolloClient()

  const isSearchTextValid = useCallback((searchText?: string) => {
    return (
      !!searchText &&
      searchText.trim().length >= COMMUNITY_SEARCH_MINIMUM_LENGTH
    )
  }, [])

  const searchCommunitySkills = useCallback(
    async (params: SearchCommunitySkillsParams) => {
      return client.query<
        Pick<MainSchema.Query, 'searchSkills'>,
        MainSchema.QuerySearchSkillsArgs
      >({
        query: searchSkillsQuery,
        // TODO: update this to support caching, and then update the cache on create
        fetchPolicy: 'no-cache',
        context: {
          batch: true,
          headers: {
            'x-community-id': params.communityId,
          },
        },
        variables: {
          communityId: params.communityId,
          searchText: params.searchText,
          limit: params.limit ?? 10,
        },
      })
    },
    [client],
  )

  const searchCommunityTags = useCallback(
    async (params: SearchCommunityTagsParams) => {
      return client.query<
        Pick<MainSchema.Query, 'searchCommunityTags'>,
        MainSchema.QuerySearchCommunityTagsArgs
      >({
        query: searchCommunityTagsQuery,
        // TODO: update this to support caching, and then update the cache on create
        fetchPolicy: 'no-cache',
        context: {
          batch: true,
          headers: {
            'x-community-id': params.communityId,
          },
        },
        variables: {
          communityId: params.communityId,
          searchText: params.searchText,
          limit: params.limit ?? 10,
          kind: params.kind,
        },
      })
    },
    [client],
  )

  const semanticSearchCommunityUsers = useCallback(
    async (params: SearchCommunityUsersParams) => {
      return client.query<
        Pick<MainSchema.Query, 'semanticSearchCommunityUsers'>,
        MainSchema.QuerySemanticSearchCommunityUsersArgs
      >({
        query: semanticSearchCommunityUsersQuery,
        // TODO: update this to support caching, and then update the cache on create
        fetchPolicy: 'no-cache',
        context: {
          batch: true,
          headers: {
            'x-community-id': params.communityId,
          },
        },
        variables: {
          communityId: params.communityId,
          searchText: params.searchText,
          limit: params.limit ?? 10,
        },
      })
    },
    [client],
  )

  const directSearchCommunityUsers = useCallback(
    async (params: SearchCommunityUsersParams) => {
      return client.query<
        Pick<MainSchema.Query, 'directSearchCommunityUsers'>,
        MainSchema.QueryDirectSearchCommunityUsersArgs
      >({
        query: directSearchCommunityUsersQuery,
        // TODO: update this to support caching, and then update the cache on create
        fetchPolicy: 'no-cache',
        context: {
          batch: true,
          headers: {
            'x-community-id': params.communityId,
          },
        },
        variables: {
          communityId: params.communityId,
          searchText: params.searchText,
          limit: params.limit ?? 10,
        },
      })
    },
    [client],
  )

  const searchCommunity = useCallback(
    async <T extends SearchCommunityType>(params: SearchCommunityParams<T>) => {
      const availableSearchFunctions = {
        [SearchCommunityType.CustomTags]: () =>
          searchCommunityTags({
            communityId: params.communityId,
            searchText: params.searchText,
            kind: TagKind.Custom,
          }),
        [SearchCommunityType.EventTags]: () =>
          searchCommunityTags({
            communityId: params.communityId,
            searchText: params.searchText,
            kind: TagKind.Event,
          }),
        [SearchCommunityType.GroupTags]: () =>
          searchCommunityTags({
            communityId: params.communityId,
            searchText: params.searchText,
            kind: TagKind.Group,
          }),
        [SearchCommunityType.IndustryTags]: () =>
          searchCommunityTags({
            communityId: params.communityId,
            searchText: params.searchText,
            kind: TagKind.Industry,
          }),
        [SearchCommunityType.ProjectTags]: () =>
          searchCommunityTags({
            communityId: params.communityId,
            searchText: params.searchText,
            kind: TagKind.Project,
          }),
        [SearchCommunityType.RoleTags]: () =>
          searchCommunityTags({
            communityId: params.communityId,
            searchText: params.searchText,
            kind: TagKind.Role,
          }),
        [SearchCommunityType.Skills]: () =>
          searchCommunitySkills({
            communityId: params.communityId,
            searchText: params.searchText,
          }),
        [SearchCommunityType.DirectUsers]: () =>
          directSearchCommunityUsers({
            communityId: params.communityId,
            searchText: params.searchText,
          }),
        [SearchCommunityType.SemanticUsers]: () =>
          semanticSearchCommunityUsers({
            communityId: params.communityId,
            searchText: params.searchText,
          }),
      }

      const searchTypes = values(params.types)
      const searchFunctions = params.types.map(
        type => availableSearchFunctions[type],
      )
      const searchPromises = values(searchFunctions).map(fn => fn())
      const searchResults = await Promise.all(searchPromises)

      return reduce(
        searchResults,
        (reducedResults, value, index) => ({
          ...reducedResults,
          [searchTypes[index]]: value,
        }),
        {},
      ) as {
        [K in T]: Awaited<ReturnType<(typeof availableSearchFunctions)[K]>>
      }
    },
    [
      searchCommunitySkills,
      searchCommunityTags,
      directSearchCommunityUsers,
      semanticSearchCommunityUsers,
    ],
  )

  const communitySearch = useMemo(() => {
    return {
      isSearchTextValid,
      searchCommunitySkills,
      searchCommunityTags,
      semanticSearchCommunityUsers,
      directSearchCommunityUsers,
      searchCommunity,
    }
  }, [
    isSearchTextValid,
    searchCommunitySkills,
    searchCommunityTags,
    semanticSearchCommunityUsers,
    directSearchCommunityUsers,
    searchCommunity,
  ])

  return communitySearch
}

export default useCommunitySearch
