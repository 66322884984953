import React from 'react'
import PropTypes from 'prop-types'

import { Row, Tag } from 'Components/UI'

import { TAG_COLOR_KIND } from 'Constants/tags'

function SkillsCell({ row }) {
  const recommendation = row?.original

  return (
    <Row gap={2}>
      {recommendation.skills?.map(skill => (
        <Tag
          colorKind={TAG_COLOR_KIND.SKILL}
          key={skill.id}
          small
          text={skill.name}
        />
      ))}
    </Row>
  )
}

SkillsCell.defaultProps = {
  row: {},
}

SkillsCell.propTypes = {
  row: PropTypes.object,
}

export default SkillsCell
