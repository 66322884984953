import React, { useCallback, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'

import { useMutation } from '@apollo/client'
import connectUsersToSkillsMutation from 'GraphQL/Mutations/Community/connectUsersToSkills.graphql'
import connectUsersToTagsMutation from 'GraphQL/Mutations/Community/connectUsersToTags.graphql'
import disconnectUsersFromTagsMutation from 'GraphQL/Mutations/Community/disconnectUsersFromTags.graphql'
import updateCommunityUserMutation from 'GraphQL/Mutations/CommunityUser/updateCommunityUser.graphql'
import removeUserSkillsMutation from 'GraphQL/Mutations/User/removeUserSkills.graphql'
import { getCommunityUserTagsUpdater } from 'GraphQL/Updaters/GetCommunityUserTags'
import Utils from 'Utils'
import validate from 'validate.js'

import differenceBy from 'lodash/differenceBy'
import find from 'lodash/find'
import fromPairs from 'lodash/fromPairs'
import map from 'lodash/map'
import noop from 'lodash/noop'

import { OPTIONS as RELATIONSHIP_OPTIONS } from 'Components/Blocks/Forms/Fields/RelationshipStrengthField'
import { Divider, MenuItem, Modal, Row } from 'Components/UI'

import { LINKEDIN_REGEX } from 'Constants/regex'

import { useCommunityContext } from 'Hooks'

import EventBus from 'Services/EventBus'
import _ from 'Services/I18n'
import toast from 'Services/Toast'

import { FIELD } from './fields'
import { ErrorWrapper } from './styles'
import { GeneralTab, TagsTab } from './Tabs'

const TABS = {
  GENERAL_INFO: 'General Info',
  TAGS: 'Tags',
}

const DEFAULT_TABS = [TABS.GENERAL_INFO, TABS.TAGS]

function entitiesToValues(entities) {
  return map(entities, entity => ({
    ...entity,
    value: entity.id,
    label: entity.name,
  }))
}

function UpdateCommunityUserModal({ user, isOpen, onClose }) {
  const { community } = useCommunityContext()

  const [updateCommunityUser] = useMutation(updateCommunityUserMutation)
  const [connectUsersToTags] = useMutation(connectUsersToTagsMutation)
  const [connectUsersToSkills] = useMutation(connectUsersToSkillsMutation)
  const [removeUserSkills] = useMutation(removeUserSkillsMutation)
  const [disconnectUsersFromTags] = useMutation(disconnectUsersFromTagsMutation)

  const [loading, setLoading] = useState(false)
  const [tab, setTab] = useState(TABS.GENERAL_INFO)

  const initialSkills = useMemo(() => entitiesToValues(user?.skills), [user])

  const initialTags = useMemo(() => {
    const tags = Utils.Tag.tagsByKind(entitiesToValues(user?.tags))
    delete tags.industries

    return tags
  }, [user])

  const initialValues = useMemo(() => {
    const relationshipOption = find(
      RELATIONSHIP_OPTIONS,
      item => item.value === user?.relationshipStrength,
    )

    const values = {
      [FIELD.ID]: user?.id,
      [FIELD.USER_ID]: user?.userId,
      [FIELD.FIRST_NAME]: user?.firstName,
      [FIELD.LAST_NAME]: user?.lastName,
      [FIELD.PHOTO_URL]: user?.photoUrl,
      [FIELD.JOB]: user?.jobTitle?.name,
      [FIELD.LINKED_IN]: user?.linkedIn || null,
      [FIELD.PHONE_NUMBER]: user?.phoneNumber,
      [FIELD.RELATIONSHIP]: relationshipOption,
      [FIELD.ORGANIZATION]: user?.organization?.name,
      [FIELD.INTERESTS_HOBBIES]: user?.interestsHobbies,
      [FIELD.ABOUT]: user?.about,
      [FIELD.SKILLS]: initialSkills,
      [FIELD.CUSTOM]: entitiesToValues(initialTags.custom),
      [FIELD.PROJECT]: entitiesToValues(initialTags.projects),
      [FIELD.ROLE]: entitiesToValues(initialTags.roles),
      [FIELD.EVENT]: entitiesToValues(initialTags.events),
      [FIELD.GROUP]: entitiesToValues(initialTags.groups),
    }

    if (user?.email !== 'Private') values[FIELD.EMAIL] = user?.email
    if (user?.phoneNumber !== 'Private')
      values[FIELD.PHONE_NUMBER] = user?.phoneNumber

    return values
  }, [user, initialTags, initialSkills])

  const formConstraints = useMemo(() => {
    const constraints = {
      [FIELD.PHOTO_URL]: {
        type: 'string',
      },
      [FIELD.FIRST_NAME]: {
        type: 'string',
        presence: {
          allowEmpty: false,
          message: `^${_('auth.shared.firsNameRequired')}`,
        },
        length: {
          maximum: 255,
          tooLong: `^${_('auth.shared.firsNameTooLong')}`,
        },
      },
      [FIELD.LAST_NAME]: {
        type: 'string',
        presence: {
          allowEmpty: false,
          message: `^${_('auth.shared.lastNameRequired')}`,
        },
        length: {
          maximum: 255,
          tooLong: `^${_('auth.shared.lastNameTooLong')}`,
        },
      },
      [FIELD.JOB]: {
        type: 'string',
        length: {
          maximum: 255,
          tooLong: `^${_('auth.shared.jobTooLong')}`,
        },
      },
      [FIELD.LINKED_IN]: {
        type: 'string',
        format: {
          pattern: LINKEDIN_REGEX,
          message: `^${_('auth.shared.linkedinProfileUrl')}`,
        },
      },
      [FIELD.ORGANIZATION]: {
        type: 'string',
        length: {
          maximum: 255,
          tooLong: `^${_('auth.shared.organizationTooLong')}`,
        },
      },
      [FIELD.INTERESTS_HOBBIES]: {
        type: 'string',
      },
      [FIELD.ABOUT]: {
        type: 'string',
      },
    }

    return constraints
  }, [])

  const submit = useCallback(
    async values => {
      setLoading(true)

      const tags = [
        ...values[FIELD.EVENT],
        ...values[FIELD.GROUP],
        ...values[FIELD.PROJECT],
        ...values[FIELD.ROLE],
        ...values[FIELD.CUSTOM],
      ]

      const removedSkills = differenceBy(
        initialSkills,
        values[FIELD.SKILLS],
        'value',
      )

      const removedTags = differenceBy(
        Object.values(initialTags).flat(),
        tags,
        'value',
      )

      const skillIds = map(values[FIELD.SKILLS], 'value')
      const tagIds = map(tags, 'value')

      try {
        const changedValues = fromPairs(
          Object.entries(values)?.filter(
            ([key, value]) =>
              initialValues?.[key] !== value && value !== 'Private',
          ),
        )
        if (initialValues.email === changedValues.email) {
          changedValues.email = undefined
          delete changedValues.email
        }

        await updateCommunityUser({
          variables: {
            communityId: community?.id,
            userId: user?.userId,
            id: user?.id,
            ...values,
            ...changedValues,
            [FIELD.RELATIONSHIP]: values[FIELD.RELATIONSHIP]?.value,
          },
        })

        if (removedSkills?.length) {
          await removeUserSkills({
            variables: {
              userId: user?.userId,
              communityId: community.id,
              skillIds: map(removedSkills, 'value'),
            },
          })
        }

        if (removedTags?.length) {
          const usersFromTags = removedTags.map(tag => ({
            communityUserId: user?.communityUserId,
            tagId: tag.value,
          }))

          await disconnectUsersFromTags({
            variables: {
              communityId: community.id,
              usersFromTags,
            },
          })
        }

        if (skillIds?.length) {
          const usersToSkills = []
          skillIds.forEach(skillId => {
            usersToSkills.push({
              skillId,
              communityUserId: user?.communityUserId,
            })
          })

          await connectUsersToSkills({
            variables: {
              communityId: community?.id,
              usersToSkills,
            },
          })
        }

        if (tagIds?.length) {
          const usersToTags = []
          tagIds.forEach(tagId => {
            usersToTags.push({
              tagId,
              communityUserId: user?.communityUserId,
            })
          })

          const communityUsers = [
            {
              communityUserId: user?.communityUserId,
              userId: user?.userId,
              communityId: community?.id,
            },
          ]

          await connectUsersToTags({
            variables: {
              communityId: community?.id,
              usersToTags,
            },
            update: getCommunityUserTagsUpdater({
              communityUsers,
              tags,
            }),
          })
        }

        EventBus.trigger(EventBus.actions.graph.addUserById, {
          userId: user?.userId,
        })

        toast.success({
          title: 'Community User',
          text: `Community user updated`,
        })

        setTab(TABS.GENERAL_INFO)
        setLoading(false)

        onClose(true)
      } catch (error) {
        toast.error({
          title: 'Server error',
          text: _(`error.${error?.message || 'generic'}`),
        })
        setLoading(false)
      }
    },
    [
      initialSkills,
      initialTags,
      initialValues,
      updateCommunityUser,
      community.id,
      user?.userId,
      user?.id,
      user?.communityUserId,
      onClose,
      removeUserSkills,
      disconnectUsersFromTags,
      connectUsersToSkills,
      connectUsersToTags,
    ],
  )

  const renderForm = useCallback(
    ({ handleSubmit, form }) => {
      const formErrors = Utils.Form.errors(form, FIELD, {
        checkDirty: true,
      })

      return (
        <Modal
          cancelText={_('general.cancel')}
          confirmDisabled={loading}
          confirmText={_('general.save')}
          isOpen={isOpen}
          p={0}
          title={'Update Contact'}
          width={[0, 0, 600]}
          onClose={onClose}
          onConfirm={handleSubmit}
        >
          <Row fullWidth px={5}>
            {DEFAULT_TABS.map(item => (
              <MenuItem
                caption={item}
                isActive={item === tab}
                isButton
                key={item}
                onClick={() => setTab(item)}
              />
            ))}
          </Row>

          <Divider my={2} />

          <GeneralTab
            canEditEmail
            canEditPhone
            mb={5}
            visible={tab === TABS.GENERAL_INFO}
          />

          <TagsTab mb={5} visible={tab === TABS.TAGS} />

          {Object.values(formErrors).length > 0 && (
            <Row center justifyEnd pb={4}>
              <ErrorWrapper>* Please fill all required fields!</ErrorWrapper>
            </Row>
          )}
        </Modal>
      )
    },
    [loading, isOpen, onClose, tab],
  )

  if (!isOpen) return null

  return (
    <Form
      initialValues={initialValues}
      render={renderForm}
      validate={values => validate(values, formConstraints)}
      onSubmit={submit}
    />
  )
}

UpdateCommunityUserModal.defaultProps = {
  isOpen: false,
  user: null,
  onClose: noop,
}

UpdateCommunityUserModal.propTypes = {
  isOpen: PropTypes.bool,
  user: PropTypes.object,
  onClose: PropTypes.func,
}

export default UpdateCommunityUserModal
