import styled from 'styled-components'

import { resetButton } from 'Components/Styles'

export const IconButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;

  ${resetButton}
`

export const FormContent = styled.form``
