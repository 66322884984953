import React from 'react'

// import CreateWorkHistoryModal from 'Components/Blocks/Modals/CreateWorkHistoryModal/CreateWorkHistoryModal'
// import DeleteWorkHistoryModal from 'Components/Blocks/Modals/DeleteWorkHistoryModal/DeleteWorkHistoryModal'
// import UpdateWorkHistoryModal from 'Components/Blocks/Modals/UpdateWorkHistoryModal/UpdateWorkHistoryModal'
// import AddButton from 'Components/Blocks/UserProfile/Blocks/AddButton'
import { Column, Row } from 'Components/UI'

import { SEARCH_TYPES } from 'Constants/ids'

// import { useEntityModal } from 'Hooks'
import EventBus from 'Services/EventBus'
import { useScopedI18n } from 'Services/I18n'

import colors from 'Theme/_v2/colors'

import BlockTitle from '../BlockTitle'
import Card from '../Card'
import ShowMore from '../ShowMore'
import { IconWorkHistories } from '../styles'
import WorkHistory from '../WorkHistory/WorkHistory'

export interface WorkHistoryBoxProps {
  communityUser: MainSchema.GraphUser | MainSchema.GetCommunityUser
}

export default function WorkHistoryBox({ communityUser }: WorkHistoryBoxProps) {
  const t = useScopedI18n('components.blocks.userProfile.blocks.workHistoryBox')

  // const [deleteModal, deleteModalActions] =
  //   useEntityModal<MainSchema.CommunityUserWorkHistory>()
  // const [updateModal, updateModalActions] =
  //   useEntityModal<MainSchema.CommunityUserWorkHistory>()
  // const [createModal, createModalActions] = useEntityModal<
  //   MainSchema.GraphUser | MainSchema.GetCommunityUser
  // >()

  // const handleOpenDeleteModal = deleteModalActions.openModal
  // const handleDeleteModalClose = deleteModalActions.closeModal

  // const handleOpenUpdateModal = updateModalActions.openModal
  // const handleUpdateModalClose = updateModalActions.closeModal

  // const handleOpenCreateModal = createModalActions.openModal
  // const handleCreateModalClose = createModalActions.closeModal

  const handleView = (workHistory: MainSchema.CommunityUserWorkHistory) => {
    if (!workHistory.organization) {
      return
    }

    // TODO: refactor events so this is clear about what it's doing
    EventBus.trigger(EventBus.actions.search.community, {
      id: workHistory.organization.id,
      type: SEARCH_TYPES.organization,
      label: workHistory.organization.name,
      value: workHistory.organization,
    })
    EventBus.trigger(
      EventBus.actions.graph.focusNode,
      workHistory.organization.id,
    )
  }

  return (
    <>
      <Card>
        <Row spaceBetween>
          <BlockTitle
            icon={<IconWorkHistories color={colors.icon.profile} />}
            title={t('title')}
          />

          {/* <AddButton onClick={() => handleOpenCreateModal(communityUser)} /> */}
        </Row>

        <Column gap={1} pl={5}>
          <Column gap={2}>
            <ShowMore initialShown={2}>
              {communityUser.workHistory?.map(workHistory => (
                <WorkHistory
                  key={workHistory.id}
                  workHistory={workHistory}
                  // onDelete={() => handleOpenDeleteModal(workHistory)}
                  // onEdit={() => handleOpenUpdateModal(workHistory)}
                  onView={handleView}
                />
              ))}
            </ShowMore>
          </Column>
        </Column>
      </Card>

      {/* {deleteModal.entity && deleteModal.isOpen && (
        <DeleteWorkHistoryModal
          workHistory={deleteModal.entity}
          onClose={handleDeleteModalClose}
        />
      )}

      {updateModal.entity && updateModal.isOpen && (
        <UpdateWorkHistoryModal
          workHistory={updateModal.entity}
          onClose={handleUpdateModalClose}
        />
      )}

      {createModal.entity && createModal.isOpen && (
        <CreateWorkHistoryModal
          // user={createModal.entity}
          onClose={handleCreateModalClose}
        />
      )} */}
    </>
  )
}
