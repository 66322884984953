import React from 'react'

import { Row, Tag, Tooltip } from 'Components/UI'

import { PROPOSAL_STATUS } from 'Constants/ids'
import { TagColorKind } from 'Constants/tags'

import EventBus from 'Services/EventBus'

import { Button, ClockIcon, CloseIcon } from './styles'

export interface IProposalProps {
  name: string
  showDelete?: boolean
  status?: MainSchema.ProposalStatus
  kind: TagColorKind
  proposal: MainSchema.Proposal
  onDelete?: () => void
}

function Proposal({
  name,
  status,
  showDelete = true,
  kind,
  onDelete,
  proposal,
}: IProposalProps) {
  return (
    <Tag
      colorKind={kind}
      renderAfter={
        showDelete ? (
          <Button onClick={onDelete}>
            <CloseIcon />
          </Button>
        ) : null
      }
      renderBefore={
        status === PROPOSAL_STATUS.ACCEPTED ? (
          <Tooltip
            appendTo={document.body}
            content="Pending"
            placement="top"
            withArrow
          >
            <Row>
              <ClockIcon />
            </Row>
          </Tooltip>
        ) : null
      }
      small
      text={name}
      onClick={() => {
        EventBus.trigger(EventBus.actions.search.community, {
          id: proposal.entityId,
          type: kind,
          label: proposal.kind,
          value: proposal.value,
        })
        EventBus.trigger(EventBus.actions.graph.focusNode, proposal.entityId)
      }}
    />
  )
}

export default Proposal
