import React from 'react'

import { IconArrowBack, IconChartDots3, IconReload } from '@tabler/icons-react'

import { Column, IconButton, Row, Tooltip } from 'Components/UI'

import _ from 'Services/I18n'

import { ButtonContainer, Container, GraphLoader } from './styles'
import useControlHandlers from './useControlHandlers'

import { NetworkMagicContext, ResetContext } from '../ContextMenu'

export interface IMobile {
  graphControls?: {
    reset?: boolean
    options?: boolean
    myNetwork?: boolean
    search?: boolean
    browse?: boolean
  }
  isSubGraph?: boolean
  loading?: boolean
}

function Mobile({ graphControls, isSubGraph, loading }: IMobile) {
  const {
    setShowResetMenu,
    setShowNetworkMenu,
    showResetMenu,
    showNetworkMenu,
    contextResetMenuRef,
    contextNetworkMenuRef,
    handleExitSubGraph,
    handleResetGraph,
    handleLayoutGraph,
    handleShowNetwork,
    handleShowAllTags,
    handleShowAllUsers,
    handleSetAnalyzer,
    handleSetLayout,
  } = useControlHandlers()

  // TODO: Make showing controls and position of controls more customizable in next iteration
  return (
    <Container isMobile>
      <Row center fullWidth gap={2}>
        {graphControls?.myNetwork && (
          <ButtonContainer>
            <Tooltip content={_('tips.networkOptions')}>
              <IconButton
                small
                tertiary
                onClick={() => setShowNetworkMenu(true)}
              >
                <IconChartDots3 />
              </IconButton>
            </Tooltip>

            <NetworkMagicContext
              isOpen={showNetworkMenu}
              left={0}
              ref={contextNetworkMenuRef}
              top={10}
              onSetAnalyzer={handleSetAnalyzer}
              onSetLayout={handleSetLayout}
              onShowAllTags={handleShowAllTags}
              onShowAllUsers={handleShowAllUsers}
              onShowNetwork={handleShowNetwork}
            />
          </ButtonContainer>
        )}

        {graphControls?.reset && (
          <ButtonContainer>
            <Tooltip content={_('tips.graphReset')}>
              <IconButton small tertiary onClick={() => setShowResetMenu(true)}>
                <IconReload />
              </IconButton>
            </Tooltip>
            <ResetContext
              isOpen={showResetMenu}
              left={0}
              ref={contextResetMenuRef}
              top={10}
              onLayoutGraph={handleResetGraph}
              onResetGraph={handleLayoutGraph}
            />
          </ButtonContainer>
        )}

        {isSubGraph && (
          <ButtonContainer>
            <Tooltip content="Exit Subgraph">
              <IconButton small tertiary onClick={handleExitSubGraph}>
                <IconArrowBack />
              </IconButton>
            </Tooltip>
          </ButtonContainer>
        )}

        {loading && (
          <Column>
            <GraphLoader size={24} />
          </Column>
        )}
      </Row>
    </Container>
  )
}

export default Mobile
