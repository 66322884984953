import React, { PropsWithChildren, useCallback, useState } from 'react'

import { pick } from '@styled-system/props'

import { Loader, Row } from 'Components/UI'

import * as Styled from './Action.styles'

import AddButton from '../AddButton'

export interface IAction extends PropsWithChildren {
  disabled?: boolean
  isLoading?: boolean
  showOnlyTitle?: boolean
  title?: React.ReactNode
  onCancel?: () => void
  onSave: () => Promise<void>
}

function Action({
  children,
  disabled,
  title,
  isLoading,
  showOnlyTitle,
  onCancel,
  onSave,
  ...rest
}: IAction) {
  const [isOpen, setOpen] = useState(false)

  const handleCancel = useCallback(() => {
    setOpen(false)
    onCancel?.()
  }, [onCancel])

  const handleSave = useCallback(async () => {
    await onSave()
    setOpen(false)
  }, [onSave])

  return (
    <Styled.Container {...pick(rest)}>
      <Row center spaceBetween>
        {title}

        {!showOnlyTitle && (
          <Row>
            {!isOpen && <AddButton onClick={() => setOpen(true)} />}

            {isOpen && (
              <Row center gap={4}>
                <Styled.ActionButton
                  disabled={isLoading}
                  secondary
                  onClick={handleCancel}
                >
                  Cancel
                </Styled.ActionButton>
                <Styled.ActionButton
                  disabled={disabled || isLoading}
                  onClick={handleSave}
                >
                  {isLoading ? <Loader /> : 'Save'}
                </Styled.ActionButton>
              </Row>
            )}
          </Row>
        )}
      </Row>

      {isOpen && <Row fullWidth>{children}</Row>}
    </Styled.Container>
  )
}

export default Action
