import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { useQuery } from '@apollo/client'
import sharedNotesByNoteIdQuery from 'GraphQL/Queries/Notes/sharedNotesByNoteId.graphql'
import { getFullName } from 'Utils/User'

import noop from 'lodash/noop'

import { Input, Modal, Pagination, Table, Text } from 'Components/UI'

import { useEntityModal, useEntityTable, useTableSearch } from 'Hooks'

import _, { useScopedI18n } from 'Services/I18n'

import { useColumns } from './columns'
import TableContext from './context'
import { Container } from './styles'

const DEFAULT_PAGE_SIZE = 10

function ShareNotesTable({
  noteId,
  deleteUserIds,
  onDeleteUser,
  onChangeAccess,
}) {
  const s = useScopedI18n('modals.shareNote.table')
  const [deleteModal, deleteActions] = useEntityModal()

  const [mostRecentPage, setMostRecentPage] = useState(0)
  const [mostRecentLimit, setMostRecentLimit] = useState(DEFAULT_PAGE_SIZE)

  const { data, loading, refetch } = useQuery(sharedNotesByNoteIdQuery, {
    variables: {
      noteId,
      page: mostRecentPage,
      limit: mostRecentLimit,
    },
    skip: !noteId,
    fetchPolicy: 'network-only',
  })

  const columns = useColumns()
  const entities = useMemo(() => data?.sharedNotesByNoteId, [data])

  const [tableProps, rowsCount] = useEntityTable({
    data: entities,
    columns,
    disableSortBy: true,
    deleteUserIds,
  })

  const { pageSize, pageIndex } = tableProps.state

  useEffect(() => {
    setMostRecentPage(tableProps.state.pageIndex)
  }, [tableProps.state.pageIndex])

  useEffect(() => {
    setMostRecentPage(0)
    setMostRecentLimit(tableProps.state.pageSize)
  }, [tableProps.state.pageSize])

  const [search, changeSearch] = useTableSearch({
    onSearch: value => {
      tableProps.gotoPage(0)

      refetch({
        limit: mostRecentLimit,
        search: value || undefined,
      }).then()
    },
  })

  const handleDeleteModalClose = deleteActions.closeModal

  const handleDelete = useCallback(
    success => {
      if (!success) {
        return
      }
      onDeleteUser(deleteModal.entity.id)
      handleDeleteModalClose()
    },
    [deleteModal, handleDeleteModalClose, onDeleteUser],
  )

  const { deleteMessage, confirmButtonText, modalTitle } = useMemo(() => {
    const isDelete = !deleteUserIds?.includes(deleteModal?.entity?.id)

    return isDelete
      ? {
          deleteMessage: (
            <Text>
              {s('messages.deleteUser', {
                userName: getFullName(deleteModal?.entity?.recipient?.profile),
              })}
            </Text>
          ),
          confirmButtonText: 'Delete',
          modalTitle: s('actions.deleteUser'),
        }
      : {
          deleteMessage: (
            <Text>
              {s('messages.deleteUser', {
                userName: getFullName(deleteModal?.entity?.recipient?.profile),
              })}
            </Text>
          ),
          confirmButtonText: 'Restore',
          modalTitle: s('actions.restoreUser'),
        }
  }, [deleteModal, deleteUserIds, s])

  const memoizedContext = useMemo(
    () => ({ onDeleteRow: deleteActions.openModal, onChangeAccess }),
    [deleteActions.openModal, onChangeAccess],
  )

  return (
    <TableContext.Provider value={memoizedContext}>
      <Input
        label={_('search.byName')}
        mb={4}
        name="search"
        placeholder="Search by users"
        value={search}
        onChange={changeSearch}
        onClear={changeSearch}
      />

      <Container>
        <Table {...tableProps} loading={loading} />
      </Container>

      <Pagination
        showTotal={false}
        state={{ pageIndex, pageSize }}
        total={rowsCount}
        onPageIndexChange={tableProps?.gotoPage}
        onPageSizeChange={tableProps?.setPageSize}
      />

      <Modal
        cancelText={_('general.cancel')}
        confirmText={confirmButtonText}
        isOpen={deleteModal.isOpen}
        title={modalTitle}
        onClose={handleDeleteModalClose}
        onConfirm={handleDelete}
      >
        {deleteMessage}
      </Modal>
    </TableContext.Provider>
  )
}

ShareNotesTable.defaultProps = {
  deleteUserIds: [],
  noteId: null,
  onChangeAccess: noop,
  onDeleteUser: noop,
}

ShareNotesTable.propTypes = {
  deleteUserIds: PropTypes.array,
  noteId: PropTypes.string,
  onChangeAccess: PropTypes.func,
  onDeleteUser: PropTypes.func,
}
export default ShareNotesTable
