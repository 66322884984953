import React from 'react'

import { Row } from 'Components/UI'
import { Text } from 'Components/UI/_v2'

import colors from 'Theme/_v2/colors'

export interface BlockTitleProps {
  icon: React.ReactNode
  title: string
}

export default function BlockTitle({ icon, title }: BlockTitleProps) {
  return (
    <Row alignItems={'center'} gap={2}>
      {icon}
      <Text
        color={colors.text.primary}
        fontSize={'12px'}
        fontWeight={600}
        lineHeight={'16px'}
      >
        {title}
      </Text>
    </Row>
  )
}
