import { getAuth } from 'Services/Store/auth'

const apiUrlProtocol =
  process.env.REACT_APP_API_SSL === 'true' ? 'https' : 'http'
const apiUrl = `${apiUrlProtocol}://${process.env.REACT_APP_API_URL}`

export default async function fetchWithAuth<T>(
  route: string,
  options: RequestInit = {},
) {
  const token = getAuth()?.accessToken
  const headers = {
    ...options.headers,
    Authorization: token ? `Bearer ${token}` : '',
    'Content-Type': 'application/json',
  }

  const response = await fetch(`${apiUrl}${route}`, {
    ...options,
    headers,
  })

  if (!response.ok) {
    throw new Error('Network response was not ok')
  }

  return response.json() as Promise<T>
}
