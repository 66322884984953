import { down } from 'styled-breakpoints'
import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { customScrollbar } from 'Theme/styles'

export interface IScrollableMobileCollapsedCssProps {
  collapsed?: boolean
}

const scrollableMobileCollapsedCss = ({
  collapsed,
}: IScrollableMobileCollapsedCssProps) =>
  collapsed &&
  css`
    height: calc(100vh - 410px);
    flex-grow: 0;
  `

export const CloseContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`

export const NameHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: ${themeGet('space.3')}px ${themeGet('space.5')}px;
  border-bottom: 1px solid ${themeGet('colors.divider.default')};
  position: relative;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding-inline: ${themeGet('space.3')}px;
  padding-bottom: ${themeGet('space.3')}px;
`

export interface IScrollableContainerProps
  extends IScrollableMobileCollapsedCssProps {}

export const ScrollableContainer = styled.div<IScrollableContainerProps>`
  height: 100%;
  margin-right: -12px;
  padding-right: 12px;
  overflow: scroll;

  ${customScrollbar}

  ${down('md')} {
    flex-grow: 1;
    flex-direction: column;
    width: 100%;
    height: unset;

    ${scrollableMobileCollapsedCss};
  }
`

export const TitleHolder = styled.div`
  display: flex;
  align-items: center;
  gap: ${themeGet('space.1')}px;
  color: ${themeGet('colors.text.header')};
`
