import React from 'react'
import PropTypes from 'prop-types'

import { Checkbox } from 'Components/UI'

import { useTableContext } from '../context'

function SelectCell({ row }) {
  const { onSelectRow, selectedRowIds } = useTableContext()

  const recommendation = row?.original
  const isChecked = selectedRowIds.includes(recommendation?.id)

  return (
    <Checkbox
      checked={isChecked}
      rounded
      secondary
      small
      onChange={() => onSelectRow(recommendation)}
    />
  )
}

SelectCell.defaultProps = {
  row: {},
}

SelectCell.propTypes = {
  row: PropTypes.object,
}

export default SelectCell
