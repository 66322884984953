import { useMemo } from 'react'

import userSurveyQuery from 'GraphQL/Queries/User/userSurvey.graphql'

import { useQuery } from 'Services/Apollo'

import useCommunity from './useCommunity'

export default function useSurvey() {
  const { community } = useCommunity()

  const { data, loading, error } = useQuery<
    Pick<MainSchema.Query, 'userSurvey'>,
    MainSchema.QueryUserSurveyArgs
  >(userSurveyQuery, {
    variables: { communityId: community?.id },
  })

  return useMemo(
    () => ({ survey: data?.userSurvey, loading, error }),
    [data, loading, error],
  )
}
