import { ApolloCache, MutationUpdaterFn } from '@apollo/client'
import GraphUserFragment from 'GraphQL/Fragments/Graph/User.graphql'
import communitySearchUsersQuery from 'GraphQL/Queries/Community/communitySearchUsers.graphql'
import communityUserDirectConnectionsQuery from 'GraphQL/Queries/Community/communityUserDirectConnections.graphql'
import { ITagOption } from 'Utils/Options'

export function updateCommunityUsersUpdater(
  communityId: string,
  store: ApolloCache<Pick<MainSchema.Mutation, 'createUserStub'>>,
  appendUser?: MainSchema.GraphUser,
) {
  const query = {
    query: communitySearchUsersQuery,
    variables: {
      communityId,
      limit: 9999,
    },
  }

  const queryData = store.readQuery<
    Pick<MainSchema.Query, 'communitySearch'>,
    MainSchema.QueryCommunitySearchArgs
  >(query)

  if (queryData) {
    const updatedUsers = queryData.communitySearch.users

    if (appendUser) {
      updatedUsers.unshift(appendUser)
    }

    store.writeQuery<
      Pick<MainSchema.Query, 'communitySearch'>,
      MainSchema.QueryCommunitySearchArgs
    >({
      ...query,
      data: {
        communitySearch: {
          ...queryData.communitySearch,
          users: updatedUsers,
        },
      },
    })
  }
}

export function updateCommunityUserDirectConnectionsUpdater(
  communityId: string,
  userId: string,
  store: ApolloCache<
    | Pick<MainSchema.Mutation, 'createCommunityUser'>
    | Pick<MainSchema.Mutation, 'communityConnectUsers'>
  >,
  graphUser?: MainSchema.GraphUser,
) {
  const query = {
    query: communityUserDirectConnectionsQuery,
    variables: {
      communityId,
      userId,
    },
  }
  const queryData = store.readQuery<
    Pick<MainSchema.Query, 'communityUserDirectConnections'>,
    MainSchema.QueryCommunityUserDirectConnectionsArgs
  >(query)
  if (queryData && graphUser) {
    store.writeQuery<
      Pick<MainSchema.Query, 'communityUserDirectConnections'>,
      MainSchema.QueryCommunityUserDirectConnectionsArgs
    >({
      ...query,
      data: {
        communityUserDirectConnections: [
          ...queryData.communityUserDirectConnections,
          graphUser,
        ],
      },
    })
  }
}

export function updateUserProfileAdditionalInfo({
  userId,
  communityId,
  skills,
  needSkills,
  tags,
  interestsHobbies,
}: {
  userId: string
  communityId: string
  skills: ITagOption[]
  needSkills: ITagOption[]
  tags: ITagOption[]
  interestsHobbies?: string
}): MutationUpdaterFn<
  Pick<MainSchema.Mutation, 'updateProfileAdditionalInfo'>
> {
  return function updater(store) {
    const fragment = {
      id: `GraphUser:${userId}`,
      fragmentName: 'GraphUserFields',
      fragment: GraphUserFragment,
      variables: { communityId },
    }

    // TODO: add type
    const fragmentData = store.readFragment(fragment)

    if (fragmentData) {
      // TODO: add type
      store.writeFragment({
        ...fragment,
        data: {
          ...fragmentData,
          interestsHobbies,
          skills: skills?.map(skill => ({
            name: skill.name,
            id: skill.id,
          })),
          needSkills: needSkills?.map(skill => ({
            name: skill.name,
            id: skill.id,
          })),
          tags: tags?.map(tag => ({
            name: tag.name,
            id: tag.id,
            kind: tag.kind,
          })),
        },
      })
    }
  }
}
